import { isNil } from 'ramda';
import { TAuthStorage } from '~/features/auth/types/auth-storage.type';
import { parseJwt } from '~/utils/parse-jwt';
import { IParsedToken } from '~/features/auth/interfaces/parsed-token.interface';
import { ETokenFlag } from '~/features/auth/enums/token-flag.enum';

export const setUserParamsFromToken = ($storage: TAuthStorage, token?: string): void => {
  const parsedToken = isNil(token) ? undefined : parseJwt<IParsedToken>(token);
  $storage.setUniversal('memberships', parsedToken?.user.memberships);
  $storage.setUniversal('roles', parsedToken?.user.roles);
  $storage.setUniversal('permissions', parsedToken?.user.permissions);
  $storage.setUniversal('onBehalfOfMode', parsedToken?.user.onBehalfOfMode);

  $storage.setUniversal('isPurchaser', !!parsedToken?.user.isPurchaser);
  $storage.setUniversal('hasFailedPayments', !!parsedToken?.user.hasFailedPayments);
  $storage.setUniversal('artClubRank', parsedToken?.user.artClubRank);

  $storage.setUniversal(
    'isOnboardingPassed',
    isNil(token) ? false : !parsedToken?.user.flags?.includes(ETokenFlag.NotPassedOnboarding),
  );
  $storage.setUniversal(
    'isEmailChangedPendingVerification',
    isNil(token)
      ? false
      : parsedToken?.user.flags?.includes(ETokenFlag.EmailChangedPendingVerification) || false,
  );
  $storage.setUniversal(
    'isFirstEmailVerified',
    isNil(token) ? false : !parsedToken?.user.flags?.includes(ETokenFlag.EmailNotVerified),
  );
};
