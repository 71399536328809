import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { find } from 'ramda';
import { IContestForUserDetailed } from '~/features/contests/interfaces/contest.interface';
import { EContestStatus } from '~/features/contests/enums/contest-status.enum';
import { IGetUserVotesResponse } from '~/api/interfaces/art-club-contests-user.interface';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IPrizeCategoryForDetailedContest } from '~/features/contests/interfaces/prize-category.interface';
import { IFileExtras } from '~/features/files/interfaces/file.interface';

export interface IContestState {
  contest: IContestForUserDetailed;
  votes: IGetUserVotesResponse;
}

const getDefaultState = (): IContestState => ({
  contest: {
    id: '',
    name: '',
    description: '',
    image: null,
    startedAt: '',
    votingUntil: '',
    submissionPossibleUntil: '',
    submissionsCount: 0,
    isSubmitted: false,
    mySubmissionId: '',
    requirementsText: null,
    requirementsSize: null,
    requirementsMediums: null,
    requirementsMedia: null,
    status: EContestStatus.NotStarted,
    relatedContent: [],
    prizeCategories: [],
  },
  votes: {
    total: 0,
    used: 0,
    remaining: 0,
  },
});

export const state = (): IContestState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getContest(ctx, contestId: string) {
    const contest = await this.$api.artClubContestsUser.getContestDetailsById(contestId);
    ctx.commit('setContest', contest);

    if (this.$auth.loggedIn) await ctx.dispatch('getVotes');
  },

  async getVotes(ctx) {
    const votes = await this.$api.artClubContestsUser.getUserVotesCount(ctx.state.contest.id);
    ctx.commit('setVotes', votes);
  },
};

export const mutations: MutationTree<RootState> = {
  setContest(state, contest: IContestForUserDetailed) {
    state.contest = contest;
  },

  setIsSubmitted(state, isSubmitted: boolean) {
    state.contest.isSubmitted = isSubmitted;
  },

  setVotes(state, votes: IGetUserVotesResponse) {
    state.votes = votes;
  },

  changeRelatedVideoStatus(state, videoId: string) {
    const video = find((v) => v.id === videoId, state.contest.relatedContent) as IArtClubUserVideo;

    video.isSaved = !video.isSaved;
  },
};

export const getters: GetterTree<IContestState, RootState> = {
  isSubmissionStatus(state) {
    return state.contest.status === EContestStatus.Submission;
  },
  isVotingStatus(state) {
    return state.contest.status === EContestStatus.Voting;
  },
  isCountingVotesStatus(state) {
    return state.contest.status === EContestStatus.CountingVotes;
  },
  isClosedStatus(state) {
    return state.contest.status === EContestStatus.Closed;
  },
  imageUrl(state): string | undefined {
    return state.contest.image?.url;
  },
  submissionPossibleUntil(state): string {
    return state.contest.submissionPossibleUntil;
  },
  votingUntil(state): string {
    return state.contest.votingUntil;
  },
  description(state): string {
    return state.contest.description;
  },
  name(state): string {
    return state.contest.name;
  },
  contestId(state): string {
    return state.contest.id;
  },
  isSubmitted(state): boolean {
    return state.contest.isSubmitted;
  },
  submissionsCount(state): number {
    return state.contest.submissionsCount;
  },
  votedCount(state): number {
    return state.votes.used;
  },
  votesRemaining(state): number {
    return state.votes.remaining;
  },
  relatedContent(state): IArtClubUserVideo[] {
    return state.contest.relatedContent;
  },
  prizeCategories(state): IPrizeCategoryForDetailedContest[] {
    return state.contest.prizeCategories;
  },
  requirementsSize(state): string | null {
    return state.contest.requirementsSize;
  },
  requirementsMediums(state): string | null {
    return state.contest.requirementsMediums;
  },
  requirementsMedia(state): string | null {
    return state.contest.requirementsMedia;
  },
  requirementsText(state): string | null {
    return state.contest.requirementsText;
  },
  contestImageExtras(state): IFileExtras | undefined {
    return state.contest.image?.extras;
  },
};
