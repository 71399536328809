import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IBlogApi } from '~/api/interfaces/blog.interface';
import {
  IItemsListResponse,
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IBlogListItemForUser } from '~/features/blog/interfaces/blog-list-item.interface';
import { IBlogItemForUser } from '~/features/blog/interfaces/blog-item.interface';

export default ($axios: NuxtAxiosInstance): IBlogApi => ({
  getPostsList(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IBlogListItemForUser>> {
    return $axios.$get('/blog/getPostsList', { params });
  },

  getPostListItemById(id: string): Promise<IBlogListItemForUser> {
    return $axios.$get('/blog/getPostListItemById', { params: { id }, localError: true });
  },

  getPostBySlug(slug: string): Promise<IBlogItemForUser> {
    return $axios.$get('/blog/getPostBySlug', { params: { slug }, localError: true });
  },

  getPostById(id: string): Promise<IBlogItemForUser> {
    return $axios.$get('/blog/getPostById', { params: { id }, localError: true });
  },

  getPostRecommendationsList(currentPostId: string): Promise<IItemsListResponse<IBlogItemForUser>> {
    return $axios.$get('/blog/getPostRecommendationsList', {
      params: { currentPostId, count: 3 },
    });
  },
});
