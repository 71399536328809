


























import { defineComponent } from '@nuxtjs/composition-api';
import UiModal from '~/shared/components/modals/UiModal.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import { MILAN_ART_APPLE_URL, MILAN_ART_GOOGLE_URL } from '~/shared/constants';

export default defineComponent({
  name: 'MobileAppModal',
  components: { UiButton, UiModal },
  methods: {
    show(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).show();
    },

    openLinkToApp(): void {
      window.open(`milanart-app://${this.$route.path.slice(1)}`, '_self');
    },

    openInstallLink(): void {
      window.open(this.$device.isIos ? MILAN_ART_APPLE_URL : MILAN_ART_GOOGLE_URL, '_self');
    },
  },
});
