const middleware = {}

middleware['admin'] = require('../middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['art-club'] = require('../middleware/art-club.ts')
middleware['art-club'] = middleware['art-club'].default || middleware['art-club']

middleware['check-page-meta'] = require('../middleware/check-page-meta.ts')
middleware['check-page-meta'] = middleware['check-page-meta'].default || middleware['check-page-meta']

middleware['get-has-user-joined-any-communities'] = require('../middleware/get-has-user-joined-any-communities.ts')
middleware['get-has-user-joined-any-communities'] = middleware['get-has-user-joined-any-communities'].default || middleware['get-has-user-joined-any-communities']

middleware['get-is-current-user-profile-page'] = require('../middleware/get-is-current-user-profile-page.ts')
middleware['get-is-current-user-profile-page'] = middleware['get-is-current-user-profile-page'].default || middleware['get-is-current-user-profile-page']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['static-pages-last-modified'] = require('../middleware/static-pages-last-modified.ts')
middleware['static-pages-last-modified'] = middleware['static-pages-last-modified'].default || middleware['static-pages-last-modified']

middleware['thank-you-page'] = require('../middleware/thank-you-page.ts')
middleware['thank-you-page'] = middleware['thank-you-page'].default || middleware['thank-you-page']

middleware['user-ip-and-referer'] = require('../middleware/user-ip-and-referer.ts')
middleware['user-ip-and-referer'] = middleware['user-ip-and-referer'].default || middleware['user-ip-and-referer']

export default middleware
