import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IBannerForUser } from '~/features/banners/interfaces/banner.interface';
import { IBannerApi } from '~/api/interfaces/banner.interface';

export default ($axios: NuxtAxiosInstance): IBannerApi => ({
  getUserBanners(): Promise<IItemsListResponse<IBannerForUser>> {
    return $axios.$get('/promo/banners/getUserBanners');
  },
});
