import { isNil } from 'ramda';
import { IApi } from '~/api/interfaces/api.interface';
import { makeTimeout } from '~/utils/make-timeout';

export const generateUniqueSlugWithCounter = async (
  slug: string,
  $api: IApi,
  previousCounter?: number,
): Promise<string> => {
  const counter = isNil(previousCounter) ? 0 : previousCounter + 1;
  const newSlug = counter ? `${slug}-${counter}` : slug;

  const { isAvailable } = await $api.blogAdmin.checkIsBlogSlugAvailable(newSlug);

  if (isAvailable) return newSlug;

  // To prevent 429 error code
  await makeTimeout(250);

  return generateUniqueSlugWithCounter(slug, $api, counter);
};
