import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetPaymentsStatisticsResponse,
  IGetStripeProfileLink,
  IGetUserStatisticsResponse,
  IUserCrmInfoApi,
} from '~/api/interfaces/user-crm-info.interface';
import { IUserProfileForCrmInfo } from '~/features/users/interfaces/users.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IUserRole } from '~/features/users/interfaces/user-role.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmInfoApi => ({
  getUserInfo(userId: string): Promise<IUserProfileForCrmInfo> {
    return $axios.$get('/users/admin/crm/userInfo/getUserInfo', { params: { userId } });
  },

  getUserStatistics(userId: string): Promise<IGetUserStatisticsResponse> {
    return $axios.$get('/users/admin/crm/userInfo/getUserStatistics', { params: { userId } });
  },

  getPaymentsStatistics(userId: string): Promise<IGetPaymentsStatisticsResponse> {
    return $axios.$get('/users/admin/crm/userInfo/getPaymentsStatistics', { params: { userId } });
  },

  getAllUserRoles(userId: string): Promise<IItemsListResponse<IUserRole>> {
    return $axios.$get('/users/admin/crm/userInfo/getAllUserRoles', { params: { userId } });
  },

  getStripeProfileLink(userId: string): Promise<IGetStripeProfileLink> {
    return $axios.$get('/users/admin/crm/userInfo/getStripeProfileLink', { params: { userId } });
  },
});
