import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IFollowersApi,
  IGetFollowersParams,
  IGetFollowSuggestionsParams,
  IGetFollowSuggestionsResponse,
} from './interfaces/followers.interface';
import {
  IItemsListRequestParams,
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IFollower } from '~/features/followers/interface/follower.interface';
import { IFollowSuggestionWithMutual } from '~/features/followers/interface/follow-suggestion.interface';
import { IUserProfileShortWithMutual } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): IFollowersApi => ({
  followUser(userId: string): Promise<void> {
    return $axios.$post('/users/followUser', { userId });
  },

  unfollowUser(userId: string): Promise<void> {
    return $axios.$post('/users/unfollowUser', { userId });
  },

  getFollowers(params: IGetFollowersParams): Promise<IItemsListWithTotalResponse<IFollower>> {
    return $axios.$get('/users/getFollowers', { params });
  },

  getFollowedUsers(params: IGetFollowersParams): Promise<IItemsListWithTotalResponse<IFollower>> {
    return $axios.$get('/users/getFollowedUsers', { params });
  },

  getMyFollowers(params: IItemsListRequestParams): Promise<IItemsListWithTotalResponse<IFollower>> {
    return $axios.$get('/users/getMyFollowers', { params });
  },

  getMyFollowedUsers(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IFollower>> {
    return $axios.$get('/users/getMyFollowedUsers', { params });
  },

  getFollowSuggestions(
    params: IGetFollowSuggestionsParams,
  ): Promise<IGetFollowSuggestionsResponse> {
    return $axios.$get('/users/getFollowSuggestions', { params });
  },

  getFollowSuggestionsWithMutual(
    count: number,
  ): Promise<IItemsListResponse<IFollowSuggestionWithMutual>> {
    return $axios.$get('/users/getFollowSuggestionsWithMutual', {
      params: { count },
    });
  },

  getMutualFollowers(
    params: IGetFollowersParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShortWithMutual>> {
    return $axios.$get('/users/getMutualFollowersById', { params, localError: true });
  },

  getFollowSuggestionsWithMutualByExactIds(
    userIds: string[],
  ): Promise<IItemsListResponse<IFollowSuggestionWithMutual>> {
    return $axios.$post('/users/getFollowSuggestionsWithMutualByExactIds', {
      userIds,
    });
  },
});
