import { EFileType } from '~/features/files/enums/file-type.enum';
import { IFile } from '~/features/files/interfaces/file.interface';

export const getDefaultFileState = (type = EFileType.Image): IFile => {
  return {
    id: '',
    filename: '',
    fileSize: '',
    type,
    url: '',
    owner: '',
  };
};
