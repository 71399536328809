import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { IMentorForUser } from '~/features/mentoring/interfaces/mentor.interface';
import { EMentorStatus } from '~/features/mentoring/enums/mentor-status.enum';

export interface IMentoringState {
  mentor: IMentorForUser | null;
  status: EMentorStatus | null;
  isFirstTimePassed: boolean | null;
  isInitialPopoverShown: boolean;
  communityId: string;
}

const getDefaultState = (): IMentoringState => ({
  mentor: null,
  status: EMentorStatus.Pending,
  isFirstTimePassed: false,
  isInitialPopoverShown: false,
  communityId: '',
});

export const state = (): IMentoringState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getMentoringInfo(ctx): Promise<void> {
    const response = await this.$api.mentoringStudents.getMentoringInfo();

    ctx.commit('setMentor', response?.mentor || null);
    ctx.commit('setStatus', response?.status || EMentorStatus.Pending);
    ctx.commit('setIsFirstTimePassed', response?.isFirstTimePassed || false);
    ctx.commit('setCommunityId', response?.communityId || '');
  },

  async setIsFirstTimePassed(ctx): Promise<void> {
    await this.$api.mentoringStudents.setFirstTimePassed();
    await ctx.dispatch('getMentoringInfo');
  },
};

export const mutations: MutationTree<RootState> = {
  setMentor(state, mentor) {
    state.mentor = mentor;
  },

  setStatus(state, status) {
    state.status = status;
  },

  setIsFirstTimePassed(state, isFirstTimePassed) {
    state.isFirstTimePassed = isFirstTimePassed;
  },

  setIsInitialPopoverShown(state) {
    state.isInitialPopoverShown = true;
  },

  setCommunityId(state, communityId) {
    state.communityId = communityId;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IMentoringState, RootState> = {
  isMentorStatusPending(state): boolean {
    return state.status === EMentorStatus.Pending;
  },

  isMentorStatusAssigned(state): boolean {
    return state.status === EMentorStatus.Assigned;
  },

  isMentorStatusFailed(state): boolean {
    return state.status === EMentorStatus.PaymentFailed;
  },
};
