import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { IGetModuleContentsParams } from '~/api/interfaces/user-courses.interface';
import { IUserModule } from '~/features/module/interfaces/module.interface';
import { IUserLessonShort } from '~/features/lesson/interfaces/lesson.interface';

export interface IModuleState {
  module: IUserModule;
}

const getDefaultState = (): IModuleState => {
  return {
    module: {
      courseId: '',
      id: '',
      title: '',
      availableFrom: '',
      lessons: [],
      isSingleModuleInCourse: false,
      prevModule: {
        id: '',
        title: '',
        firstLessonId: '',
        availableFrom: '',
      },
      nextModule: {
        id: '',
        title: '',
        firstLessonId: '',
        availableFrom: '',
      },
    },
  };
};

export const state = (): IModuleState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getModuleContents(ctx, payload: IGetModuleContentsParams) {
    const response = await this.$api.userCourses.getModuleContents(payload);

    ctx.commit('setModule', response);
  },
};

export const mutations: MutationTree<RootState> = {
  setModule(state, module) {
    state.module = module;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IModuleState, RootState> = {
  getNextLessonId: (state) => (currentLessonId: string): string | undefined => {
    const currentLessonIndex = (state.module.lessons as IUserLessonShort[]).findIndex(
      (i) => i.id === currentLessonId,
    );

    return state.module.lessons?.[currentLessonIndex + 1]?.id;
  },

  getPrevLessonId: (state) => (currentLessonId: string): string | undefined => {
    const currentLessonIndex = (state.module.lessons as IUserLessonShort[]).findIndex(
      (i) => i.id === currentLessonId,
    );

    return state.module.lessons?.[currentLessonIndex - 1]?.id;
  },
};
