import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IBannerAdmin,
  ICreateBannerPayload,
  IUpdateBannerPayload,
} from '~/api/interfaces/banner-admin.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import {
  IBannerForAdmin,
  IBannerListItemForAdmin,
} from '~/features/banners/interfaces/banner.interface';

export default ($axios: NuxtAxiosInstance): IBannerAdmin => ({
  getBannersList(query: string): Promise<IItemsListResponse<IBannerListItemForAdmin>> {
    return $axios.$get('/promo/banners/admin/crm/getBannersList', { params: { query } });
  },

  getBannerListItemById(id: string): Promise<IBannerListItemForAdmin> {
    return $axios.$get('/promo/banners/admin/crm/getBannerListItemById', { params: { id } });
  },

  getBannerById(id: string): Promise<IBannerForAdmin> {
    return $axios.$get('/promo/banners/admin/crm/getBannerById', { params: { id } });
  },

  createBanner(payload: ICreateBannerPayload): Promise<{ id: string }> {
    return $axios.$post('/promo/banners/admin/crm/createBanner', payload, { localError: true });
  },

  updateBanner(payload: IUpdateBannerPayload): Promise<void> {
    return $axios.$post('/promo/banners/admin/crm/updateBanner', payload);
  },

  publishBanner(id: string): Promise<void> {
    return $axios.$post('/promo/banners/admin/crm/publishBanner', { id }, { localError: true });
  },

  unpublishBanner(id: string): Promise<void> {
    return $axios.$post('/promo/banners/admin/crm/unpublishBanner', { id }, { localError: true });
  },

  deleteBanner(id: string): Promise<void> {
    return $axios.$post('/promo/banners/admin/crm/deleteBanner', { id }, { localError: true });
  },
});
