import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { TAuthUser } from '~/features/auth/types/auth-user.type';

export default defineNuxtPlugin(({ $auth, $sentry }) => {
  if (!$auth.loggedIn) return;

  $sentry.setUser({
    id: ($auth.user as TAuthUser).id,
    username: ($auth.user as TAuthUser).username,
    email: ($auth.user as TAuthUser).email,
    fullName: `${($auth.user as TAuthUser).firstName} ${($auth.user as TAuthUser).lastName}`,
  });
});
