export enum ESignUpFlow {
  Default = 'default',
  Modal = 'modal',
  ArtClub = 'art-club',
  ArtClubPlus = 'art-club-plus',
  ArtClubPro = 'art-club-pro',
  WorkshopRegistration = 'workshop-registration',
  MpOrientationCourse = 'mp-orientation-course',
  MasteryStudentMentoringPlus = 'mastery-student-mentoring-plus',
  MasteryStudentMentoringPro = 'mastery-student-mentoring-pro',
  MasteryGraduateMentoringPlus = 'mastery-graduate-mentoring-plus',
  MasteryGraduateMentoringPro = 'mastery-graduate-mentoring-pro',
}
