import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetOverviewResponse,
  ISetVideoReactionPayload,
  ISetVideoReactionResponse,
  ISetVideoSavedPayload,
  IStartVideoSessionPayload,
  IUpdateVideoSession,
} from '~/api/interfaces/art-club-videos.interface';
import { IArtClubUserSingleVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IArtClubCategory } from '~/features/art-club/interfaces/art-club-category.interface';
import { IRating } from '~/features/art-club/interfaces/rating.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getOverview(): Promise<IGetOverviewResponse> {
    return $axios.$get('/artClub/media/getOverview');
  },

  getVideoDetails(id: string): Promise<IArtClubUserSingleVideo> {
    return $axios.$get('/artClub/media/getVideoDetails', { params: { id } });
  },

  getVideoRating(id: string): Promise<IRating> {
    return $axios.$get('/artClub/media/getVideoRating', { params: { id } });
  },

  setVideoReaction(payload: ISetVideoReactionPayload): Promise<ISetVideoReactionResponse> {
    return $axios.$post('/artClub/media/setVideoReaction', payload);
  },

  setVideoSaved(payload: ISetVideoSavedPayload): Promise<void> {
    return $axios.$post('/artClub/media/setVideoSaved', payload);
  },

  startVideoSession(payload: IStartVideoSessionPayload): Promise<IUpdateVideoSession> {
    return $axios.$post('/artClub/media/startVideoSession', payload);
  },

  updateVideoSession(payload: IUpdateVideoSession): Promise<IUpdateVideoSession> {
    return $axios.$post('/artClub/media/updateVideoSession', payload);
  },

  getCategories(): Promise<IItemsListResponse<IArtClubCategory>> {
    return $axios.$get('/artClub/media/getCategories');
  },
});
