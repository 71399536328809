import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(async ({ $auth, store, route, error: nuxtError }) => {
  try {
    await store.dispatch('users/profile/getUserProfile', route.params.id);

    const isCurrentUserProfilePage = store.state.users.profile.profile.id === $auth.user?.id;

    if (!isCurrentUserProfilePage) nuxtError({ statusCode: 404 });
  } catch (error) {
    nuxtError({ statusCode: 404 });
  }
});
