import { computed, ComputedRef, reactive, ref, Ref, useContext } from '@nuxtjs/composition-api';
import { omit } from 'ramda';
import { TranslateResult } from 'vue-i18n';
import { useFormValidation } from '~/shared/composable/useFormValidation/useFormValidation';
import { IUseFormValidation } from '~/shared/composable/useFormValidation/interfaces';
import { ISignUpPayload } from '~/api/interfaces/auth.interface';
import { IFormRule } from '~/shared/interfaces/form-rule.interface';
import { VForm } from '~/shared/types/form.type';
import { ESignUpFlow } from '~/features/auth/enums/sign-up-flow.enum';
import { IResponseErrorData } from '~/shared/interfaces/response.interface';
import UiPhoneInput from '~/shared/components/UiPhoneInput.vue';
import { isWebview } from '~/utils/is-webview';
import { EToastType } from '~/shared/enums/toast.enum';
import { useGcpAnalytics } from '~/features/gcp-analytics/composables/useGcpAnalytics';
import { EReactNativeEventType } from '~/shared/enums/react-native-event-type.enum';
import { useFormErrorMessages } from '~/shared/composable/useFormErrorMessages';
import { usePasswordValidation } from '~/shared/composable/usePasswordValidation';
import { EAuthBackendErrorCode } from '~/shared/enums/backend-error-code.enum';

export interface IFormData extends Omit<ISignUpPayload, 'options'> {
  passwordConfirm: string;
}

interface IUseRegistration extends Omit<IUseFormValidation, 'validateField'> {
  formData: IFormData;
  rules: Record<string, IFormRule[]>;
  handleSubmit(): Promise<boolean>;
  isSubmitDisabled: ComputedRef<boolean>;
  isAgreeCheckboxShown: ComputedRef<boolean>;
  agreeTerms: Ref<boolean>;
  isPending: Ref<boolean>;
  customEmailError: Ref<null | TranslateResult>;
}

export interface IUseRegistrationPayload {
  form: Ref<VForm>;
  flow: ESignUpFlow;
  phoneInput?: Ref<InstanceType<typeof UiPhoneInput>>;
  withAgreeCheckbox?: true;
}

export const useRegistration = ({
  form,
  flow,
  phoneInput,
  withAgreeCheckbox,
}: IUseRegistrationPayload): IUseRegistration => {
  const ctx = useContext();
  const formData = reactive<IFormData>({
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });

  const agreeTerms = ref(false);
  const isAgreeCheckboxShown = computed(() => {
    if (process.server) return false;

    return withAgreeCheckbox ? isWebview() && ctx.$device.isAndroid : false;
  });

  const { getFormRequiredErrorMessage } = useFormErrorMessages();

  const { passwordRules } = usePasswordValidation(form, formData, 'none');

  const rules: Record<string, IFormRule[]> = {
    ...passwordRules,
    email: [
      { required: true, message: getFormRequiredErrorMessage('form.email'), trigger: 'none' },
      { type: 'email', message: ctx.i18n.t('form.invalidEmail'), trigger: 'none' },
    ],
    firstName: [
      { required: true, message: getFormRequiredErrorMessage('form.firstName'), trigger: 'none' },
    ],
    lastName: [
      { required: true, message: getFormRequiredErrorMessage('form.lastName'), trigger: 'none' },
    ],
  };

  const { onValidate, errors, hasErrors, validate } = useFormValidation(formData);

  const isSubmitDisabled = computed(() => {
    const entitiesToValidate = phoneInput
      ? formData
      : omit(['phoneNumber', 'trackingId'], formData);

    if (isAgreeCheckboxShown?.value && !agreeTerms?.value) return true;

    return Object.values(entitiesToValidate)?.some((i) => !i?.length);
  });

  const isPending = ref(false);

  const { applyForRegistration } = useGcpAnalytics();

  const customEmailError = ref<TranslateResult | null>(null);

  const handleSubmit = async (): Promise<boolean> => {
    if (isPending.value) return false;
    const start = Date.now();

    checkInputsValues();

    isPending.value = true;
    let isPhoneValid = true;

    customEmailError.value = null;

    try {
      if (phoneInput) isPhoneValid = phoneInput.value.checkIsValid();

      await validate(form.value);
      if (!isPhoneValid) return false;

      const trackingId = ctx.$cookies.get('affiliateTrackingId') || undefined;

      const { accessToken, refreshToken } = await ctx.$api.auth.signup({
        ...omit(['passwordConfirm'], formData),
        options: flow,
        trackingId,
      });

      await ctx.$auth.setUserToken(accessToken, refreshToken);

      if (isWebview()) {
        ((window as any)?.ReactNativeWebView as any)?.postMessage(
          JSON.stringify({
            type: EReactNativeEventType.Auth,
            data: formData.email,
          }),
        );
      }

      await ctx.store.dispatch('feature-access/getAccessInfo');
      await ctx.$auth.fetchUser();

      ctx.$websocketApi.connect();

      applyForRegistration(Date.now() - start);

      return true;
    } catch (error) {
      const { localizedErrorMessage, errorCode } = error as IResponseErrorData;

      if (errorCode === EAuthBackendErrorCode.SignUpEmailDomainValidationFailed)
        customEmailError.value = ctx.i18n.t('notifications.emailIsIncorrect');
      else ctx.$showToast(localizedErrorMessage, { type: EToastType.Error });

      return false;
    } finally {
      isPending.value = false;
    }
  };

  const checkInputsValues = (): void => {
    try {
      const formElements = (form.value.$el as HTMLFormElement).elements;

      const emailInputValue = (formElements.namedItem('email') as HTMLInputElement).value;
      const firstNameInputValue = (formElements.namedItem('firstName') as HTMLInputElement).value;
      const lastNameInputValue = (formElements.namedItem('lastName') as HTMLInputElement).value;
      const passwordInputValue = (formElements.namedItem('password') as HTMLInputElement).value;
      const passwordConfirmInputValue = (formElements.namedItem(
        'passwordConfirm',
      ) as HTMLInputElement).value;

      if (emailInputValue !== formData.email) formData.email = emailInputValue;
      if (passwordInputValue !== formData.password) formData.password = passwordInputValue;
      if (firstNameInputValue !== formData.firstName) formData.firstName = firstNameInputValue;
      if (lastNameInputValue !== formData.lastName) formData.lastName = lastNameInputValue;
      if (passwordConfirmInputValue !== formData.passwordConfirm)
        formData.passwordConfirm = passwordConfirmInputValue;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    formData,
    onValidate,
    errors,
    hasErrors,
    validate,
    rules,
    handleSubmit,
    isSubmitDisabled,
    isPending,
    isAgreeCheckboxShown,
    agreeTerms,
    customEmailError,
  };
};
