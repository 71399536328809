import { NuxtCookies } from 'cookie-universal-nuxt';
import { GOOGLE_MEASUREMENT_ID } from '~/features/gcp-analytics/constants/index.constant';

export const getBrowserSessionId = ($cookies: NuxtCookies): string => {
  const googleAnalyticsId = $cookies.get(`_ga_${GOOGLE_MEASUREMENT_ID}`);

  if (!googleAnalyticsId) return 'GOOGLE_ANALYTICS_ID_NOT_FOUND';

  return googleAnalyticsId?.split('.')?.[2] || 'BROWSER_SESSION_ID_PARSING_ERROR';
};
