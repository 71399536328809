import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IGetReportsParams } from '~/api/interfaces/comments-moderation.interface';
import {
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IPostReport } from '~/features/social-moderation/interfaces/post-report.interface';
import {
  IGetAllPostsParams,
  IHidePostResponse,
  IPostsModerationApi,
  ISetEntityResolutionStatusByReportPayload,
} from '~/api/interfaces/posts-moderation.interface';
import { IUserPost } from '~/features/profile/interfaces/user-post.interface';

export default ($axios: NuxtAxiosInstance): IPostsModerationApi => ({
  getReportById(id: string): Promise<IPostReport> {
    return $axios.$get('/admin/moderation/userPosts/getReportById', { params: { id } });
  },

  getReports(params: IGetReportsParams): Promise<IItemsListWithTotalResponse<IPostReport>> {
    return $axios.$post('/admin/moderation/userPosts/getReports', params);
  },

  setEntityResolutionStatusByReport(
    payload: ISetEntityResolutionStatusByReportPayload,
  ): Promise<void> {
    return $axios.$post('/admin/moderation/userPosts/setEntityResolutionStatusByReport', payload);
  },

  getAllPosts(params: IGetAllPostsParams): Promise<IItemsListResponse<IUserPost>> {
    return $axios.$post('/admin/moderation/userPosts/getAllPosts', params);
  },

  deletePost(postId: string): Promise<void> {
    return $axios.$post('/admin/moderation/userPosts/deletePost', { postId });
  },

  hidePost(postId: string): Promise<IHidePostResponse> {
    return $axios.$post('/admin/moderation/userPosts/hidePost', { postId });
  },
});
