import { Plugin } from '@nuxt/types';
import { IApi } from '~/api/interfaces/api.interface';
import auth from '~/api/auth';
import users from '~/api/users';
import courses from '~/api/courses';
import lesson from '~/api/lesson';
import module from '~/api/module';
import files from '~/api/files';
import utils from '~/api/utils';
import account from '~/api/account';
import userCourses from '~/api/user-courses';
import countries from '~/api/countries';
import chats from '~/api/chats';
import userSearch from '~/api/user-search';
import onBoarding from '~/api/on-boarding';
import followers from '~/api/followers';
import likes from '~/api/likes';
import portfolio from '~/api/portfolio';
import comments from '~/api/comments';
import reports from '~/api/reports';
import directories from '~/api/directories';
import editOffers from '~/api/edit-offers';
import userPosts from '~/api/user-posts';
import feed from '~/api/feed';
import feedAdmin from '~/api/feed-admin';
import artClubVideosAdmin from '~/api/art-club-videos-admin';
import artClubVideos from '~/api/art-club-videos';
import artClubVideoLists from '~/api/art-club-video-lists';
import artClubContestsAdmin from '~/api/art-club-contests-admin';
import artClubContestsUser from '~/api/art-club-contests-user';
import notifications from '~/api/notifications';
import customerSupport from '~/api/customer-support';
import stripePayments from '~/api/stripe-payments';
import adminPayments from '~/api/admin-payments';
import payments from '~/api/payments';
import globalPinnedPosts from '~/api/global-pinned-posts';
import commentsModeration from '~/api/comments-moderation';
import postsModeration from '~/api/posts-moderation';
import artClubSeasonsAdmin from '~/api/art-club-seasons-admin';
import artClubSeasons from '~/api/art-club-seasons';
import userCrm from '~/api/user-crm';
import userCrmInfo from '~/api/user-crm-info';
import userCrmLabels from '~/api/user-crm-labels';
import userCrmProducts from '~/api/user-crm-products';
import userCrmTags from '~/api/user-crm-tags';
import userCrmUsersToTags from '~/api/user-crm-users-to-tags';
import roles from '~/api/roles';
import artClubBanners from '~/api/art-club-banners';
import artClubBannersAdmin from '~/api/art-club-banners-admin';
import usersModeration from '~/api/users-moderation';
import blockUserToUser from '~/api/block-user-to-user';
import communitiesAdmin from '~/api/communities-admin';
import communitiesAdminModerators from '~/api/communities-admin-moderators';
import communitiesAdminRecommended from '~/api/communities-admin-recommended';
import communities from '~/api/communities';
import communitiesAdminMembers from '~/api/communities-admin-members';
import communitiesActions from '~/api/communities-actions';
import communitiesModeration from '~/api/communities-moderation';
import paymentsInfo from '~/api/payments-info';
import products from '~/api/products';
import mentoringStudents from '~/api/mentoring-students';
import features from '~/api/features';
import mentoringMentor from '~/api/mentoring-mentor';
import mentorsAdmin from '~/api/mentors-admin';
import userCrmOffers from '~/api/user-crm-offers';
import blogAdmin from '~/api/blog-admin';
import blog from '~/api/blog';
import blogBannerAdmin from '~/api/blog-banner-admin';
import blogBanner from '~/api/blog-banner';
import affiliate from '~/api/affiliate';
import workshop from '~/api/workshop';
import workshopAdmin from '~/api/workshop-admin';
import instructorsAdmin from '~/api/instructors-admin';
import artClubInstructors from '~/api/art-club-instructors';
import bannerAdmin from '~/api/banner-admin';
import banner from '~/api/banner';
import artSales from '~/api/art-sales';

const api: Plugin = ({ $axios }, inject) => {
  const apiObj: IApi = {
    auth: auth($axios),
    users: users($axios),
    courses: courses($axios),
    lesson: lesson($axios),
    module: module($axios),
    files: files($axios),
    utils: utils($axios),
    account: account($axios),
    userCourses: userCourses($axios),
    countries: countries($axios),
    chats: chats($axios),
    userSearch: userSearch($axios),
    onBoarding: onBoarding($axios),
    followers: followers($axios),
    likes: likes($axios),
    portfolio: portfolio($axios),
    comments: comments($axios),
    reports: reports($axios),
    directories: directories($axios),
    editOffers: editOffers($axios),
    userPosts: userPosts($axios),
    feed: feed($axios),
    feedAdmin: feedAdmin($axios),
    artClubVideosAdmin: artClubVideosAdmin($axios),
    artClubVideos: artClubVideos($axios),
    artClubVideoLists: artClubVideoLists($axios),
    artClubContestsAdmin: artClubContestsAdmin($axios),
    artClubContestsUser: artClubContestsUser($axios),
    notifications: notifications($axios),
    customerSupport: customerSupport($axios),
    stripePayments: stripePayments($axios),
    adminPayments: adminPayments($axios),
    payments: payments($axios),
    globalPinnedPosts: globalPinnedPosts($axios),
    commentsModeration: commentsModeration($axios),
    postsModeration: postsModeration($axios),
    artClubSeasonsAdmin: artClubSeasonsAdmin($axios),
    artClubSeasons: artClubSeasons($axios),
    userCrm: userCrm($axios),
    userCrmInfo: userCrmInfo($axios),
    userCrmLabels: userCrmLabels($axios),
    userCrmOffers: userCrmOffers($axios),
    userCrmProducts: userCrmProducts($axios),
    userCrmTags: userCrmTags($axios),
    userCrmUsersToTags: userCrmUsersToTags($axios),
    roles: roles($axios),
    artClubBanners: artClubBanners($axios),
    artClubBannersAdmin: artClubBannersAdmin($axios),
    usersModeration: usersModeration($axios),
    blockUserToUser: blockUserToUser($axios),
    communities: communities($axios),
    communitiesActions: communitiesActions($axios),
    communitiesAdmin: communitiesAdmin($axios),
    communitiesAdminModerators: communitiesAdminModerators($axios),
    communitiesAdminRecommended: communitiesAdminRecommended($axios),
    communitiesAdminMembers: communitiesAdminMembers($axios),
    communitiesModeration: communitiesModeration($axios),
    paymentsInfo: paymentsInfo($axios),
    products: products($axios),
    mentoringStudents: mentoringStudents($axios),
    features: features($axios),
    mentoringMentor: mentoringMentor($axios),
    mentorsAdmin: mentorsAdmin($axios),
    blog: blog($axios),
    blogAdmin: blogAdmin($axios),
    blogBanner: blogBanner($axios),
    blogBannerAdmin: blogBannerAdmin($axios),
    affiliate: affiliate($axios),
    workshop: workshop($axios),
    workshopAdmin: workshopAdmin($axios),
    instructorsAdmin: instructorsAdmin($axios),
    artClubInstructors: artClubInstructors($axios),
    bannerAdmin: bannerAdmin($axios),
    banner: banner($axios),
    artSales: artSales($axios),
  };

  inject('api', apiObj);
};

export default api;
