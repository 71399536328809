import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IArtClubBannersApi } from '~/api/interfaces/art-club-banners.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { TBannerForUser } from '~/features/art-club/types/banner-for-user.type';

export default ($axios: NuxtAxiosInstance): IArtClubBannersApi => ({
  getActualBannerById(id: string): Promise<TBannerForUser> {
    return $axios.$get('/artClub/banners/getActualBannerById', { params: { id } });
  },

  getActualBanners(): Promise<IItemsListResponse<TBannerForUser>> {
    return $axios.$get('/artClub/banners/getActualBanners');
  },

  logBannerImpression(bannerId: string): Promise<void> {
    return $axios.$post('/artClub/banners/logBannerImpression', { bannerId }, { localError: true });
  },

  logBannerClick(bannerId: string): Promise<void> {
    return $axios.$post('/artClub/banners/logBannerClick', { bannerId }, { localError: true });
  },
});
