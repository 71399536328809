import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { ICountry } from '~/shared/interfaces/country.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getCountriesList(query: string): Promise<IItemsListWithTotalResponse<ICountry>> {
    return $axios.$get('/info/countries/getCountriesList', { params: { query } });
  },
  getCountryById(id: number): Promise<ICountry> {
    return $axios.$get('/info/countries/getCountryById', { params: { id } });
  },
});
