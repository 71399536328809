




















import { defineComponent } from '@nuxtjs/composition-api';
import UiModal from '~/shared/components/modals/UiModal.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import ContactUsForm from '~/shared/components/ContactUsForm.vue';
import { CONTACT_US_MESSENGER_LINK } from '~/shared/constants';
import { EToastType } from '~/shared/enums/toast.enum';

export default defineComponent({
  name: 'ContactUsModal',
  components: { ContactUsForm, UiModal, UiButton },
  methods: {
    openMessenger(): void {
      window.open(CONTACT_US_MESSENGER_LINK, '_blank');
    },

    show(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).show();
    },

    hide(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).hide();

      this.clearData();
    },

    clearData(): void {
      (this.$refs.form as InstanceType<typeof ContactUsForm>).clearData();
    },

    onSubmit() {
      this.$showToast(this.$t('notifications.customerSupport'), { type: EToastType.Success });

      this.hide();
    },
  },
});
