import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IPaymentsApi } from '~/api/interfaces/payments.interface';
import {
  IItemsListRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { ISubscriptionPlan } from '~/shared/interfaces/subscription-plan.interface';
import { IPurchase } from '~/shared/interfaces/purchase.interface';
import { ISubscription } from '~/shared/interfaces/subscription.interface';

export default ($axios: NuxtAxiosInstance): IPaymentsApi => ({
  getSubscriptionPlanById(id: string): Promise<ISubscriptionPlan> {
    return $axios.$get('/payments/getSubscriptionPlanById', { params: { id }, localError: true });
  },

  getSubscriptionById(id: string): Promise<ISubscription> {
    return $axios.$get('/payments/getSubscriptionById', { params: { id } });
  },

  getPurchasesList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IPurchase>> {
    return $axios.$get('/payments/getPurchasesList', { params });
  },

  getPurchaseById(id: string): Promise<IPurchase> {
    return $axios.$get('/payments/getPurchaseById', { params: { id }, localError: true });
  },
});
