










import { defineComponent } from '@nuxtjs/composition-api';
import UiButton from '~/shared/components/UiButton/index.vue';

export default defineComponent({
  name: 'EmailConfirmationLine',
  components: { UiButton },
  methods: {
    onClick(): void {
      this.$router.push(this.localePath('/settings/account'));
    },
  },
});
