



















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import { isNil, omit } from 'ramda';
import { containsOtherLetters } from '~/utils/contains-other-letters';
import { allowedNumberInputCharsList } from '~/shared/config/allowed-number-input-chars-list.config';

export type TInputType = 'default' | 'search' | 'user-search' | 'share';
export type THintPlacement = 'start' | 'end';

export default defineComponent({
  name: 'UiInput',
  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<string | 'text' | 'number'>,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    isError: {
      type: Boolean,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    inputClass: {
      type: String,
      default: null,
    },
    isIconRight: {
      type: Boolean,
      default: false,
    },
    uiType: {
      type: String as PropType<TInputType>,
      default: 'default',
    },
    label: {
      type: String as PropType<TranslateResult>,
      default: null,
    },
    prepend: {
      type: String as PropType<string | TranslateResult>,
      default: null,
    },
    hintPlacement: {
      type: String as PropType<THintPlacement>,
      default: 'start',
    },
  },
  computed: {
    // made custom events object to remove input event because it rewrites custom onInput method.
    bindListeners(): Record<string, Function | Function[]> {
      return omit(['input'], this.$listeners);
    },
    withLeftIcon(): boolean {
      return !!this.icon && !this.isIconRight;
    },

    typeToSet(): string {
      return this.type === 'number' ? 'text' : this.type;
    },

    withRightIcon(): boolean {
      return !!this.icon && this.isIconRight;
    },

    isMaxLengthReached(): boolean {
      if (isNil(this.maxlength)) return false;

      return this.value?.length >= +this.maxlength;
    },
  },
  methods: {
    onInput(newVal: string) {
      if (this.type !== 'number') return this.$emit('input', newVal);

      if (containsOtherLetters(newVal, allowedNumberInputCharsList)) return;

      // Prevented number with dot in the start
      if (newVal.charAt(0) === '.') return;

      // Prevented more than 1 dot in the string
      if (newVal.split('').filter((char) => char === '.')?.length > 1) return;

      this.$emit('input', newVal);
    },
  },
});
