import { getScrollTopPosition } from '~/utils/scroll-to-anchor';

export default (to, _from, savedPosition) => {
  if(to.params.savePosition) return savedPosition;

  if (to.hash) return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          x: 0,
          y: getScrollTopPosition(to.hash),
        });
      }, 300);
    });

  return savedPosition || { x: 0, y: 0 };
};
