import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAcceptJoinRequestPayload,
  ICommunitiesModerationApi,
  IGetUserJoinRequestsParams,
} from '~/api/interfaces/communities-moderation.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IUserProfileShortForCommunityModerator } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesModerationApi => ({
  getUserJoinRequests(
    params: IGetUserJoinRequestsParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShortForCommunityModerator>> {
    return $axios.$get('/communities/moderator/getUserJoinRequests', { params });
  },

  acceptJoinRequest(payload: IAcceptJoinRequestPayload): Promise<void> {
    return $axios.$post('/communities/moderator/acceptJoinRequest', payload);
  },

  declineJoinRequest(payload: IAcceptJoinRequestPayload): Promise<void> {
    return $axios.$post('/communities/moderator/declineJoinRequest', payload);
  },

  removeMember(payload: IAcceptJoinRequestPayload): Promise<void> {
    return $axios.$post('/communities/moderator/removeMember', payload);
  },

  addPinnedPost(postId: string): Promise<void> {
    return $axios.$post('/communities/moderator/addPinnedPost', { postId });
  },

  removePinnedPost(postId: string): Promise<void> {
    return $axios.$post('/communities/moderator/deletePinnedPost', { postId });
  },
});
