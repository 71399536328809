<template>
  <client-only>
    <el-container class="tw-min-h-screen">
      <admin-sidebar />

      <div class="admin-layout__wrapper">
        <nuxt />
      </div>
    </el-container>
  </client-only>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AdminLayout',
  components: {
    AdminSidebar: () => import('./parts/admin-sidebar.vue'),
  },
  middleware: 'admin',
});
</script>

<style scoped lang="scss">
.admin-layout__wrapper {
  @screen md {
    @apply tw-px-6;
    width: calc(100% - 240px);
  }
}
</style>
