export enum EOfferEditPermission {
  CanEditName = 'canEditName',
  CanEditProductsList = 'canEditProductsList',
  CanEditProductSettings = 'canEditProductSettings',
  CanEditAvailability = 'canEditAvailability',
  CanEditQuantityLimit = 'canEditQuantityLimit',
  CanChangePublishedState = 'canChangePublishedState',
  CanManageVersionsList = 'canManageVersionsList',
  CanEditVersionImage = 'canEditVersionImage',
  CanEditVersionCheckout = 'canEditVersionCheckout',
  CanEditVersionPrice = 'canEditVersionPrice',
  CanEditVersionRequiredUserData = 'canEditVersionRequiredUserData',
  CanEditVersionThankYouLink = 'canEditVersionThankYouLink',
  CanEditAutomations = 'canEditAutomations',
}
