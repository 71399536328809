import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { EAppEnvironment } from '~/shared/enums/app-environment.enum';

export const checkEnvValidity = (
  $config: NuxtRuntimeConfig,
  restrictedEnvironments: EAppEnvironment[] | undefined,
): boolean => {
  if (!restrictedEnvironments) return true;

  return !restrictedEnvironments.includes($config.envType as EAppEnvironment);
};
