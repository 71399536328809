import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IProductsApi } from '~/api/interfaces/products.interface';
import { IProduct, IProductForOwnershipRequest } from '~/shared/interfaces/product.interface';

export default ($axios: NuxtAxiosInstance): IProductsApi => ({
  getProductById(id: string): Promise<IProduct> {
    return $axios.$get('/products/getProductById', { params: { id } });
  },

  getProductOwnership(id: string, loadDetails?: true): Promise<IProductForOwnershipRequest> {
    return $axios.$get('/products/getProductOwnership', { params: { id, loadDetails } });
  },

  getFreeProduct(id: string): Promise<void> {
    return $axios.$post('/products/getFreeProduct', { id });
  },
});
