import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetByAuthorParams,
  IGetByAuthorResponse,
  IGetByCategoryParams,
  IGetRecommendationsByVideoParams,
  ISearchVideoParams,
  IGetByCategoryResponse,
  IArtClubVideoListApi,
} from '~/api/interfaces/art-club-video-lists.interface';
import {
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';

export default ($axios: NuxtAxiosInstance): IArtClubVideoListApi => ({
  getPersonalRecommendations(count: number): Promise<IItemsListResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getPersonalRecommendations', { params: { count } });
  },

  getContinueWatching(count: number): Promise<IItemsListResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getContinueWatching', { params: { count } });
  },

  getPopular(params: ISearchVideoParams): Promise<IItemsListResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getPopular', { params });
  },

  getNewReleases(params: ISearchVideoParams): Promise<IItemsListResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getNewReleases', { params });
  },

  getSaved(params: ISearchVideoParams): Promise<IItemsListWithTotalResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getSaved', { params });
  },

  getByCategory(params: IGetByCategoryParams): Promise<IGetByCategoryResponse> {
    return $axios.$get('/artClub/media/list/getByCategory', { params });
  },

  getByAuthor(params: IGetByAuthorParams): Promise<IGetByAuthorResponse> {
    return $axios.$get('/artClub/media/list/getByAuthor', { params });
  },

  getRecommendationsByVideo(
    params: IGetRecommendationsByVideoParams,
  ): Promise<IItemsListResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getRecommendationsByVideo', { params });
  },

  searchVideos(
    params: ISearchVideoParams,
  ): Promise<IItemsListWithTotalResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/searchVideos', { params });
  },

  getWatchHistory(
    params: ISearchVideoParams,
  ): Promise<IItemsListWithTotalResponse<IArtClubUserVideo>> {
    return $axios.$get('/artClub/media/list/getWatchHistory', { params });
  },
});
