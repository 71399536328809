import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICourse,
  ICourseHistoryVersionItem,
} from '~/features/courses/interfaces/courses.interface';
import {
  IGetCourseByIdParams,
  IGetCourseEditHistoryParams,
  IStartEditCourseSessionPayload,
  IPublishCourseVersionPayload,
  IUpdateCourseStatePayload,
  ISaveEditCourseSessionPayload,
  ICreateCoursePayload,
  IStartEditCourseSessionResponse,
  ICourseContentItems,
  IAddCourseInstructorPayload,
} from '~/api/interfaces/courses.interface';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IInstructor } from '~/features/courses/interfaces/instructor.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getCourses(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<ICourse>> {
    return $axios.$get('/courses/edit/getCourses', {
      params,
    });
  },

  createCourse(payload: ICreateCoursePayload): Promise<ICourse> {
    return $axios.$post('/courses/edit/createCourse', payload);
  },

  getCourseById(params: IGetCourseByIdParams): Promise<ICourse> {
    return $axios.$get('/courses/edit/getCourseById', {
      params,
    });
  },

  getCourseEditHistory(
    params: IGetCourseEditHistoryParams,
  ): Promise<IItemsListWithTotalResponse<ICourseHistoryVersionItem>> {
    return $axios.$get('/courses/edit/getCourseEditHistory', { params });
  },

  startEditCourseSession(
    payload: IStartEditCourseSessionPayload,
  ): Promise<IStartEditCourseSessionResponse> {
    return $axios.$post('/courses/edit/startEditCourseSession', payload);
  },

  saveEditCourseSession(payload: ISaveEditCourseSessionPayload): Promise<void> {
    return $axios.$post('/courses/edit/saveEditCourseSession', payload);
  },

  discardEditCourseSession(versionId: string): Promise<void> {
    return $axios.$post('/courses/edit/discardEditCourseSession', { versionId });
  },

  publishCourseVersion(payload: IPublishCourseVersionPayload): Promise<void> {
    return $axios.$post('/courses/edit/publishCourseVersion', payload);
  },

  updateState(payload: IUpdateCourseStatePayload): Promise<void> {
    return $axios.$post('/courses/edit/updateCourse', payload);
  },

  getCourseContentItems(courseId: string): Promise<ICourseContentItems> {
    return $axios.$get('/courses/content/edit/getContentItems', { params: { courseId } });
  },

  reorderCourseContentItems(payload: ICourseContentItems): Promise<ICourseContentItems> {
    return $axios.$post('/courses/content/edit/reorderContentItems', payload);
  },

  getInstructorList(courseId: string): Promise<IInstructor[]> {
    return $axios.$get('/courses/edit/getInstructorList', {
      params: { courseId },
    });
  },

  addInstructor(payload: IAddCourseInstructorPayload): Promise<void> {
    return $axios.$post('/courses/edit/addInstructor', payload);
  },

  removeInstructor(payload: IAddCourseInstructorPayload): Promise<void> {
    return $axios.$post('/courses/edit/removeInstructor', payload);
  },
});
