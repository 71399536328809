import { IContactUsItem } from '~/shared/interfaces/contact-us-item.interface';

export const contactUsSectionItemsList: IContactUsItem[] = [
  {
    icon: 'messenger',
    url: 'https://m.me/milanartinstitute',
    label: 'salesLanding.contactUs.messenger',
  },
  {
    icon: 'email',
    url: '#',
    label: 'salesLanding.contactUs.email',
    alternative: true,
  },
];
