import Vue from 'vue';
// @ts-ignore
import elementLang from 'element-ui/lib/locale/lang/en';
// @ts-ignore
import locale from 'element-ui/lib/locale';

import VueI18n from 'vue-i18n';

import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  Drawer,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Progress,
  Card,
  Collapse,
  CollapseItem,
  Container,
  Header,
  Aside,
  Main,
  Divider,
  Loading,
  ColorPicker,
} from 'element-ui';
import { mergeDeepRight } from 'ramda';
import en from '../locales/en.js';

export default (): void => {
  Vue.use(VueI18n);

  // to use custom translations from our locale file under stored 'el' object in Element components,
  // if there is no option provided to customize it
  const combinedEnLocale = mergeDeepRight(elementLang.el, en.el);

  const messages = {
    en: {
      el: combinedEnLocale,
    },
  };

  const i18n = new VueI18n({
    locale: 'en',
    messages,
  });

  locale.i18n((key: string, value: string) => i18n.t(key, value));

  Vue.use(Pagination);
  Vue.use(Dialog);
  Vue.use(Autocomplete);
  Vue.use(Dropdown);
  Vue.use(DropdownMenu);
  Vue.use(DropdownItem);
  Vue.use(Menu);
  Vue.use(Submenu);
  Vue.use(MenuItem);
  Vue.use(Input);
  Vue.use(Radio);
  Vue.use(RadioGroup);
  Vue.use(RadioButton);
  Vue.use(Checkbox);
  Vue.use(CheckboxGroup);
  Vue.use(Switch);
  Vue.use(Select);
  Vue.use(Option);
  Vue.use(Button);
  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(DatePicker);
  Vue.use(TimeSelect);
  Vue.use(Popover);
  Vue.use(Tooltip);
  Vue.use(Form);
  Vue.use(FormItem);
  Vue.use(Tabs);
  Vue.use(TabPane);
  Vue.use(Tag);
  Vue.use(Alert);
  Vue.use(Progress);
  Vue.use(Card);
  Vue.use(Collapse);
  Vue.use(CollapseItem);
  Vue.use(Container);
  Vue.use(Header);
  Vue.use(Aside);
  Vue.use(Main);
  Vue.use(Divider);
  Vue.use(Drawer);
  Vue.use(ColorPicker);

  Vue.use(Loading.directive);

  Vue.prototype.$loading = Loading.service;
};
