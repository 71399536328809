import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil } from 'ramda';
import { ICommunityDataForUser } from '~/features/communities/interfaces/community-data.interface';
import { ECommunityType } from '~/features/communities/enums/community-type.enum';
import { EMembershipStatus } from '~/features/communities/enums/membership-status.enum';
import { GROUP_MENTORING_COMMUNITY_ID } from '~/shared/constants';

export interface IUserCommunityState {
  community: ICommunityDataForUser;
  hasUserJoinedAnyCommunities: boolean;
  isCommunityUpdatesPending: boolean;
}

const getDefaultState = (): IUserCommunityState => ({
  community: {
    id: '',
    name: '',
    type: ECommunityType.Public,
    image: null,
    about: '',
    isFollowing: false,
    isUserModerator: false,
    membersCount: 0,
    followersCount: 0,
    membershipStatus: null,
    joinRequestsCount: null,
    allowedActions: [],
  },
  hasUserJoinedAnyCommunities: false,
  isCommunityUpdatesPending: false,
});

export const state = (): IUserCommunityState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getCommunity(ctx, id: string): Promise<void> {
    let community = (undefined as unknown) as ICommunityDataForUser;

    try {
      community = await this.$api.communities.getCommunityBySlug(id);
    } catch {}

    if (isNil(community)) community = await this.$api.communities.getCommunityById(id);

    ctx.commit('setCommunity', community);
  },

  async getHasUserJoinedAnyCommunities(ctx): Promise<void> {
    const { total } = await this.$api.communities.getMyCommunitiesList({
      count: 1,
      offset: 0,
      mutualMembersProfilesCount: 1,
    });

    ctx.commit('setHasUserJoinedAnyCommunities', !!total);
  },
};

export const mutations: MutationTree<RootState> = {
  setCommunity(state, community: ICommunityDataForUser) {
    state.community = community;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },

  setHasUserJoinedAnyCommunities(state, hasUserJoinedAnyCommunities: boolean) {
    state.hasUserJoinedAnyCommunities = hasUserJoinedAnyCommunities;
  },

  setCommunityUpdatesPending(state, isPending: boolean) {
    state.isCommunityUpdatesPending = isPending;
  },
};

export const getters: GetterTree<IUserCommunityState, RootState> = {
  isStatusModerator(state) {
    return state.community.isUserModerator;
  },

  isStatusMember(state) {
    return state.community.membershipStatus === EMembershipStatus.Member;
  },

  isStatusPending(state) {
    return state.community.membershipStatus === EMembershipStatus.Pending;
  },

  isTypePublic(state): boolean {
    return state.community.type === ECommunityType.Public;
  },

  isTypeLimited(state): boolean {
    return state.community.type === ECommunityType.Limited;
  },

  isTypePrivate(state): boolean {
    return state.community.type === ECommunityType.Private;
  },

  isFeedShown(_state, getters): boolean {
    return getters.isTypePrivate ? getters.isStatusMember : true;
  },

  isGroupMentoringCommunity(state): boolean {
    return state.community.id === GROUP_MENTORING_COMMUNITY_ID;
  },
};
