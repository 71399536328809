import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IChat } from '~/features/messages/interfaces/chat.interface';
import {
  IAddUserToChatPayload,
  IChatsApi,
  ICreateGroupChatPayload,
  IEditChatMessagePayload,
  IGetChatMessagesHistoryParams,
  IGetChatMessagesHistoryResponse,
  IGetChatMessageUpdatesParams,
  IGetChatMessageUpdatesResponse,
  IGetChatParticipantsParams,
  IGetInitialChatMessagesParams,
  ISendChatMessagePayload,
  ISetChatAdminStatusToUserPayload,
  IUpdateChatImagePayload,
  IUpdateChatTitlePayload,
} from '~/api/interfaces/chats.interface';
import { IChatMessage } from '~/features/messages/interfaces/chat-message.interface';
import { IChatData } from '~/features/messages/interfaces/chat-data.interface';
import { IChatParticipant } from '~/features/messages/interfaces/chat-participant.interface';
import { IUserProfileShort } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): IChatsApi => ({
  getChats(params: IItemsListWithQueryRequestParams): Promise<IItemsListWithTotalResponse<IChat>> {
    return $axios.$get('/chats/getChats', { params });
  },

  getChatById(id: string): Promise<IChat> {
    return $axios.$get('/chats/getChatById', { params: { id } });
  },

  startDirectChat(interlocutorId: string): Promise<IChat> {
    return $axios.$post('/chats/startDirectChat', { interlocutorId });
  },

  createGroupChat(payload: ICreateGroupChatPayload): Promise<IChat> {
    return $axios.$post('/chats/createGroupChat', payload);
  },

  updateChatTitle(payload: IUpdateChatTitlePayload): Promise<void> {
    return $axios.$post('/chats/updateChatTitle', payload);
  },

  updateChatImage(payload: IUpdateChatImagePayload): Promise<IChat> {
    return $axios.$post('/chats/updateChatImage', payload);
  },

  setChatAdminStatusToUser(payload: ISetChatAdminStatusToUserPayload): Promise<void> {
    return $axios.$post('/chats/setChatAdminStatusToUser', payload);
  },

  addUserToChat(payload: IAddUserToChatPayload): Promise<void> {
    return $axios.$post('/chats/addUserToChat', payload);
  },

  removeUserFromChat(payload: IAddUserToChatPayload): Promise<void> {
    return $axios.$post('/chats/removeUserFromChat', payload);
  },

  leaveChat(chatId: string): Promise<void> {
    return $axios.$post('/chats/leaveChat', { chatId });
  },

  getChatParticipants(
    params: IGetChatParticipantsParams,
  ): Promise<IItemsListWithTotalResponse<IChatParticipant>> {
    return $axios.$get('/chats/getChatParticipants', { params });
  },

  getUserInfos(userIds: string[]): Promise<IUserProfileShort[]> {
    return $axios.$get('/chats/getUserInfos', { params: { userIds: userIds.join() } });
  },

  sendChatMessage(payload: ISendChatMessagePayload): Promise<void> {
    return $axios.$post('/chats/messages/sendChatMessage', payload);
  },

  getChatMessageById(id: string): Promise<IChatMessage> {
    return $axios.$get('/chats/messages/getChatMessageById', { params: { id } });
  },

  getInitialChatMessages(params: IGetInitialChatMessagesParams): Promise<IChatData> {
    return $axios.$get('/chats/messages/getInitialChatMessages', { params });
  },

  getChatMessagesHistory(
    params: IGetChatMessagesHistoryParams,
  ): Promise<IGetChatMessagesHistoryResponse> {
    return $axios.$get('/chats/messages/getChatMessagesHistory', { params });
  },

  getChatMessageUpdates(
    params: IGetChatMessageUpdatesParams,
  ): Promise<IGetChatMessageUpdatesResponse> {
    return $axios.$get('/chats/messages/getChatMessageUpdates', { params });
  },

  deleteChatMessage(messageId: string): Promise<void> {
    return $axios.$post('/chats/messages/deleteChatMessage', { messageId }, { localError: true });
  },

  editChatMessage(payload: IEditChatMessagePayload): Promise<void> {
    return $axios.$post('/chats/messages/editChatMessage', payload);
  },

  markMessageAsRead(messageId: string): Promise<void> {
    return $axios.$post('/chats/messages/markMessageAsRead', { messageId });
  },
});
