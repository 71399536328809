import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IBlogBannerApi } from '~/api/interfaces/blog-banner.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IBlogBannerListItemForUser } from '~/features/blog/interfaces/blog-banner-list-item.interface';

export default ($axios: NuxtAxiosInstance): IBlogBannerApi => ({
  getBannersList(): Promise<IItemsListResponse<IBlogBannerListItemForUser>> {
    return $axios.$get('/blog/banner/getBannersList');
  },

  getBannerListItemById(id: string): Promise<IBlogBannerListItemForUser> {
    return $axios.$get('/blog/banner/getBannerListItemById', { params: { id } });
  },
});
