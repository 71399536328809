import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IArtClubContestsUserApi,
  IGetPinnedContestResponse,
  IGetSubmissionsParams,
  IGetUserVotesResponse,
  ISubmitPostPayload,
  IUpdatePostPayload,
} from '~/api/interfaces/art-club-contests-user.interface';
import {
  IContestForUserDetailed,
  IContestForUserForList,
} from '~/features/contests/interfaces/contest.interface';
import {
  IItemsListRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IContestSubmissionById } from '~/features/contests/interfaces/contest-submission.interface';

export default ($axios: NuxtAxiosInstance): IArtClubContestsUserApi => ({
  getContestListItemById(id: string): Promise<IContestForUserForList> {
    return $axios.$get('/artClub/contests/getContestListItemById', { params: { id } });
  },

  getPinnedContest(): Promise<IGetPinnedContestResponse | null> {
    return $axios.$get('/artClub/contests/getPinnedContest');
  },

  getMainContestsList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IContestForUserForList>> {
    return $axios.$get('/artClub/contests/getMainContestsList', { params });
  },

  getMyContestsList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IContestForUserForList>> {
    return $axios.$get('/artClub/contests/getMyContestsList', { params });
  },

  getArchivedContestsList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IContestForUserForList>> {
    return $axios.$get('/artClub/contests/getArchivedContestsList', { params });
  },

  getContestDetailsById(id: string): Promise<IContestForUserDetailed> {
    return $axios.$get('/artClub/contests/getContestDetailsById', { params: { id } });
  },

  addVote(postId: string): Promise<void> {
    return $axios.$post('/artClub/contests/addVote', { postId });
  },

  removeVote(postId: string): Promise<void> {
    return $axios.$post('/artClub/contests/removeVote', { postId });
  },

  getUserVotesCount(contestId: string): Promise<IGetUserVotesResponse> {
    return $axios.$get('/artClub/contests/getUserVotesCount', { params: { contestId } });
  },

  submitPost(payload: ISubmitPostPayload): Promise<IContestSubmissionById> {
    return $axios.$post('/artClub/contests/submitPost', payload);
  },

  updatePost(payload: IUpdatePostPayload): Promise<IContestSubmissionById> {
    return $axios.$post('/artClub/contests/updatePost', payload);
  },

  removePost(postId: string): Promise<void> {
    return $axios.$post('/artClub/contests/removePost', { postId });
  },

  getMySubmission(contestId: string): Promise<IContestSubmissionById> {
    return $axios.$get('/artClub/contests/getMySubmission', { params: { contestId } });
  },

  getSubmissionById(id: string): Promise<IContestSubmissionById> {
    return $axios.$get('/artClub/contests/getSubmissionById', { params: { id }, localError: true });
  },

  getSubmissions(
    params: IGetSubmissionsParams,
  ): Promise<IItemsListWithTotalResponse<IContestSubmissionById>> {
    return $axios.$get('/artClub/contests/getSubmissions', { params });
  },
});
