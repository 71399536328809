import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { ILesson, ILessonHistoryVersionItem } from '~/features/lesson/interfaces/lesson.interface';
import {
  ICreateLessonPayload,
  IGetLessonByIdParams,
  IGetLessonHistoryParams,
  ISaveEditLessonPayload,
  IStartEditLessonSessionPayload,
  IStartEditLessonSessionResponse,
  ISaveLessonAttachmentPayload,
  IRemoveLessonAttachmentPayload,
  IPublishLessonVersionPayload,
  IUpdateLessonPayload,
} from '~/api/interfaces/lesson.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getLessonById(params: IGetLessonByIdParams): Promise<ILesson> {
    return $axios.$get('/courses/lessons/edit/getLessonById', { params });
  },

  createLesson(payload: ICreateLessonPayload): Promise<ILesson> {
    return $axios.$post('/courses/lessons/edit/createLesson', payload);
  },

  getLessonEditHistory(
    params: IGetLessonHistoryParams,
  ): Promise<IItemsListWithTotalResponse<ILessonHistoryVersionItem>> {
    return $axios.$get('/courses/lessons/edit/getLessonEditHistory', { params });
  },

  startEditLessonSession(
    payload: IStartEditLessonSessionPayload,
  ): Promise<IStartEditLessonSessionResponse> {
    return $axios.$post('/courses/lessons/edit/startEditLessonSession', payload);
  },

  saveEditLessonSession(payload: ISaveEditLessonPayload): Promise<void> {
    return $axios.$post('/courses/lessons/edit/saveEditLessonSession', payload);
  },
  saveLessonAttachment(payload: ISaveLessonAttachmentPayload): Promise<void> {
    return $axios.$post('/courses/lessons/edit/saveLessonAttachment', payload);
  },
  removeLessonAttachment(payload: IRemoveLessonAttachmentPayload): Promise<void> {
    return $axios.$post('/courses/lessons/edit/removeLessonAttachment', payload);
  },
  discardEditLessonSession(versionId: string): Promise<void> {
    return $axios.$post('/courses/lessons/edit/discardEditLessonSession', { versionId });
  },
  publishLessonVersion(payload: IPublishLessonVersionPayload): Promise<void> {
    return $axios.$post('/courses/lessons/edit/publishLessonVersion', payload);
  },
  updateLesson(payload: IUpdateLessonPayload): Promise<void> {
    return $axios.$post('/courses/lessons/edit/updateLesson', payload);
  },
});
