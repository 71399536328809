import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICreateFolderPayload,
  IGetFoldersParams,
  IGetFoldersResponse,
  IMoveFolderPayload,
  IRenameFolderPayload,
} from '~/api/interfaces/directories.interface';
import { IFolder } from '~/features/directories/interfaces/folder.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getFolders(params: IGetFoldersParams): Promise<IGetFoldersResponse> {
    return $axios.$get('/directories/getFolders', { params });
  },
  createFolder(payload: ICreateFolderPayload): Promise<IFolder> {
    return $axios.$post('/directories/createFolder', payload);
  },
  moveFolder(payload: IMoveFolderPayload): Promise<IFolder> {
    return $axios.$post('/directories/moveFolder', payload);
  },
  renameFolder(payload: IRenameFolderPayload): Promise<IFolder> {
    return $axios.$post('/directories/renameFolder', payload);
  },
  deleteFolder(folderId: string): Promise<void> {
    return $axios.$post('/directories/deleteFolder', { folderId });
  },
});
