import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { ICommunitiesActionsApi } from '~/api/interfaces/communities-actions.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesActionsApi => ({
  join(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/join', { communityId });
  },

  leave(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/leave', { communityId });
  },

  follow(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/follow', { communityId });
  },

  unfollow(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/unfollow', { communityId });
  },

  requestJoin(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/requestJoin', { communityId });
  },

  cancelJoinRequest(communityId: string): Promise<void> {
    return $axios.$post('/communities/actions/cancelJoinRequest', { communityId });
  },
});
