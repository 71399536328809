import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil, omit } from 'ramda';
import { IArtClubVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IArtClubVideoInfo } from '~/features/art-club/interfaces/art-club-video-info.interface';
import { IArtClubCategory } from '~/features/art-club/interfaces/art-club-category.interface';
import { IArtClubVideoAuthor } from '~/features/art-club/interfaces/art-club-video-author.interface';
import { IArtClubVideoStatistic } from '~/features/art-club/interfaces/art-club-video-statistic.interface';
import {
  DESIRE_VIDEO_THUMBNAIL_HEIGHT,
  DESIRE_VIDEO_THUMBNAIL_WIDTH,
} from '~/features/art-club/constants/desire-video-thumbnail-size.constant';
import { DEFAULT_ADMIN_TIMEZONE } from '~/shared/constants';

export interface IArtClubAdminVideoState {
  isStateChanged: boolean;
  video: IArtClubVideo;
  videoInfo: null | IArtClubVideoInfo;
  statistic: IArtClubVideoStatistic;
}

const getDefaultState = (): IArtClubAdminVideoState => ({
  isStateChanged: false,
  video: {
    id: '',
    vimeoVideoUrl: '',
    title: '',
    description: '',
    authors: [],
    categories: [],
    isPublished: false,
    publishedAt: null,
    durationSeconds: 0,
    watchedUntilSeconds: 0,
    isPartOf: null,
    isSaved: false,
  },
  statistic: {
    commentsCount: 0,
    commentsCountToday: 0,
    likesCount: 0,
    likesPercentage: 0,
    viewsCount: 0,
    viewsCountToday: 0,
  },
  videoInfo: null,
});

export const state = (): IArtClubAdminVideoState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getNewVideoPageInitials(ctx, videoUrl: string) {
    ctx.commit('setVideoUrl', videoUrl);

    await ctx.dispatch('getVideoInfo');
  },

  async saveVideo(ctx): Promise<string> {
    let videoId = ctx.state.video.id;
    const extractIds = (entities: { id: string }[]): string[] => entities.map((item) => item.id);

    if (!videoId.length) {
      const { id } = await this.$api.artClubVideosAdmin.createVideo(ctx.state.video.vimeoVideoUrl);
      videoId = id;
    }

    await this.$api.artClubVideosAdmin.saveVideo({
      ...omit(['categories', 'authors'], ctx.state.video),
      id: videoId,
      categoryIds: extractIds(ctx.state.video.categories),
      authorIds: extractIds(ctx.state.video.authors),
    });

    ctx.commit('setIsStateChanged', false);

    return videoId;
  },

  async getVideoInfo(ctx): Promise<void> {
    const videoInfo = await this.$api.artClubVideosAdmin.getVimeoVideoInfo({
      vimeoVideoUrl: ctx.state.video.vimeoVideoUrl,
      desiredWidth: DESIRE_VIDEO_THUMBNAIL_WIDTH,
      desiredHeight: DESIRE_VIDEO_THUMBNAIL_HEIGHT,
    });

    ctx.commit('setVideoInfo', videoInfo);
  },

  async getVideoById(ctx, id: string) {
    const video = await this.$api.artClubVideosAdmin.getVideoDetails(id);
    const statistic = await this.$api.artClubVideosAdmin.getVideoStatistics(id);

    ctx.commit('setVideo', video);
    ctx.commit('setStatistic', statistic);

    await ctx.dispatch('getVideoInfo');
  },
};

export const mutations: MutationTree<RootState> = {
  setVideo(state, video: IArtClubVideo) {
    state.video = video;
  },

  setVideoInfo(state, videoInfo: IArtClubVideoInfo | null) {
    state.videoInfo = videoInfo;
  },

  setVideoUrl(state, videoUrl: string) {
    state.video.vimeoVideoUrl = videoUrl;

    state.isStateChanged = true;
  },

  setVideoTitle(state, title: string) {
    state.video.title = title;

    state.isStateChanged = true;
  },

  setVideoDescription(state, description: string) {
    state.video.description = description;

    state.isStateChanged = true;
  },

  addCategory(state, category: IArtClubCategory) {
    state.video.categories = [...state.video.categories, category];

    state.isStateChanged = true;
  },

  removeCategory(state, categoryId: string) {
    state.video.categories = state.video.categories.filter((cat) => cat.id !== categoryId);

    state.isStateChanged = true;
  },

  addAuthor(state, author: IArtClubVideoAuthor) {
    state.video.authors = [...state.video.authors, author];

    state.isStateChanged = true;
  },

  removeAuthor(state, authorId: string) {
    state.video.authors = state.video.authors.filter((author) => author.id !== authorId);

    state.isStateChanged = true;
  },

  setVisibility(state, visibility: boolean) {
    state.video.isPublished = visibility;

    state.isStateChanged = true;
  },

  setStatistic(state, statistic: IArtClubVideoStatistic) {
    state.statistic = statistic;
  },

  setPublishedAt(state, publishedAt: string | null) {
    state.video.publishedAt = isNil(publishedAt)
      ? publishedAt
      : // @ts-ignore
        this.$dayjs(publishedAt).tz(DEFAULT_ADMIN_TIMEZONE, true).format();

    state.isStateChanged = true;
  },

  setIsStateChanged(state, isStateChanged: boolean) {
    state.isStateChanged = isStateChanged;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IArtClubAdminVideoState, RootState> = {
  videoUrl(state): string {
    return state.video.vimeoVideoUrl;
  },

  videoTitle(state): string {
    return state.video.title;
  },

  videoDescription(state): string {
    return state.video.description;
  },

  categories(state): IArtClubCategory[] {
    return state.video.categories;
  },

  authors(state): IArtClubVideoAuthor[] {
    return state.video.authors;
  },

  visibility(state): boolean {
    return state.video.isPublished;
  },

  publishedAt(state): string | null {
    return state.video.publishedAt;
  },
};
