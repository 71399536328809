








































































import { defineComponent } from '@nuxtjs/composition-api';
import { mapActions, mapState } from 'vuex';
import AppMenuLink from '~/shared/components/layouts/parts/menus/AppMenuLink.vue';
import { OUTSTANDING_ARTISTS_SHOW_ID } from '~/features/tv-show/constants';
import { IArtClubCategoryOverview } from '~/features/art-club/interfaces/art-club-category.interface';
import CategoriesMenu from '~/shared/components/layouts/parts/menus/CategoriesMenu.vue';
import UiSpinner from '~/shared/components/UiSpinner.vue';

export default defineComponent({
  name: 'ArtClubMenu',
  components: { UiSpinner, CategoriesMenu, AppMenuLink },
  emits: ['click'],
  setup() {
    return {
      OUTSTANDING_ARTISTS_SHOW_ID,
    };
  },
  computed: {
    ...mapState('users/art-club', {
      navigationOverview: 'navigationOverview',
      isNavigationOverviewLoading: 'isNavigationOverviewLoading',
    }),

    isCategoriesMenuShown(): boolean {
      return this.navigationOverview.categories?.length;
    },
  },
  methods: {
    ...mapActions('users/art-club', {
      updateNavigationOverview: 'updateNavigationOverview',
    }),

    onLinkClick(): void {
      this.$emit('click');
    },

    getCategoryPath(category: IArtClubCategoryOverview): string {
      return this.localePath(
        category.videoSeriesId
          ? `/users/art-club/shows/${category.videoSeriesId}`
          : `/users/art-club/search?categoryId=${category.id}`,
      );
    },
  },
});
