import { ProviderGetImage } from '@nuxt/image';
import { withQuery } from 'ufo';
import { isNil } from 'ramda';

export const getImage: ProviderGetImage = (src, { modifiers } = {}) => {
  const isFit = !isNil(modifiers?.fit);

  const query = {
    resize: `${isFit ? (modifiers?.fit ? 'fit' : 'max') : 'max'}${modifiers?.width}x${
      modifiers?.height
    }`,
    quality: modifiers?.quality || '60',
  };

  return {
    url: withQuery(src, query),
  };
};
