import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import mitt from 'mitt';
const emitter = mitt();

export default defineNuxtPlugin((_, inject) => {
  inject('bus', {
    on: emitter.on,
    emit: emitter.emit,
    off: emitter.off,
  });
});
