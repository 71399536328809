import { reactive, Ref, ref, useContext } from '@nuxtjs/composition-api';
import { IUseFormValidation } from '~/shared/composable/useFormValidation/interfaces';
import { useFormValidation } from '~/shared/composable/useFormValidation/useFormValidation';
import { IFormRule } from '~/shared/interfaces/form-rule.interface';
import { VForm } from '~/shared/types/form.type';
import { ESignUpFlow } from '~/features/auth/enums/sign-up-flow.enum';
import { IResponseErrorData } from '~/shared/interfaces/response.interface';
import { EToastType } from '~/shared/enums/toast.enum';
import { useFormErrorMessages } from '~/shared/composable/useFormErrorMessages';

export interface IUseForgotPasswordForm {
  email: string;
}

export interface IUseForgotPassword extends Omit<IUseFormValidation, 'validateField'> {
  formData: IUseForgotPasswordForm;
  rules: Record<string, IFormRule[]>;
  handleSubmit(): Promise<boolean>;
}

export const useForgotPassword = (form: Ref<VForm>, flow: ESignUpFlow): IUseForgotPassword => {
  const ctx = useContext();
  const formData = reactive({
    email: '',
  });
  const hasSubmitted = ref(false);

  const { getFormRequiredErrorMessage } = useFormErrorMessages();
  const rules: Record<string, IFormRule[]> = {
    email: [
      {
        required: true,
        message: getFormRequiredErrorMessage('form.email'),
        trigger: 'none',
      },
      { type: 'email', message: ctx.i18n.t('form.invalidEmail'), trigger: 'none' },
      {
        validator: async (_, value, callback): Promise<void> => {
          await form.value.clearValidate();

          if (!hasSubmitted.value) {
            callback();
          } else if (!value) {
            callback(new Error(getFormRequiredErrorMessage('form.email')));
          }
        },
        trigger: 'change',
      },
    ],
  };

  const handleSubmit = async (): Promise<boolean> => {
    try {
      hasSubmitted.value = true;

      await validate(form.value);

      await ctx.$api.account.requestPasswordReset({ email: formData.email, options: flow });

      return true;
    } catch (error) {
      ctx.$showToast((error as IResponseErrorData).localizedErrorMessage, {
        type: EToastType.Error,
      });

      return false;
    }
  };

  const { onValidate, errors, hasErrors, validate } = useFormValidation(formData);

  return { onValidate, errors, hasErrors, validate, formData, rules, handleSubmit };
};
