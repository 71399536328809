import { ActionTree, MutationTree } from 'vuex';
import { find } from 'ramda';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IGetOverviewResponse } from '~/api/interfaces/art-club-videos.interface';
import { TBannerForUser } from '~/features/art-club/types/banner-for-user.type';
export interface IArtClubState {
  banners: TBannerForUser[];
  continueWatching: IArtClubUserVideo[];
  newReleases: IArtClubUserVideo[];
  recommendations: IArtClubUserVideo[];
  popular: IArtClubUserVideo[];
  isNavigationOverviewLoading: boolean;
  navigationOverview: IGetOverviewResponse;
}

const getDefaultState = (): IArtClubState => ({
  banners: [],
  continueWatching: [],
  newReleases: [],
  recommendations: [],
  popular: [],
  isNavigationOverviewLoading: false,
  navigationOverview: {
    totalCount: 0,
    savedCount: 0,
    monthlyUpdatesCount: 0,
    categories: [],
  },
});

export const state = (): IArtClubState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getBanners(ctx) {
    const { items = [] } = await this.$api.artClubBanners.getActualBanners();

    ctx.commit('setBanners', items);
  },
  async getContinueWatching(ctx) {
    const { items = [] } = await this.$api.artClubVideoLists.getContinueWatching(12);

    ctx.commit('setContinueWatching', items);
  },

  async getNewReleases(ctx) {
    const { items = [] } = await this.$api.artClubVideoLists.getNewReleases({
      count: 12,
    });

    ctx.commit('setNewReleases', items);
  },

  async getRecommendations(ctx) {
    const { items = [] } = await this.$api.artClubVideoLists.getPersonalRecommendations(12);

    ctx.commit('setRecommendations', items);
  },

  async getPopular(ctx) {
    const { items = [] } = await this.$api.artClubVideoLists.getPopular({ count: 12 });

    ctx.commit('setPopular', items);
  },

  async updateNavigationOverview(ctx) {
    const response = await this.$api.artClubVideos.getOverview();

    ctx.commit('setNavigationOverview', response);
  },

  async getNavigationOverview(ctx) {
    ctx.commit('setIsNavigationOverviewLoading', true);
    const response = await this.$api.artClubVideos.getOverview();

    ctx.commit('setNavigationOverview', response);
    ctx.commit('setIsNavigationOverviewLoading', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setIsNavigationOverviewLoading(state, isNavigationOverviewLoading: boolean) {
    state.isNavigationOverviewLoading = isNavigationOverviewLoading;
  },

  setNavigationOverview(state, navigationOverview: IGetOverviewResponse) {
    state.navigationOverview = navigationOverview;
  },

  setContinueWatching(state, continueWatching: IArtClubUserVideo[]) {
    state.continueWatching = continueWatching;
  },

  setNewReleases(state, newReleases: IArtClubUserVideo[]) {
    state.newReleases = newReleases;
  },

  setRecommendations(state, recommendations: IArtClubUserVideo[]) {
    state.recommendations = recommendations;
  },

  setPopular(state, popular: IArtClubUserVideo[]) {
    state.popular = popular;
  },

  setBanners(state, banners: TBannerForUser[]) {
    state.banners = banners;
  },

  updateVideoSaveStatus(state, videoId: string) {
    const action = (items: IArtClubUserVideo[]): void => {
      const searchedItem = find((i) => i.id === videoId, items);

      if (!searchedItem) return;

      searchedItem.isSaved = !searchedItem.isSaved;
    };

    action(state.continueWatching);
    action(state.newReleases);
    action(state.recommendations);
    action(state.popular);
  },
};
