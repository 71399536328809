import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IGetUrlMetaResponse } from '~/api/interfaces/utils.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getUrlMetadata(url: string): Promise<IGetUrlMetaResponse> {
    return $axios.$get('/utils/getUrlMetadata', {
      params: {
        url,
      },
      localError: true,
    });
  },
});
