import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IBasicSearchParams,
  ISearchArtistsParams,
  IUserSearchApi,
} from '~/api/interfaces/user-search.interface';
import {
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IUserProfileSearch, IUserProfileShort } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): IUserSearchApi => ({
  basicSearch(params: IBasicSearchParams): Promise<IItemsListResponse<IUserProfileSearch>> {
    return $axios.$get('/users/search/basicSearch', {
      params,
    });
  },

  searchArtists(
    params: ISearchArtistsParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/users/search/searchArtists', { params });
  },
});
