import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICompletePaymentSetupSessionResponse,
  IGetMyStripeInfoResponse,
  IMakePurchasePayload,
  IMakePurchaseResponse,
  IPayFailedSubscriptionPlanPaymentResponse,
  IResumeSubscriptionPayload,
  IResumeSubscriptionPlanPayload,
  IResumeSubscriptionPlanResponse,
  IStartPaymentSetupSessionResponse,
  IStartSubscriptionPayload,
  IStripePaymentsApi,
  IUpdateSubscriptionPaymentMethodPayload,
  IUpdateSubscriptionPlanPaymentMethodPayload,
} from '~/api/interfaces/stripe-payments.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IStripePaymentMethod } from '~/shared/interfaces/stripe-payment-method.interface';
import { TStripeProductPrice } from '~/shared/types/stripe-product-price.type';

export default ($axios: NuxtAxiosInstance): IStripePaymentsApi => ({
  getMyStripeInfo(): Promise<IGetMyStripeInfoResponse> {
    return $axios.$get('/payments/stripe/getMyStripeInfo');
  },

  getPaymentMethodById(id: string): Promise<IStripePaymentMethod> {
    return $axios.$get('/payments/stripe/getPaymentMethodById', { params: { id } });
  },

  getPaymentMethodsList(): Promise<IItemsListResponse<IStripePaymentMethod>> {
    return $axios.$get('/payments/stripe/getAllPaymentMethodsList');
  },

  startPaymentSetupSession(): Promise<IStartPaymentSetupSessionResponse> {
    return $axios.$post('/payments/stripe/startPaymentSetupSession');
  },

  completePaymentSetupSession(setupIntent: string): Promise<ICompletePaymentSetupSessionResponse> {
    return $axios.$post('/payments/stripe/completePaymentSetupSession', { setupIntent });
  },

  getProductPricesList(productId: string): Promise<IItemsListResponse<TStripeProductPrice>> {
    return $axios.$get('/payments/stripe/getProductPricesList', { params: { productId } });
  },

  getPriceById(priceId: string, promoCode?: string): Promise<TStripeProductPrice> {
    return $axios.$get('/payments/stripe/getPriceById', { params: { priceId, promoCode } });
  },

  makePurchase(payload: IMakePurchasePayload): Promise<IMakePurchaseResponse> {
    return $axios.$post('/payments/stripe/makePurchase', payload);
  },

  startSubscriptionPlan(payload: IMakePurchasePayload): Promise<IMakePurchaseResponse> {
    return $axios.$post('/payments/stripe/startSubscriptionPlan', payload);
  },

  updateSubscriptionPlanPaymentMethod(
    payload: IUpdateSubscriptionPlanPaymentMethodPayload,
  ): Promise<void> {
    return $axios.$post('/payments/stripe/updateSubscriptionPlanPaymentMethod', payload);
  },

  setDefaultPaymentMethod(paymentMethodId: string): Promise<void> {
    return $axios.$post('/payments/stripe/setDefaultPaymentMethod', { paymentMethodId });
  },

  deletePaymentMethod(paymentMethodId: string): Promise<void> {
    return $axios.$post('/payments/stripe/deletePaymentMethod', { paymentMethodId });
  },

  payFailedSubscriptionPlanPayment(
    subscriptionPlanId: string,
  ): Promise<IPayFailedSubscriptionPlanPaymentResponse> {
    return $axios.$post('/payments/stripe/payFailedSubscriptionPlanPayment', {
      subscriptionPlanId,
    });
  },

  payFailedSubscriptionPayment(
    subscriptionId: string,
  ): Promise<IPayFailedSubscriptionPlanPaymentResponse> {
    return $axios.$post('/payments/stripe/payFailedSubscriptionPayment', {
      subscriptionId,
    });
  },

  cancelSubscriptionPlan(subscriptionPlanId: string): Promise<void> {
    return $axios.$post('/payments/stripe/cancelSubscriptionPlan', { subscriptionPlanId });
  },

  startSubscription(payload: IStartSubscriptionPayload): Promise<IMakePurchaseResponse> {
    return $axios.$post('/payments/stripe/startSubscription', payload);
  },

  updateSubscriptionPaymentMethod(payload: IUpdateSubscriptionPaymentMethodPayload): Promise<void> {
    return $axios.$post('/payments/stripe/updateSubscriptionPaymentMethod', payload);
  },

  cancelSubscription(subscriptionId: string): Promise<void> {
    return $axios.$post('/payments/stripe/cancelSubscription', { subscriptionId });
  },

  resumeSubscription(
    payload: IResumeSubscriptionPayload,
  ): Promise<IResumeSubscriptionPlanResponse> {
    return $axios.$post('/payments/stripe/resumeSubscription', payload);
  },

  resumeSubscriptionPlan(
    payload: IResumeSubscriptionPlanPayload,
  ): Promise<IResumeSubscriptionPlanResponse> {
    return $axios.$post('/payments/stripe/resumeSubscriptionPlan', payload);
  },
});
