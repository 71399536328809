













import { defineComponent, PropType } from '@nuxtjs/composition-api';

type TLandingButtonType = 'default' | 'primary' | 'secondary';
type TLandingButtonSize = 'large' | 'small';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<TLandingButtonType>,
      default: 'primary',
    },
    size: {
      type: String as PropType<TLandingButtonSize>,
      default: 'large',
    },
    nativeType: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
});
