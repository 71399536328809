import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICreateWorkshopPayload,
  ICreateWorkshopResponse,
  ISetWorkshopPublishedPayload,
  IUpdateWorkshopPayload,
  IWorkshopAdminApi,
} from '~/api/interfaces/workshop-admin.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import {
  IWorkshopForAdmin,
  IWorkshopListItemForAdmin,
} from '~/features/workshop/interfaces/workshop.interface';

export default ($axios: NuxtAxiosInstance): IWorkshopAdminApi => ({
  getWorkshopsList(): Promise<IItemsListResponse<IWorkshopListItemForAdmin>> {
    return $axios.$get('/workshop/admin/getWorkshopsList');
  },

  getWorkshopListItemById(id: string): Promise<IWorkshopListItemForAdmin> {
    return $axios.$get('/workshop/admin/getWorkshopListItemById', { params: { id } });
  },

  getWorkshopById(id: string): Promise<IWorkshopForAdmin> {
    return $axios.$get('/workshop/admin/getWorkshopById', { params: { id } });
  },

  createWorkshop(payload: ICreateWorkshopPayload): Promise<ICreateWorkshopResponse> {
    return $axios.$post('/workshop/admin/createWorkshop', payload);
  },

  updateWorkshop(payload: IUpdateWorkshopPayload): Promise<ICreateWorkshopResponse> {
    return $axios.$post('/workshop/admin/updateWorkshop', payload);
  },

  setWorkshopPublished(payload: ISetWorkshopPublishedPayload): Promise<void> {
    return $axios.$post('/workshop/admin/setWorkshopPublished', payload, { localError: true });
  },

  deleteWorkshop(id: string): Promise<void> {
    return $axios.$post('/workshop/admin/deleteWorkshop', { id });
  },
});
