export enum EUserPermission {
  ManageUserRoles = 'manage_user_roles',
  SearchUsers = 'search_users',
  ViewUserProfile = 'view_user_profile',
  EditCourses = 'edit_courses',
  ManageContentFiles = 'manage_content_files',
  ManageUserCourses = 'manage_user_courses',
  ManageOffers = 'manage_offers',
  ManageLandings = 'manage_landings',
  ManageGlobalPinnedPosts = 'manage_global_pinned_posts',
  ManageArtClub = 'manage_art_club',
  ManageArtContests = 'manage_art_contests',
  ManageUserSubscriptions = 'manage_user_subscriptions',
  LoginOnBehalf = 'login_on_behalf',
  SocialModeration = 'social_moderation',
  UserCrm = 'users_crm',
  DeleteUser = 'delete_user',
  ManageCommunities = 'manage_communities',
  ManageDemoFeedUsers = 'manage_demo_feed_users',
  ManageMentors = 'manage_mentors',
  ManageBlog = 'manage_blog',
  ManageWorkshops = 'manage_workshops',
  ManageBanners = 'manage_banners',
}
