
























import { defineComponent, PropType } from '@nuxtjs/composition-api';

const calcPropertyValue = (
  propName: string,
  originalValue: any,
  modificator: number,
): Record<string, number> => {
  const computedStyle = {};
  const timeQuantityOuter = originalValue.match(/^\d*\.?\d+/)[0];
  const timeUnit = originalValue.match(/s|(ms)$/)[0];
  const timeQuantityInner = Math.round(timeQuantityOuter * 1000 * modificator) / 1000;

  computedStyle[propName] = timeQuantityInner + timeUnit;

  return computedStyle;
};

export type TSpinner = 'default' | 'color-red' | 'color-teal';

export default defineComponent({
  name: 'UiSpinner',
  props: {
    type: {
      type: String as PropType<TSpinner>,
      default: 'default',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 80,
    },
    duration: {
      type: String,
      default: '1.2s',
    },
  },
  computed: {
    spinnerStyle(): object {
      return {
        width: `${this.size * 0.0875}px`,
        height: `${this.size * 0.0875}px`,
        margin: `-${this.size * 0.05}px 0 0 -${this.size * 0.05}px`,
      };
    },

    mainAnimation(): Record<string, string> {
      return { animationDuration: this.duration };
    },

    animDivs(): Record<string, number>[] {
      let divsStyles = [] as Record<string, number>[];
      for (let i = 1; i <= 8; i++) {
        divsStyles = [...divsStyles, calcPropertyValue('animationDelay', this.duration, -0.03 * i)];
      }

      return divsStyles;
    },
  },
  methods: {
    spinnerStylePosition(nthChild: number): Record<string, string> {
      const topLeftTable = [
        [0.7875, 0.7875],
        [0.85, 0.7],
        [0.8875, 0.6],
        [0.9, 0.5],
        [0.8875, 0.4],
        [0.85, 0.3],
        [0.7875, 0.2125],
        [0.7, 0.15],
      ];
      const [top, left] = topLeftTable[nthChild].map((i) => i * this.size);

      return { top: `${top}px`, left: `${left}px` };
    },
  },
});
