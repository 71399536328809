










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  TAppLogo,
  TAppLogoSize,
  uiAppLogoCommonTypes,
  uiAppLogoTypes,
} from '~/shared/components/UiAppLogo/config';
import { useArtClubRankCheck } from '~/shared/composable/useArtClubRankCheck';

export default defineComponent({
  name: 'UiAppLogo',
  props: {
    type: {
      type: String as PropType<TAppLogo>,
      default: 'default',
      validator: (value: string) => uiAppLogoTypes.includes(value),
    },
    size: {
      type: String as PropType<TAppLogoSize>,
      default: 'normal',
    },
    preventDynamicArtClubLogo: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isArtClubPro, isArtClubPlus } = useArtClubRankCheck();

    return {
      isArtClubPro,
      isArtClubPlus,
    };
  },
  computed: {
    commonType(): TAppLogo {
      return uiAppLogoCommonTypes.find((t) => this.type?.includes(t)) as TAppLogo;
    },

    imagePath(): string {
      const isTypeClub = this.commonType === 'club';

      if (this.size === 'small') return `img/new-logo/${this.commonType}--${this.size}`;
      else if (isTypeClub && !this.preventDynamicArtClubLogo && this.isArtClubPro)
        return 'img/new-logo/club-pro-white';
      else if (isTypeClub && !this.preventDynamicArtClubLogo && this.isArtClubPlus)
        return 'img/new-logo/club-plus-white';

      return `img/new-logo/${this.type}`;
    },

    srcSet(): string {
      const imagePath = require(`~/assets/${this.imagePath}.png`);
      const imagePath2x = require(`~/assets/${this.imagePath}@2x.png`);
      const imagePath3x = require(`~/assets/${this.imagePath}@3x.png`);

      if (this.size === 'normal')
        return `${imagePath} 180w, ${imagePath2x} 360w, ${imagePath3x} 540w`;

      return `${imagePath} 72w, ${imagePath2x} 144w, ${imagePath3x} 216w`;
    },
  },
});
