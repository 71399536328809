import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetUserCoursesParams,
  IGetCourseContents,
  INoteUserLessonEventsPayload,
  IGetModuleContentsParams,
  IGetCourseContentsParams,
  IUserCoursesApi,
} from '~/api/interfaces/user-courses.interface';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IUserCourse } from '~/features/courses/interfaces/courses.interface';
import { IUserModule } from '~/features/module/interfaces/module.interface';
import { IUserLesson } from '~/features/lesson/interfaces/lesson.interface';

export default ($axios: NuxtAxiosInstance): IUserCoursesApi => ({
  fakePurchase(courseId: string): Promise<void> {
    return $axios.post('/courses/fakePurchase', { courseId });
  },
  getCoursesList(params: IGetUserCoursesParams): Promise<IItemsListWithTotalResponse<IUserCourse>> {
    return $axios.$get('/courses/getCoursesList', { params });
  },
  getProLibraryCoursesList(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IUserCourse>> {
    return $axios.$get('/courses/getProLibraryCoursesList', { params });
  },
  getCourseContents(params: IGetCourseContentsParams): Promise<IGetCourseContents> {
    return $axios.$get('/courses/getCourseContents', { params });
  },
  getModuleContents(params: IGetModuleContentsParams): Promise<IUserModule> {
    return $axios.$get('/courses/getModuleContents', { params });
  },
  getLessonData(lessonId: string): Promise<IUserLesson> {
    return $axios.$get('/courses/getLessonData', { params: { lessonId } });
  },
  noteUserLessonEvents(payload: INoteUserLessonEventsPayload): Promise<void> {
    return $axios.$post('/courses/noteUserLessonEvents', payload);
  },
});
