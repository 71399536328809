import { Ref, useContext } from '@nuxtjs/composition-api';
import { IFormRule } from '~/shared/interfaces/form-rule.interface';
import { MIN_PASSWORD_LENGTH } from '~/shared/constants/form.constant';
import { VForm } from '~/shared/types/form.type';
import { useFormErrorMessages } from '~/shared/composable/useFormErrorMessages';

export interface IPasswordData {
  password: string;
  passwordConfirm: string;
}

export interface IUseChangePassword {
  passwordRules: Record<string, IFormRule[]>;
}

export const usePasswordValidation = (
  form: Ref<VForm>,
  passwordData: IPasswordData,
  trigger = 'blur',
): IUseChangePassword => {
  const ctx = useContext();

  const { getFormRequiredErrorMessage } = useFormErrorMessages();

  const validatePassword = (value: string, callback: (error?: Error) => void): void => {
    if (!value) {
      callback(new Error(getFormRequiredErrorMessage('form.password')));
    } else if (value.length < MIN_PASSWORD_LENGTH) {
      callback(new Error(ctx.i18n.t('form.minLength', { length: MIN_PASSWORD_LENGTH }) as string));
    }
  };

  const passwordRules: Record<string, IFormRule[]> = {
    password: [
      {
        trigger,
        validator: async (_, value: string, callback): Promise<void> => {
          validatePassword(value, callback);

          if (passwordData.passwordConfirm !== '') {
            await form.value.validateField('passwordConfirm');
          }

          callback();
        },
      },
    ],
    passwordConfirm: [
      {
        trigger,
        validator: (_, value: string, callback): void => {
          validatePassword(value, callback);

          if (passwordData.password !== value) {
            callback(new Error(ctx.i18n.t('form.samePassword') as string));
          }

          callback();
        },
      },
    ],
  };

  return {
    passwordRules,
  };
};
