import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAppLoginResponse,
  IAuthApi,
  ISignUpPayload,
  ISignUpResponse,
} from '~/api/interfaces/auth.interface';
import { TLoginOnBehalfPayload } from '~/api/types/auth.types';

export default ($axios: NuxtAxiosInstance): IAuthApi => ({
  signup(data: ISignUpPayload): Promise<ISignUpResponse> {
    return $axios.$post('/signUp', data, { localError: true });
  },

  loginOnBehalf(payload: TLoginOnBehalfPayload): Promise<void> {
    return $axios.$post('/auth/loginOnBehalf', payload, { localError: true });
  },

  loginOnBehalfCancel(refreshToken: string): Promise<void> {
    return $axios.$post('/auth/loginOnBehalfCancel', { refreshToken });
  },

  appLogin(appId: string): Promise<IAppLoginResponse> {
    return $axios.$post('/auth/appLogin', { appId }, { localError: true });
  },
});
