import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IOfferProduct } from '~/features/offers/interfaces/offer-product.interface';
import {
  ICheckOfferNameAvailablePayload,
  ICheckOfferNameAvailableResponse,
  ICreateOfferPayload,
  ICreateOfferResponse,
  IGetOffersResponse,
  IRenameOfferPayload,
  ISaveOfferPayload,
} from '~/api/interfaces/edit-offers.interface';
import { IOffer, IOfferFull } from '~/features/offers/interfaces/offer.interface';
import { TMoveOfferPayload } from '~/api/types/edit-offers.types';

export default ($axios: NuxtAxiosInstance): any => ({
  createOffer(payload: ICreateOfferPayload): Promise<ICreateOfferResponse> {
    return $axios.$post('/directories/offers/createOffer', payload);
  },
  getOffers(folderId?: string | null): Promise<IGetOffersResponse> {
    return $axios.$get('/directories/offers/getOffers', { params: { folderId } });
  },
  moveOffer(payload: TMoveOfferPayload): Promise<IOffer> {
    return $axios.$post('/directories/offers/moveOffer', payload);
  },
  renameOffer(payload: IRenameOfferPayload): Promise<IOffer> {
    return $axios.$post('/directories/offers/renameOffer', payload);
  },
  checkOfferNameAvailable(
    payload: ICheckOfferNameAvailablePayload,
  ): Promise<ICheckOfferNameAvailableResponse> {
    return $axios.$post('/directories/offers/checkOfferNameAvailable', payload);
  },
  getOfferById(offerId: string): Promise<IOfferFull> {
    return $axios.$get('/directories/offers/getOfferById', { params: { offerId } });
  },
  saveOffer(payload: ISaveOfferPayload): Promise<IOfferFull> {
    return $axios.$post('/directories/offers/saveOffer', payload);
  },
  searchProducts(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IOfferProduct>> {
    return $axios.$get('/directories/offers/searchProducts', { params });
  },
});
