import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosRequestConfig } from 'axios';
import {
  ICreateMediaFileTokenPayload,
  ICreateMediaFileTokenResponse,
  IFilePayload,
  IFilesApi,
  IUploadEncodedFileByTokenPayload,
} from '~/api/interfaces/files.interface';
import { IFile } from '~/features/files/interfaces/file.interface';

export default ($axios: NuxtAxiosInstance): IFilesApi => ({
  uploadContentFile(payload: IFilePayload, config?: AxiosRequestConfig): Promise<IFile> {
    return $axios.$post('/files/uploadContentFile', payload, config);
  },

  uploadUserFile(payload: IFilePayload, config?: AxiosRequestConfig): Promise<IFile> {
    return $axios.$post('/files/uploadUserFile', payload, config);
  },

  getFileById(id: string): Promise<IFile> {
    return $axios.$get('/files/getFileById', {
      params: { id },
    });
  },

  createMediaFileToken(
    payload: ICreateMediaFileTokenPayload,
  ): Promise<ICreateMediaFileTokenResponse> {
    return $axios.$post('/files/createMediaFileToken', payload);
  },

  uploadEncodedFileByToken(
    payload: IUploadEncodedFileByTokenPayload,
    config?: AxiosRequestConfig,
  ): Promise<IFile> {
    return $axios.$post('/files/uploadEncodedFileByToken', payload, config);
  },
});
