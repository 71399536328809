












































import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api';
import FormInput from '~/shared/components/FormInput.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import { IFormRule } from '~/shared/interfaces/form-rule.interface';
import { useFormValidation } from '~/shared/composable/useFormValidation/useFormValidation';
import { MAX_CONTACT_US_MESSAGE_LENGTH } from '~/shared/constants';
import { VForm } from '~/shared/types/form.type';

export default defineComponent({
  name: 'ContactUsForm',
  components: { UiButton, FormInput },
  emits: ['submit'],
  setup() {
    const formData = reactive({
      name: '',
      email: '',
      message: '',
    });

    const ctx = useContext();
    const rules: Record<string, IFormRule[]> = {
      name: [{ required: true, message: ctx.i18n.t('form.required') }],
      email: [
        { required: true, message: ctx.i18n.t('form.required') },
        { type: 'email', message: ctx.i18n.t('form.invalidEmail') },
      ],
      message: [{ required: true, message: ctx.i18n.t('form.required') }],
    };

    const { onValidate, errors, hasErrors, validate } = useFormValidation(formData);

    return {
      onValidate,
      errors,
      hasErrors,
      validate,
      formData,
      rules,
      MAX_CONTACT_US_MESSAGE_LENGTH,
    };
  },

  computed: {
    questionLeftCharactersTitle(): string {
      return `Characters left: ${
        MAX_CONTACT_US_MESSAGE_LENGTH - (this.formData.message?.length || 0)
      }`;
    },
  },
  methods: {
    clearData(): void {
      (this.$refs.form as VForm).resetFields();
    },

    async onSubmit() {
      try {
        await this.validate(this.$refs.form as VForm);

        await this.$api.customerSupport.sendCustomerSupport(this.formData);

        this.$emit('submit');
      } catch (error) {
        console.error(error);
      }
    },
  },
});
