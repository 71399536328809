









































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
import { TranslateResult } from 'vue-i18n';
import { VModal } from '~/shared/types/modal.type';
import { useBodyScrollLock } from '~/shared/composable/useBodyScrollLock';

export default defineComponent({
  name: 'UiModal',
  props: {
    title: {
      type: String as PropType<TranslateResult | string>,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    fullscreen: Boolean,
    beforeClose: {
      type: Function as PropType<(hide: Function) => void>,
      default: (close: Function) => close(),
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['hide'],
  setup() {
    const { makeDisabledBodyScroll, makeEnabledBodyScroll } = useBodyScrollLock();

    return { makeDisabledBodyScroll, makeEnabledBodyScroll };
  },
  data() {
    return {
      isVisible: false,
      isMouseDownOnModalMask: false,
      isMouseUpOnModalMask: false,
    };
  },
  computed: {
    classes(): string {
      return `${this.customClass || ''} ${this.hideHeader ? 'modal--hide-header' : ''}`;
    },
  },
  deactivated() {
    if (this.isVisible) this.hide();
  },
  beforeDestroy() {
    if (this.isVisible) this.enableBodyScroll();
  },
  methods: {
    async show(): Promise<void> {
      this.isVisible = true;

      await this.$nextTick();

      this.makeDisabledBodyScroll(this.$el.querySelector('.js-ui-modal') as HTMLElement);
    },

    onCrossClick(): void {
      this.enableBodyScroll();

      (this.$refs.modal as VModal).handleClose();
    },

    onClose(): void {
      this.enableBodyScroll();

      this.$emit('hide');
    },

    hide(): void {
      this.enableBodyScroll();

      this.isVisible = false;
    },

    enableBodyScroll() {
      const element = this?.$el?.querySelector('.js-ui-modal') as HTMLElement;

      if (isNil(element)) return;

      this.makeEnabledBodyScroll(element);
    },

    getIsTargetModalMask(event: MouseEvent): boolean {
      const targetElement = event.target as HTMLElement;
      const modalParentElement = (this.$refs.modal as VModal)?.$parent?.$el;

      return targetElement === modalParentElement;
    },

    onMouseUp(event: MouseEvent): void {
      if (!this.closeOnClickModal) return;

      this.isMouseUpOnModalMask = this.getIsTargetModalMask(event);
    },

    onMouseDown(event: MouseEvent): void {
      if (!this.closeOnClickModal) return;

      this.isMouseDownOnModalMask = this.getIsTargetModalMask(event);
    },

    onBeforeClose(close: Function): void {
      // dirty hack to prevent modal closing when cursor is dragging from modal body to mask
      // https://github.com/ElemeFE/element/issues/15000
      if (this.isMouseUpOnModalMask !== this.isMouseDownOnModalMask) return;

      this.beforeClose(close);
    },
  },
});
