import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAddModeratorPayload,
  ICommunitiesAdminModeratorsApi,
  ICommunitiesAdminModeratorsSearchUsers,
  IGetModeratorsListParams,
} from '~/api/interfaces/communities-admin-moderators.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import {
  IUserProfileShort,
  IUserProfileShortForCommunityModerators,
} from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesAdminModeratorsApi => ({
  searchUsers(
    params: ICommunitiesAdminModeratorsSearchUsers,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShortForCommunityModerators>> {
    return $axios.$get('/communities/admin/moderators/searchUsers', { params });
  },

  getModeratorsList(
    params: IGetModeratorsListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/communities/admin/moderators/getModeratorsList', { params });
  },

  addModerator(payload: IAddModeratorPayload): Promise<void> {
    return $axios.$post('/communities/admin/moderators/addModerator', payload);
  },

  removeModerator(payload: IAddModeratorPayload): Promise<void> {
    return $axios.$post('/communities/admin/moderators/removeModerator', payload);
  },
});
