




































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { mapActions } from 'vuex';
import UiAppLogo from '~/shared/components/UiAppLogo/index.vue';
import { TAppLogo } from '~/shared/components/UiAppLogo/config';
import AdditionalMenu from '~/shared/components/layouts/parts/menus/AdditionalMenu.vue';
import ArtClubMenu from '~/shared/components/layouts/parts/menus/ArtClubMenu.vue';
import { useMembershipsCheck } from '~/shared/composable/useMembershipsCheck';
import SocialMenu from '~/shared/components/layouts/parts/menus/SocialMenu.vue';
import AppMenu from '~/shared/components/layouts/parts/menus/AppMenu.vue';
import { guestMenuItemsForSocialSidebar } from '~/shared/config/guest-menu.config';
import StoreBadges from '~/shared/components/layouts/parts/StoreBadges.vue';

type TSocialSidebar = 'default' | 'art-club';

export default defineComponent({
  name: 'SocialSidebarDesktop',
  components: { StoreBadges, AppMenu, SocialMenu, ArtClubMenu, AdditionalMenu, UiAppLogo },
  props: {
    type: {
      type: String as PropType<TSocialSidebar>,
      default: 'default',
    },
    appLogo: {
      type: String as PropType<TAppLogo>,
      default: 'default',
    },
    customClass: {
      type: [String, Object, Array],
      default: null,
    },
  },
  setup() {
    const { isArtClubMembership } = useMembershipsCheck();

    return { isArtClubMembership, guestMenuItemsForSocialSidebar };
  },
  computed: {
    isArtClubMenuShown(): boolean {
      return this.type === 'art-club' && this.isArtClubMembership;
    },

    logoLinkPath(): string {
      switch (this.type) {
        case 'art-club':
          return this.localePath('/users/art-club/browse');
        default:
          return this.localePath('/feed');
      }
    },
  },
  async mounted() {
    if (this.$auth.loggedIn) await this.getCountOfJoinRequestsWhereUserIsModerator();
  },
  methods: {
    ...mapActions('notifications', {
      getCountOfJoinRequestsWhereUserIsModerator: 'getCountOfJoinRequestsWhereUserIsModerator',
    }),
    refreshFeed() {
      const feedPageRouteName = 'feed';
      const currentPageRouteName: string | undefined = this.getRouteBaseName();

      if (currentPageRouteName === feedPageRouteName && !this.$isMobile()) {
        this.$bus.emit('refresh-feed');

        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
});
