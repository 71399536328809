import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { clone, isNil, omit } from 'ramda';
import {
  IBannerForAdmin,
  IBannerForAdminStore,
} from '~/features/banners/interfaces/banner.interface';
import { TButtonType } from '~/shared/components/UiButton/config';
import { EFiltersCategory } from '~/features/users/enums/filters-category.enum';
import { filterStructuresMap } from '~/features/users/config/filter-structures-map.config';
import { TTypedFilterForComponent } from '~/features/users/types/typed-filters.type';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface IState {
  isStateChanged: boolean;
  banner: IBannerForAdminStore;
}

const getDefaultState = (): IState => ({
  isStateChanged: false,
  banner: {
    id: '',
    name: '',
    image: null,
    button: {
      name: '',
      url: '',
      style: {
        type: 'with-red-grad-border',
      },
    },
    segmentation: [],
  },
});

interface ISetImagePayload {
  imageId: string;
  imageUrl: string;
}

interface IUpdateSegmentationItemPayload {
  item: TTypedFilterForComponent;
  index: number;
}

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async createBanner(ctx) {
    try {
      const { id } = await this.$api.bannerAdmin.createBanner({
        ...omit(['id', 'segmentation'], ctx.state.banner),
        imageId: ctx.state.banner.image?.id,
        segmentation: ctx.state.banner.segmentation.map((item) =>
          item.category === EFiltersCategory.Tag
            ? {
                category: item.category,
                condition: item.condition,
                tagIds: item.selectedTags.map((tag) => tag.value as string),
              }
            : item,
        ),
      });

      ctx.commit('setIsStateChanged', false);

      return id;
    } catch (error) {
      console.error(error);

      throw new Error('Error while creating banner');
    }
  },

  async updateBanner(ctx) {
    await this.$api.bannerAdmin.updateBanner({
      ...omit(['segmentation'], ctx.state.banner),
      imageId: ctx.state.banner.image?.id,
      segmentation: ctx.state.banner.segmentation.map((item) =>
        item.category === EFiltersCategory.Tag
          ? {
              category: item.category,
              condition: item.condition,
              tagIds: item.selectedTags.map((tag) => tag.value as string),
            }
          : item,
      ),
    });

    ctx.commit('setIsStateChanged', false);
  },

  async getBannerDetails(ctx, id: string) {
    const banner = await this.$api.bannerAdmin.getBannerById(id);

    ctx.commit('setBanner', banner);
  },
};

export const mutations: MutationTree<RootState> = {
  setBanner(state, banner: IBannerForAdmin) {
    state.banner = {
      ...banner,
      segmentation: isNil(banner.segmentation)
        ? []
        : banner.segmentation.map((item) => {
            if (item.category === EFiltersCategory.Tag)
              return {
                category: item.category,
                condition: item.condition,
                selectedTags: item.tags.map((tag) => ({
                  label: tag.name,
                  value: tag.id as string,
                })),
              };
            else if (item.category === EFiltersCategory.Workshop)
              return {
                category: item.category,
                condition: item.condition,
                workshopIds: item.workshops.map((workshop) => workshop.id as string),
              };
            else if (item.category === EFiltersCategory.Offer)
              return {
                category: item.category,
                condition: item.condition,
                offerIds: item.offers.map((offer) => offer.id as string),
              };

            return {
              category: item.category,
              condition: item.condition,
              labelIds: item.labels.map((label) => label.id as number),
            };
          }),
    };
  },

  setName(state, name: string) {
    state.isStateChanged = true;
    state.banner.name = name;
  },

  setImage(state, payload: ISetImagePayload) {
    state.isStateChanged = true;
    state.banner.image = state.banner.image ?? getDefaultFileState();
    state.banner.image.id = payload.imageId;
    state.banner.image.url = payload.imageUrl;
  },

  setButtonName(state, name: string) {
    state.isStateChanged = true;
    state.banner.button.name = name;
  },

  setButtonUrl(state, url: string) {
    state.isStateChanged = true;
    state.banner.button.url = url;
  },

  setButtonType(state, type: TButtonType) {
    state.isStateChanged = true;
    state.banner.button.style.type = type;
  },

  addSegmentation(state, category: EFiltersCategory) {
    state.isStateChanged = true;
    state.banner.segmentation = [
      ...state.banner.segmentation,
      clone(filterStructuresMap[category]),
    ];
  },

  updateSegmentation(state, payload: IUpdateSegmentationItemPayload) {
    state.isStateChanged = true;
    state.banner.segmentation = state.banner.segmentation?.map((item, index) =>
      index === payload.index ? payload.item : item,
    );
  },

  deleteSegmentation(state, index: number) {
    state.isStateChanged = true;
    state.banner.segmentation = state.banner.segmentation?.filter((_, i) => i !== index);
  },

  setIsStateChanged(state, isStateChanged: boolean) {
    state.isStateChanged = isStateChanged;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IState, RootState> = {};
