import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { IInstructor } from '~/features/courses/interfaces/instructor.interface';
import { IUserModuleShort } from '~/features/module/interfaces/module.interface';
import {
  IGetCourseContents,
  IGetCourseContentsParams,
} from '~/api/interfaces/user-courses.interface';
import {
  MASTERY_PROGRAM_COURSE_ID,
  MASTERY_PROGRAM_ORIENTATION_COURSE_ID,
  MASTERY_PROGRAM_ORIENTATION_SLUG,
  MASTERY_PROGRAM_SLUG,
} from '~/features/courses/constants/index.constant';

export interface ICourseState {
  courseContents: IGetCourseContents;
}

const getDefaultState = (): ICourseState => {
  return {
    courseContents: {
      id: '',
      title: '',
      landingLink: '',
      description: '',
      isAddedToArtClubProLibrary: false,
      instructors: [],
      modules: [],
    },
  };
};

export const state = (): ICourseState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  isSingleModuleCourse(state): boolean {
    return state.courseContents.modules?.length === 1;
  },

  courseDescription(state): string {
    return state.courseContents.description;
  },

  courseInstructors(state): IInstructor[] {
    return state.courseContents.instructors;
  },

  getFilteredModules: (state) => (searchName: string): IUserModuleShort[] => {
    if (!searchName) return state.courseContents.modules;

    return state.courseContents.modules.filter((module: IUserModuleShort) => {
      return module.title.toLowerCase().includes(searchName.toLowerCase());
    });
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getCourseContents(ctx, params: IGetCourseContentsParams) {
    const courseId =
      params.courseId === MASTERY_PROGRAM_SLUG
        ? MASTERY_PROGRAM_COURSE_ID
        : params.courseId === MASTERY_PROGRAM_ORIENTATION_SLUG
        ? MASTERY_PROGRAM_ORIENTATION_COURSE_ID
        : params.courseId;

    const response = await this.$api.userCourses.getCourseContents({
      courseId,
      previewMode: params.previewMode,
    });

    ctx.commit('setCourseContents', response);
  },
};

export const mutations: MutationTree<RootState> = {
  setCourseContents(state, courseContents) {
    state.courseContents = courseContents;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
