import { isNil } from 'ramda';
import { FILTER_PAGE_SLUG_REGEXP } from '~/shared/constants/regexp.contant';

export const prepareStringToSlug = (str: string, filterEdgesFromSpace?: true): string => {
  if (isNil(str)) return '';

  const EDGES_REGEX = /[A-Za-z0-9]/;
  let newSlug = (str.toLowerCase().replaceAll(' ', '-').match(FILTER_PAGE_SLUG_REGEXP) || []).join(
    '',
  );
  const firstChar = newSlug[0];
  const lastChar = newSlug[newSlug.length - 1];

  if (!firstChar?.match(EDGES_REGEX)?.length && filterEdgesFromSpace) newSlug = newSlug.slice(1);
  if (!lastChar?.match(EDGES_REGEX)?.length && filterEdgesFromSpace) newSlug = newSlug.slice(0, -1);

  return newSlug;
};
