import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { pick } from 'ramda';
import { EBlogItemStatus } from '~/features/blog/enums/blog-item-status.enum';
import { IBlogBannerItemForAdmin } from '~/features/blog/interfaces/blog-banner-item.interface';
import { IFile } from '~/features/files/interfaces/file.interface';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface IBlogBannerState {
  isStateChanged: boolean;
  banner: IBlogBannerItemForAdmin;
}

const getDefaultState = (): IBlogBannerState => ({
  isStateChanged: false,
  banner: {
    id: '',
    status: EBlogItemStatus.Draft,
    title: '',
    displayOrder: 0,
    description: '',
    image: null,
    buttonName: '',
    buttonUrl: '',
  },
});

export const state = (): IBlogBannerState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getBanner(ctx, bannerId: string) {
    const banner = await this.$api.blogBannerAdmin.getBannerById(bannerId);

    ctx.commit('setBanner', banner);
  },

  async createBanner(ctx): Promise<string> {
    const payload = {
      ...pick(['title', 'description', 'buttonName', 'buttonUrl'], ctx.state.banner),
      imageId: ctx.state.banner.image?.id,
    };

    const id = await this.$api.blogBannerAdmin.createBanner(payload);

    ctx.commit('setIsStatusChanged', false);

    return id;
  },

  async updateBanner(ctx): Promise<void> {
    const payload = {
      ...pick(['id', 'title', 'description', 'buttonName', 'buttonUrl'], ctx.state.banner),
      imageId: ctx.state.banner.image?.id,
    };

    await this.$api.blogBannerAdmin.updateBanner(payload);

    ctx.commit('setIsStatusChanged', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setBanner(state, banner) {
    state.banner = banner;
  },

  setTitle(state, title: string) {
    state.isStateChanged = true;
    state.banner.title = title;
  },

  setDescription(state, description: string) {
    state.isStateChanged = true;
    state.banner.description = description;
  },

  setImage(state, image: IFile) {
    state.isStateChanged = true;
    state.banner.image = state.banner.image ?? getDefaultFileState();
    state.banner.image.id = image.id;
    state.banner.image.url = image.url;
  },

  setButtonName(state, buttonName: string) {
    state.isStateChanged = true;
    state.banner.buttonName = buttonName;
  },

  setButtonUrl(state, buttonUrl: string) {
    state.isStateChanged = true;
    state.banner.buttonUrl = buttonUrl;
  },

  setIsStatusChanged(state, isStateChanged: boolean) {
    state.isStateChanged = isStateChanged;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IBlogBannerState, RootState> = {
  isNewBanner(state): boolean {
    return !state.banner.id?.length;
  },
};
