import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICheckIsSlugAvailableParams,
  ICommunitiesAdminApi,
  ICreateCommunityPayload,
  IGetCommunitiesListParams,
  IReorderCommunityPayload,
  ISaveCommunityPayload,
} from '~/api/interfaces/communities-admin.interface';
import { ICommunityDataForAdmin } from '~/features/communities/interfaces/community-data.interface';
import { ICommunityListItemForAdmin } from '~/features/communities/interfaces/community-list-item.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesAdminApi => ({
  createCommunity(payload: ICreateCommunityPayload): Promise<IEntityWithId> {
    return $axios.$post('/communities/admin/createCommunity', payload);
  },

  saveCommunity(payload: ISaveCommunityPayload): Promise<void> {
    return $axios.$post('/communities/admin/saveCommunity', payload);
  },

  deleteCommunity(id: string): Promise<void> {
    return $axios.$post('/communities/admin/deleteCommunity', { id });
  },

  reorderCommunity(payload: IReorderCommunityPayload): Promise<void> {
    return $axios.$post('/communities/admin/reorderCommunity', payload);
  },

  getCommunityInfoById(id: string): Promise<ICommunityDataForAdmin> {
    return $axios.$get('/communities/admin/getCommunityInfoById', { params: { id } });
  },

  getCommunitiesListItemById(id: string): Promise<ICommunityListItemForAdmin> {
    return $axios.$get('/communities/admin/getCommunitiesListItemById', { params: { id } });
  },

  getCommunitiesList(
    params: IGetCommunitiesListParams,
  ): Promise<IItemsListWithTotalResponse<ICommunityListItemForAdmin>> {
    return $axios.$get('/communities/admin/getCommunitiesList', { params });
  },

  checkIsSlugAvailable(slug: string): Promise<ICheckIsSlugAvailableParams> {
    return $axios.$post('/communities/admin/checkIsSlugAvailable', { slug });
  },
});
