import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IUserProfileShortWithMutual } from '~/features/users/interfaces/users.interface';
import {
  ICommunitiesApi,
  IGetAvailableCommunitiesParams,
  IGetCommunityFeedPayload,
  IGetCommunityFollowersListParams,
  IGetCommunityMembersListParams,
  IGetDashboardResponse,
  IGetMembersCardParams,
  IGetMembersCardResponse,
} from '~/api/interfaces/communitites.interface';
import { ICommunityDataForUser } from '~/features/communities/interfaces/community-data.interface';
import { TCommunityListItemForUser } from '~/features/communities/types/comunity-list-item.type';
import { IGetFeedResponse } from '~/api/interfaces/feed.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesApi => ({
  getFeed(payload: IGetCommunityFeedPayload): Promise<IGetFeedResponse> {
    return $axios.$post('/communities/getFeed', payload);
  },

  getAvailableCommunitiesList(
    params: IGetAvailableCommunitiesParams,
  ): Promise<IItemsListWithTotalResponse<TCommunityListItemForUser>> {
    return $axios.$get('/communities/getAvailableCommunitiesList', { params });
  },

  getMyCommunitiesList(
    params: IGetAvailableCommunitiesParams,
  ): Promise<IItemsListWithTotalResponse<TCommunityListItemForUser>> {
    return $axios.$get('/communities/getMyCommunitiesList', { params });
  },

  getCommunityListItemById(id: string): Promise<TCommunityListItemForUser> {
    return $axios.$get('/communities/getCommunityListItemById', {
      params: { id, mutualMembersProfilesCount: 2 },
    });
  },

  getCommunityById(id: string): Promise<ICommunityDataForUser> {
    return $axios.$get('/communities/getCommunityById', { params: { id } });
  },

  getCommunityBySlug(slug: string): Promise<ICommunityDataForUser> {
    return $axios.$get('/communities/getCommunityBySlug', { params: { slug }, localError: true });
  },

  getFollowersList(
    params: IGetCommunityFollowersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShortWithMutual>> {
    return $axios.$get('/communities/getFollowersList', {
      params: { ...params, mutualMembersProfilesCount: 2 },
    });
  },

  getMembersList(
    params: IGetCommunityMembersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShortWithMutual>> {
    return $axios.$get('/communities/getMembersList', {
      params: { ...params, mutualMembersProfilesCount: 2 },
    });
  },

  getRecommendedCommunitiesList(
    maxCount: number,
  ): Promise<IItemsListResponse<TCommunityListItemForUser>> {
    return $axios.$get('/communities/getRecommendedCommunitiesList', {
      params: { mutualMembersProfilesCount: 2, maxCount },
    });
  },

  getMembersCard(params: IGetMembersCardParams): Promise<IGetMembersCardResponse> {
    return $axios.$get('/communities/getMembersCard', { params });
  },

  getDashboard(): Promise<IGetDashboardResponse> {
    return $axios.$get('/communities/getDashboard');
  },
});
