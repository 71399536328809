import { defineNuxtPlugin } from '@nuxtjs/composition-api';

export default defineNuxtPlugin(({ route, $cookies, $api, $auth }) => {
  try {
    const referrerPage = $cookies.get('referer') || document.referrer || undefined;

    if (referrerPage) {
      const url = new URL(referrerPage);
      const query = new URLSearchParams(url.search);
      const trackingId = query.get('trackingId');

      if (trackingId) {
        if (trackingId === $cookies.get('affiliateTrackingId')) return;

        $cookies.set('affiliateTrackingId', trackingId, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });

        if ($auth.loggedIn) $api.affiliate.assignTrackingId(trackingId);

        return;
      }
    }

    const affiliateRef = route.query.ref as string;

    if (!affiliateRef) return;

    if (affiliateRef === $cookies.get('ref')) return;

    $cookies.set('ref', affiliateRef, {
      maxAge: 60 * 60 * 24 * 30, // 30 days
      path: '/',
    });

    const userIp = $cookies.get('userIp');
    const userAgent = navigator?.userAgent;

    $api.affiliate
      .registerClick({
        referrerPage,
        ref: affiliateRef,
        userAgent,
        userIp,
      })
      .then(({ trackingId }) => {
        $cookies.set('affiliateTrackingId', trackingId, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });

        if ($auth.loggedIn) $api.affiliate.assignTrackingId(trackingId);
      })
      .catch((error) => console.error(error));
  } catch (error) {
    console.error(error);
  }
});
