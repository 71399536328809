import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil, omit, pick } from 'ramda';
import { IContestForAdmin } from '~/features/contests/interfaces/contest.interface';
import {
  IGetSubmissionsListParams,
  ISaveContestPayload,
} from '~/api/interfaces/art-club-contests-admin.interface';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IRelatedContentVideo } from '~/features/contests/interfaces/related-content-video.interface';
import {
  IPrizeCategory,
  IPrizeCategoryForContest,
} from '~/features/contests/interfaces/prize-category.interface';
import { IContestSubmissionSearch } from '~/features/contests/interfaces/contest-submission.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { EContestStatus } from '~/features/contests/enums/contest-status.enum';
import { DEFAULT_ADMIN_TIMEZONE } from '~/shared/constants';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface IContestState {
  isStateChanged: boolean;
  contest: IContestForAdmin;
  allPrizeCategories: IPrizeCategoryForContest[];
  submissions: IContestSubmissionSearch[];
  submissionsCount: number;
}

interface IAddPrizeCategoryPayload {
  category: IPrizeCategoryForContest;
  prize: string;
}

interface IEditPrizeCategoryPayload {
  categoryId: string;
  prize: string;
}

interface ISetImagePayload {
  imageUrl: string;
  imageId: string;
}

interface ISetCategoryWinnerPayload {
  categoryId: string;
  winner: IContestSubmissionSearch;
}

const getDefaultState = (): IContestState => ({
  isStateChanged: false,
  contest: {
    id: '',
    name: '',
    description: '',
    shortDescription: '',
    requirementsText: '',
    requirementsSize: '',
    requirementsMediums: '',
    requirementsMedia: '',
    image: null,
    startedAt: '',
    votingStartDate: '',
    votingEndDate: '',
    limitParticipantsCount: null,
    isPublished: false,
    status: EContestStatus.Draft,
    createdAt: '',
    relatedContentVideos: [],
    prizeCategories: [],
  },
  allPrizeCategories: [],
  submissions: [],
  submissionsCount: 0,
});

export const state = (): IContestState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getContest(ctx, contestId: string) {
    const contest = await this.$api.artClubContestsAdmin.getContestDetails(contestId);
    const { items } = await this.$api.artClubContestsAdmin.getPrizeCategories();

    ctx.commit('setContest', {
      ...contest,
      votingStartDate: contest.votingStartDate
        ? this.$dayjs(contest.votingStartDate)
            .tz(DEFAULT_ADMIN_TIMEZONE)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
            .format()
        : '',
      votingEndDate: contest.votingEndDate
        ? this.$dayjs(contest.votingEndDate)
            .tz(DEFAULT_ADMIN_TIMEZONE)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
            .format()
        : '',
    });
    ctx.commit('setAllPrizeCategories', items);
  },

  async getContestSubmissions(ctx, params: IGetSubmissionsListParams) {
    const response = await this.$api.artClubContestsAdmin.getSubmissionsList(params);

    ctx.commit('setSubmissions', response);
  },

  async saveContest(ctx) {
    const payload: ISaveContestPayload = omit(
      ['relatedContentVideos', 'prizeCategories', 'status', 'image'],
      ctx.state.contest,
    );

    payload.startedAt = payload.startedAt
      ? this.$dayjs(payload.startedAt).tz(DEFAULT_ADMIN_TIMEZONE, true).format()
      : undefined;
    payload.votingStartDate = this.$dayjs(payload.votingStartDate)
      .tz(DEFAULT_ADMIN_TIMEZONE, true)
      .format();
    payload.votingEndDate = this.$dayjs(payload.votingEndDate)
      .tz(DEFAULT_ADMIN_TIMEZONE, true)
      .format();
    payload.imageId = ctx.state.contest.image?.id;

    if (ctx.state.contest.relatedContentVideos?.length)
      payload.relatedContentVideoIds = ctx.state.contest.relatedContentVideos.map((v) => v.videoId);

    if (ctx.state.contest.prizeCategories?.length)
      payload.prizeCategories = ctx.state.contest.prizeCategories.map((c) => ({
        ...pick(['categoryId', 'prizeAmountUsd'], c),
        winnerPostId: c.winner?.postId || null,
      }));

    await this.$api.artClubContestsAdmin.saveContest(payload);

    ctx.commit('setIsStateChanged', false);
  },

  async deleteContest(ctx) {
    await this.$api.artClubContestsAdmin.deleteContest(ctx.state.contest.id);

    ctx.commit('setIsStateChanged', false);
  },

  async closeContest(ctx) {
    await this.$api.artClubContestsAdmin.saveContest({
      id: ctx.state.contest.id,
      prizeCategories: ctx.state.contest.prizeCategories.map((c) => ({
        ...pick(['categoryId', 'prizeAmountUsd'], c),
        winnerPostId: c.winner?.postId || null,
      })),
    });

    await this.$api.artClubContestsAdmin.closeContest(ctx.state.contest.id);

    await ctx.dispatch('getContest', ctx.state.contest.id);

    ctx.commit('setIsStateChanged', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setContest(state, contest: IContestForAdmin) {
    state.contest = contest;
  },

  setName(state, name: string) {
    state.contest.name = name;

    state.isStateChanged = true;
  },

  setImage(state, image: ISetImagePayload) {
    state.contest.image = state.contest.image ?? getDefaultFileState();
    state.contest.image.id = image.imageId;
    state.contest.image.url = image.imageUrl;

    state.isStateChanged = true;
  },

  setDescription(state, description: string) {
    state.contest.description = description;

    state.isStateChanged = true;
  },

  setShortDescription(state, shortDescription: string) {
    state.contest.shortDescription = shortDescription;
  },

  setRequirementsText(state, requirementsText: string) {
    state.contest.requirementsText = requirementsText;

    state.isStateChanged = true;
  },

  setIsPublished(state, isPublished: boolean) {
    state.contest.isPublished = isPublished;

    state.isStateChanged = true;
  },

  setStartedAt(state, startedAt: string) {
    state.contest.startedAt = startedAt;

    state.isStateChanged = true;
  },

  setVotingStartDate(state, votingStartDate: string) {
    state.contest.votingStartDate = votingStartDate;

    state.isStateChanged = true;
  },

  setVotingEndDate(state, votingEndDate: string) {
    state.contest.votingEndDate = votingEndDate;

    state.isStateChanged = true;
  },

  setLimitParticipantsCount(state, limitParticipantsCount: number | null) {
    state.contest.limitParticipantsCount = isNil(limitParticipantsCount)
      ? limitParticipantsCount
      : parseInt(`${limitParticipantsCount}`) || 0;

    state.isStateChanged = true;
  },

  setRequirementsSize(state, requirementsSize: string) {
    state.contest.requirementsSize = requirementsSize;

    state.isStateChanged = true;
  },

  setRequirementsMediums(state, requirementsMediums: string) {
    state.contest.requirementsMediums = requirementsMediums;

    state.isStateChanged = true;
  },

  setRequirementsMedia(state, requirementsMedia: string) {
    state.contest.requirementsMedia = requirementsMedia;

    state.isStateChanged = true;
  },

  addRelatedVideos(state, relatedVideo: IArtClubUserVideo[]) {
    const newVideos = relatedVideo.map((video) => ({
      ...pick(['thumbnailImage', 'title'], video),
      videoId: video.id,
    }));

    state.contest.relatedContentVideos = [...state.contest.relatedContentVideos, ...newVideos];

    state.isStateChanged = true;
  },

  removeRelatedVideo(state, videoId: string) {
    state.contest.relatedContentVideos = state.contest.relatedContentVideos.filter(
      (video) => video.videoId !== videoId,
    );

    state.isStateChanged = true;
  },

  addPrizeCategory(state, payload: IAddPrizeCategoryPayload) {
    state.contest.prizeCategories = [
      ...state.contest.prizeCategories,
      {
        categoryId: payload.category.id,
        categoryName: payload.category.name,
        prizeAmountUsd: payload.prize,
        winnerSelectable: payload.category.winnerSelectable,
        winner: null,
      },
    ];

    state.isStateChanged = true;
  },

  editPrizeCategory(state, payload: IEditPrizeCategoryPayload) {
    const current = state.contest.prizeCategories.find(
      (c) => c.categoryId === payload.categoryId,
    ) as IPrizeCategory;

    current.prizeAmountUsd = payload.prize;
  },

  setSubmissions(state, payload: IItemsListWithTotalResponse<IContestSubmissionSearch>) {
    state.submissions = payload.items;
    state.submissionsCount = payload.total;
  },

  setAllPrizeCategories(state, allPrizeCategories: IPrizeCategoryForContest[]) {
    state.allPrizeCategories = allPrizeCategories;
  },

  setCategoryWinner(state, { categoryId, winner }: ISetCategoryWinnerPayload) {
    const category = state.contest.prizeCategories.find(
      (c) => c.categoryId === categoryId,
    ) as IPrizeCategory;

    category.winner = {
      ...pick(['postId', 'votesCount'], winner),
      user: winner.author,
    };

    state.isStateChanged = true;
  },

  deleteCategoryWinner(state, categoryId: string) {
    const current = state.contest.prizeCategories.find(
      (c) => c.categoryId === categoryId,
    ) as IPrizeCategory;

    current.winner = null;
  },

  setIsStateChanged(state, isStateChanged: boolean) {
    state.isStateChanged = isStateChanged;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IContestState, RootState> = {
  isSubmissionStatus(state): boolean {
    return state.contest.status === EContestStatus.Submission;
  },
  isVotingStatus(state): boolean {
    return state.contest.status === EContestStatus.Voting;
  },
  isClosedStatus(state): boolean {
    return state.contest.status === EContestStatus.Closed;
  },

  contestId(state): string {
    return state.contest.id;
  },
  name(state): string {
    return state.contest.name;
  },

  imageUrl(state): string | undefined {
    return state.contest.image?.url;
  },

  description(state): string {
    return state.contest.description;
  },

  shortDescription(state): string {
    return state.contest.shortDescription;
  },

  requirementsText(state): string {
    return state.contest.requirementsText;
  },

  requirementsSize(state): string {
    return state.contest.requirementsSize;
  },

  requirementsMediums(state): string {
    return state.contest.requirementsMediums;
  },

  requirementsMedia(state): string {
    return state.contest.requirementsMedia;
  },

  startedAt(state): string | null {
    return state.contest.startedAt;
  },

  isPublished(state): boolean {
    return state.contest.isPublished;
  },

  votingStartDate(state): string {
    return state.contest.votingStartDate;
  },

  votingEndDate(state): string {
    return state.contest.votingEndDate;
  },

  limitParticipantsCount(state): number | null {
    return state.contest.limitParticipantsCount;
  },

  relatedContentVideos(state): IRelatedContentVideo[] {
    return state.contest.relatedContentVideos;
  },

  prizeCategories(state): IPrizeCategory[] {
    return state.contest.prizeCategories;
  },

  prizeCategoriesWinnersIds(state) {
    return state.contest.prizeCategories.map((cat) => cat.winner?.user?.id);
  },

  availableCategoriesToAdd(state): IPrizeCategoryForContest[] {
    return state.allPrizeCategories.filter(
      (c) => !state.contest.prizeCategories?.some((contestCat) => contestCat.categoryId === c.id),
    );
  },

  isAllWinnersSelected(state): boolean {
    return state.contest.prizeCategories
      ?.filter((c) => c.winnerSelectable)
      .every((c) => !isNil(c.winner));
  },

  isAllCategoriesValidToPublish(state): boolean {
    return state.contest.prizeCategories.length === state.allPrizeCategories.length;
  },
};
