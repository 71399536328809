import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { find, omit } from 'ramda';
import {
  IGetByCategoryParams,
  ISearchVideoParams,
} from '~/api/interfaces/art-club-video-lists.interface';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';

export interface IArtClubSearchState {
  videos: IArtClubUserVideo[];
  total: number;
}

export interface IGetSearchedVideosParams extends ISearchVideoParams {
  initial?: boolean;
}

const getDefaultState = (): IArtClubSearchState => ({
  videos: [],
  total: 0,
});

export const state = (): IArtClubSearchState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getInitialSearchedVideos(ctx, params: ISearchVideoParams) {
    await ctx.dispatch('getSearchedVideos', { ...params, offset: 0, initial: true });
  },

  async getSearchedVideos(ctx, params: IGetSearchedVideosParams) {
    const reqParams = omit(['initial'], params);
    const commitVideosKey = params?.initial ? 'setInitialVideos' : 'setVideos';

    if (reqParams.query?.length) {
      const { items, total } = await this.$api.artClubVideoLists.searchVideos(
        reqParams.categoryId?.length ? reqParams : omit(['categoryId'], reqParams),
      );

      ctx.commit(commitVideosKey, items);
      ctx.commit('setTotal', total);

      return;
    }

    if (!reqParams.categoryId?.length) return;

    const response = await this.$api.artClubVideoLists.getByCategory(
      omit(['query'], reqParams) as IGetByCategoryParams,
    );

    ctx.commit(commitVideosKey, response?.items || []);
    ctx.commit('setTotal', response?.total || 0);
  },
};

export const mutations: MutationTree<RootState> = {
  setInitialVideos(state, videos: IArtClubUserVideo[]) {
    state.videos = videos;
  },

  setVideos(state, videos: IArtClubUserVideo[]) {
    state.videos = [...state.videos, ...videos];
  },

  setTotal(state, total: number) {
    state.total = total;
  },

  updateVideoSaveStatus(state, videoId: string) {
    const searchedItem = find((i) => i.id === videoId, state.videos) as IArtClubUserVideo;

    searchedItem.isSaved = !searchedItem.isSaved;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IArtClubSearchState, RootState> = {
  isMoreVideos(state): boolean {
    return state.videos.length < state.total;
  },
};
