





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TButtonType, uiButtonTypes } from '~/shared/components/UiButton/config';

export default defineComponent({
  name: 'UiButton',
  inheritAttrs: false,

  props: {
    type: {
      type: String as PropType<TButtonType>,
      default: 'default',
      validator: (value: string) => uiButtonTypes.includes(value),
    },

    icon: {
      type: String,
      default: null,
    },

    iconWithStroke: {
      type: Boolean,
      default: false,
    },

    customIconClass: {
      type: String,
      default: null,
    },

    onlyIcon: {
      type: Boolean,
      default: false,
    },

    isIconRight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonClasses(): string[] {
      const classes = [`button-${this.type}`, this.$attrs.class as string];

      if (this.onlyIcon) classes[2] = 'button-only-icon';

      return classes;
    },
    withLeftIcon(): boolean {
      return !!this.icon && !this.isIconRight;
    },

    withRightIcon(): boolean {
      return !!this.icon && this.isIconRight;
    },
  },
});
