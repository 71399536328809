import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IBlogAdminApi,
  ICheckIsBlogSlugAvailableResponse,
  ICreateBlogPostPayload,
  IGetPostsListParams,
  IUpdateBlogPostPayload,
} from '~/api/interfaces/blog-admin.interface';
import { IBlogListItemForAdmin } from '~/features/blog/interfaces/blog-list-item.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IBlogItemForUser } from '~/features/blog/interfaces/blog-item.interface';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';

export default ($axios: NuxtAxiosInstance): IBlogAdminApi => ({
  getPostListItemById(id: string): Promise<IBlogListItemForAdmin> {
    return $axios.$get('/blog/admin/getPostListItemById', { params: { id } });
  },

  getPostsList(
    params: IGetPostsListParams,
  ): Promise<IItemsListWithTotalResponse<IBlogListItemForAdmin>> {
    return $axios.$get('/blog/admin/getPostsList', { params });
  },

  getPreviewPostBySlug(slug: string): Promise<IBlogItemForUser> {
    return $axios.$get('/blog/admin/getPreviewPostBySlug', { params: { slug }, localError: true });
  },

  getPreviewPostById(id: string): Promise<IBlogItemForUser> {
    return $axios.$get('/blog/admin/getPreviewPostById', { params: { id }, localError: true });
  },

  createPost(payload: ICreateBlogPostPayload): Promise<IEntityWithId> {
    return $axios.$post('/blog/admin/createPost', payload);
  },

  updatePost(payload: IUpdateBlogPostPayload): Promise<void> {
    return $axios.$post('/blog/admin/updatePost', payload);
  },

  deletePost(id: string): Promise<void> {
    return $axios.$post('/blog/admin/deletePost', { id });
  },

  checkIsBlogSlugAvailable(slug: string): Promise<ICheckIsBlogSlugAvailableResponse> {
    return $axios.$get('/blog/admin/checkIsBlogSlugAvailable', { params: { slug } });
  },
});
