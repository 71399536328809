























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { mapState } from 'vuex';
import SocialSidebar from './parts/social-sidebar/index.vue';
import { useLayout } from '~/shared/composable/useLayout';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import GuestModal from '~/shared/components/modals/GuestModal.vue';
import { TABLET_WIDTH } from '~/shared/constants';
import { ELayoutType } from '~/shared/enums/layout-type.enum';

export default defineComponent({
  name: 'ArtClubSocialLayout',
  components: {
    SessionExpiredModal: () => import('~/layouts/parts/session-expired-modal.vue'),
    FailedPaymentLine: () => import('~/layouts/parts/failed-payment-line.vue'),
    MobileAppBanner: () => import('~/shared/components/MobileAppBanner.vue'),
    GuestModal,
    UiAdaptive,
    CreatePostMobile: () => import('~/shared/components/create-post-dialog/CreatePostMobile.vue'),
    EmailConfirmationLine: () => import('~/layouts/parts/email-confirmation-line.vue'),
    OnBoardingConfetti: () => import('~/features/on-boarding/components/OnBoardingConfetti.vue'),
    OnBoardingFormModal: () =>
      import('~/features/on-boarding/components/on-boarding-form/OnBoardingFormModal.vue'),
    SocialSidebar,
  },
  setup() {
    const confetti = ref(null as any);
    const {
      layoutCssVars,
      isOnboardingShown,
      isConfirmationLineShown,
      onOnboardingFinished,
      isMobileBannerShown,
      onHideBannerClick,
      isPageTopOffset,
      isFailedPaymentLineShown,
    } = useLayout(confetti, ELayoutType.ArtClubSocial);

    return {
      confetti,
      layoutCssVars,
      isOnboardingShown,
      isFailedPaymentLineShown,
      isConfirmationLineShown,
      onOnboardingFinished,
      isMobileBannerShown,
      onHideBannerClick,
      isPageTopOffset,
      TABLET_WIDTH,
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isOnboardingShown ? 'theme--social' : 'theme--art-club-social',
      },
    };
  },
  computed: {
    ...mapState({
      isSessionExpired: 'isSessionExpired',
    }),
  },
});
