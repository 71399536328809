import { useContext, useRoute } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
import { OAUTH_COOKIE_KEY } from '~/shared/constants';

export interface IOAuthLogin {
  handleOAuthLogin(): Promise<void>;
  handleOAuthLogout(): void;
}

export const useOAuth = (): IOAuthLogin => {
  const { $cookies, $api, redirect, localePath } = useContext();
  const route = useRoute();

  const makeRedirectTo = (): void => {
    const redirectTo = route.value.query.redirectTo as string;

    if (isNil(redirectTo)) return redirect(localePath('/feed'));

    // Fix: this logic made because of the rules in redirect module which replaces // with / in all URL.
    const newRedirectTo = `https://${redirectTo
      .replaceAll('https://', '')
      .replaceAll('https:/', '')}`;
    //

    redirect(newRedirectTo);
  };

  const handleOAuthLogin = async (): Promise<void> => {
    try {
      const appId = route.value.query.appId as string;

      const { accessToken } = await $api.auth.appLogin(appId);

      $cookies.set(OAUTH_COOKIE_KEY, accessToken, {
        domain: `.${window.location.host.replace('www.', '')}`,
      });

      makeRedirectTo();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOAuthLogout = (): void => {
    try {
      $cookies.remove(OAUTH_COOKIE_KEY, {
        domain: `.${window.location.host.replace('www.', '')}`,
      });

      makeRedirectTo();
    } catch (error) {
      console.error(error);
    }
  };

  return { handleOAuthLogin, handleOAuthLogout };
};
