import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetAllPaymentEntitiesListResponse,
  IPaymentsInfoApi,
} from '~/api/interfaces/payments-info.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IPaymentsListItem } from '~/shared/interfaces/payments-list-item.interface';

export default ($axios: NuxtAxiosInstance): IPaymentsInfoApi => ({
  getAllPaymentEntitiesList(): Promise<IGetAllPaymentEntitiesListResponse> {
    return $axios.$get('/payments/info/getAllPaymentEntitiesList');
  },

  getAllPaymentsList(paymentEntityId: string): Promise<IItemsListResponse<IPaymentsListItem>> {
    return $axios.$get('/payments/info/getAllPaymentsList', { params: { paymentEntityId } });
  },
});
