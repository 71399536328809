import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAffiliateApi,
  IRegisterClickPayload,
  IRegisterClickResponse,
} from '~/api/interfaces/affiliate.interface';

export default ($axios: NuxtAxiosInstance): IAffiliateApi => ({
  registerClick(payload: IRegisterClickPayload): Promise<IRegisterClickResponse> {
    return $axios.$post('/affiliate/registerClick', payload);
  },

  assignTrackingId(trackingId: string): Promise<void> {
    return $axios.$post('/affiliate/assignTrackingId', { trackingId });
  },
});
