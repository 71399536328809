import { ActionTree, MutationTree } from 'vuex';
import { IArtClubTableVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IGetVideoListParams } from '~/api/interfaces/art-club-videos-admin.interface';

export interface IArtClubAdminState {
  videos: IArtClubTableVideo[];
  total: number;
}

const getDefaultState = (): IArtClubAdminState => ({
  videos: [],
  total: 0,
});

export const state = (): IArtClubAdminState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getVideos(ctx, payload: IGetVideoListParams) {
    const { items, total } = await this.$api.artClubVideosAdmin.getVideosList({
      ...payload,
    });

    ctx.commit('setVideos', items);
    ctx.commit('setTotal', total);
  },
};

export const mutations: MutationTree<RootState> = {
  setVideos(state, videos) {
    state.videos = videos;
  },

  setTotal(state, total) {
    state.total = total;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
