import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAddPinnedPostPayload,
  IGetLimitsResponse,
  IGlobalPinnedPostsApi,
} from '~/api/interfaces/global-pinned-posts.interface';
import { IGlobalPinnedPost } from '~/features/profile/interfaces/global-pinned-post.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): IGlobalPinnedPostsApi => ({
  addPinnedPost(payload: IAddPinnedPostPayload): Promise<IGlobalPinnedPost> {
    return $axios.$post('/feed/globalPinnedPosts/addPinnedPost', payload);
  },

  updatePinnedPost(payload: IAddPinnedPostPayload): Promise<IGlobalPinnedPost> {
    return $axios.$post('/feed/globalPinnedPosts/updatePinnedPost', payload);
  },

  removePinnedPost(postId: string): Promise<void> {
    return $axios.$post('/feed/globalPinnedPosts/removePinnedPost', { postId });
  },

  getLimits(): Promise<IGetLimitsResponse> {
    return $axios.$get('/feed/globalPinnedPosts/getLimits');
  },

  getAllPosts(): Promise<IItemsListResponse<IGlobalPinnedPost>> {
    return $axios.$get('/feed/globalPinnedPosts/getAllPosts');
  },
});
