import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { ICommunityListItemForAdmin } from '~/features/communities/interfaces/community-list-item.interface';
import {
  ICommunitiesAdminRecommendedApi,
  IGetRecommendedCommunitiesListResponse,
  ISearchForAddPayload,
  ISetRecommendedCommunitiesListPayload,
} from '~/api/interfaces/communities-admin-recommended.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesAdminRecommendedApi => ({
  getList(): Promise<IGetRecommendedCommunitiesListResponse> {
    return $axios.$get('/communities/admin/recommended/getList');
  },

  setList(payload: ISetRecommendedCommunitiesListPayload): Promise<void> {
    return $axios.$post('/communities/admin/recommended/setList', payload);
  },

  searchForAdd(
    payload: ISearchForAddPayload,
  ): Promise<IItemsListWithTotalResponse<ICommunityListItemForAdmin>> {
    return $axios.$post('/communities/admin/recommended/searchForAdd', {
      count: 20,
      offset: 0,
      ...payload,
    });
  },
});
