import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  ICreateLessonActionPayload,
  IGetLessonParams,
  ILessonState,
} from '~/store/admin/courses/interfaces';
import { ELessonVersion } from '~/features/lesson/enums/lesson-version.enum';
import { IFile } from '~/features/files/interfaces/file.interface';
import { EFileType } from '~/features/files/enums/file-type.enum';
import { IItemsListRequestParams } from '~/shared/interfaces/items-list.interface';
import { ILesson } from '~/features/lesson/interfaces/lesson.interface';

const getDefaultState = (): ILessonState => {
  return {
    isLessonEdited: false,
    cachedLessonVersionId: null,
    lesson: {
      courseId: '',
      moduleId: null,
      id: '',
      isPublished: false,
      title: '',
      content: '',
      vimeoVideoUrl: '',
      image: null,
      createdAt: '',
      attachments: [],
      additionalResources: '',
      sourcesUrl: '',
    },

    versionsHistory: [],
    versionsHistoryCount: 0,
  };
};

export const state = (): ILessonState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<ILessonState, RootState> = {
  title(state) {
    return state.lesson.title;
  },
  content(state) {
    return state.lesson.content;
  },
  vimeoVideoUrl(state) {
    return state.lesson.vimeoVideoUrl;
  },
  attachments(state) {
    return state.lesson.attachments;
  },
  attachmentsToUpload(state) {
    return state.lesson.attachments.map((item) => ({ name: item.filename, fileId: item.fileId }));
  },
  imageUrl(state) {
    return state.lesson.image?.url;
  },
  sourcesUrl(state) {
    return state.lesson.sourcesUrl;
  },
  additionalResources(state) {
    return state.lesson.additionalResources;
  },
  isPublished(state) {
    return state.lesson.isPublished;
  },
};

export const actions: ActionTree<ILessonState, RootState> = {
  async getLesson(ctx, { lessonId, version }: IGetLessonParams) {
    const response = await this.$api.lesson.getLessonById({
      lessonId,
      version: version || ELessonVersion.Latest,
    });

    ctx.commit('setLesson', response);
  },

  async getLessonVersionsHistory(ctx, params: IItemsListRequestParams) {
    const response = await this.$api.lesson.getLessonEditHistory({
      lessonId: ctx.state.lesson.id,
      ...params,
    });

    ctx.commit('setVersionsHistory', response.items);
    ctx.commit('setVersionsHistoryCount', response.total);
  },

  async createLesson(ctx, payload: ICreateLessonActionPayload): Promise<ILesson> {
    const response = await this.$api.lesson.createLesson({
      courseId: payload.courseId,
      moduleId: payload.moduleId,
      title: ctx.getters.title,
      content: ctx.getters.content,
      imageId: ctx.state.lesson.image?.id,
      vimeoVideoUrl: ctx.getters.vimeoVideoUrl,
      attachments: ctx.getters.attachmentsToUpload,
      additionalResources: ctx.getters.additionalResources,
      sourcesUrl: ctx.getters.sourcesUrl,
    });

    await ctx.dispatch('updateLessonPublishState', response.id);

    return response;
  },

  async updateLessonSession(ctx) {
    let versionId = ctx.state.cachedLessonVersionId;

    if (!versionId) {
      const startEditSessionResponse = await this.$api.lesson.startEditLessonSession({
        lessonId: ctx.state.lesson.id,
        fromVersion: ELessonVersion.Latest,
      });

      versionId = startEditSessionResponse.versionId;
    }

    await this.$api.lesson.saveEditLessonSession({
      versionId,
      title: ctx.getters.title,
      content: ctx.getters.content,
      vimeoVideoUrl: ctx.getters.vimeoVideoUrl,
      imageId: ctx.state.lesson.image?.id,
      additionalResources: ctx.getters.additionalResources,
      sourcesUrl: ctx.getters.sourcesUrl,
      attachments: ctx.getters.attachmentsToUpload,
    });

    ctx.commit('setCachedLessonVersionId', versionId);
    ctx.commit('setIsLessonEdited', false);

    return versionId;
  },

  async updateLessonPublishState(ctx, lessonId?) {
    return await this.$api.lesson.updateLesson({
      lessonId: lessonId || ctx.state.lesson.id,
      isPublished: ctx.state.lesson.isPublished,
    });
  },

  async publishLesson(ctx) {
    const lessonVersionId = await ctx.dispatch('updateLessonSession');

    await this.$api.lesson.publishLessonVersion({
      lessonId: ctx.state.lesson.id,
      version: lessonVersionId,
    });

    await ctx.dispatch('updateLessonPublishState');

    ctx.commit('setIsLessonEdited', false);

    ctx.commit('setCachedLessonVersionId', null);
  },

  async deleteLesson(ctx, lessonId?) {
    ctx.commit('setIsLessonEdited', false);

    await this.$api.lesson.updateLesson({
      lessonId: lessonId || ctx.state.lesson.id,
      isDeleted: true,
    });
  },
};

export const mutations: MutationTree<ILessonState> = {
  setCachedLessonVersionId(state, cachedLessonVersionId) {
    state.cachedLessonVersionId = cachedLessonVersionId;
  },

  setIsLessonEdited(state, isLessonEdited) {
    state.isLessonEdited = isLessonEdited;
  },

  setLesson(state, lesson) {
    state.lesson = lesson;
  },

  setVersionsHistory(state, versionsHistory) {
    state.versionsHistory = versionsHistory;
  },

  setVersionsHistoryCount(state, versionsHistoryCount) {
    state.versionsHistoryCount = versionsHistoryCount;
  },

  setTitle(state, title) {
    state.lesson.title = title;

    state.isLessonEdited = true;
  },

  setContent(state, content) {
    state.lesson.content = content;

    state.isLessonEdited = true;
  },

  setVimeoVideoUrl(state, vimeoVideoUrl) {
    state.lesson.vimeoVideoUrl = vimeoVideoUrl;

    state.isLessonEdited = true;
  },

  setAdditionalResources(state, additionalResources) {
    state.lesson.additionalResources = additionalResources;

    state.isLessonEdited = true;
  },

  setSourcesUrl(state, sourcesUrl) {
    state.lesson.sourcesUrl = sourcesUrl;

    state.isLessonEdited = true;
  },

  addAttachments(state, attachments: IFile[]) {
    for (const file of attachments) {
      state.lesson.attachments = [
        ...state.lesson.attachments,
        {
          fileId: file.id,
          filename: file.filename,
          fileSize: file.fileSize,
          name: '',
          type: EFileType.File,
          url: file.url,
        },
      ];
    }

    state.isLessonEdited = true;
  },

  removeAttachment(state, attachmentIndex: number) {
    state.lesson.attachments.splice(attachmentIndex, 1);

    state.isLessonEdited = true;
  },

  setIsPublished(state, isPublished) {
    state.lesson.isPublished = isPublished;

    state.isLessonEdited = true;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
