export const getScrollTopPosition = (hash: string, useHeaderHeight = true): number => {
  const anchor = document.querySelector(hash);
  let top = 0;

  // Check if the anchor has been found
  if (anchor) {
    top = anchor.getBoundingClientRect().top + window.pageYOffset - (useHeaderHeight ? 150 : 0);
  }

  return top;
};

export const scrollToAnchor = (anchorId: string, useHeaderHeight = true): void => {
  const top = getScrollTopPosition(`#${anchorId}`, useHeaderHeight);

  // Check if the anchor has been found
  if (top) {
    window.scroll({
      // Scroll so that the anchor is at the top of the view
      top,
      behavior: 'smooth',
    });
  }
};
