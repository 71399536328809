import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAddTagToUserPayload,
  IAddTagToUsersBySelectionPayload,
  IAddTagToUsersByUserIdsPayload,
  IGetAllUserTagsResponse,
  ISearchTagsForAssignmentParams,
  IUserCrmUsersToTagsApi,
} from '~/api/interfaces/user-crm-users-to-tags.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IUserTag } from '~/features/users/interfaces/user-tag.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmUsersToTagsApi => ({
  searchTagsForAssignment(
    params: ISearchTagsForAssignmentParams,
  ): Promise<IItemsListWithTotalResponse<IUserTag>> {
    return $axios.$get('/users/admin/crm/usersToTags/searchTagsForAssignment', {
      params: { ...params, count: 20, offset: 0 },
    });
  },

  getAllUserTags(userId: string): Promise<IGetAllUserTagsResponse> {
    return $axios.$get('/users/admin/crm/usersToTags/getAllUserTags', { params: { userId } });
  },

  addTagToUser(payload: IAddTagToUserPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/addTagToUser', payload, { localError: true });
  },

  removeTagFromUser(payload: IAddTagToUserPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/removeTagFromUser', payload, {
      localError: true,
    });
  },

  addTagToUsersByUserIds(payload: IAddTagToUsersByUserIdsPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/addTagToUsersByUserIds', payload, {
      localError: true,
    });
  },

  removeTagFromUsersByUserIds(payload: IAddTagToUsersByUserIdsPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/removeTagFromUsersByUserIds', payload, {
      localError: true,
    });
  },

  addTagToUsersBySelection(payload: IAddTagToUsersBySelectionPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/addTagToUsersBySelection', payload, {
      localError: true,
    });
  },

  removeTagFromUsersBySelection(payload: IAddTagToUsersBySelectionPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/usersToTags/removeTagFromUsersBySelection', payload, {
      localError: true,
    });
  },
});
