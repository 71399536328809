






































import { defineComponent } from '@nuxtjs/composition-api';
import { mapState } from 'vuex';
import LUiButton from '~/shared/components/landings/LUiButton.vue';
import MobileAppModal from '~/shared/components/modals/MobileAppModal.vue';
import { useScrollDirection } from '~/shared/composable/useScrollDirection';

export default defineComponent({
  name: 'MobileAppBanner',
  components: {
    MobileAppModal,
    LUiButton,
  },
  emits: ['hide'],
  setup() {
    const { isGoingDown, resetGoingDown } = useScrollDirection();

    return { isGoingDown, resetGoingDown };
  },
  computed: {
    ...mapState({
      isMobileBannerPositionLocked: 'isMobileBannerPositionLocked',
    }),
  },
  methods: {
    openModal(): void {
      (this.$refs.modal as InstanceType<typeof MobileAppModal>).show();
    },

    onHideClick(): void {
      this.$emit('hide');
    },
  },
});
