import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(async ({ store, $auth }) => {
  if ($auth.loggedIn) {
    await store.dispatch('users/community/getHasUserJoinedAnyCommunities', {
      count: 1,
      offset: 0,
      mutualMembersProfilesCount: 1,
    });
  }
});
