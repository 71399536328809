import { locale } from 'dayjs';
import enLocale from 'dayjs/locale/en';

export default function (): void {
  const shortEnLocale = {
    ...enLocale,
    name: 'en-short',
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d sec',
      m: '1 min',
      mm: '%d min',
      h: '1 hr',
      hh: '%d hr',
      d: '1 d',
      dd: '%d d',
      M: '1 m',
      MM: '%d m',
      y: '1 y',
      yy: '%d y',
    },
  };

  locale(shortEnLocale, {}, true);
}
