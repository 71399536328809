export enum EToastType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  ErrorDark = 'error-dark',
  ErrorLight = 'error-light',
  Secondary = 'secondary',
}

export enum EToastIcon {
  Check = 'check',
  Trash = 'trash',
}
