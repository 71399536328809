import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IBlockUserToUserApi } from '~/api/interfaces/block-user-to-user.interface';
import {
  IItemsListRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IUserProfileShort } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): IBlockUserToUserApi => ({
  blockUser(userId: string): Promise<void> {
    return $axios.$post('/users/block/blockUser', { userId }, { localError: true });
  },

  unblockUser(userId: string): Promise<void> {
    return $axios.$post('/users/block/unblockUser', { userId }, { localError: true });
  },

  getMyBlockedUsersList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/users/block/getMyBlockedUsersList', { params });
  },
});
