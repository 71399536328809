


























import { defineComponent } from '@nuxtjs/composition-api';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import { useLayout } from '~/shared/composable/useLayout';
import GuestModal from '~/shared/components/modals/GuestModal.vue';
import { TABLET_WIDTH } from '~/shared/constants';

export default defineComponent({
  name: 'PublicLayout',
  components: {
    MobileAppBanner: () => import('~/shared/components/MobileAppBanner.vue'),
    GuestModal,
    UiAdaptive,
    CreatePostMobile: () => import('~/shared/components/create-post-dialog/CreatePostMobile.vue'),
  },
  setup() {
    const { layoutCssVars, isMobileBannerShown, onHideBannerClick } = useLayout();

    return {
      layoutCssVars,
      isMobileBannerShown,
      onHideBannerClick,
      TABLET_WIDTH,
    };
  },
});
