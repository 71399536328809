/* eslint-disable camelcase */
import axios from 'axios';
import { useContext } from '@nuxtjs/composition-api';
import { EGcpActionType } from '~/features/gcp-analytics/enums/gcp-action-type.enum';
import { EAppEnvironment } from '~/shared/enums/app-environment.enum';
import { getGaPseudoId } from '~/utils/get-ga-pseudo-id';
import { getBrowserSessionId } from '~/utils/get-browser-session-id';
import { TAuthStrategy } from '~/features/auth/types/auth-strategy.type';

export interface IUseGcpAnalytics {
  applyForRegistration(engagement_time_msec: number): Promise<void>;
  applyForLogin(engagement_time_msec: number): Promise<void>;
  applyForPost(engagement_time_msec: number, entity_id: string): Promise<void>;
  applyForLike(engagement_time_msec: number, entity_id: string): Promise<void>;
  applyForSave(engagement_time_msec: number, entity_id: string): Promise<void>;
  applyForCommentAdd(engagement_time_msec: number, entity_id: string): Promise<void>;
  applyForDeleteAccount(engagement_time_msec: number): Promise<void>;
  applyUnsubscribe(
    payload: Pick<
      IRequestPayload,
      'entity_id' | 'engagement_time_msec' | 'product_name' | 'product_id'
    >,
  ): Promise<void>;
}

export interface IRequestPayload {
  action_type: EGcpActionType;
  entity_id?: string | null;
  product_name?: string | null;
  product_id?: string | null;
  price_id?: string | null;
  deal_value?: string | null;
  deal_currency?: 'USD' | null;
  engagement_time_msec: number;
}

export const useGcpAnalytics = (): IUseGcpAnalytics => {
  const { $auth, $dayjs, $cookies, $config } = useContext();
  const referer = $cookies.get('referer') || null;

  const getRecordId = (actionType: EGcpActionType, entityId?: string | null): string =>
    `${$auth.user?.id}.${actionType}.${entityId || $dayjs().unix()}`;

  const request = async (payload: IRequestPayload): Promise<void> => {
    if ($config.envType !== EAppEnvironment.Production) {
      console.log('Analytics payload:', payload);

      return;
    }

    const token = ($auth.strategy as TAuthStrategy).token.get();
    const axiosInstance = axios.create({
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    await axiosInstance.post('/gcpAnalytics/sendAction', {
      user_id: $auth.user?.id,
      record_id: getRecordId(payload.action_type, payload?.entity_id),
      entity_id: null,
      product_name: null,
      product_id: null,
      price_id: null,
      deal_value: null,
      deal_currency: null,
      payment_plan: null,
      datetime_sent: $dayjs().toISOString(),
      page_location: window.location.href,
      ga_pseudo_id: getGaPseudoId($cookies),
      ga_session_id: getBrowserSessionId($cookies),
      page_referrer: referer,
      ...payload,
    });
  };

  const applyForRegistration = (engagement_time_msec: number): Promise<void> => {
    return request({
      action_type: EGcpActionType.Registration,
      engagement_time_msec,
    });
  };

  const applyForLogin = (engagement_time_msec: number): Promise<void> => {
    return request({
      action_type: EGcpActionType.Login,
      engagement_time_msec,
    });
  };

  const applyForPost = (engagement_time_msec: number, entity_id: string): Promise<void> => {
    return request({
      action_type: EGcpActionType.Post,
      entity_id,
      engagement_time_msec,
    });
  };

  const applyForLike = (engagement_time_msec: number, entity_id: string): Promise<void> => {
    return request({
      action_type: EGcpActionType.Like,
      entity_id,
      engagement_time_msec,
    });
  };

  const applyForSave = (engagement_time_msec: number, entity_id: string): Promise<void> => {
    return request({
      action_type: EGcpActionType.Save,
      entity_id,
      engagement_time_msec,
    });
  };

  const applyForCommentAdd = (engagement_time_msec: number, entity_id: string): Promise<void> => {
    return request({
      action_type: EGcpActionType.Comment,
      engagement_time_msec,
      entity_id,
    });
  };

  const applyForDeleteAccount = (engagement_time_msec: number): Promise<void> => {
    return request({
      action_type: EGcpActionType.DeleteAccount,
      engagement_time_msec,
    });
  };

  const applyUnsubscribe = (
    payload: Pick<
      IRequestPayload,
      'entity_id' | 'engagement_time_msec' | 'product_name' | 'product_id'
    >,
  ): Promise<void> => {
    return request({
      action_type: EGcpActionType.Unsubscribe,
      ...payload,
    });
  };

  return {
    applyForRegistration,
    applyForLogin,
    applyForPost,
    applyForLike,
    applyForSave,
    applyForCommentAdd,
    applyForDeleteAccount,
    applyUnsubscribe,
  };
};
