import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil } from 'ramda';
import { set } from 'vue';
import {
  IContestForUserForList,
  IContestPost,
} from '~/features/contests/interfaces/contest.interface';
import { IArtClubUserVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { IGetPinnedContestResponse } from '~/api/interfaces/art-club-contests-user.interface';
import {
  DESKTOP_PINNED_CONTEST_SUBMISSIONS_COUNT,
  USERS_CONTESTS_PER_PAGE,
} from '~/features/contests/constants/index.constant';
import { EContestsFilter } from '~/features/contests/enums/contests-filter.enum';
import { EContestStatus } from '~/features/contests/enums/contest-status.enum';

export interface IState {
  pinnedContest: IContestForUserForList | null;
  pinnedContestRelatedVideos: IArtClubUserVideo[];
  contests: IContestForUserForList[];
  pinnedContestSubmissions: IContestPost[];
  total: number;
}

const getDefaultState = (): IState => ({
  pinnedContest: null,
  pinnedContestSubmissions: [],
  pinnedContestRelatedVideos: [],
  contests: [],
  total: 0,
});

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getPinnedContest(ctx) {
    const pinned = await this.$api.artClubContestsUser.getPinnedContest();

    if (isNil(pinned) || !pinned.contest) return;

    const isContestFinished = pinned.contest.status === EContestStatus.Closed;
    const offset = isContestFinished
      ? 0
      : pinned.contest.submissionsCount > DESKTOP_PINNED_CONTEST_SUBMISSIONS_COUNT
      ? pinned.contest.submissionsCount - DESKTOP_PINNED_CONTEST_SUBMISSIONS_COUNT
      : 0;

    const { items } = await this.$api.artClubContestsUser.getSubmissions({
      contestId: pinned.contest.id,
      count: DESKTOP_PINNED_CONTEST_SUBMISSIONS_COUNT,
      offset,
      filter: isContestFinished ? EContestsFilter.Winners : EContestsFilter.All,
    });

    ctx.commit('setPinnedContestData', pinned);
    ctx.commit('setPinnedContestSubmissions', items);
  },

  async updateContest(ctx, contestId: string) {
    const contest = await this.$api.artClubContestsUser.getContestListItemById(contestId);

    ctx.commit('updateContest', contest);
  },

  async getMySubmissionsContests(ctx) {
    const { items, total } = await this.$api.artClubContestsUser.getMyContestsList({
      count: USERS_CONTESTS_PER_PAGE,
      offset: ctx.state.contests.length,
    });

    ctx.commit('setContests', items);
    ctx.commit('setTotal', total);
  },

  async getArchiveContests(ctx) {
    const { items, total } = await this.$api.artClubContestsUser.getArchivedContestsList({
      count: USERS_CONTESTS_PER_PAGE,
      offset: ctx.state.contests.length,
    });

    ctx.commit('setContests', items);
    ctx.commit('setTotal', total);
  },
};

export const mutations: MutationTree<RootState> = {
  setContests(state, contests: IContestForUserForList[]) {
    state.contests = [...state.contests, ...contests];
  },

  setTotal(state, total: number) {
    state.total = total;
  },

  setPinnedContestData(state, pinnedContestData: IGetPinnedContestResponse) {
    state.pinnedContest = pinnedContestData.contest;
    state.pinnedContestRelatedVideos = pinnedContestData.relatedContent;
  },

  setPinnedContestSubmissions(state, submissions: IContestPost[]) {
    state.pinnedContestSubmissions = submissions;
  },

  updateContest(state, contest: IContestForUserForList) {
    const currentIndex = state.contests.findIndex((c) => c.id === contest.id);

    set(state.contests, currentIndex, contest);
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IState, RootState> = {
  isMoreContests(state): boolean {
    return state.contests.length < state.total;
  },

  isPinnedContestSubmissionsShown(state): boolean {
    return isNil(state.pinnedContestSubmissions) ? false : !!state.pinnedContestSubmissions?.length;
  },
};
