import {
  ONE_DAY_IN_SECONDS,
  ONE_MONTH_IN_SECONDS,
  ONE_WEEK_IN_SECONDS,
} from '~/shared/constants/time-durations.constant';
import { ITimeParametersList } from '~/features/users/interfaces/time-parameters-list.interface';

export const timeParametersList: ITimeParametersList = {
  OneDay: ONE_DAY_IN_SECONDS,
  OneWeek: ONE_WEEK_IN_SECONDS,
  OneMonth: ONE_MONTH_IN_SECONDS,
  Forever: null,
};
