import { onMounted, onUnmounted, Ref, ref, useContext } from '@nuxtjs/composition-api';
import { MOBILE_WIDTH } from '~/shared/constants';

export interface IUseDynamicIsMobileStatus {
  isMobile: Ref<boolean>;
}

export const useDynamicIsMobileStatus = (
  breakpointWidth = MOBILE_WIDTH,
): IUseDynamicIsMobileStatus => {
  const isMobile = ref(false);

  const ctx = useContext();

  const onWindowResize = (): void => {
    isMobile.value = ctx.$isMobile(breakpointWidth);
  };

  onMounted(() => {
    onWindowResize();

    window.addEventListener('resize', onWindowResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onWindowResize);
  });

  return { isMobile };
};
