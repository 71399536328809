



















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import { EFormInputValidateState } from '~/shared/enums/form-input-validate-state.enum';
import UiInput, { THintPlacement } from '~/shared/components/UiInput/index.vue';

export default defineComponent({
  name: 'FormInput',
  components: { UiInput },
  props: {
    validationField: {
      type: String,
      default: null,
    },
    inputClass: {
      type: String,
      default: null,
    },
    hint: {
      type: String as PropType<TranslateResult>,
      default: null,
    },
    hintPlacement: {
      type: String as PropType<THintPlacement>,
      default: 'start',
    },
    error: {
      type: String as PropType<TranslateResult>,
      default: null,
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      validateState: undefined as undefined | EFormInputValidateState,
      validateMessage: undefined,
    };
  },
  computed: {
    isValid(): boolean {
      return this.validateState === EFormInputValidateState.Success;
    },
    isError(): boolean {
      return !!this.error || this.validateState === EFormInputValidateState.Error;
    },
    hintMessage(): string | undefined {
      if (!this.showMessage) return;

      return (this.validateMessage || this.hint || this.error) as string;
    },
  },
  mounted() {
    this.$watch('$refs.formItem.validateState', (newVal) => {
      this.validateState = newVal;
    });

    this.$watch('$refs.formItem.validateMessage', (newVal) => {
      this.validateMessage = newVal;
    });
  },
});
