import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IUserProfile,
  IUserShort,
  IUserPublicProfile,
} from '~/features/users/interfaces/users.interface';
import { IUsersWithRolePayload, IGetPublicProfileParams } from '~/api/interfaces/users.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): any => {
  const searchUsers = (
    query: string,
    count = 10,
  ): Promise<IItemsListWithTotalResponse<IUserShort>> => {
    return $axios.$get('/users/searchUsers', { params: { query, count } });
  };

  const getProfileById = (userId: string): Promise<IUserProfile> => {
    return $axios.$get('/users/getProfileById', {
      params: {
        userId,
      },
    });
  };

  const getProfileByEmail = (email: string): Promise<IUserProfile> => {
    return $axios.$get('/users/getProfileByEmail', {
      params: {
        email,
      },
    });
  };

  const getUsersWithRole = (
    params: IUsersWithRolePayload,
  ): Promise<IItemsListWithTotalResponse<IUserShort>> => {
    return $axios.$get('/users/getUsersWithRole', {
      params,
    });
  };

  const addRole = (userId: string, role: string): Promise<void> => {
    return $axios.$post('/users/addRole', { userId, role });
  };

  const removeRole = (userId: string, role: string): Promise<void> => {
    return $axios.$post('/users/removeRole', { userId, role });
  };

  const getPublicProfile = (params: IGetPublicProfileParams): Promise<IUserPublicProfile> => {
    return $axios.$get('/users/getPublicProfile', { params, localError: true });
  };

  return {
    getProfileById,
    getProfileByEmail,
    getUsersWithRole,
    addRole,
    removeRole,
    searchUsers,
    getPublicProfile,
  };
};
