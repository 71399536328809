import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetOnboardingReportResponse,
  IGetWhatLookingForQuestions,
  IOnBoardingApi,
  ISetFollowSuggestionsPayload,
  ISetOnBoardingStepPayload,
} from './interfaces/on-boarding.interface';
import { IOnBoardingStatus } from '~/features/on-boarding/interfaces/on-boarding-status.interface';

export default ($axios: NuxtAxiosInstance): IOnBoardingApi => ({
  getStatus(): Promise<IOnBoardingStatus> {
    return $axios.$get('/users/onboarding/getStatus');
  },

  getWhatLookingForQuestions(): Promise<IGetWhatLookingForQuestions> {
    return $axios.$get('/users/onboarding/getWhatLookingForQuestions');
  },

  setWhatLookingFor(payload: ISetOnBoardingStepPayload): Promise<void> {
    return $axios.$post('/users/onboarding/setWhatLookingFor', payload);
  },

  setWhatAreYouInto(payload: ISetOnBoardingStepPayload): Promise<void> {
    return $axios.$post('/users/onboarding/setWhatAreYouInto', payload);
  },

  setFollowSuggestions(payload: ISetFollowSuggestionsPayload): Promise<void> {
    return $axios.$post('/users/onboarding/setFollowSuggestions', payload);
  },

  getOnboardingReport(userId: string): Promise<IGetOnboardingReportResponse> {
    return $axios.$get('/users/onboarding/getOnboardingReport', { params: { userId } });
  },
});
