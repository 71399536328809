import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil, omit } from 'ramda';
import { IBannerCustomData } from '~/features/art-club/interfaces/banner.interface';
import { EArtClubBannerStatus } from '~/features/art-club/enums/art-club-banner-status.enum';
import { EArtClubBannerType } from '~/features/art-club/enums/art-club-banner-type.enum';
import { DEFAULT_ADMIN_TIMEZONE } from '~/shared/constants';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface IAdminBannerState {
  bannerData: IBannerCustomData;
}

interface ISetBannerImgPayload {
  imageUrl: string;
  imageId: string;
}

const getDefaultState = (): IAdminBannerState => ({
  bannerData: {
    id: '',
    status: EArtClubBannerStatus.Draft,
    expiresAt: null,
    image: null,
    headingText: '',
    bodyText: '',
    labelText: '',
    button1: {
      enabled: false,
      name: '',
      url: '',
    },
    button2: {
      enabled: false,
      name: '',
      url: '',
    },
  },
});

export const state = (): IAdminBannerState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getBannerData(ctx, bannerId: string) {
    const data = await this.$api.artClubBannersAdmin.getCustomBannerById(bannerId);

    ctx.commit('setBannerData', {
      ...data,
      expiresAt: isNil(data.expiresAt)
        ? data.expiresAt
        : this.$dayjs(data.expiresAt)
            .tz(DEFAULT_ADMIN_TIMEZONE)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
            .format(),
    });
  },

  async saveBanner(ctx): Promise<string | void> {
    const payload = {
      ...omit(['status'], ctx.state.bannerData),
      imageId: ctx.state.bannerData.image?.id,
      expiresAt: isNil(ctx.state.bannerData.expiresAt)
        ? ctx.state.bannerData.expiresAt
        : this.$dayjs(ctx.state.bannerData.expiresAt).tz(DEFAULT_ADMIN_TIMEZONE, true).format(),
    };

    if (ctx.getters.isNewBanner) {
      const { id } = await this.$api.artClubBannersAdmin.createBanner({
        type: EArtClubBannerType.Custom,
      });

      await this.$api.artClubBannersAdmin.updateCustomBanner({
        ...payload,
        id,
      });

      return id;
    }

    await this.$api.artClubBannersAdmin.updateCustomBanner(payload);
  },
};

export const mutations: MutationTree<RootState> = {
  setBannerData(state, bannerData: IBannerCustomData) {
    state.bannerData = bannerData;
  },

  setBannerImage(state, payload: ISetBannerImgPayload) {
    state.bannerData.image = state.bannerData.image ?? getDefaultFileState();
    state.bannerData.image.id = payload.imageId;
    state.bannerData.image.url = payload.imageUrl;
  },

  setHeadingText(state, headingText: string) {
    state.bannerData.headingText = headingText;
  },

  setBodyText(state, bodyText: string) {
    state.bannerData.bodyText = bodyText;
  },

  setLabelText(state, labelText: string) {
    state.bannerData.labelText = labelText;
  },

  setButton1Enabled(state, enabled: boolean) {
    state.bannerData.button1.enabled = enabled;
  },

  setButton2Enabled(state, enabled: boolean) {
    state.bannerData.button2.enabled = enabled;
  },

  setButton1Name(state, name: string) {
    state.bannerData.button1.name = name;
  },

  setButton2Name(state, name: string) {
    state.bannerData.button2.name = name;
  },

  setButton1Url(state, url: string) {
    state.bannerData.button1.url = url;
  },

  setButton2Url(state, url: string) {
    state.bannerData.button2.url = url;
  },

  setExpiresAt(state, expiresAt: string | null) {
    state.bannerData.expiresAt = expiresAt;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IAdminBannerState, RootState> = {
  isNewBanner(state) {
    return !state.bannerData.id;
  },
};
