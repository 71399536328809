import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ELessonUserEvent } from '~/features/lesson/enums/lesson-user-event.enum';
import { IUserLesson } from '~/features/lesson/interfaces/lesson.interface';

export interface ILessonState {
  lesson: IUserLesson;
}

const getDefaultState = (): ILessonState => {
  return {
    lesson: {
      courseId: '',
      moduleId: '',
      id: '',
      title: '',
      content: '',
      additionalResources: '',
      sourcesUrl: '',
      image: null,
      vimeoVideoUrl: '',
      attachments: [],
      instructors: [],
      lastVisitedAt: '',
      startedAt: '',
      completedAt: '',
    },
  };
};

export const state = (): ILessonState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getLessonData(ctx, lessonId: string) {
    const response = await this.$api.userCourses.getLessonData(lessonId);

    ctx.commit('setLesson', response);
  },

  async noteLessonWasVisited(ctx) {
    await this.$api.userCourses.noteUserLessonEvents({
      lessonId: ctx.state.lesson.id,
      events: [ELessonUserEvent.Visited],
    });
  },

  async noteLessonWasStarted(ctx) {
    await this.$api.userCourses.noteUserLessonEvents({
      lessonId: ctx.state.lesson.id,
      events: [ELessonUserEvent.Started],
    });
  },

  async noteLessonWasCompleted(ctx) {
    await this.$api.userCourses.noteUserLessonEvents({
      lessonId: ctx.state.lesson.id,
      events: [ELessonUserEvent.Completed],
    });
  },
};

export const mutations: MutationTree<RootState> = {
  setLesson(state, lesson) {
    state.lesson = lesson;
  },

  setLessonCompleted(state) {
    state.lesson.completedAt = new Date().toLocaleDateString();
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<RootState, RootState> = {
  isLessonCompleted(state): boolean {
    return !!state.lesson.completedAt?.length;
  },
};
