import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAdminPaymentsApi,
  ICreateSubscriptionPayload,
} from '~/api/interfaces/admin-payments.interface';

export default ($axios: NuxtAxiosInstance): IAdminPaymentsApi => ({
  createSubscription(payload: ICreateSubscriptionPayload): Promise<void> {
    return $axios.$post('/payments/admin/createSubscription', payload);
  },
});
