import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api';

export interface IUseStatusLines {
  isConfirmationLineShown: ComputedRef<boolean>;
  isFailedPaymentLineShown: ComputedRef<boolean>;
}

export const useStatusLines = (): IUseStatusLines => {
  const { $auth } = useContext();

  const isConfirmationLineShown = computed(
    () =>
      ($auth.$state.isEmailChangedPendingVerification || !$auth.$state.isFirstEmailVerified) &&
      $auth.loggedIn,
  );
  const isFailedPaymentLineShown = computed(() => $auth.$state.hasFailedPayments && $auth.loggedIn);

  return { isConfirmationLineShown, isFailedPaymentLineShown };
};
