import { IFooterLink, IFooterLinkIcon } from '~/shared/interfaces/footer-link.interface';

export const socialFooterShortenLinks: IFooterLink[] = [
  {
    title: 'social.footer.about',
    to: '/about',
  },
  {
    title: 'social.footer.contact',
    isContactButton: true,
  },
  {
    title: 'social.footer.legal',
    to: '/terms/general',
    blank: true,
  },
];

// TODO: Replace mocked url's and texts;
export const socialFooterSocialsLinks: IFooterLinkIcon[] = [
  {
    icon: 'social/footer-instagram',
    url: 'https://www.instagram.com/milanartinstitute/',
  },
  {
    icon: 'social/footer-facebook',
    url: 'https://www.facebook.com/milanartinstitute/',
  },
  {
    icon: 'social/footer-youtube',
    url: 'https://www.youtube.com/channel/UCRH4YWhFhLYuHBcx-9Lg14w',
  },
  {
    icon: 'social/footer-pinterest',
    url: 'https://www.pinterest.com/milanartinstitute/',
  },
];
