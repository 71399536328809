/* eslint import/no-webpack-loader-syntax: off */
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { ToastOptions } from 'vue-toasted';
import { isNil } from 'ramda';
import { EToastType, EToastIcon } from '~/shared/enums/toast.enum';
import { IToastParams } from '~/shared/interfaces/toast.interface';

// eslint-disable-next-line import/no-unresolved
import trashIcon from '!!raw-loader!~/assets/svg/trash.svg';
// eslint-disable-next-line import/no-unresolved
import checkIcon from '!!raw-loader!~/assets/svg/check-5.svg';

const getSvgIcon = (iconName: EToastIcon): string => {
  switch (iconName) {
    case EToastIcon.Trash:
      return trashIcon;

    case EToastIcon.Check:
      return checkIcon;

    default:
      return EToastIcon.Check;
  }
};

export default defineNuxtPlugin((ctx, inject) => {
  inject('showToast', (message: string, params: IToastParams = {}): void => {
    const toastParams = {
      type: params.type || EToastType.Info,
      className: params.className || undefined,
      containerClass: 'toasted-container--custom',
    } as ToastOptions;

    if (params.icon) {
      toastParams.iconPack = 'callback';
      toastParams.icon = (el): HTMLElement => {
        el.innerHTML = getSvgIcon(params.icon as EToastIcon);
        el.className = `toasted-icon toasted-icon--${params.icon}`;

        return el;
      };
    }

    if (!isNil(params.action)) {
      toastParams.action = {
        text: params.action.text as string,
        class: params.action.class,
        onClick: async (_e, toastObject): Promise<void> => {
          if (typeof params.action?.onClick === 'function')
            await params.action.onClick(_e, toastObject);

          toastObject.goAway(0);
        },
      };
    }

    ctx.$toast.show(message, toastParams);
  });
});
