import { ActionTree, MutationTree } from 'vuex';
import { IContestForList } from '~/features/contests/interfaces/contest.interface';
import { IItemsListRequestParams } from '~/shared/interfaces/items-list.interface';

export interface IContestsState {
  contests: IContestForList[];
  total: number;
}

interface ISetContestIsPinnedPayload {
  contestId: string;
  isPinned: boolean;
}

const getDefaultState = (): IContestsState => ({
  contests: [],
  total: 0,
});

export const state = (): IContestsState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getContests(ctx, params: IItemsListRequestParams) {
    const { items, total } = await this.$api.artClubContestsAdmin.getContestsList(params);

    ctx.commit('setContests', items);
    ctx.commit('setTotal', total);
  },
};

export const mutations: MutationTree<RootState> = {
  setContests(state, contests: IContestForList[]) {
    state.contests = contests;
  },

  setTotal(state, total: number) {
    state.total = total;
  },

  setContestIsPinned(state, payload: ISetContestIsPinnedPayload) {
    const oldPinnedContest = state.contests.find((c) => c.isPinned);

    if (oldPinnedContest) {
      oldPinnedContest.isPinned = false;
    }

    const current = state.contests.find((c) => c.id === payload.contestId) as IContestForList;

    current.isPinned = payload.isPinned;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
