export const ONLY_YEAR = 'YYYY';
export const FULL_DATE_WITH_DOTS = 'MM.DD.YYYY';
export const FULL_DATE_TIME_SHORT = 'DD MMMM YYYY hh:mm A';
export const DATE_TIME_WITHOUT_YEAR_SHORT = 'DD MMMM hh:mm A';
export const FULL_DATE_SHORT = 'MMMM D, YYYY';
export const FULL_DATE_SHORT_2 = 'MMM DD, YYYY';
export const FULL_DATE_SHORT_3 = 'MMM D, YYYY';
export const FULL_DATE_SHORT_4 = 'DD MMM YYYY';
export const FULL_DATE_SHORT_WITH_TIME = 'MMM DD, YYYY hh:mm A';
export const FULL_DATE_SHORT_WITH_TIME_2 = 'MM/DD/YYYY hh:mm A';
export const MONTH_YEAR_DATE = 'MMMM YYYY';
export const TIME_LEFT = 'D[d] : H[h] : m[m]';
export const TIME_LEFT_WITH_SECONDS = 'D[d] : H[h] : m[m] : s[s]';
export const VOTING_UNTIL = 'MMM DD [@]h:mm A';
export const DATE_MONTH = 'DD MMMM';
export const DATE_MONTH_YEAR = 'DD MMMM YYYY';
export const MINUTES_TIMER = 'm:ss';
export const MONTH_DATE = 'MMM D';
export const MONTH_DATE_EXTEND = 'MMMM DD';
export const TIME_WITH_AM_PM = 'h:mm A';
export const CARD_EXPIRATION = 'MM/YY';
export const FULL_DATE_FOR_LAST_MODIFIED = 'ddd, DD MMM YYYY HH:mm:ss [GMT]';
export const FULL_DATE_ET_WITH_BR_DIVIDER = 'dddd, MMMM Do[<br />]h:mm A [ET]';
