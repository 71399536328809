import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { pick } from 'ramda';
import { ICommunityDataForAdmin } from '~/features/communities/interfaces/community-data.interface';
import { ECommunityType } from '~/features/communities/enums/community-type.enum';
import { ECommunityStatus } from '~/features/communities/enums/community-status.enum';
import { IFile } from '~/features/files/interfaces/file.interface';
import { prepareStringToSlug } from '~/utils/prepare-string-to-slug';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface ICommunityState {
  community: ICommunityDataForAdmin;
}

type TFieldsToUpdate = 'name' | 'about' | 'type' | 'slug';

const getDefaultState = (): ICommunityState => ({
  community: {
    id: '',
    name: '',
    about: '',
    image: null,
    slug: '',
    type: ECommunityType.Public,
    status: ECommunityStatus.Draft,
    isPublished: false,
    createdAt: '',
    membersCount: 0,
    followersCount: 0,
    postsCount: 0,
  },
});

export const state = (): ICommunityState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getCommunity(ctx, communityId: string): Promise<void> {
    const data = await this.$api.communitiesAdmin.getCommunityInfoById(communityId);

    ctx.commit('setCommunity', data);
  },

  async createCommunity(ctx, isPublished: boolean): Promise<void> {
    const { id } = await this.$api.communitiesAdmin.createCommunity({
      name: ctx.state.community.name,
      type: ctx.state.community.type,
      imageId: ctx.state.community.image?.id,
    });

    await this.$api.communitiesAdmin.saveCommunity({
      ...pick(['name', 'about', 'type'], ctx.state.community),
      imageId: ctx.state.community.image?.id,
      slug: ctx.state.community.slug ? prepareStringToSlug(ctx.state.community.slug, true) : null,
      id,
      isPublished,
    });
  },

  async saveCommunityFields(ctx, fieldsToSave: TFieldsToUpdate[]): Promise<void> {
    await this.$api.communitiesAdmin.saveCommunity({
      id: ctx.state.community.id,
      imageId: ctx.state.community.image?.id,
      ...pick(fieldsToSave, ctx.state.community),
    });
  },

  async changePublishedStatus(ctx, isPublished: boolean): Promise<void> {
    await this.$api.communitiesAdmin.saveCommunity({
      id: ctx.state.community.id,
      isPublished,
    });

    ctx.commit('setIsPublished', isPublished);
  },

  async updateLocalFields(ctx, fields: TFieldsToUpdate[]): Promise<void> {
    const data = await this.$api.communitiesAdmin.getCommunityInfoById(ctx.state.community.id);

    ctx.commit('updateFields', pick(fields, data));
  },
};

export const mutations: MutationTree<RootState> = {
  setCommunity(state, community: ICommunityDataForAdmin) {
    state.community = community;
  },

  updateFields(state, fields: Partial<ICommunityDataForAdmin>) {
    state.community = { ...state.community, ...fields };
  },

  setName(state, name: string) {
    state.community.name = name;
  },

  setSlug(state, slug: string) {
    state.community.slug = prepareStringToSlug(slug) || null;
  },

  setAbout(state, about: string) {
    state.community.about = about;
  },

  setImage(state, image: IFile) {
    state.community.image = state.community.image ?? getDefaultFileState();
    state.community.image.id = image.id;
    state.community.image.url = image.url;
  },

  setType(state, type: ECommunityType) {
    state.community.type = type;
  },

  setIsPublished(state, isPublished: boolean) {
    state.community.isPublished = isPublished;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<ICommunityState, RootState> = {
  isSubmitDisabled(state) {
    return (
      !state.community.name.length ||
      !state.community.about?.length ||
      !state.community.image?.id.length
    );
  },

  isCommunitySlugEmpty(state) {
    return !state.community.slug?.length;
  },

  isEditCommunityPage(state) {
    return !!state.community.id.length;
  },

  isTypePublic(state) {
    return state.community.type === ECommunityType.Public;
  },

  isTypeLimited(state) {
    return state.community.type === ECommunityType.Limited;
  },

  isTypePrivate(state) {
    return state.community.type === ECommunityType.Private;
  },
};
