import { render, staticRenderFns } from "./UiSpinner.vue?vue&type=template&id=2eb6c134&scoped=true&"
import script from "./UiSpinner.vue?vue&type=script&lang=ts&"
export * from "./UiSpinner.vue?vue&type=script&lang=ts&"
import style0 from "./UiSpinner.vue?vue&type=style&index=0&id=2eb6c134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb6c134",
  null
  
)

export default component.exports