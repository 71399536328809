import { isNil } from 'ramda';
import { NuxtCookies } from 'cookie-universal-nuxt';

export const getGaPseudoId = ($cookies: NuxtCookies): string => {
  const gaCodeArray = $cookies.get('_ga')?.split('.');

  if (isNil(gaCodeArray)) return 'GA_PSEUDO_ID_NOT_FOUND';

  return `${gaCodeArray[gaCodeArray.length - 2]}.${gaCodeArray[gaCodeArray.length - 1]}`;
};
