import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { ExpiredAuthSessionError } from '~auth/runtime' /* we're using this path because of internal module reasons.
If we change it the error will cause (https://github.com/nuxt-community/auth-module/issues/1109#issuecomment-902507116) */;
import { TResponse } from '~/shared/types/response.type';
import { backendRedirectsMap } from '~/shared/config/backend-redirects-map.config';
import { EAuthBackendErrorCode } from '~/shared/enums/backend-error-code.enum';
import { nonHandledServerErrorsStatusCodes } from '~/shared/config/non-handled-server-errors-status-codes.config';
import { makeTimeout } from '~/utils/make-timeout';

export default defineNuxtPlugin(
  ({ redirect, $axios, localePath, $toast, app, error: nuxtError, $sentry, route, store }) => {
    $axios.onRequest((config) => {
      // Backend needed to have unique session id for each device to implement trottling prevention
      config.headers.Cookies = `session_id=${app.store?.state.sessionId}; Secure; HttpOnly`;
      //

      return config;
    });

    $axios.onResponseError(async (error) => {
      if (error instanceof ExpiredAuthSessionError) {
        store.commit('setIsSessionExpired', true);

        // returning an obj with empty data in this case only to prevent application failing with errors
        // like can't read property X of undefined
        return Promise.resolve({ data: {} });
      }

      const originalRequest = error.config;
      const statusCode = error.response?.status;

      // Handling of 500 error code from the server
      if (statusCode === 500) return nuxtError({ statusCode });

      // Handling of 429, 502, 503, 504 error codes from the server
      if (
        (isNil(error.response) ||
          nonHandledServerErrorsStatusCodes.includes(statusCode as number)) &&
        // @ts-ignore
        !originalRequest?._retry
      ) {
        if (isNil(originalRequest)) return;

        // @ts-ignore
        originalRequest._retry = true;

        await makeTimeout(2000);

        return await $axios.request(originalRequest);
      } else if (
        nonHandledServerErrorsStatusCodes.includes(statusCode as number) &&
        // @ts-ignore
        originalRequest?._retry
      ) {
        // It's temporary. To prevent app crashing on workshop page if it will be caused
        if (route.path === '/workshop') return;
        //

        $sentry.captureException(error, {
          tags: {
            errorPlaceType: 'axios-plugin-exception-429-error',
          },
          extra: {
            prevRequest: originalRequest.url,
          },
        });

        return nuxtError({ statusCode });
      }
      //

      // Refresh token logic on 401 response status
      if (statusCode === 401 && originalRequest?.url?.includes('/auth/refreshToken')) {
        redirect(localePath('/'));

        $sentry.captureException(error, {
          tags: {
            errorPlaceType: 'axios-plugin-exception',
          },
          extra: {
            prevRequest: originalRequest.url,
          },
        });

        $toast?.error(`${error?.response?.status} ${error?.response?.statusText}`);

        return Promise.reject(error);
        // @ts-ignore
      } else if (statusCode === 401 && !originalRequest?._retry) {
        // @ts-ignore
        originalRequest._retry = true;

        await app.$auth.refreshTokens().catch((error) => {
          console.error(error);

          $sentry.captureException(error, {
            tags: {
              errorPlaceType: 'axios-after-refresh-plugin-exception',
            },
            extra: {
              prevRequest: originalRequest.url,
            },
          });
        });

        return $axios.request(originalRequest);
      }
      //

      if (!originalRequest?.localError) $toast?.error(error?.response?.data.message);

      return originalRequest?.localError ? Promise.reject(error) : Promise.resolve(false);
    });

    $axios.onResponse((response) => {
      if (isNil(response)) return {};

      const { data } = response as TResponse;

      if (data && !data.success) {
        if (response.config?.localError) throw data;

        if (data.errorCode === EAuthBackendErrorCode.AuthFailedUserIsBlocked) {
          store.commit('setIsSessionExpired', false);

          redirect(
            localePath({
              path: '/user-blocked',
              query: data.errorData,
            }),
          );

          return;
        }

        $toast?.error(data.localizedErrorMessage);

        if (data.redirectCode)
          redirect(localePath(backendRedirectsMap[data.redirectCode] || '/feed'));

        return;
      }

      if (response.config?.url === '/auth/login' || response.config?.url === '/auth/refreshToken')
        return response;

      return response.data?.data !== undefined ? response.data : response;
    });
  },
);
