import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { filter, find, findIndex, includes, isNil, map, omit, propEq } from 'ramda';
import { IOfferFull } from '~/features/offers/interfaces/offer.interface';
import { EOfferType } from '~/features/offers/enums/offer-type.enum';
import { IOfferProduct } from '~/features/offers/interfaces/offer-product.interface';
import { TAutomation, TAutomationData } from '~/features/offers/types/automation.type';
import { EAutomationTrigger } from '~/features/offers/enums/automation-trigger.enum';
import { EAutomationAction } from '~/features/offers/enums/automation-action.enum';
import { IOfferVersion } from '~/features/offers/interfaces/offer-version.interface';
import { EEffectivePayment } from '~/features/offers/enums/effective-payment.enum';
import { ERequiredUserData } from '~/features/offers/enums/required-user-data.enum';
import { EOfferVersionPeriod } from '~/features/offers/enums/offer-version-period.enum';
import { IQuantityLimit } from '~/features/offers/interfaces/quantity-limit.interface';
import {
  IOfferVersionPriceWithPaymentsCount,
  IOfferVersionPriceWithUsdValue,
} from '~/features/offers/interfaces/offer-version-price.interface';
import { EOfferEditPermission } from '~/features/offers/enums/offer-edit-permission.enum';
import { getDefaultFileState } from '~/utils/get-default-image-state';

interface IOfferState {
  isOfferEdited: boolean;
  offer: IOfferFull;
  editedAutomation: TAutomation | null;
  activeVersionIndex: number;
  isActiveCreateOfferVersionMode: boolean;
}

interface ISetProductStartDatePayload {
  productId: string;
  startDate: string;
}

interface ISetProductApplyDripSettings {
  productId: string;
  applyDripSettings: boolean;
}

interface ISetProductAccessLengthLimitPayload {
  productId: string;
  accessLengthLimit: number;
}

interface ISetProductIncludeMentorshipPayload {
  productId: string;
  includeMentorship: boolean;
}

interface ISetVersionStatusPayload {
  id: number;
  status: boolean;
}

interface ISetVersionImage {
  imageId: string;
  imageUrl: string;
}

const getDefaultState = (): IOfferState => ({
  isOfferEdited: false,
  offer: {
    data: {
      id: '',
      type: EOfferType.Managed,
      name: '',
      availableFrom: null,
      availableUntil: null,
      quantityLimit: null,
      isPublished: false,
      versions: [],
      products: [],
      automations: [],
    },
    editPermissions: {
      canEditName: false,
      canEditProductsList: false,
      canEditProductSettings: false,
      canEditAvailability: false,
      canEditQuantityLimit: false,
      canChangePublishedState: false,
      canManageVersionsList: false,
      canEditVersionImage: false,
      canEditVersionCheckout: false,
      canEditVersionPrice: false,
      versionPriceOptionsAllowed: [],
      canEditVersionRequiredUserData: false,
      canEditVersionThankYouLink: false,
      canEditAutomations: false,
    },
  },
  activeVersionIndex: 0,
  isActiveCreateOfferVersionMode: false,
  editedAutomation: null,
});

export const state = (): IOfferState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<IOfferState, RootState> = {
  offerProducts(state): IOfferProduct[] {
    return state.offer.data.products;
  },

  offerName(state): string {
    return state.offer.data.name;
  },

  isAutomationEdited(state): boolean {
    return !isNil(state.editedAutomation);
  },

  automations(state): TAutomation[] {
    return state.offer.data.automations;
  },

  versions(state): IOfferVersion[] {
    return state.offer.data.versions;
  },

  activeVersion(state): IOfferVersion | undefined {
    return state.offer.data.versions[state.activeVersionIndex];
  },

  requestPhoneNumber(_state, getters): boolean {
    if (!getters.activeVersion) return false;

    return getters.activeVersion.requiredUserData.includes(ERequiredUserData.PhoneNumber);
  },

  availableFrom(state): string | null {
    return state.offer.data.availableFrom;
  },

  availableUntil(state): string | null {
    return state.offer.data.availableUntil;
  },

  quantityLimit(state): null | IQuantityLimit {
    return state.offer.data.quantityLimit;
  },

  showLimitPurchaseQuantity(state): boolean {
    return !isNil(state.offer.data.quantityLimit);
  },

  isCustomAvailableFrom(state): boolean {
    return !isNil(state.offer.data.availableFrom);
  },

  isCustomAvailableUntil(state): boolean {
    return !isNil(state.offer.data.availableUntil);
  },

  getPermissionStatus: (state) => (permissionKey: EOfferEditPermission): boolean =>
    state.offer.editPermissions[permissionKey],

  canEditName(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditName);
  },

  canEditProductsList(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditProductsList);
  },

  canEditProductSettings(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditProductSettings);
  },

  canEditAutomations(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditAutomations);
  },

  canManageVersionsList(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanManageVersionsList);
  },

  canEditVersionPrice(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditVersionPrice);
  },

  canEditVersionRequiredUserData(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditVersionRequiredUserData);
  },

  canEditVersionThankYouLink(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditVersionThankYouLink);
  },

  canEditVersionImage(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditVersionImage);
  },

  canEditVersionCheckout(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditVersionCheckout);
  },

  canEditAvailability(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditAvailability);
  },

  canEditQuantityLimit(_state, getters): boolean {
    return getters.getPermissionStatus(EOfferEditPermission.CanEditQuantityLimit);
  },

  isPriceOptionAvailable: (state) => (price: EEffectivePayment): boolean => {
    return includes(price, state.offer.editPermissions.versionPriceOptionsAllowed);
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getOfferById(ctx, offerId: string) {
    const response = await this.$api.editOffers.getOfferById(offerId);

    ctx.commit('setOffer', response);
    ctx.commit('setActiveVersion', findIndex(propEq('isPublished', true), response.data.versions));
  },

  async saveOffer(ctx) {
    await this.$api.editOffers.saveOffer(omit(['type'], ctx.state.offer.data));

    await ctx.dispatch('getOfferById', ctx.state.offer.data.id);

    ctx.commit('setIsOfferEdited', false);
  },

  async publishOffer(ctx) {
    await this.$api.editOffers.saveOffer(
      omit(['type'], { ...ctx.state.offer.data, isPublished: true }),
    );

    await ctx.dispatch('getOfferById', ctx.state.offer.data.id);

    ctx.commit('setIsOfferEdited', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setOffer(state, offer) {
    state.offer = offer;
  },

  setOfferName(state, offerName: string) {
    state.offer.data.name = offerName;
    state.isOfferEdited = true;
  },

  addOfferProducts(state, products: IOfferProduct[]) {
    state.offer.data.products = [
      ...state.offer.data.products,
      ...map(
        (product) => ({
          ...product,
          settings: {
            // TODO: Remove ts-ignore when the problem will be resolved (https://github.com/nuxt-community/dayjs-module/issues/353)
            // @ts-ignore
            startDate: this.$dayjs(new Date() as string)
              .utc()
              .format(),
            accessLengthLimit: 1,
            applyDripSettings: true,
            includeMentorship: false,
          },
        }),
        products,
      ),
    ];

    state.isOfferEdited = true;
  },

  setProductStartDate(state, { productId, startDate }: ISetProductStartDatePayload) {
    const product = find((product) => product.id === productId, state.offer.data.products);
    if (!product?.settings) return;

    product.settings = {
      ...product.settings,
      // TODO: Remove ts-ignore when the problem will be resolved (https://github.com/nuxt-community/dayjs-module/issues/353)
      // @ts-ignore
      startDate: this.$dayjs(startDate).utc().format(),
    };

    state.isOfferEdited = true;
  },

  setProductApplyDripSettings(
    state,
    { productId, applyDripSettings }: ISetProductApplyDripSettings,
  ) {
    const product = find((product) => product.id === productId, state.offer.data.products);
    if (!product?.settings) return;

    product.settings = { ...product.settings, applyDripSettings };

    state.isOfferEdited = true;
  },

  setProductAccessLengthLimit(
    state,
    { productId, accessLengthLimit }: ISetProductAccessLengthLimitPayload,
  ) {
    const product = find((product) => product.id === productId, state.offer.data.products);
    if (!product?.settings) return;

    product.settings = { ...product.settings, accessLengthLimit };

    state.isOfferEdited = true;
  },

  setProductIncludeMentorship(
    state,
    { productId, includeMentorship }: ISetProductIncludeMentorshipPayload,
  ) {
    const product = find((product) => product.id === productId, state.offer.data.products);
    if (!product?.settings) return;

    product.settings = { ...product.settings, includeMentorship };

    state.isOfferEdited = true;
  },

  removeProduct(state, productId: string) {
    state.offer.data.products = filter(
      (product) => product.id !== productId,
      state.offer.data.products,
    );

    state.isOfferEdited = true;
  },

  startOfferAutomationCreation(state) {
    state.editedAutomation = {
      trigger: EAutomationTrigger.OfferPurchased,
      action: EAutomationAction.AddTagToUser,
      data: {
        tag: '',
      },
    };

    state.isOfferEdited = true;
  },

  setAutomationToEdit(state, automationIndex: number) {
    state.editedAutomation = state.offer.data.automations[automationIndex];

    state.offer.data.automations.splice(automationIndex, 1);

    state.isOfferEdited = true;
  },

  removeAutomation(state, automationIndex: number) {
    state.offer.data.automations.splice(automationIndex, 1);

    state.isOfferEdited = true;
  },

  setEditedAutomationTrigger(state, trigger: EAutomationTrigger) {
    if (isNil(state.editedAutomation)) return;

    state.editedAutomation.trigger = trigger;

    state.isOfferEdited = true;
  },

  setEditedAutomationAction(state, action: EAutomationAction) {
    if (isNil(state.editedAutomation)) return;

    state.editedAutomation.action = action;

    state.isOfferEdited = true;
  },

  addEditedAutomationData(state, data: TAutomationData) {
    if (isNil(state.editedAutomation)) return;

    state.editedAutomation.data = data;

    state.isOfferEdited = true;
  },

  addEditedAutomation(state) {
    state.offer.data.automations = [
      ...state.offer.data.automations,
      state.editedAutomation as TAutomation,
    ];

    state.editedAutomation = null;

    state.isOfferEdited = true;
  },

  setActiveVersion(state, versionIndex: number) {
    state.activeVersionIndex = versionIndex;
  },

  setVersionStatus(state, { id, status }: ISetVersionStatusPayload) {
    const version = find(propEq('id', id), state.offer.data.versions);

    if (!version) return;

    version.isPublished = status;

    state.isOfferEdited = true;
  },

  setVersionCheckoutHeading(state, checkoutHeading) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    version.checkoutHeading = checkoutHeading;

    state.isOfferEdited = true;
  },

  setVersionPaymentType(state, paymentType: EEffectivePayment) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    if (paymentType === EEffectivePayment.Free) {
      version.price = { type: paymentType };
    } else if (paymentType === EEffectivePayment.OneTime) {
      version.price = {
        type: paymentType,
        usdValue: '',
      };
    } else if (paymentType === EEffectivePayment.MultiplePayments) {
      version.price = {
        type: paymentType,
        period: EOfferVersionPeriod.Month,
        paymentsCount: 1,
        usdValue: '',
      };
    } else {
      version.price = {
        type: paymentType,
        period: EOfferVersionPeriod.Month,
        usdValue: '',
      };
    }

    state.isOfferEdited = true;
  },

  setVersionUsdValue(state, usdValue: number | string) {
    const versionPrice = state.offer.data.versions[state.activeVersionIndex]
      .price as IOfferVersionPriceWithUsdValue;

    if (!versionPrice) return;

    versionPrice.usdValue = `${usdValue}`;
  },

  setVersionPaymentsCount(state, paymentsCount: number) {
    const versionPrice = state.offer.data.versions[state.activeVersionIndex]
      .price as IOfferVersionPriceWithPaymentsCount;

    if (!versionPrice) return;

    versionPrice.paymentsCount = paymentsCount;
  },

  setThankYouLink(state, thankYouLink: string) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    version.thankYouLink = thankYouLink;

    state.isOfferEdited = true;
  },

  setVersionImage(state, { imageUrl, imageId }: ISetVersionImage) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    version.image = version.image ?? getDefaultFileState();
    version.image.id = imageId;
    version.image.url = imageUrl;
    version.imageId = imageId;

    state.isOfferEdited = true;
  },

  setVersionCheckoutText(state, checkoutText: string) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    version.checkoutText = checkoutText;

    state.isOfferEdited = true;
  },

  setRequestPhoneNumber(state, isSetRequestPhoneNumber: boolean) {
    const version = state.offer.data.versions[state.activeVersionIndex];

    if (!version) return;

    if (isSetRequestPhoneNumber) {
      version.requiredUserData = [...version.requiredUserData, ERequiredUserData.PhoneNumber];

      return;
    }

    version.requiredUserData = filter(
      (value) => value !== ERequiredUserData.PhoneNumber,
      version.requiredUserData,
    );

    state.isOfferEdited = true;
  },

  startCreateOfferVersion(state) {
    state.offer.data.versions = [
      ...state.offer.data.versions,
      {
        id: null,
        image: null,
        checkoutHeading: null,
        checkoutText: null,
        isPublished: false,
        effectiveFrom: null,
        price: {
          type: EEffectivePayment.Free,
        },
        requiredUserData: [],
        thankYouLink: null,
      },
    ];

    state.activeVersionIndex = state.offer.data.versions.length - 1;
    state.isActiveCreateOfferVersionMode = true;

    state.isOfferEdited = true;
  },

  cancelCreateOfferVersion(state) {
    state.isActiveCreateOfferVersionMode = false;
    state.offer.data.versions.splice(state.offer.data.versions.length - 1, 1);
    state.activeVersionIndex = state.offer.data.versions.length - 1;
  },

  setEffectiveFrom(state, effectiveFrom: string) {
    const version = state.offer.data.versions[state.activeVersionIndex];
    if (!version) return;

    // TODO: Remove ts-ignore when the problem will be resolved (https://github.com/nuxt-community/dayjs-module/issues/353)
    // @ts-ignore
    version.effectiveFrom = this.$dayjs(effectiveFrom).utc().format();

    state.isOfferEdited = true;
  },

  saveOfferVersion(state) {
    state.isActiveCreateOfferVersionMode = false;
  },

  setQuantityLimitDefaultState(state) {
    state.offer.data.quantityLimit = {
      limit: 0,
      soldOutText: '',
    };

    state.isOfferEdited = true;
  },

  removeQuantityLimitDefaultState(state) {
    state.offer.data.quantityLimit = null;

    state.isOfferEdited = true;
  },

  setQuantityLimit(state, limit: number) {
    if (isNil(state.offer.data.quantityLimit)) return;

    state.offer.data.quantityLimit.limit = limit;

    state.isOfferEdited = true;
  },

  setSoldOutText(state, soldOutText: string) {
    if (isNil(state.offer.data.quantityLimit)) return;

    state.offer.data.quantityLimit.soldOutText = soldOutText;

    state.isOfferEdited = true;
  },

  setAvailableFrom(state, availableFrom: string | null) {
    if (isNil(availableFrom)) {
      state.offer.data.availableFrom = null;

      return;
    }

    // TODO: Remove ts-ignore when the problem will be resolved (https://github.com/nuxt-community/dayjs-module/issues/353)
    // @ts-ignore
    state.offer.data.availableFrom = this.$dayjs(availableFrom).utc().format();

    state.isOfferEdited = true;
  },

  setAvailableUntil(state, availableUntil: string | null) {
    if (isNil(availableUntil)) {
      state.offer.data.availableUntil = null;

      return;
    }

    // TODO: Remove ts-ignore when the problem will be resolved (https://github.com/nuxt-community/dayjs-module/issues/353)
    // @ts-ignore
    state.offer.data.availableUntil = this.$dayjs(availableUntil).utc().format();

    state.isOfferEdited = true;
  },

  setIsOfferEdited(state, isOfferEdited: boolean) {
    state.isOfferEdited = isOfferEdited;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
