import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { MOBILE_WIDTH } from '~/shared/constants';

export default defineNuxtPlugin((_ctx, inject) => {
  inject('isMobile', (mobileWidth?: number): boolean => {
    const comparableMobileWidth = mobileWidth || MOBILE_WIDTH;

    return window.innerWidth < comparableMobileWidth;
  });
});
