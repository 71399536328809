import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IArtClubBannersAdminApi,
  IGetArtContestsListForBannerParams,
  IGetBannersListParams,
  ISetBannerPublishedPayload,
} from '~/api/interfaces/art-club-banners-admin.interface';
import {
  IBannerCustomData,
  IBannerDataForAdmin,
} from '~/features/art-club/interfaces/banner.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IContestForBanner } from '~/features/contests/interfaces/contest.interface';
import { TCreateBannerPayload } from '~/api/types/art-club-banners-admin.types';
import { IPublishingDataLimits } from '~/features/art-club/interfaces/publishing-data-limits.interface';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';

export default ($axios: NuxtAxiosInstance): IArtClubBannersAdminApi => ({
  getBannerListItemById(id: string): Promise<IBannerDataForAdmin> {
    return $axios.$get('/artClub/banners/admin/getBannerListItemById', { params: { id } });
  },

  getBannersList(
    params: IGetBannersListParams,
  ): Promise<IItemsListWithTotalResponse<IBannerDataForAdmin>> {
    return $axios.$get('/artClub/banners/admin/getBannersList', { params });
  },

  createBanner(payload: TCreateBannerPayload): Promise<IEntityWithId> {
    return $axios.$post('/artClub/banners/admin/createBanner', payload);
  },

  getArtContestsListForBanner(
    params: IGetArtContestsListForBannerParams,
  ): Promise<IItemsListWithTotalResponse<IContestForBanner>> {
    return $axios.$get('/artClub/banners/admin/getArtContestsListForBanner', { params });
  },

  duplicateBannerById(id: string): Promise<IEntityWithId> {
    return $axios.$post('/artClub/banners/admin/duplicateBannerById', { id });
  },

  setBannerPublished(payload: ISetBannerPublishedPayload): Promise<void> {
    return $axios.$post('/artClub/banners/admin/setBannerPublished', payload, { localError: true });
  },

  deleteBanner(id: string): Promise<void> {
    return $axios.$post('/artClub/banners/admin/deleteBanner', { id }, { localError: true });
  },

  getCustomBannerById(id: string): Promise<IBannerCustomData> {
    return $axios.$get('/artClub/banners/admin/getCustomBannerById', { params: { id } });
  },

  updateCustomBanner(
    payload: Omit<IBannerCustomData, 'status' | 'image'>,
  ): Promise<IBannerCustomData> {
    return $axios.$post('/artClub/banners/admin/updateCustomBanner', payload);
  },

  getBannerPublishingLimits(): Promise<IPublishingDataLimits> {
    return $axios.$get('/artClub/banners/admin/getBannerPublishingLimits');
  },
});
