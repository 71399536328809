import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IFeedAdminApi } from '~/api/interfaces/feed-admin.interface';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';

import { ISelectedUserForCrm } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): IFeedAdminApi => ({
  getSelectedUsersList(
    payload: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<ISelectedUserForCrm>> {
    return $axios.$get('/feed/demo/admin/getSelectedUsersList', { params: payload });
  },

  searchUser(
    payload: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<ISelectedUserForCrm>> {
    return $axios.$get('/feed/demo/admin/searchUser', { params: payload });
  },

  addUser(userId: string): Promise<void> {
    return $axios.$post('/feed/demo/admin/addUser', { userId });
  },

  deleteUser(userId: string): Promise<void> {
    return $axios.$post('/feed/demo/admin/deleteUser', { userId });
  },
});
