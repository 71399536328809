import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IArtClubContestsAdmin,
  ICreateContestPayload,
  ICreateContestResponse,
  IGetSubmissionsListParams,
  ISaveContestPayload,
  ISetContestPinnedPayload,
} from '~/api/interfaces/art-club-contests-admin.interface';
import {
  IContestForAdmin,
  IContestForList,
} from '~/features/contests/interfaces/contest.interface';
import {
  IItemsListRequestParams,
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IPrizeCategoryForContest } from '~/features/contests/interfaces/prize-category.interface';
import { IContestSubmissionSearch } from '~/features/contests/interfaces/contest-submission.interface';

export default ($axios: NuxtAxiosInstance): IArtClubContestsAdmin => ({
  createContest(payload: ICreateContestPayload): Promise<ICreateContestResponse> {
    return $axios.$post('/artClub/contests/edit/createContest', payload);
  },

  saveContest(payload: ISaveContestPayload): Promise<IContestForAdmin> {
    return $axios.$post('/artClub/contests/edit/saveContest', payload);
  },

  deleteContest(contestId: string): Promise<void> {
    return $axios.$post('/artClub/contests/edit/deleteContest', { contestId });
  },

  getContestsList(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IContestForList>> {
    return $axios.$get('/artClub/contests/edit/getContestsList', { params });
  },

  getContestDetails(id: string): Promise<IContestForAdmin> {
    return $axios.$get('/artClub/contests/edit/getContestDetails', { params: { id } });
  },

  getPrizeCategories(): Promise<IItemsListResponse<IPrizeCategoryForContest>> {
    return $axios.$get('/artClub/contests/edit/getPrizeCategories');
  },

  getSubmissionsList(
    params: IGetSubmissionsListParams,
  ): Promise<IItemsListWithTotalResponse<IContestSubmissionSearch>> {
    return $axios.$get('/artClub/contests/edit/getSubmissionsList', { params });
  },

  closeContest(contestId: string): Promise<void> {
    return $axios.$post('/artClub/contests/edit/closeContest', { contestId });
  },

  setContestPinned(payload: ISetContestPinnedPayload): Promise<void> {
    return $axios.$post('/artClub/contests/edit/setContestPinned', payload);
  },
});
