import { useContext } from '@nuxtjs/composition-api';

export interface IUseFormErrorMessages {
  getFormRequiredErrorMessage(key: string): string;
}

export const useFormErrorMessages = (): IUseFormErrorMessages => {
  const { i18n } = useContext();

  const getFormRequiredErrorMessage = (key: string): string => {
    const field = i18n.t(key) as string;

    return i18n.t('form.pleaseInputX', { field: field.toLowerCase() }) as string;
  };

  return {
    getFormRequiredErrorMessage,
  };
};
