import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { setUserParamsFromToken } from '~/utils/set-user-params-from-token';
import { getProp } from '~/utils/get-prop';
import { EAuthBackendErrorCode } from '~/shared/enums/backend-error-code.enum';
import { TAuthStrategy } from '~/features/auth/types/auth-strategy.type';

export default defineNuxtPlugin(({ $auth, $sentry }) => {
  $auth.hasScope = (scope: string): boolean => {
    const userScopes = $auth.$state.permissions as string[];

    if (!userScopes) {
      return false;
    }

    if (Array.isArray(userScopes)) {
      return userScopes.includes(scope);
    }

    return Boolean(getProp(userScopes, scope));
  };

  $auth.onError((error, name, endpoint) => {
    // don't throw error to sentry if the error is EAuthBackendErrorCode
    if (!Object.values(EAuthBackendErrorCode).includes((error as any)?.errorCode)) {
      $sentry.captureException(error, {
        tags: {
          errorPlaceType: 'auth-plugin-exception',
        },
        extra: {
          name: name as string,
          endpoint: endpoint as string,
        },
      });
    }
  });

  const tokenValue = ($auth.strategy as TAuthStrategy).token.get();

  if (!tokenValue) return;

  setUserParamsFromToken($auth.$storage, tokenValue);
});
