import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IFeaturesApi,
  IGetAccessStructureResponse,
  IGetFeatureAccessResponse,
} from '~/api/interfaces/features.interface';
import { TFeature } from '~/shared/types/feature.type';
import { IFeatureCommunityMembership } from '~/shared/interfaces/feature.interface';

export default ($axios: NuxtAxiosInstance): IFeaturesApi => ({
  getFeatureById(): Promise<TFeature> {
    return $axios.$get('/features/getFeatureById');
  },

  getPaidCommunityMembershipFeatureByCommunityId(
    communityId: string,
  ): Promise<IFeatureCommunityMembership> {
    return $axios.$get('/features/getPaidCommunityMembershipFeatureByCommunityId', {
      params: { communityId },
    });
  },

  getFeatureAccess(id: string, loadDetails?: true): Promise<IGetFeatureAccessResponse> {
    return $axios.$get('/features/getFeatureAccess', { params: { id, loadDetails } });
  },

  getAccessStructure(): Promise<IGetAccessStructureResponse> {
    return $axios.$get('/features/getAccessStructure');
  },
});
