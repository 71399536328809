import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _003a5784 = () => interopDefault(import('../pages/after-login.vue' /* webpackChunkName: "pages/after-login" */))
const _096371ca = () => interopDefault(import('../pages/art-club.vue' /* webpackChunkName: "pages/art-club" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _cf67b8e0 = () => interopDefault(import('../pages/communities/index.vue' /* webpackChunkName: "pages/communities/index" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _372cbd62 = () => interopDefault(import('../pages/email_confirmation.vue' /* webpackChunkName: "pages/email_confirmation" */))
const _f82920f0 = () => interopDefault(import('../pages/feed.vue' /* webpackChunkName: "pages/feed" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _79e78b36 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _6f305139 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _1f336459 = () => interopDefault(import('../pages/messages/_chatId.vue' /* webpackChunkName: "pages/messages/_chatId" */))
const _75c29c19 = () => interopDefault(import('../pages/mp-prep-registration.vue' /* webpackChunkName: "pages/mp-prep-registration" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _118a6f39 = () => interopDefault(import('../pages/oauth-login.vue' /* webpackChunkName: "pages/oauth-login" */))
const _6cd4806a = () => interopDefault(import('../pages/oauth-logout.vue' /* webpackChunkName: "pages/oauth-logout" */))
const _788c9795 = () => interopDefault(import('../pages/password_reset.vue' /* webpackChunkName: "pages/password_reset" */))
const _bad27560 = () => interopDefault(import('../pages/password-recovery/index.vue' /* webpackChunkName: "pages/password-recovery/index" */))
const _9cf7a16e = () => interopDefault(import('../pages/password-restore/index.vue' /* webpackChunkName: "pages/password-restore/index" */))
const _f3ca17be = () => interopDefault(import('../pages/pro-library.vue' /* webpackChunkName: "pages/pro-library" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _4f3345ab = () => interopDefault(import('../pages/settings/account.vue' /* webpackChunkName: "pages/settings/account" */))
const _2f4dae24 = () => interopDefault(import('../pages/settings/billing-details.vue' /* webpackChunkName: "pages/settings/billing-details" */))
const _7459db10 = () => interopDefault(import('../pages/settings/purchases.vue' /* webpackChunkName: "pages/settings/purchases" */))
const _44b60560 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _ea347058 = () => interopDefault(import('../pages/user-blocked.vue' /* webpackChunkName: "pages/user-blocked" */))
const _113690f0 = () => interopDefault(import('../pages/user-search.vue' /* webpackChunkName: "pages/user-search" */))
const _c91b56de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _d608b41e = () => interopDefault(import('../pages/workshop.vue' /* webpackChunkName: "pages/workshop" */))
const _84eabb76 = () => interopDefault(import('../pages/workshop-registration.vue' /* webpackChunkName: "pages/workshop-registration" */))
const _62f0ebe6 = () => interopDefault(import('../pages/admin/art-club/index.vue' /* webpackChunkName: "pages/admin/art-club/index" */))
const _22ffa460 = () => interopDefault(import('../pages/admin/banners/index.vue' /* webpackChunkName: "pages/admin/banners/index" */))
const _06f4f64f = () => interopDefault(import('../pages/admin/blog/index.vue' /* webpackChunkName: "pages/admin/blog/index" */))
const _38c03181 = () => interopDefault(import('../pages/admin/courses/index.vue' /* webpackChunkName: "pages/admin/courses/index" */))
const _27778d84 = () => interopDefault(import('../pages/admin/offers/index.vue' /* webpackChunkName: "pages/admin/offers/index" */))
const _2ab65ac8 = () => interopDefault(import('../pages/admin/pinned-posts.vue' /* webpackChunkName: "pages/admin/pinned-posts" */))
const _55aa3362 = () => interopDefault(import('../pages/admin/social-moderation/index.vue' /* webpackChunkName: "pages/admin/social-moderation/index" */))
const _808f695e = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _586e1115 = () => interopDefault(import('../pages/admin/workshops/index.vue' /* webpackChunkName: "pages/admin/workshops/index" */))
const _83d68ce2 = () => interopDefault(import('../pages/checkout/art-club.vue' /* webpackChunkName: "pages/checkout/art-club" */))
const _55ddbc70 = () => interopDefault(import('../pages/checkout/art-club-plus.vue' /* webpackChunkName: "pages/checkout/art-club-plus" */))
const _212ddd6f = () => interopDefault(import('../pages/checkout/art-club-pro.vue' /* webpackChunkName: "pages/checkout/art-club-pro" */))
const _7f1efd76 = () => interopDefault(import('../pages/checkout/art-club-pro-499.vue' /* webpackChunkName: "pages/checkout/art-club-pro-499" */))
const _7b755d53 = () => interopDefault(import('../pages/checkout/mastery-graduate-mentoring-plus.vue' /* webpackChunkName: "pages/checkout/mastery-graduate-mentoring-plus" */))
const _70de8484 = () => interopDefault(import('../pages/checkout/mastery-graduate-mentoring-pro.vue' /* webpackChunkName: "pages/checkout/mastery-graduate-mentoring-pro" */))
const _ad67dbea = () => interopDefault(import('../pages/checkout/mastery-graduate-mentoring-pro-499.vue' /* webpackChunkName: "pages/checkout/mastery-graduate-mentoring-pro-499" */))
const _07e74f81 = () => interopDefault(import('../pages/checkout/mastery-program-12-months.vue' /* webpackChunkName: "pages/checkout/mastery-program-12-months" */))
const _ed502b18 = () => interopDefault(import('../pages/checkout/mastery-program-pay-in-full.vue' /* webpackChunkName: "pages/checkout/mastery-program-pay-in-full" */))
const _47b6c6dd = () => interopDefault(import('../pages/checkout/mastery-student-mentoring-plus.vue' /* webpackChunkName: "pages/checkout/mastery-student-mentoring-plus" */))
const _3564c1ba = () => interopDefault(import('../pages/checkout/mastery-student-mentoring-pro.vue' /* webpackChunkName: "pages/checkout/mastery-student-mentoring-pro" */))
const _1964c441 = () => interopDefault(import('../pages/checkout/mastery-student-mentoring-pro-499.vue' /* webpackChunkName: "pages/checkout/mastery-student-mentoring-pro-499" */))
const _39c5b31b = () => interopDefault(import('../pages/communities/discover.vue' /* webpackChunkName: "pages/communities/discover" */))
const _e9b1ea44 = () => interopDefault(import('../pages/communities/my.vue' /* webpackChunkName: "pages/communities/my" */))
const _a5781822 = () => interopDefault(import('../pages/free-materials/ebooks.vue' /* webpackChunkName: "pages/free-materials/ebooks" */))
const _46560ef9 = () => interopDefault(import('../pages/free-materials/podcasts.vue' /* webpackChunkName: "pages/free-materials/podcasts" */))
const _71c0875a = () => interopDefault(import('../pages/free-materials/webinars.vue' /* webpackChunkName: "pages/free-materials/webinars" */))
const _0ede318e = () => interopDefault(import('../pages/password-restore/art-club.vue' /* webpackChunkName: "pages/password-restore/art-club" */))
const _977e2344 = () => interopDefault(import('../pages/password-restore/art-club-plus.vue' /* webpackChunkName: "pages/password-restore/art-club-plus" */))
const _41272619 = () => interopDefault(import('../pages/password-restore/art-club-pro.vue' /* webpackChunkName: "pages/password-restore/art-club-pro" */))
const _56c11f2e = () => interopDefault(import('../pages/password-restore/mastery-graduate-mentoring-plus.vue' /* webpackChunkName: "pages/password-restore/mastery-graduate-mentoring-plus" */))
const _633ab6ae = () => interopDefault(import('../pages/password-restore/mastery-graduate-mentoring-pro.vue' /* webpackChunkName: "pages/password-restore/mastery-graduate-mentoring-pro" */))
const _3a12f907 = () => interopDefault(import('../pages/password-restore/mastery-student-mentoring-plus.vue' /* webpackChunkName: "pages/password-restore/mastery-student-mentoring-plus" */))
const _7704a250 = () => interopDefault(import('../pages/password-restore/mastery-student-mentoring-pro.vue' /* webpackChunkName: "pages/password-restore/mastery-student-mentoring-pro" */))
const _19480eaa = () => interopDefault(import('../pages/password-restore/mp-prep-course.vue' /* webpackChunkName: "pages/password-restore/mp-prep-course" */))
const _28648d94 = () => interopDefault(import('../pages/password-restore/workshop-registration.vue' /* webpackChunkName: "pages/password-restore/workshop-registration" */))
const _b9cb4552 = () => interopDefault(import('../pages/terms/art-auction.vue' /* webpackChunkName: "pages/terms/art-auction" */))
const _361887d2 = () => interopDefault(import('../pages/terms/art-club.vue' /* webpackChunkName: "pages/terms/art-club" */))
const _775d4d32 = () => interopDefault(import('../pages/terms/art-for-sale.vue' /* webpackChunkName: "pages/terms/art-for-sale" */))
const _2b9dc0e6 = () => interopDefault(import('../pages/terms/general.vue' /* webpackChunkName: "pages/terms/general" */))
const _7391ba09 = () => interopDefault(import('../pages/terms/privacy-policy.vue' /* webpackChunkName: "pages/terms/privacy-policy" */))
const _0b7aa774 = () => interopDefault(import('../pages/terms/subscriptions.vue' /* webpackChunkName: "pages/terms/subscriptions" */))
const _82226886 = () => interopDefault(import('../pages/thank-you/art-club.vue' /* webpackChunkName: "pages/thank-you/art-club" */))
const _52313c5a = () => interopDefault(import('../pages/thank-you/art-club-plus.vue' /* webpackChunkName: "pages/thank-you/art-club-plus" */))
const _2537289d = () => interopDefault(import('../pages/thank-you/art-club-pro.vue' /* webpackChunkName: "pages/thank-you/art-club-pro" */))
const _7a1301a4 = () => interopDefault(import('../pages/thank-you/art-club-pro-499.vue' /* webpackChunkName: "pages/thank-you/art-club-pro-499" */))
const _eca29136 = () => interopDefault(import('../pages/thank-you/mastery-graduate-mentoring-plus.vue' /* webpackChunkName: "pages/thank-you/mastery-graduate-mentoring-plus" */))
const _782ebf9c = () => interopDefault(import('../pages/thank-you/mastery-graduate-mentoring-pro.vue' /* webpackChunkName: "pages/thank-you/mastery-graduate-mentoring-pro" */))
const _2536b28e = () => interopDefault(import('../pages/thank-you/mastery-graduate-mentoring-pro-499.vue' /* webpackChunkName: "pages/thank-you/mastery-graduate-mentoring-pro-499" */))
const _2a6a1f13 = () => interopDefault(import('../pages/thank-you/mastery-program-12-months.vue' /* webpackChunkName: "pages/thank-you/mastery-program-12-months" */))
const _16651d86 = () => interopDefault(import('../pages/thank-you/mastery-program-pay-in-full.vue' /* webpackChunkName: "pages/thank-you/mastery-program-pay-in-full" */))
const _ca7e3aea = () => interopDefault(import('../pages/thank-you/mastery-student-mentoring-plus.vue' /* webpackChunkName: "pages/thank-you/mastery-student-mentoring-plus" */))
const _981d0f68 = () => interopDefault(import('../pages/thank-you/mastery-student-mentoring-pro.vue' /* webpackChunkName: "pages/thank-you/mastery-student-mentoring-pro" */))
const _7eafe1d3 = () => interopDefault(import('../pages/thank-you/mastery-student-mentoring-pro-499.vue' /* webpackChunkName: "pages/thank-you/mastery-student-mentoring-pro-499" */))
const _ca2aaa98 = () => interopDefault(import('../pages/users/art-club/index.vue' /* webpackChunkName: "pages/users/art-club/index" */))
const _aaae5476 = () => interopDefault(import('../pages/admin/art-club/banners/index.vue' /* webpackChunkName: "pages/admin/art-club/banners/index" */))
const _144f6c12 = () => interopDefault(import('../pages/admin/art-club/contests/index.vue' /* webpackChunkName: "pages/admin/art-club/contests/index" */))
const _176ccb98 = () => interopDefault(import('../pages/admin/art-club/instructors/index.vue' /* webpackChunkName: "pages/admin/art-club/instructors/index" */))
const _78378926 = () => interopDefault(import('../pages/admin/art-club/tv-show/index.vue' /* webpackChunkName: "pages/admin/art-club/tv-show/index" */))
const _b7e34120 = () => interopDefault(import('../pages/admin/art-club/videos/index.vue' /* webpackChunkName: "pages/admin/art-club/videos/index" */))
const _e6d58dc4 = () => interopDefault(import('../pages/admin/banners/new.vue' /* webpackChunkName: "pages/admin/banners/new" */))
const _3e8ee007 = () => interopDefault(import('../pages/admin/blog/banners/index.vue' /* webpackChunkName: "pages/admin/blog/banners/index" */))
const _af5fd546 = () => interopDefault(import('../pages/admin/blog/new.vue' /* webpackChunkName: "pages/admin/blog/new" */))
const _5a3930ec = () => interopDefault(import('../pages/admin/mentoring/mentors.vue' /* webpackChunkName: "pages/admin/mentoring/mentors" */))
const _1fc3d193 = () => interopDefault(import('../pages/admin/mentoring/students/index.vue' /* webpackChunkName: "pages/admin/mentoring/students/index" */))
const _aa1426f2 = () => interopDefault(import('../pages/admin/social-moderation/comments.vue' /* webpackChunkName: "pages/admin/social-moderation/comments" */))
const _03a8b907 = () => interopDefault(import('../pages/admin/social-moderation/communities/index.vue' /* webpackChunkName: "pages/admin/social-moderation/communities/index" */))
const _61625745 = () => interopDefault(import('../pages/admin/social-moderation/demo-feed.vue' /* webpackChunkName: "pages/admin/social-moderation/demo-feed" */))
const _2d89095a = () => interopDefault(import('../pages/admin/social-moderation/posts/index.vue' /* webpackChunkName: "pages/admin/social-moderation/posts/index" */))
const _ecc5f780 = () => interopDefault(import('../pages/admin/social-moderation/profiles/index.vue' /* webpackChunkName: "pages/admin/social-moderation/profiles/index" */))
const _3777eb95 = () => interopDefault(import('../pages/admin/users/admins.vue' /* webpackChunkName: "pages/admin/users/admins" */))
const _eec85f2c = () => interopDefault(import('../pages/admin/users/tags.vue' /* webpackChunkName: "pages/admin/users/tags" */))
const _60bd29a3 = () => interopDefault(import('../pages/admin/workshops/new.vue' /* webpackChunkName: "pages/admin/workshops/new" */))
const _e294f5d0 = () => interopDefault(import('../pages/users/art-club/browse.vue' /* webpackChunkName: "pages/users/art-club/browse" */))
const _1a3c15a0 = () => interopDefault(import('../pages/users/art-club/contests/index.vue' /* webpackChunkName: "pages/users/art-club/contests/index" */))
const _e2726a14 = () => interopDefault(import('../pages/users/art-club/history.vue' /* webpackChunkName: "pages/users/art-club/history" */))
const _68649567 = () => interopDefault(import('../pages/users/art-club/new-releases.vue' /* webpackChunkName: "pages/users/art-club/new-releases" */))
const _42bda28a = () => interopDefault(import('../pages/users/art-club/popular.vue' /* webpackChunkName: "pages/users/art-club/popular" */))
const _5383e42e = () => interopDefault(import('../pages/users/art-club/saved.vue' /* webpackChunkName: "pages/users/art-club/saved" */))
const _6e298536 = () => interopDefault(import('../pages/users/art-club/search.vue' /* webpackChunkName: "pages/users/art-club/search" */))
const _f50f9d0a = () => interopDefault(import('../pages/users/art-club/shows/index.vue' /* webpackChunkName: "pages/users/art-club/shows/index" */))
const _573686a0 = () => interopDefault(import('../pages/users/art-club/video/index.vue' /* webpackChunkName: "pages/users/art-club/video/index" */))
const _43415a53 = () => interopDefault(import('../pages/admin/art-club/banners/new.vue' /* webpackChunkName: "pages/admin/art-club/banners/new" */))
const _95795a84 = () => interopDefault(import('../pages/admin/art-club/videos/new.vue' /* webpackChunkName: "pages/admin/art-club/videos/new" */))
const _7a490d15 = () => interopDefault(import('../pages/admin/blog/banners/new.vue' /* webpackChunkName: "pages/admin/blog/banners/new" */))
const _11a83ce6 = () => interopDefault(import('../pages/admin/social-moderation/communities/drafts.vue' /* webpackChunkName: "pages/admin/social-moderation/communities/drafts" */))
const _3e052615 = () => interopDefault(import('../pages/admin/social-moderation/communities/new.vue' /* webpackChunkName: "pages/admin/social-moderation/communities/new" */))
const _c2d453c6 = () => interopDefault(import('../pages/admin/social-moderation/communities/recommended-card.vue' /* webpackChunkName: "pages/admin/social-moderation/communities/recommended-card" */))
const _9c2aef3c = () => interopDefault(import('../pages/admin/social-moderation/posts/reported.vue' /* webpackChunkName: "pages/admin/social-moderation/posts/reported" */))
const _5058fa4c = () => interopDefault(import('../pages/admin/social-moderation/profiles/blocked.vue' /* webpackChunkName: "pages/admin/social-moderation/profiles/blocked" */))
const _202ec8c0 = () => interopDefault(import('../pages/users/art-club/contests/archive.vue' /* webpackChunkName: "pages/users/art-club/contests/archive" */))
const _35a78a1c = () => interopDefault(import('../pages/users/art-club/contests/browse.vue' /* webpackChunkName: "pages/users/art-club/contests/browse" */))
const _c0bb50d0 = () => interopDefault(import('../pages/users/art-club/contests/my-submissions.vue' /* webpackChunkName: "pages/users/art-club/contests/my-submissions" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d920c39 = () => interopDefault(import('../pages/admin/art-club/banners/_bannerId.vue' /* webpackChunkName: "pages/admin/art-club/banners/_bannerId" */))
const _19a016fb = () => interopDefault(import('../pages/admin/art-club/contests/_contestId.vue' /* webpackChunkName: "pages/admin/art-club/contests/_contestId" */))
const _d632e62a = () => interopDefault(import('../pages/admin/art-club/tv-show/_showId.vue' /* webpackChunkName: "pages/admin/art-club/tv-show/_showId" */))
const _5ae1ee09 = () => interopDefault(import('../pages/admin/art-club/videos/_videoId.vue' /* webpackChunkName: "pages/admin/art-club/videos/_videoId" */))
const _2f05257b = () => interopDefault(import('../pages/admin/blog/banners/_bannerId.vue' /* webpackChunkName: "pages/admin/blog/banners/_bannerId" */))
const _7acf6b69 = () => interopDefault(import('../pages/admin/mentoring/students/_categorySlug.vue' /* webpackChunkName: "pages/admin/mentoring/students/_categorySlug" */))
const _16e50800 = () => interopDefault(import('../pages/admin/social-moderation/communities/_communityId.vue' /* webpackChunkName: "pages/admin/social-moderation/communities/_communityId" */))
const _2f9b7c65 = () => interopDefault(import('../pages/users/art-club/contests/_contestId/index.vue' /* webpackChunkName: "pages/users/art-club/contests/_contestId/index" */))
const _549e7400 = () => interopDefault(import('../pages/users/art-club/shows/_showId.vue' /* webpackChunkName: "pages/users/art-club/shows/_showId" */))
const _088a38d9 = () => interopDefault(import('../pages/users/art-club/video/_videoId.vue' /* webpackChunkName: "pages/users/art-club/video/_videoId" */))
const _6e7aafe6 = () => interopDefault(import('../pages/users/art-club/contests/_contestId/brief.vue' /* webpackChunkName: "pages/users/art-club/contests/_contestId/brief" */))
const _cd94310c = () => interopDefault(import('../pages/users/art-club/contests/_contestId/submissions.vue' /* webpackChunkName: "pages/users/art-club/contests/_contestId/submissions" */))
const _6c402a4d = () => interopDefault(import('../pages/users/art-club/contests/_contestId/voted.vue' /* webpackChunkName: "pages/users/art-club/contests/_contestId/voted" */))
const _72cf3278 = () => interopDefault(import('../pages/admin/banners/_bannerId.vue' /* webpackChunkName: "pages/admin/banners/_bannerId" */))
const _c85be992 = () => interopDefault(import('../pages/admin/blog/_postId.vue' /* webpackChunkName: "pages/admin/blog/_postId" */))
const _4703d1b2 = () => interopDefault(import('../pages/admin/courses/_courseId/index.vue' /* webpackChunkName: "pages/admin/courses/_courseId/index" */))
const _c02d1ed4 = () => interopDefault(import('../pages/admin/offers/_offerId.vue' /* webpackChunkName: "pages/admin/offers/_offerId" */))
const _78d2edf9 = () => interopDefault(import('../pages/admin/users/_id.vue' /* webpackChunkName: "pages/admin/users/_id" */))
const _05d84724 = () => interopDefault(import('../pages/admin/workshops/_workshopId.vue' /* webpackChunkName: "pages/admin/workshops/_workshopId" */))
const _37296b7d = () => interopDefault(import('../pages/users/posts/_postId.vue' /* webpackChunkName: "pages/users/posts/_postId" */))
const _168098a0 = () => interopDefault(import('../pages/admin/courses/_courseId/preview/index.vue' /* webpackChunkName: "pages/admin/courses/_courseId/preview/index" */))
const _a7656a52 = () => interopDefault(import('../pages/admin/courses/_courseId/preview/_moduleId/_lessonId.vue' /* webpackChunkName: "pages/admin/courses/_courseId/preview/_moduleId/_lessonId" */))
const _4aea40ec = () => interopDefault(import('../pages/admin/courses/_courseId/_moduleId/new.vue' /* webpackChunkName: "pages/admin/courses/_courseId/_moduleId/new" */))
const _148b511e = () => interopDefault(import('../pages/admin/courses/_courseId/_moduleId/_lessonId.vue' /* webpackChunkName: "pages/admin/courses/_courseId/_moduleId/_lessonId" */))
const _182e42f2 = () => interopDefault(import('../pages/blog/_postSlug.vue' /* webpackChunkName: "pages/blog/_postSlug" */))
const _68f76157 = () => interopDefault(import('../pages/communities/_communityId.vue' /* webpackChunkName: "pages/communities/_communityId" */))
const _83052056 = () => interopDefault(import('../pages/communities/_communityId/about.vue' /* webpackChunkName: "pages/communities/_communityId/about" */))
const _fd4ea9f4 = () => interopDefault(import('../pages/communities/_communityId/feed.vue' /* webpackChunkName: "pages/communities/_communityId/feed" */))
const _498c145d = () => interopDefault(import('../pages/communities/_communityId/followers.vue' /* webpackChunkName: "pages/communities/_communityId/followers" */))
const _68782361 = () => interopDefault(import('../pages/communities/_communityId/members.vue' /* webpackChunkName: "pages/communities/_communityId/members" */))
const _4cf58ba8 = () => interopDefault(import('../pages/communities/_communityId/requests.vue' /* webpackChunkName: "pages/communities/_communityId/requests" */))
const _2d344067 = () => interopDefault(import('../pages/courses/_courseId/index.vue' /* webpackChunkName: "pages/courses/_courseId/index" */))
const _9e068208 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _dd763792 = () => interopDefault(import('../pages/users/_id/about.vue' /* webpackChunkName: "pages/users/_id/about" */))
const _5256a5dd = () => interopDefault(import('../pages/users/_id/liked-posts.vue' /* webpackChunkName: "pages/users/_id/liked-posts" */))
const _598ca97d = () => interopDefault(import('../pages/users/_id/posts.vue' /* webpackChunkName: "pages/users/_id/posts" */))
const _9e55c7d4 = () => interopDefault(import('../pages/users/_id/profile/index.vue' /* webpackChunkName: "pages/users/_id/profile/index" */))
const _0fecef92 = () => interopDefault(import('../pages/users/_id/saved-posts.vue' /* webpackChunkName: "pages/users/_id/saved-posts" */))
const _1ce505a2 = () => interopDefault(import('../pages/users/_id/mentoring/students/index.vue' /* webpackChunkName: "pages/users/_id/mentoring/students/index" */))
const _ddc57d5e = () => interopDefault(import('../pages/users/_id/profile/about.vue' /* webpackChunkName: "pages/users/_id/profile/about" */))
const _ca89d0e8 = () => interopDefault(import('../pages/users/_id/profile/portfolio.vue' /* webpackChunkName: "pages/users/_id/profile/portfolio" */))
const _59650697 = () => interopDefault(import('../pages/users/_id/profile/posts.vue' /* webpackChunkName: "pages/users/_id/profile/posts" */))
const _13e68e87 = () => interopDefault(import('../pages/users/_id/settings/account.vue' /* webpackChunkName: "pages/users/_id/settings/account" */))
const _17d8546c = () => interopDefault(import('../pages/users/_id/settings/billing-details.vue' /* webpackChunkName: "pages/users/_id/settings/billing-details" */))
const _4d434628 = () => interopDefault(import('../pages/users/_id/settings/purchases.vue' /* webpackChunkName: "pages/users/_id/settings/purchases" */))
const _aa93d944 = () => interopDefault(import('../pages/courses/_courseId/_moduleId/index.vue' /* webpackChunkName: "pages/courses/_courseId/_moduleId/index" */))
const _ecbf6b44 = () => interopDefault(import('../pages/courses/_courseId/_moduleId/_lessonId.vue' /* webpackChunkName: "pages/courses/_courseId/_moduleId/_lessonId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about___en"
  }, {
    path: "/admin",
    component: _be9dc5d0,
    name: "admin___en"
  }, {
    path: "/after-login",
    component: _003a5784,
    name: "after-login___en"
  }, {
    path: "/art-club",
    component: _096371ca,
    name: "art-club___en"
  }, {
    path: "/blog",
    component: _999675e2,
    name: "blog___en"
  }, {
    path: "/communities",
    component: _cf67b8e0,
    name: "communities___en"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us___en"
  }, {
    path: "/email_confirmation",
    component: _372cbd62,
    name: "email_confirmation___en"
  }, {
    path: "/feed",
    component: _f82920f0,
    name: "feed___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/messages",
    component: _79e78b36,
    children: [{
      path: "",
      component: _6f305139,
      name: "messages___en"
    }, {
      path: ":chatId",
      component: _1f336459,
      name: "messages-chatId___en"
    }]
  }, {
    path: "/mp-prep-registration",
    component: _75c29c19,
    name: "mp-prep-registration___en"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications___en"
  }, {
    path: "/oauth-login",
    component: _118a6f39,
    name: "oauth-login___en"
  }, {
    path: "/oauth-logout",
    component: _6cd4806a,
    name: "oauth-logout___en"
  }, {
    path: "/password_reset",
    component: _788c9795,
    name: "password_reset___en"
  }, {
    path: "/password-recovery",
    component: _bad27560,
    name: "password-recovery___en"
  }, {
    path: "/password-restore",
    component: _9cf7a16e,
    name: "password-restore___en"
  }, {
    path: "/pro-library",
    component: _f3ca17be,
    name: "pro-library___en"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings___en",
    children: [{
      path: "account",
      component: _4f3345ab,
      name: "settings-account___en"
    }, {
      path: "billing-details",
      component: _2f4dae24,
      name: "settings-billing-details___en"
    }, {
      path: "purchases",
      component: _7459db10,
      name: "settings-purchases___en"
    }]
  }, {
    path: "/terms",
    component: _44b60560,
    name: "terms___en"
  }, {
    path: "/user-blocked",
    component: _ea347058,
    name: "user-blocked___en"
  }, {
    path: "/user-search",
    component: _113690f0,
    name: "user-search___en"
  }, {
    path: "/users",
    component: _c91b56de,
    name: "users___en"
  }, {
    path: "/workshop",
    component: _d608b41e,
    name: "workshop___en"
  }, {
    path: "/workshop-registration",
    component: _84eabb76,
    name: "workshop-registration___en"
  }, {
    path: "/admin/art-club",
    component: _62f0ebe6,
    name: "admin-art-club___en"
  }, {
    path: "/admin/banners",
    component: _22ffa460,
    name: "admin-banners___en"
  }, {
    path: "/admin/blog",
    component: _06f4f64f,
    name: "admin-blog___en"
  }, {
    path: "/admin/courses",
    component: _38c03181,
    name: "admin-courses___en"
  }, {
    path: "/admin/offers",
    component: _27778d84,
    name: "admin-offers___en"
  }, {
    path: "/admin/pinned-posts",
    component: _2ab65ac8,
    name: "admin-pinned-posts___en"
  }, {
    path: "/admin/social-moderation",
    component: _55aa3362,
    name: "admin-social-moderation___en"
  }, {
    path: "/admin/users",
    component: _808f695e,
    name: "admin-users___en"
  }, {
    path: "/admin/workshops",
    component: _586e1115,
    name: "admin-workshops___en"
  }, {
    path: "/checkout/art-club",
    component: _83d68ce2,
    name: "checkout-art-club___en"
  }, {
    path: "/checkout/art-club-plus",
    component: _55ddbc70,
    name: "checkout-art-club-plus___en"
  }, {
    path: "/checkout/art-club-pro",
    component: _212ddd6f,
    name: "checkout-art-club-pro___en"
  }, {
    path: "/checkout/art-club-pro-499",
    component: _7f1efd76,
    name: "checkout-art-club-pro-499___en"
  }, {
    path: "/checkout/mastery-graduate-mentoring-plus",
    component: _7b755d53,
    name: "checkout-mastery-graduate-mentoring-plus___en"
  }, {
    path: "/checkout/mastery-graduate-mentoring-pro",
    component: _70de8484,
    name: "checkout-mastery-graduate-mentoring-pro___en"
  }, {
    path: "/checkout/mastery-graduate-mentoring-pro-499",
    component: _ad67dbea,
    name: "checkout-mastery-graduate-mentoring-pro-499___en"
  }, {
    path: "/checkout/mastery-program-12-months",
    component: _07e74f81,
    name: "checkout-mastery-program-12-months___en"
  }, {
    path: "/checkout/mastery-program-pay-in-full",
    component: _ed502b18,
    name: "checkout-mastery-program-pay-in-full___en"
  }, {
    path: "/checkout/mastery-student-mentoring-plus",
    component: _47b6c6dd,
    name: "checkout-mastery-student-mentoring-plus___en"
  }, {
    path: "/checkout/mastery-student-mentoring-pro",
    component: _3564c1ba,
    name: "checkout-mastery-student-mentoring-pro___en"
  }, {
    path: "/checkout/mastery-student-mentoring-pro-499",
    component: _1964c441,
    name: "checkout-mastery-student-mentoring-pro-499___en"
  }, {
    path: "/communities/discover",
    component: _39c5b31b,
    name: "communities-discover___en"
  }, {
    path: "/communities/my",
    component: _e9b1ea44,
    name: "communities-my___en"
  }, {
    path: "/free-materials/ebooks",
    component: _a5781822,
    name: "free-materials-ebooks___en"
  }, {
    path: "/free-materials/podcasts",
    component: _46560ef9,
    name: "free-materials-podcasts___en"
  }, {
    path: "/free-materials/webinars",
    component: _71c0875a,
    name: "free-materials-webinars___en"
  }, {
    path: "/password-restore/art-club",
    component: _0ede318e,
    name: "password-restore-art-club___en"
  }, {
    path: "/password-restore/art-club-plus",
    component: _977e2344,
    name: "password-restore-art-club-plus___en"
  }, {
    path: "/password-restore/art-club-pro",
    component: _41272619,
    name: "password-restore-art-club-pro___en"
  }, {
    path: "/password-restore/mastery-graduate-mentoring-plus",
    component: _56c11f2e,
    name: "password-restore-mastery-graduate-mentoring-plus___en"
  }, {
    path: "/password-restore/mastery-graduate-mentoring-pro",
    component: _633ab6ae,
    name: "password-restore-mastery-graduate-mentoring-pro___en"
  }, {
    path: "/password-restore/mastery-student-mentoring-plus",
    component: _3a12f907,
    name: "password-restore-mastery-student-mentoring-plus___en"
  }, {
    path: "/password-restore/mastery-student-mentoring-pro",
    component: _7704a250,
    name: "password-restore-mastery-student-mentoring-pro___en"
  }, {
    path: "/password-restore/mp-prep-course",
    component: _19480eaa,
    name: "password-restore-mp-prep-course___en"
  }, {
    path: "/password-restore/workshop-registration",
    component: _28648d94,
    name: "password-restore-workshop-registration___en"
  }, {
    path: "/terms/art-auction",
    component: _b9cb4552,
    name: "terms-art-auction___en"
  }, {
    path: "/terms/art-club",
    component: _361887d2,
    name: "terms-art-club___en"
  }, {
    path: "/terms/art-for-sale",
    component: _775d4d32,
    name: "terms-art-for-sale___en"
  }, {
    path: "/terms/general",
    component: _2b9dc0e6,
    name: "terms-general___en"
  }, {
    path: "/terms/privacy-policy",
    component: _7391ba09,
    name: "terms-privacy-policy___en"
  }, {
    path: "/terms/subscriptions",
    component: _0b7aa774,
    name: "terms-subscriptions___en"
  }, {
    path: "/thank-you/art-club",
    component: _82226886,
    name: "thank-you-art-club___en"
  }, {
    path: "/thank-you/art-club-plus",
    component: _52313c5a,
    name: "thank-you-art-club-plus___en"
  }, {
    path: "/thank-you/art-club-pro",
    component: _2537289d,
    name: "thank-you-art-club-pro___en"
  }, {
    path: "/thank-you/art-club-pro-499",
    component: _7a1301a4,
    name: "thank-you-art-club-pro-499___en"
  }, {
    path: "/thank-you/mastery-graduate-mentoring-plus",
    component: _eca29136,
    name: "thank-you-mastery-graduate-mentoring-plus___en"
  }, {
    path: "/thank-you/mastery-graduate-mentoring-pro",
    component: _782ebf9c,
    name: "thank-you-mastery-graduate-mentoring-pro___en"
  }, {
    path: "/thank-you/mastery-graduate-mentoring-pro-499",
    component: _2536b28e,
    name: "thank-you-mastery-graduate-mentoring-pro-499___en"
  }, {
    path: "/thank-you/mastery-program-12-months",
    component: _2a6a1f13,
    name: "thank-you-mastery-program-12-months___en"
  }, {
    path: "/thank-you/mastery-program-pay-in-full",
    component: _16651d86,
    name: "thank-you-mastery-program-pay-in-full___en"
  }, {
    path: "/thank-you/mastery-student-mentoring-plus",
    component: _ca7e3aea,
    name: "thank-you-mastery-student-mentoring-plus___en"
  }, {
    path: "/thank-you/mastery-student-mentoring-pro",
    component: _981d0f68,
    name: "thank-you-mastery-student-mentoring-pro___en"
  }, {
    path: "/thank-you/mastery-student-mentoring-pro-499",
    component: _7eafe1d3,
    name: "thank-you-mastery-student-mentoring-pro-499___en"
  }, {
    path: "/users/art-club",
    component: _ca2aaa98,
    name: "users-art-club___en"
  }, {
    path: "/admin/art-club/banners",
    component: _aaae5476,
    name: "admin-art-club-banners___en"
  }, {
    path: "/admin/art-club/contests",
    component: _144f6c12,
    name: "admin-art-club-contests___en"
  }, {
    path: "/admin/art-club/instructors",
    component: _176ccb98,
    name: "admin-art-club-instructors___en"
  }, {
    path: "/admin/art-club/tv-show",
    component: _78378926,
    name: "admin-art-club-tv-show___en"
  }, {
    path: "/admin/art-club/videos",
    component: _b7e34120,
    name: "admin-art-club-videos___en"
  }, {
    path: "/admin/banners/new",
    component: _e6d58dc4,
    name: "admin-banners-new___en"
  }, {
    path: "/admin/blog/banners",
    component: _3e8ee007,
    name: "admin-blog-banners___en"
  }, {
    path: "/admin/blog/new",
    component: _af5fd546,
    name: "admin-blog-new___en"
  }, {
    path: "/admin/mentoring/mentors",
    component: _5a3930ec,
    name: "admin-mentoring-mentors___en"
  }, {
    path: "/admin/mentoring/students",
    component: _1fc3d193,
    name: "admin-mentoring-students___en"
  }, {
    path: "/admin/social-moderation/comments",
    component: _aa1426f2,
    name: "admin-social-moderation-comments___en"
  }, {
    path: "/admin/social-moderation/communities",
    component: _03a8b907,
    name: "admin-social-moderation-communities___en"
  }, {
    path: "/admin/social-moderation/demo-feed",
    component: _61625745,
    name: "admin-social-moderation-demo-feed___en"
  }, {
    path: "/admin/social-moderation/posts",
    component: _2d89095a,
    name: "admin-social-moderation-posts___en"
  }, {
    path: "/admin/social-moderation/profiles",
    component: _ecc5f780,
    name: "admin-social-moderation-profiles___en"
  }, {
    path: "/admin/users/admins",
    component: _3777eb95,
    name: "admin-users-admins___en"
  }, {
    path: "/admin/users/tags",
    component: _eec85f2c,
    name: "admin-users-tags___en"
  }, {
    path: "/admin/workshops/new",
    component: _60bd29a3,
    name: "admin-workshops-new___en"
  }, {
    path: "/users/art-club/browse",
    component: _e294f5d0,
    name: "users-art-club-browse___en"
  }, {
    path: "/users/art-club/contests",
    component: _1a3c15a0,
    name: "users-art-club-contests___en"
  }, {
    path: "/users/art-club/history",
    component: _e2726a14,
    name: "users-art-club-history___en"
  }, {
    path: "/users/art-club/new-releases",
    component: _68649567,
    name: "users-art-club-new-releases___en"
  }, {
    path: "/users/art-club/popular",
    component: _42bda28a,
    name: "users-art-club-popular___en"
  }, {
    path: "/users/art-club/saved",
    component: _5383e42e,
    name: "users-art-club-saved___en"
  }, {
    path: "/users/art-club/search",
    component: _6e298536,
    name: "users-art-club-search___en"
  }, {
    path: "/users/art-club/shows",
    component: _f50f9d0a,
    name: "users-art-club-shows___en"
  }, {
    path: "/users/art-club/video",
    component: _573686a0,
    name: "users-art-club-video___en"
  }, {
    path: "/admin/art-club/banners/new",
    component: _43415a53,
    name: "admin-art-club-banners-new___en"
  }, {
    path: "/admin/art-club/videos/new",
    component: _95795a84,
    name: "admin-art-club-videos-new___en"
  }, {
    path: "/admin/blog/banners/new",
    component: _7a490d15,
    name: "admin-blog-banners-new___en"
  }, {
    path: "/admin/social-moderation/communities/drafts",
    component: _11a83ce6,
    name: "admin-social-moderation-communities-drafts___en"
  }, {
    path: "/admin/social-moderation/communities/new",
    component: _3e052615,
    name: "admin-social-moderation-communities-new___en"
  }, {
    path: "/admin/social-moderation/communities/recommended-card",
    component: _c2d453c6,
    name: "admin-social-moderation-communities-recommended-card___en"
  }, {
    path: "/admin/social-moderation/posts/reported",
    component: _9c2aef3c,
    name: "admin-social-moderation-posts-reported___en"
  }, {
    path: "/admin/social-moderation/profiles/blocked",
    component: _5058fa4c,
    name: "admin-social-moderation-profiles-blocked___en"
  }, {
    path: "/users/art-club/contests/archive",
    component: _202ec8c0,
    name: "users-art-club-contests-archive___en"
  }, {
    path: "/users/art-club/contests/browse",
    component: _35a78a1c,
    name: "users-art-club-contests-browse___en"
  }, {
    path: "/users/art-club/contests/my-submissions",
    component: _c0bb50d0,
    name: "users-art-club-contests-my-submissions___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/admin/art-club/banners/:bannerId",
    component: _6d920c39,
    name: "admin-art-club-banners-bannerId___en"
  }, {
    path: "/admin/art-club/contests/:contestId",
    component: _19a016fb,
    name: "admin-art-club-contests-contestId___en"
  }, {
    path: "/admin/art-club/tv-show/:showId",
    component: _d632e62a,
    name: "admin-art-club-tv-show-showId___en"
  }, {
    path: "/admin/art-club/videos/:videoId",
    component: _5ae1ee09,
    name: "admin-art-club-videos-videoId___en"
  }, {
    path: "/admin/blog/banners/:bannerId",
    component: _2f05257b,
    name: "admin-blog-banners-bannerId___en"
  }, {
    path: "/admin/mentoring/students/:categorySlug",
    component: _7acf6b69,
    name: "admin-mentoring-students-categorySlug___en"
  }, {
    path: "/admin/social-moderation/communities/:communityId",
    component: _16e50800,
    name: "admin-social-moderation-communities-communityId___en"
  }, {
    path: "/users/art-club/contests/:contestId",
    component: _2f9b7c65,
    name: "users-art-club-contests-contestId___en"
  }, {
    path: "/users/art-club/shows/:showId",
    component: _549e7400,
    name: "users-art-club-shows-showId___en"
  }, {
    path: "/users/art-club/video/:videoId",
    component: _088a38d9,
    name: "users-art-club-video-videoId___en"
  }, {
    path: "/users/art-club/contests/:contestId/brief",
    component: _6e7aafe6,
    name: "users-art-club-contests-contestId-brief___en"
  }, {
    path: "/users/art-club/contests/:contestId/submissions",
    component: _cd94310c,
    name: "users-art-club-contests-contestId-submissions___en"
  }, {
    path: "/users/art-club/contests/:contestId/voted",
    component: _6c402a4d,
    name: "users-art-club-contests-contestId-voted___en"
  }, {
    path: "/admin/banners/:bannerId",
    component: _72cf3278,
    name: "admin-banners-bannerId___en"
  }, {
    path: "/admin/blog/:postId",
    component: _c85be992,
    name: "admin-blog-postId___en"
  }, {
    path: "/admin/courses/:courseId",
    component: _4703d1b2,
    name: "admin-courses-courseId___en"
  }, {
    path: "/admin/offers/:offerId",
    component: _c02d1ed4,
    name: "admin-offers-offerId___en"
  }, {
    path: "/admin/users/:id",
    component: _78d2edf9,
    name: "admin-users-id___en"
  }, {
    path: "/admin/workshops/:workshopId",
    component: _05d84724,
    name: "admin-workshops-workshopId___en"
  }, {
    path: "/users/posts/:postId?",
    component: _37296b7d,
    name: "users-posts-postId___en"
  }, {
    path: "/admin/courses/:courseId/preview",
    component: _168098a0,
    name: "admin-courses-courseId-preview___en"
  }, {
    path: "/admin/courses/:courseId/preview/:moduleId/:lessonId?",
    component: _a7656a52,
    name: "admin-courses-courseId-preview-moduleId-lessonId___en"
  }, {
    path: "/admin/courses/:courseId/:moduleId/new",
    component: _4aea40ec,
    name: "admin-courses-courseId-moduleId-new___en"
  }, {
    path: "/admin/courses/:courseId/:moduleId/:lessonId?",
    component: _148b511e,
    name: "admin-courses-courseId-moduleId-lessonId___en"
  }, {
    path: "/blog/:postSlug",
    component: _182e42f2,
    name: "blog-postSlug___en"
  }, {
    path: "/communities/:communityId",
    component: _68f76157,
    name: "communities-communityId___en",
    children: [{
      path: "about",
      component: _83052056,
      name: "communities-communityId-about___en"
    }, {
      path: "feed",
      component: _fd4ea9f4,
      name: "communities-communityId-feed___en"
    }, {
      path: "followers",
      component: _498c145d,
      name: "communities-communityId-followers___en"
    }, {
      path: "members",
      component: _68782361,
      name: "communities-communityId-members___en"
    }, {
      path: "requests",
      component: _4cf58ba8,
      name: "communities-communityId-requests___en"
    }]
  }, {
    path: "/courses/:courseId",
    component: _2d344067,
    name: "courses-courseId___en"
  }, {
    path: "/users/:id",
    component: _9e068208,
    name: "users-id___en"
  }, {
    path: "/users/:id/about",
    component: _dd763792,
    name: "users-id-about___en"
  }, {
    path: "/users/:id/liked-posts",
    component: _5256a5dd,
    name: "users-id-liked-posts___en"
  }, {
    path: "/users/:id/posts",
    component: _598ca97d,
    name: "users-id-posts___en"
  }, {
    path: "/users/:id/profile",
    component: _9e55c7d4,
    name: "users-id-profile___en"
  }, {
    path: "/users/:id/saved-posts",
    component: _0fecef92,
    name: "users-id-saved-posts___en"
  }, {
    path: "/users/:id/mentoring/students",
    component: _1ce505a2,
    name: "users-id-mentoring-students___en"
  }, {
    path: "/users/:id/profile/about",
    component: _ddc57d5e,
    name: "users-id-profile-about___en"
  }, {
    path: "/users/:id/profile/portfolio",
    component: _ca89d0e8,
    name: "users-id-profile-portfolio___en"
  }, {
    path: "/users/:id/profile/posts",
    component: _59650697,
    name: "users-id-profile-posts___en"
  }, {
    path: "/users/:id/settings/account",
    component: _13e68e87,
    name: "users-id-settings-account___en"
  }, {
    path: "/users/:id/settings/billing-details",
    component: _17d8546c,
    name: "users-id-settings-billing-details___en"
  }, {
    path: "/users/:id/settings/purchases",
    component: _4d434628,
    name: "users-id-settings-purchases___en"
  }, {
    path: "/courses/:courseId?/:moduleId",
    component: _aa93d944,
    name: "courses-courseId-moduleId___en"
  }, {
    path: "/courses/:courseId?/:moduleId/:lessonId",
    component: _ecbf6b44,
    name: "courses-courseId-moduleId-lessonId___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
