























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  IArtClubCategoryOverview,
  IArtClubCategoryLink,
} from '~/features/art-club/interfaces/art-club-category.interface';
import AppMenuLink from '~/shared/components/layouts/parts/menus/AppMenuLink.vue';
import { MAX_CATEGORIES_TO_SHOW_INITIALLY } from '~/shared/constants/category-menu.constant';

export default defineComponent({
  name: 'CategoriesMenu',
  components: { AppMenuLink },
  props: {
    categories: {
      type: Array as PropType<IArtClubCategoryOverview[]>,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isListExpanded: false,
    };
  },
  computed: {
    listExpandButtonIcon(): string {
      return this.isListExpanded ? 'list-contract' : 'list-expand';
    },

    formattedCategories(): IArtClubCategoryLink[] {
      return this.categories.map((category: IArtClubCategoryOverview) => {
        const categoryLink = this.getCategoryPath(category);

        return {
          ...category,
          to: categoryLink,
          isActive: this.$route.fullPath === categoryLink,
        };
      });
    },

    categoriesToShow(): IArtClubCategoryLink[] {
      return this.isListExpanded
        ? this.formattedCategories
        : this.formattedCategories.slice(0, MAX_CATEGORIES_TO_SHOW_INITIALLY);
    },

    isListExpandButtonShown(): boolean {
      return this.formattedCategories.length > MAX_CATEGORIES_TO_SHOW_INITIALLY;
    },

    isInitiallyExpanded(): boolean {
      const pathNames = ['users-art-club-search', 'users-art-club-shows-showId'];
      const currentRouteBaseName = this.getRouteBaseName() || '';

      if (!pathNames.includes(currentRouteBaseName)) return false;

      const activeCategoryIdx = this.formattedCategories.findIndex(
        (category: IArtClubCategoryLink) => category.isActive,
      );

      return activeCategoryIdx + 1 > MAX_CATEGORIES_TO_SHOW_INITIALLY;
    },
  },
  watch: {
    '$route.fullPath'() {
      this.setInitialExpandedListValue();
    },
  },
  mounted() {
    this.setInitialExpandedListValue();
  },
  methods: {
    onShowMoreClick(): void {
      this.isListExpanded = !this.isListExpanded;
    },

    getCategoryPath(category: IArtClubCategoryOverview): string {
      return this.localePath(
        category.videoSeriesId
          ? `/users/art-club/shows/${category.videoSeriesId}`
          : `/users/art-club/search?categoryId=${category.id}`,
      );
    },

    onLinkClick(): void {
      this.$emit('category-clicked');
    },

    setInitialExpandedListValue() {
      this.isListExpanded = this.isInitiallyExpanded;
    },
  },
});
