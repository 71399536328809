export type TAppLogo =
  | 'default'
  | 'default-white'
  | 'club'
  | 'club-white'
  | 'club-plus-white'
  | 'club-pro-white'
  | 'mastery-student-mentoring-plus-white'
  | 'mastery-student-mentoring-pro-white'
  | 'mastery-graduate-mentoring-plus-white'
  | 'mastery-graduate-mentoring-pro-white';

export type TAppLogoSize = 'normal' | 'small';

export const uiAppLogoCommonTypes = ['default', 'club'];

export const uiAppLogoTypes = [
  ...uiAppLogoCommonTypes,
  'default-white',
  'club-white',
  'club-plus-white',
  'club-pro-white',
  'mastery-student-mentoring-plus-white',
  'mastery-student-mentoring-pro-white',
  'mastery-graduate-mentoring-plus-white',
  'mastery-graduate-mentoring-pro-white',
];
