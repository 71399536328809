import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { getLastModifiedFormattedDate } from '~/utils/get-last-modified-date';
import { staticPagesLastModifiedDatesList } from '~/shared/config/static-pages-last-modified-dates-list.config';
import { STATIC_PAGES_LAST_MODIFIED_DEFAULT } from '~/shared/constants';
import { setLastModifiedHeader } from '~/utils/set-last-modified-header';

export default defineNuxtMiddleware(({ res, $dayjs, route }) => {
  if (!process.server) return;

  setLastModifiedHeader(
    res,
    getLastModifiedFormattedDate(
      staticPagesLastModifiedDatesList[route.path] || STATIC_PAGES_LAST_MODIFIED_DEFAULT,
      $dayjs,
    ),
  );
});
