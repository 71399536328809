import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICreateModulePayload,
  IGetModuleByIdParams,
  IGetModuleEditHistoryParams,
  IStartEditModuleSessionPaylaod,
  IStartEditModuleSessionResponse,
  ISaveEditSessionPayload,
  ISaveModuleAttachmentPayload,
  IRemoveModuleAttachmentPayload,
  IPublishModuleVersionPayload,
  IUpdateModulePayload,
} from '~/api/interfaces/module.interface';
import {
  IReorderContentItemsPayload,
  IModule,
  IModuleHistoryVersionItem,
} from '~/features/module/interfaces/module.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getModuleById(params: IGetModuleByIdParams): Promise<IModule> {
    return $axios.$get('/courses/modules/edit/getModuleById', { params });
  },
  createModule(payload: ICreateModulePayload): Promise<IModule> {
    return $axios.$post('/courses/modules/edit/createModule', payload);
  },
  getModuleEditHistory(
    params: IGetModuleEditHistoryParams,
  ): Promise<IItemsListWithTotalResponse<IModuleHistoryVersionItem>> {
    return $axios.$get('/courses/modules/edit/getModuleEditHistory', { params });
  },
  startEditModuleSession(
    payload: IStartEditModuleSessionPaylaod,
  ): Promise<IStartEditModuleSessionResponse> {
    return $axios.$post('/courses/modules/edit/startEditModuleSession', payload);
  },
  saveEditModuleSession(payload: ISaveEditSessionPayload): Promise<void> {
    return $axios.$post('/courses/modules/edit/saveEditModuleSession', payload);
  },
  saveModuleAttachment(payload: ISaveModuleAttachmentPayload): Promise<void> {
    return $axios.$post('/courses/modules/edit/saveModuleAttachment', payload);
  },
  removeModuleAttachment(payload: IRemoveModuleAttachmentPayload): Promise<void> {
    return $axios.$post('/courses/modules/edit/removeModuleAttachment', payload);
  },
  discardEditModuleSession(versionId: string): Promise<void> {
    return $axios.$post('/courses/modules/edit/discardEditModuleSession', { versionId });
  },
  publishModuleVersion(payload: IPublishModuleVersionPayload): Promise<void> {
    return $axios.$post('/courses/modules/edit/publishModuleVersion', payload);
  },
  updateModule(payload: IUpdateModulePayload): Promise<void> {
    return $axios.$post('/courses/modules/edit/updateModule', payload);
  },

  reorderContentItems(payload: IReorderContentItemsPayload): Promise<void> {
    return $axios.$post('/courses/content/edit/reorderContentItems', payload);
  },
});
