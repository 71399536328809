var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"lds-roller",class:`lds-roller--${_vm.type}`,style:({ width: `${_vm.size}px`, height: `${_vm.size}px` })},_vm._l((Array(8)),function(_,i){return _c('div',{key:`lds-roller-${i}`,style:([
      _vm.mainAnimation,
      {
        transformOrigin: `${_vm.size * 0.5}px ${_vm.size * 0.5}px`,
        opacity: 1 + 0.1 * i - (1 - 0.1 * i),
      },
      _vm.animDivs[i],
    ])},[_c('div',{staticClass:"div-after",style:([_vm.spinnerStyle, _vm.spinnerStylePosition(i)])})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }