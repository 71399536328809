import { ActionTree, MutationTree } from 'vuex';
export interface IState {}

export const state = (): IState => ({});

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {};
