import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IState {
  isOrientationCourseAccess: boolean;
  isWorkshopAccess: boolean;
}

const getDefaultState = (): IState => ({
  isOrientationCourseAccess: false,
  isWorkshopAccess: false,
});

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getAccessInfo(ctx) {
    try {
      const {
        actualWorkshop: { hasAccess: hasWorkshopAccess },
        masteryProgramOrientationCourse: { hasAccess: hasFreeCourseAccess },
      } = await this.$api.features.getAccessStructure();

      ctx.commit('setOrientationCourseAccess', !!hasFreeCourseAccess);
      ctx.commit('setWorkshopAccess', !!hasWorkshopAccess);
    } catch (error) {
      console.error(error);
    }
  },
};

export const mutations: MutationTree<RootState> = {
  setOrientationCourseAccess(state, isOrientationCourseAccess) {
    state.isOrientationCourseAccess = isOrientationCourseAccess;
  },

  setWorkshopAccess(state, isWorkshopAccess) {
    state.isWorkshopAccess = isWorkshopAccess;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IState, RootState> = {};
