




import { defineComponent } from '@nuxtjs/composition-api';
import { mapState } from 'vuex';
import AppMenu from '~/shared/components/layouts/parts/menus/AppMenu.vue';
import { useMembershipsCheck } from '~/shared/composable/useMembershipsCheck';
import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import {
  userMobSidebarLinks,
  userMobSidebarLinksForArtClubPages,
  userMobSidebarLinksForNonArtClubSubscribed,
  userSidebarLinks,
  userSidebarLinksForArtClubPages,
  userSidebarLinksForNonArtClubSubscribed,
} from '~/shared/config/user-menu.config';

export default defineComponent({
  name: 'SocialMenu',
  components: { AppMenu },
  props: {
    showMobileLinks: {
      type: Boolean,
      default: false,
    },
    hideArtClubLinks: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      isArtClubMembership,
      isProLibraryMembership,
      isMasteryProgramMembership,
    } = useMembershipsCheck();

    return { isArtClubMembership, isProLibraryMembership, isMasteryProgramMembership };
  },
  computed: {
    ...mapState('feature-access', {
      isOrientationCourseAccess: 'isOrientationCourseAccess',
    }),

    socialMenuToShow(): IMenuItem[] {
      let linksList: IMenuItem[] = [];

      // TODO: refactor it please
      if (this.showMobileLinks) {
        linksList = this.isArtClubMembership
          ? this.hideArtClubLinks
            ? userMobSidebarLinksForArtClubPages
            : userMobSidebarLinks
          : userMobSidebarLinksForNonArtClubSubscribed;
      } else {
        linksList = this.isArtClubMembership
          ? this.hideArtClubLinks
            ? userSidebarLinksForArtClubPages
            : userSidebarLinks
          : userSidebarLinksForNonArtClubSubscribed;
      }

      if (!this.isProLibraryMembership) linksList = linksList.filter((item) => !item.isProLibrary);
      if (!this.isOrientationCourseAccess)
        linksList = linksList.filter((item) => !item.isMpOrientationCourse);

      linksList = linksList.map((item) =>
        item?.isMasteryProgram
          ? {
              ...item,
              href: !this.isMasteryProgramMembership ? item.href : undefined,
              to: this.isMasteryProgramMembership ? item.to : undefined,
            }
          : item,
      );

      return linksList;
    },
  },
});
