import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAddMentorPayload,
  IGetMentorsListParams,
  IGetStudentsListParams,
  IMentorsAdminApi,
} from '~/api/interfaces/mentors-admin.interface';
import {
  IItemsListResponse,
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IUserProfileWithIsMentorStatus } from '~/features/users/interfaces/users.interface';
import {
  IMentorForAdmin,
  IMentorListItemForAdmin,
} from '~/features/mentoring/interfaces/mentor.interface';
import { IStudentListItemForAdmin } from '~/features/mentoring/interfaces/student-list-item.interface';

export default ($axios: NuxtAxiosInstance): IMentorsAdminApi => ({
  searchUsersToAdd(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileWithIsMentorStatus>> {
    return $axios.$get('/mentoring/admin/searchUsersToAdd', { params });
  },

  getMentorListItemById(id: string): Promise<IMentorListItemForAdmin> {
    return $axios.$get('/mentoring/admin/getMentorListItemById', { params: { id } });
  },

  getMentorsList(
    params: IGetMentorsListParams,
  ): Promise<IItemsListWithTotalResponse<IMentorListItemForAdmin>> {
    return $axios.$get('/mentoring/admin/getMentorsList', { params });
  },

  getStudentsListByMentorId(id: string): Promise<IItemsListResponse<IStudentListItemForAdmin>> {
    return $axios.$get('/mentoring/admin/getStudentsListByMentorId', { params: { id } });
  },

  getMentorInfo(id: string): Promise<IMentorForAdmin> {
    return $axios.$get('/mentoring/admin/getMentorInfo', { params: { id } });
  },

  addMentor(payload: IAddMentorPayload): Promise<void> {
    return $axios.$post('/mentoring/admin/addMentor', payload);
  },

  updateMentor(payload: IAddMentorPayload): Promise<void> {
    return $axios.$post('/mentoring/admin/updateMentor', payload);
  },

  deleteMentor(id: string): Promise<void> {
    return $axios.$post('/mentoring/admin/deleteMentor', { id });
  },

  assignMentorToUser(userId: string, mentorId: string): Promise<void> {
    return $axios.$post('/mentoring/admin/assignMentorToUser', { userId, mentorId });
  },

  getStudentsList(
    params: IGetStudentsListParams,
  ): Promise<IItemsListWithTotalResponse<IStudentListItemForAdmin>> {
    return $axios.$get('/mentoring/admin/getStudentsList', { params });
  },

  setHiddenUserStatus(userId: string): Promise<void> {
    return $axios.$post('/mentoring/admin/setHiddenUserStatus', { userId, isHidden: true });
  },

  setProcessingStatus(userId: string, isProcessed: boolean): Promise<void> {
    return $axios.$post('/mentoring/admin/setProcessingStatus', { userId, isProcessed });
  },
});
