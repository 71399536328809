import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import { IMentoringMentorApi } from '~/api/interfaces/mentoring-mentor.interface';
import { IStudentListItem } from '~/features/mentoring/interfaces/student-list-item.interface';

export default ($axios: NuxtAxiosInstance): IMentoringMentorApi => ({
  getMyStudentsList(
    params: IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IStudentListItem>> {
    return $axios.$get('/mentoring/mentor/getMyStudentsList', { params, localError: true });
  },

  getMyStudentsListItemById(id: string): Promise<IStudentListItem> {
    return $axios.$get('/mentoring/mentor/getMyStudentsListItemById', { params: { id } });
  },

  updateStudentNotes(id: string, notes: string | null): Promise<void> {
    return $axios.$post('/mentoring/mentor/updateStudentNotes', { id, notes });
  },

  deleteMyStudent(id: string): Promise<void> {
    return $axios.$post('/mentoring/mentor/deleteMyStudent', { id }, { localError: true });
  },
});
