import Vue from 'vue';
// @ts-ignore
import VueNativeSock from 'vue-native-websocket';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
import { TAuthStrategy } from '~/features/auth/types/auth-strategy.type';

const storeActions = {
  SOCKET_ONOPEN: 'socketOnOpen',
  SOCKET_ONCLOSE: 'socketOnClose',
  SOCKET_ONERROR: 'socketOnError',
  SOCKET_ONMESSAGE: 'socketOnMessage',
  SOCKET_RECONNECT: 'socketReconnect',
  SOCKET_RECONNECT_ERROR: 'socketReconnectError',
};

export default defineNuxtPlugin(({ $config, store, $auth }, inject) => {
  Vue.use(VueNativeSock, $config.websocket.baseURL, {
    format: 'json',
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    store,
    connectManually: true,
    passToStoreHandler(eventName: string, event: MessageEvent) {
      if (!eventName.startsWith('SOCKET_')) return;

      const target = `notifications/${storeActions[eventName.toUpperCase()]}`;
      this.store.dispatch(target, event);
    },
  });

  const websocketApi = {
    connect(): void {
      Vue.prototype.$connect();
    },

    disconnect(): void {
      Vue.prototype.$disconnect();
    },

    async subscribe(connectWithTokenRefrest?: true): Promise<void> {
      if (connectWithTokenRefrest) await $auth.refreshTokens();

      const clearToken = ($auth.strategy as TAuthStrategy).token.get()?.split?.(' ')?.[1];

      if (isNil(clearToken)) return;

      Vue.prototype.$socket.sendObj({ type: 'subscribe', token: clearToken });
    },

    unsubscribe(): void {
      Vue.prototype.$disconnect();
    },
  };

  inject('websocketApi', websocketApi);

  if ($auth.loggedIn) websocketApi.connect();
});
