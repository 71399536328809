

































































































import { defineComponent, PropType, Ref, ref } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import FormInput from '~/shared/components/FormInput.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import { useRegistration } from '~/shared/composable/useRegistration';
import { VForm } from '~/shared/types/form.type';
import { ESignUpFlow } from '~/features/auth/enums/sign-up-flow.enum';
import { MAX_USERNAME_LENGTH } from '~/shared/constants';

type TRegistrationForm = 'default' | 'landing-page';

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    UiCheckbox: () => import('~/shared/components/UiCheckbox/index.vue'),
    UiButton,
    FormInput,
  },
  props: {
    type: {
      type: String as PropType<TRegistrationForm>,
      default: 'default',
    },
  },
  emits: ['register-submit', 'login-click'],
  setup() {
    const form: Ref<VForm> = ref(null as any);
    const {
      formData,
      onValidate,
      errors,
      hasErrors,
      rules,
      handleSubmit,
      isPending,
      isAgreeCheckboxShown,
      agreeTerms,
      customEmailError,
    } = useRegistration({ form, flow: ESignUpFlow.Modal, withAgreeCheckbox: true });

    return {
      formData,
      onValidate,
      errors,
      hasErrors,
      rules,
      handleSubmit,
      form,
      isPending,
      isAgreeCheckboxShown,
      agreeTerms,
      MAX_USERNAME_LENGTH,
      customEmailError,
    };
  },
  computed: {
    isLandingPageType(): boolean {
      return this.type === 'landing-page';
    },
  },
  methods: {
    async onSubmit(): Promise<void> {
      try {
        const isSubmitted = await this.handleSubmit();

        if (!isSubmitted) return;

        this.$emit('register-submit');
      } catch (error) {
        console.error(error);
      }
    },

    onLoginClick(): void {
      this.$emit('login-click');
    },

    getInputLabel(translateKey: string): TranslateResult | undefined {
      return this.isLandingPageType ? undefined : this.$t(translateKey);
    },

    getInputPlaceholder(translateKey: string): TranslateResult | undefined {
      return this.isLandingPageType ? this.$t(translateKey) : undefined;
    },
  },
});
