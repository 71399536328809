/* eslint-disable */
import {
  RefreshScheme,
  ExpiredAuthSessionError,
  Token,
  RequestHandler,
  RefreshController,
} from '~auth/runtime';
import { setUserParamsFromToken } from "~/utils/set-user-params-from-token";
import { getProp } from "~/utils/get-prop";

const addTokenPrefix = (token, tokenType) => {
  if (!token || !tokenType || typeof token !== 'string' || token.startsWith(tokenType)) {
    return token;
  }
  return tokenType + ' ' + token;
};

class CustomToken extends Token {
  constructor(scheme, storage) {
    super(scheme, storage);
  }
  _setExpiration(expiration) {
    const _key = this.scheme.options.token.expirationPrefix + this.scheme.name;
    return this.$storage.setUniversal(_key, expiration);
  }
  _setToken(token) {
    const _key = this.scheme.options.token.prefix + this.scheme.name;
    return this.$storage.setUniversal(_key, token);
  }

  _updateExpiration() {
    let tokenExpiration;
    const _tokenIssuedAtMillis = Date.now();
    const _tokenTTLMillis = Number(this.scheme.options.token.maxAge) * 1e3;
    const _tokenExpiresAtMillis = _tokenTTLMillis ? _tokenIssuedAtMillis + _tokenTTLMillis : 0;

    try {
      tokenExpiration = _tokenExpiresAtMillis;
    } catch (error) {
      if (!(error && error.name === 'InvalidTokenError')) throw error;
    }

    return this._setExpiration(tokenExpiration || false);
  }

  set(tokenValue) {
    const token = addTokenPrefix(tokenValue, this.scheme.options.token.type);
    this._setToken(token);
    this._updateExpiration(token);

    if (typeof token === 'string') this.scheme.requestHandler.setHeader(token);

    if (tokenValue) setUserParamsFromToken(this.$storage, tokenValue);


    return token;
  }
}

export default class CustomAuthScheme extends RefreshScheme {
  constructor($auth, options) {
    super($auth, options);
    this.token = new CustomToken(this, this.$auth.$storage);
    this.requestHandler = new RequestHandler(this, this.$auth.ctx.$axios);
    this.refreshController = new RefreshController(this);
  }

  async mounted() {
    if(process.server && !!this.token.get()) await this.refreshTokens();

   // Set initial values for this.$auth.$state[key] in order vue can detect their changes if they are used in computed props
    setUserParamsFromToken(this.$auth.$storage);

    return super.mounted({
      tokenCallback: () => {
        if (this.options.autoLogout) {
          this.$auth.reset();
          if(!process.sentry) return;
          process.sentry.captureException(new Error('Auto logout with token'),{ tags: { errorPlaceType: 'auth-scheme-access-exception' }, extra: { token: this.token.get() } });
        }
      },
      refreshTokenCallback: () => {
        this.$auth.reset();
        if(!process.sentry) return;
        process.sentry.captureException(new Error('Auto logout with refresh token'),{ tags: { errorPlaceType: 'auth-scheme-refresh-exception' }, extra: { token: this.token.get() } });
      }
    });
  }
  fetchUser(endpoint) {
    if (!this.check().valid) return Promise.resolve();

    if (!this.options.endpoints.user) {
      this.$auth.setUser({});
      return Promise.resolve();
    }
    return this.$auth.requestWith(this.name, endpoint, this.options.endpoints.user).then((response) => {
      const userData = getProp(
        response.data?.data !== undefined
          ? response.data.data
          : response.data,
        this.options.user.property
      );

      if (!userData) {
        const error = new Error(`User Data response does not contain field ${this.options.user.property}`);
        return Promise.reject(error);
      }
      this.$auth.setUser(userData);

      return response;
    }).catch((error) => {
      this.$auth.callOnError(error, {method: "fetchUser"});
      return Promise.reject(error);
    });
  }

  refreshTokens() {
    // Refresh endpoint is disabled
    if (!this.options.endpoints.refresh) return Promise.resolve();

    // Token and refresh token are required but not available
    if (!this.check().valid) return Promise.resolve();

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status();

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset();

      throw new ExpiredAuthSessionError();
    }

    // Delete current token from the request header before refreshing, if `tokenRequired` is disabled
    if (!this.options.refreshToken.tokenRequired) this.requestHandler.clearHeader();

    const endpoint = {
      data: {
        refreshToken: this.refreshToken.get(),
      },
    };

    // this prevents multiple refresh-token requests
    if (this.isRefreshing) return this.refreshPromise;

    this.isRefreshing = true;

    this.refreshPromise = this.$auth
      .request(endpoint, this.options.endpoints.refresh)
      .then((response) => {
        this.isRefreshing = false;
        this.refreshPromise = null;
        // Update tokens
        this.updateTokens(response, { isRefreshing: true });
        return response;
      })
      .catch((error) => {
        this.isRefreshing = false;
        this.refreshPromise = null;
        this.$auth.callOnError(error, { method: 'refreshToken' });
        return Promise.reject(error);
      });

    return this.refreshPromise;
  }

  async login(endpoint, {reset = true} = {}) {
    if (!this.options.endpoints.login) return;
    if (reset) this.$auth.reset({ resetInterceptor: false });

    const response = await this.$auth.request(
      {
        ...endpoint,
        localError: true
      },
      this.options.endpoints.login
    );
    this.updateTokens(response);

    if (!this.requestHandler.interceptor) this.initializeRequestInterceptor();
    if (this.options.user.autoFetch) await this.fetchUser();

    return response;
  }

  async logout() {
    if (this.options.endpoints.logout) {
      await this.$auth
        .requestWith(
          this.name,
          {
            data: {
              refreshToken: this.refreshToken.get(),
            },
          },
          this.options.endpoints.logout,
        )
        .catch((error) => {
          console.error(error);
          this.$auth.reset();
        });
    }

    setUserParamsFromToken(this.$auth.$storage);
    return this.$auth.reset();
  }
}
