import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetLikedUsersListParams,
  IGetLikesCountResponse,
  ILikesApi,
  ISetLikePayload,
} from '~/api/interfaces/likes.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IUserProfileShort } from '~/features/users/interfaces/users.interface';

export default ($axios: NuxtAxiosInstance): ILikesApi => ({
  setLike(payload: ISetLikePayload): Promise<void> {
    return $axios.$post('/likes/setLike', payload);
  },

  getLikesCount(entityId: string): Promise<IGetLikesCountResponse> {
    return $axios.$get('/likes/getLikesCount', { params: { entityId } });
  },

  getLikedUsersList(
    params: IGetLikedUsersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/likes/getLikedUsersList', { params });
  },
});
