import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IBlockUserPayload,
  IGetBlockedUsersListParams,
  IGetReportedListPayload,
  IGetUserBlockDetailsResponse,
  IGetUserReportsListAggregatedByReporterParams,
  IUpdateUserBlockPayload,
  IUsersModerationApi,
} from '~/api/interfaces/users-moderation.interface';
import {
  IBlockedProfileItem,
  IReportedProfileHistoryItem,
  IReportedProfileItem,
} from '~/features/social-moderation/interfaces/reported-profile-item.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IProfileReport } from '~/features/social-moderation/interfaces/profile-report.interface';

export default ($axios: NuxtAxiosInstance): IUsersModerationApi => ({
  getReportedListItemById(id: string): Promise<IReportedProfileItem> {
    return $axios.$get('/admin/moderation/users/getReportedListItemById', { params: { id } });
  },

  getReportedList(
    payload: IGetReportedListPayload,
  ): Promise<IItemsListWithTotalResponse<IReportedProfileItem>> {
    return $axios.$post('/admin/moderation/users/getReportedList', payload);
  },

  getBlockedUsersList(
    payload: IGetBlockedUsersListParams,
  ): Promise<IItemsListWithTotalResponse<IBlockedProfileItem>> {
    return $axios.$post('/admin/moderation/users/getBlockedUsersList', payload);
  },

  getUserReportsListAggregatedByReporter(
    params: IGetUserReportsListAggregatedByReporterParams,
  ): Promise<IItemsListWithTotalResponse<IProfileReport>> {
    return $axios.$get('/admin/moderation/users/getUserReportsListAggregatedByReporter', {
      params,
    });
  },

  blockUser(payload: IBlockUserPayload): Promise<void> {
    return $axios.$post('/admin/moderation/users/blockUser', payload);
  },

  getUserBlockDetails(userId: string): Promise<IGetUserBlockDetailsResponse> {
    return $axios.$get('/admin/moderation/users/getUserBlockDetails', {
      params: { userId },
      localError: true,
    });
  },

  updateUserBlock(payload: IUpdateUserBlockPayload): Promise<void> {
    return $axios.$post('/admin/moderation/users/updateUserBlock', payload);
  },

  unblockUser(userId: string): Promise<void> {
    return $axios.$post('/admin/moderation/users/unblockUser', { userId });
  },

  setUserModerationStatusPending(userId: string): Promise<void> {
    return $axios.$post('/admin/moderation/users/setUserModerationStatusPending', { userId });
  },

  setUserModerationStatusChecked(userId: string): Promise<void> {
    return $axios.$post('/admin/moderation/users/setUserModerationStatusChecked', { userId });
  },

  getBlockHistoryForUser(
    params: IGetUserReportsListAggregatedByReporterParams,
  ): Promise<IItemsListWithTotalResponse<IReportedProfileHistoryItem>> {
    return $axios.$get('/admin/moderation/users/getBlockHistoryForUser', { params });
  },

  deleteUser(userId: string): Promise<void> {
    return $axios.$post('/admin/moderation/users/deleteUser', { userId });
  },
});
