import { ActionTree, MutationTree } from 'vuex';
import { ENotificationScope } from '~/features/notifications/enums/notification-scope.enum';
import { EAccountNotificationType } from '~/features/notifications/enums/account-notification-type';
import { IAccountNotification } from '~/features/notifications/interfaces/account-notification-message.interface';

export interface IState {
  notifications: any[];
  unreadCount: number;
  hasNewNotifications: boolean;
  countOfJoinRequestsWhereUserIsModerator: number;
  lastScrollPosition: number;
}

export const state = (): IState => ({
  notifications: [],
  unreadCount: 0,
  hasNewNotifications: false,
  countOfJoinRequestsWhereUserIsModerator: 0,
  lastScrollPosition: 0,
});

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async socketOnOpen(): Promise<void> {
    await this.$websocketApi.subscribe();
  },

  socketOnClose(): void {
    console.log('close connection action');
  },

  socketOnError(): void {
    console.log('error connection action');
  },

  socketOnMessage(ctx, response: MessageEvent) {
    try {
      const data = JSON.parse(response.data);

      if (!data?.success && data.error === 'UNAUTHORIZED') this.$websocketApi.subscribe(true);

      switch (data?.data?.scope) {
        case ENotificationScope.Chat:
          ctx.dispatch('users/messages/onChatNotification', data.data.message, { root: true });
          break;
        case ENotificationScope.NotificationCenter:
          ctx.dispatch('getUnreadCount');
          ctx.dispatch('getNotifications');
          ctx.dispatch('updateNotificationsStatus', true);
          ctx.commit('setLastScrollPosition', 0);
          break;
        case ENotificationScope.Dashboards:
          ctx.dispatch('getCountOfJoinRequestsWhereUserIsModerator');
          break;
        case ENotificationScope.Account:
          ctx.dispatch('onAccountNotification', data.data.message);
          break;
      }
    } catch (ex) {
      console.log('notifications store', ex);
    }
  },

  socketReconnect(): void {},

  socketOnReconnectError(): void {
    console.log('reconnect error connection action');
  },

  async getUnreadCount(ctx) {
    const response = await this.$api.notifications.getUnreadCount();

    ctx.commit('setUnreadCount', response?.count || 0);
    ctx.commit('setHasNewNotifications', !!response?.count);
  },

  async getNotifications(ctx) {
    const response = await this.$api.notifications.getNotifications(100);

    ctx.commit('setNotifications', response?.items || []);
  },

  async getCountOfJoinRequestsWhereUserIsModerator(ctx) {
    const { countOfJoinRequestsWhereUserIsModerator } = await this.$api.communities.getDashboard();

    ctx.commit(
      'setCountOfJoinRequestsWhereUserIsModerator',
      countOfJoinRequestsWhereUserIsModerator,
    );
  },

  updateNotificationsStatus(ctx, value: boolean) {
    ctx.commit('setHasNewNotifications', value);
  },

  async onAccountNotification(_ctx, message: IAccountNotification) {
    if (message?.type !== EAccountNotificationType.EmailConfirmed) return;

    await this.$auth.refreshTokens();
  },
};

export const mutations: MutationTree<RootState> = {
  setCountOfJoinRequestsWhereUserIsModerator(
    state,
    countOfJoinRequestsWhereUserIsModerator: number,
  ) {
    state.countOfJoinRequestsWhereUserIsModerator = countOfJoinRequestsWhereUserIsModerator;
  },

  setUnreadCount(state, unreadCount) {
    state.unreadCount = unreadCount;
  },

  setNotifications(state, notifications) {
    state.notifications = notifications;
  },

  setHasNewNotifications(state, hasNewNotifications) {
    state.hasNewNotifications = hasNewNotifications;
  },

  setLastScrollPosition(state, position) {
    state.lastScrollPosition = position;
  },
};
