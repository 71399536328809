import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { isNil } from 'ramda';
import { IUserProfileForCrmInfo } from '~/features/users/interfaces/users.interface';
import { IGetUserBlockDetailsResponse } from '~/api/interfaces/users-moderation.interface';

export interface IUsersState {
  user: IUserProfileForCrmInfo;
  totalPaymentsAmountUsd: string;
  blockDetails: IGetUserBlockDetailsResponse | undefined;
}

export const state = (): IUsersState => ({
  user: {
    id: '',
    profileImage: null,
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    username: '',
    mobileNumber: '',
    registrationDate: '',
    lastActiveAt: '',
    isDeleted: false,
    labels: [],
  },
  blockDetails: undefined,
  totalPaymentsAmountUsd: '0',
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<IUsersState, any> = {
  isBlockedStatus(state): boolean {
    return !isNil(state.blockDetails);
  },
};

export const actions: ActionTree<IUsersState, RootState> = {
  async getUser(ctx, userId: string): Promise<void> {
    const response = await this.$api.userCrmInfo.getUserInfo(userId);
    const { totalPaymentsAmountUsd } = await this.$api.userCrmInfo.getPaymentsStatistics(userId);

    ctx.commit('setUser', response);
    ctx.commit('setTotalPaymentsAmountUsd', totalPaymentsAmountUsd);
  },

  async getUserBlockDetails(ctx, userId: string): Promise<void> {
    try {
      const response = await this.$api.usersModeration.getUserBlockDetails(userId);

      ctx.commit('setBlockDetails', response);
    } catch (error) {
      ctx.commit('setBlockDetails', undefined);
    }
  },
};

export const mutations: MutationTree<IUsersState> = {
  setUser(state, userProfile) {
    state.user = userProfile;
  },

  setTotalPaymentsAmountUsd(state, total: string) {
    state.totalPaymentsAmountUsd = total;
  },

  setBlockDetails(state, blockDetails: IGetUserBlockDetailsResponse | undefined) {
    state.blockDetails = blockDetails;
  },

  setUserEmail(state, userEmail: string) {
    state.user.email = userEmail;
  },
};
