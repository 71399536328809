import { reactive, ref, Ref, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
import { AxiosResponse } from 'axios';
import { VForm } from '~/shared/types/form.type';
import { useFormValidation } from '~/shared/composable/useFormValidation/useFormValidation';
import { IUseFormValidation } from '~/shared/composable/useFormValidation/interfaces';
import { IFormRule } from '~/shared/interfaces/form-rule.interface';
import {
  IResponseErrorData,
  IResponseErrorDataBlocked,
} from '~/shared/interfaces/response.interface';
import { EAuthBackendErrorCode } from '~/shared/enums/backend-error-code.enum';
import { EToastType } from '~/shared/enums/toast.enum';
import { TBackendErrorCode } from '~/shared/types/auth-response-error.type';
import { useGcpAnalytics } from '~/features/gcp-analytics/composables/useGcpAnalytics';
import { useOAuth } from '~/shared/composable/useOAuth';
import { isWebview } from '~/utils/is-webview';
import { EReactNativeEventType } from '~/shared/enums/react-native-event-type.enum';
import { useFormErrorMessages } from '~/shared/composable/useFormErrorMessages';

import { ILoginWithResponseData } from '~/features/auth/interfaces/login-with-response.interface';
export interface ILoginPayload {
  email: string;
  password: string;
}

export interface IUseLogin extends Omit<IUseFormValidation, 'validateField'> {
  formData: ILoginPayload;
  rules: Record<string, IFormRule[]>;
  isLogging: Ref<boolean>;
  handleSubmit(): Promise<IHandleSubmitResponse>;
}

export interface IHandleSubmitResponse {
  isSubmitted: boolean;
  errorData?: IResponseErrorDataBlocked;
  errorCode?: TBackendErrorCode;
}

export const useLogin = (form: Ref<VForm>): IUseLogin => {
  const ctx = useContext();
  const formData = reactive({
    email: '',
    password: '',
  });
  const isLogging = ref(false);
  const hasSubmitted = ref(false);

  const { getFormRequiredErrorMessage } = useFormErrorMessages();
  const rules: Record<string, IFormRule[]> = {
    email: [
      { required: true, message: getFormRequiredErrorMessage('form.email'), trigger: 'none' },
      { type: 'email', message: ctx.i18n.t('form.invalidEmail'), trigger: 'none' },
      {
        validator: async (_, value, callback): Promise<void> => {
          await form.value.clearValidate('email');

          if (!hasSubmitted.value) {
            callback();
          } else if (!value) {
            callback(new Error(getFormRequiredErrorMessage('form.email') as string));
          }
        },
        trigger: 'change',
      },
    ],
    password: [
      { required: true, message: getFormRequiredErrorMessage('form.password'), trigger: 'none' },
      {
        validator: async (_, value, callback): Promise<void> => {
          await form.value.clearValidate('password');

          if (!hasSubmitted.value) {
            callback();
          } else if (!value) {
            callback(new Error(getFormRequiredErrorMessage('form.password') as string));
          }
        },
        trigger: 'change',
      },
    ],
  };

  const { onValidate, errors, hasErrors, validate } = useFormValidation(formData);

  const { applyForLogin } = useGcpAnalytics();

  const router = useRouter();

  const { handleOAuthLogin } = useOAuth();
  const route = useRoute();

  const handleSubmit = async (): Promise<IHandleSubmitResponse> => {
    hasSubmitted.value = true;

    try {
      const start = Date.now();

      checkInputsValues();

      isLogging.value = true;
      await validate(form.value);

      const {
        data: { success },
      } = await (ctx.$auth.loginWith('custom', { data: formData }) as Promise<
        AxiosResponse<ILoginWithResponseData>
      >);

      if (!success) {
        isLogging.value = false;

        return { isSubmitted: false };
      }

      ctx.$websocketApi.connect();

      const affiliateTrackingId = ctx.$cookies.get('affiliateTrackingId');

      if (affiliateTrackingId?.length) ctx.$api.affiliate.assignTrackingId(affiliateTrackingId);

      await ctx.store.dispatch('feature-access/getAccessInfo');

      if (isWebview()) {
        ((window as any)?.ReactNativeWebView as any)?.postMessage(
          JSON.stringify({
            type: EReactNativeEventType.Auth,
            data: formData.email,
          }),
        );
      }

      if (!isNil(route.value?.query?.redirectTo) && !isNil(route.value?.query?.appId))
        await handleOAuthLogin();
      else await ctx.$auth.fetchUser();

      applyForLogin(Date.now() - start);

      return { isSubmitted: true };
    } catch (error) {
      isLogging.value = false;
      if (error === false) return { isSubmitted: false };

      const { errorCode, errorData, localizedErrorMessage } = error as IResponseErrorData;
      if (errorCode === EAuthBackendErrorCode.LoginFailedEmailConfirmationRequired) {
        await router.push(
          ctx.localePath({
            path: '/',
            query: {
              confirmation_email: formData.email,
            },
          }),
        );
      } else if (errorCode !== EAuthBackendErrorCode.AuthFailedUserIsBlocked) {
        ctx.$showToast(localizedErrorMessage, { type: EToastType.Error });
      }

      return { isSubmitted: false, errorData, errorCode };
    }
  };

  const checkInputsValues = (): void => {
    try {
      const formElements = (form.value.$el as HTMLFormElement).elements;

      const emailInputValue = (formElements.namedItem('email') as HTMLInputElement).value;
      const passwordInputValue = (formElements.namedItem('password') as HTMLInputElement).value;

      if (emailInputValue !== formData.email) formData.email = emailInputValue;
      if (passwordInputValue !== formData.password) formData.password = passwordInputValue;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    onValidate,
    errors,
    hasErrors,
    validate,
    rules,
    formData,
    isLogging,
    handleSubmit,
  };
};
