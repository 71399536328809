import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICommentsModerationApi,
  IGetReportsParams,
} from '~/api/interfaces/comments-moderation.interface';
import { ICommentReport } from '~/features/social-moderation/interfaces/comment-report.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { ISetEntityResolutionStatusByReportPayload } from '~/api/interfaces/posts-moderation.interface';

export default ($axios: NuxtAxiosInstance): ICommentsModerationApi => ({
  getReportById(id: string): Promise<ICommentReport> {
    return $axios.$get('/admin/moderation/comments/getReportById', { params: { id } });
  },

  getReports(params: IGetReportsParams): Promise<IItemsListWithTotalResponse<ICommentReport>> {
    return $axios.$post('/admin/moderation/comments/getReports', params);
  },

  setEntityResolutionStatusByReport(
    payload: ISetEntityResolutionStatusByReportPayload,
  ): Promise<void> {
    return $axios.$post('/admin/moderation/comments/setEntityResolutionStatusByReport', payload);
  },

  deleteComment(commentId: string): Promise<void> {
    return $axios.$post('/admin/moderation/comments/deleteComment', { commentId });
  },
});
