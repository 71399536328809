import { onBeforeUnmount, onMounted, Ref, ref } from '@nuxtjs/composition-api';

export interface IUseScrollDirection {
  resetGoingDown(): void;
  isGoingDown: Ref<boolean>;
}

export const useScrollDirection = (): IUseScrollDirection => {
  const lastScrollPosition = ref(0);
  const isGoingDown = ref(false);

  const resetGoingDown = (): void => {
    isGoingDown.value = false;
  };

  const onScroll = (): void => {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (
      currentScrollPosition < 0 ||
      Math.abs(currentScrollPosition - lastScrollPosition.value) < 50
    )
      return;

    // Here we determine whether we need to show or hide the navbar
    isGoingDown.value = currentScrollPosition > lastScrollPosition.value;

    // Set the current scroll position as the last scroll position
    lastScrollPosition.value = currentScrollPosition;
  };

  onMounted(() => {
    window.addEventListener('scroll', onScroll);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
  });

  return {
    resetGoingDown,
    isGoingDown,
  };
};
