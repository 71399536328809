import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICommunitiesAdminMembersApi,
  IRemoveFollowerPayload,
} from '~/api/interfaces/communities-admin-members.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IUserProfileShort } from '~/features/users/interfaces/users.interface';
import { IGetCommunityFollowersListParams } from '~/api/interfaces/communitites.interface';

export default ($axios: NuxtAxiosInstance): ICommunitiesAdminMembersApi => ({
  getFollowersList(
    params: IGetCommunityFollowersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/communities/admin/members/getFollowersList', { params });
  },

  getMembersList(
    params: IGetCommunityFollowersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileShort>> {
    return $axios.$get('/communities/admin/members/getMembersList', { params });
  },

  removeFollower(payload: IRemoveFollowerPayload): Promise<void> {
    return $axios.$post('/communities/admin/members/removeFollower', payload);
  },

  removeMember(payload: IRemoveFollowerPayload): Promise<void> {
    return $axios.$post('/communities/admin/members/removeMember', payload);
  },
});
