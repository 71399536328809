import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IArtClubInstructorsApi,
  IGetInstructorsResponse,
} from '~/api/interfaces/art-club-instructors.interface';

export default ($axios: NuxtAxiosInstance): IArtClubInstructorsApi => ({
  getInstructorCards(payload): Promise<IGetInstructorsResponse> {
    return $axios.$post('/instructors/getInstructorCards', payload);
  },
});
