import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { clone, isNil } from 'ramda';
import { EPostCategoryId } from '~/shared/enums/post-category-id.enum';
import { ICreatePostPayload } from '~/api/interfaces/user-posts.interface';
import { IUploadFileEntityShort } from '~/features/files/interfaces/file.interface';
import { stripHtmlTagsFromText } from '~/utils/strip-html-tags-from-text';
import { IUserPostExternalLink } from '~/features/profile/interfaces/user-post.interface';

export interface ICreatePostState {
  form: ICreatePostPayload;
  uploadedImages: IUploadFileEntityShort[];
}

const getDefaultState = (): ICreatePostState => ({
  form: {
    text: '',
    categoryId: EPostCategoryId.General,
    media: [] as string[],
    mediaLayoutSpec: {
      type: undefined,
    },
    externalLinks: undefined,
  },
  uploadedImages: [],
});

export const state = (): ICreatePostState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {
  addImages(state, images: IUploadFileEntityShort[]) {
    state.uploadedImages = [...state.uploadedImages, ...images];
  },

  removeImage(state, imageIndex: number) {
    state.uploadedImages = state.uploadedImages.filter((_, index) => index !== imageIndex);
  },

  setText(state, text: string) {
    state.form.text = text;
  },

  setCategoryId(state, categoryId: EPostCategoryId) {
    state.form.categoryId = categoryId;
  },

  addVideo(state, video: IUserPostExternalLink) {
    state.form.externalLinks = [clone(video)];
  },

  clearVideo(state) {
    state.form.externalLinks = undefined;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<ICreatePostState, RootState> = {
  isFormEmpty(state): boolean {
    return !stripHtmlTagsFromText(state.form.text).length && !state.uploadedImages.length;
  },

  videoInfo(state): IUserPostExternalLink | undefined {
    return state.form.externalLinks?.[0];
  },

  isVideoShown(state, getters): boolean {
    return !state.form.media?.length && !state.uploadedImages?.length && !isNil(getters.videoInfo);
  },
};
