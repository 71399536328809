import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAccountApi,
  IChangeEmailPayload,
  IChangeEmailResponse,
  IChangePasswordPayload,
  IConfirmEmailAddressResponse,
  IGetLoginOnBehalfPermissionsResponse,
  IIsEmailAvailableResponse,
  IIsUsernameAvailableResponse,
  IRequestPasswordResetPayload,
  IResetPasswordPayload,
  IResetPasswordResponse,
  IUpdateMyProfilePayload,
} from '~/api/interfaces/account.interface';
import { IAuthUser } from '~/features/auth/interfaces/auth-user.interface';
import { EResendEmailConfirmation } from '~/shared/enums/resend-email-confirmation.enum';

export default ($axios: NuxtAxiosInstance): IAccountApi => ({
  confirmEmailAddress(confirmationToken: string): Promise<IConfirmEmailAddressResponse> {
    return $axios.$post(
      '/account/confirmEmailAddress',
      { confirmationToken },
      { localError: true },
    );
  },

  changeEmail(payload: IChangeEmailPayload): Promise<IChangeEmailResponse> {
    return $axios.$post('/account/changeEmail', payload, { localError: true });
  },

  updateMyProfile(payload: IUpdateMyProfilePayload): Promise<IAuthUser> {
    return $axios.$post('/account/updateMyProfile', payload, { localError: true });
  },

  changePassword(payload: Partial<IChangePasswordPayload>): Promise<void> {
    return $axios.$post('/account/changePassword', payload);
  },

  isUsernameAvailable(username: string): Promise<IIsUsernameAvailableResponse> {
    return $axios.$get('/account/isUsernameAvailable', { params: { username } });
  },

  requestPasswordReset(payload: IRequestPasswordResetPayload): Promise<void> {
    return $axios.$post('/account/requestPasswordReset', payload, { localError: true });
  },

  checkResetPasswordToken(resetToken: string): Promise<void> {
    return $axios.$get('/account/checkResetPasswordToken', {
      params: { resetToken },
      localError: true,
    });
  },

  resetPassword(payload: IResetPasswordPayload): Promise<IResetPasswordResponse> {
    return $axios.$post('/account/resetPassword', payload, { localError: true });
  },

  resendEmailConfirmation(email: string, type = EResendEmailConfirmation.Default): Promise<void> {
    return $axios.$post('/account/resendEmailConfirmation', { email, type });
  },

  getLoginOnBehalfPermissions(): Promise<IGetLoginOnBehalfPermissionsResponse> {
    return $axios.$get('/account/getLoginOnBehalfPermissions');
  },

  isEmailAvailable(email: string): Promise<IIsEmailAvailableResponse> {
    return $axios.$get('signUp/isEmailAvailable', { params: { email } });
  },

  deleteAccount(refreshToken: string): Promise<void> {
    return $axios.$post('/account/deleteMyAccount', { refreshToken });
  },
});
