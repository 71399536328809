






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SocialSidebarMobile',
  components: {
    GuestAuthBottomMenu: () =>
      import('~/shared/components/layouts/parts/menus/GuestAuthBottomMenu.vue'),
    BottomBar: () => import('~/shared/components/layouts/parts/menus/BottomBar.vue'),
  },
});
