














import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import SocialSidebarDesktop from './SocialSidebar.desktop.vue';
import SocialSidebarMobile from './SocialSidebar.mobile.vue';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import { TABLET_WIDTH } from '~/shared/constants';

export default defineComponent({
  name: 'SocialSidebar',
  components: { UiAdaptive, SocialSidebarDesktop, SocialSidebarMobile, LazyHydrate },
  inheritAttrs: false,
  setup() {
    return {
      TABLET_WIDTH,
    };
  },
});
