import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICreatePostPayload,
  IGetMyPostsParams,
  IGetUserPostsParams,
  IUpdatePostPayload,
} from '~/api/interfaces/user-posts.interface';
import { IUserPost, IUserPostForFeed } from '~/features/profile/interfaces/user-post.interface';
import {
  IItemsListRequestParams,
  IItemsListResponse,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  createPost(payload: ICreatePostPayload): Promise<IUserPost> {
    return $axios.$post('/feed/userPosts/createPost', payload, { localError: true });
  },

  updatePost(payload: IUpdatePostPayload): Promise<void> {
    return $axios.$post('/feed/userPosts/updatePost', payload);
  },

  deletePost(postId: string): Promise<void> {
    return $axios.$post('/feed/userPosts/deletePost', { postId });
  },

  getPostById(id: string): Promise<IUserPost> {
    return $axios.$get('/feed/userPosts/getPostById', { params: { id } });
  },

  getUserPosts(params: IGetUserPostsParams): Promise<IItemsListResponse<IUserPostForFeed>> {
    return $axios.$get('/feed/userPosts/getUserPosts', { params });
  },

  getMyPosts(params: IGetMyPostsParams): Promise<IItemsListResponse<IUserPostForFeed>> {
    return $axios.$get('/feed/userPosts/getMyPosts', { params });
  },

  setUserPinnedPost(postId: string | null): Promise<void> {
    return $axios.$post('/feed/userPosts/setUserPinnedPost', { postId });
  },

  getUserPinnedPost(userId: string): Promise<IUserPostForFeed | null> {
    return $axios.$get('/feed/userPosts/getUserPinnedPost', { params: { userId } });
  },

  getMyPinnedPost(): Promise<IUserPostForFeed | null> {
    return $axios.$get('/feed/userPosts/getMyPinnedPost');
  },

  getLikedPosts(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IUserPostForFeed>> {
    return $axios.$get('/feed/userPosts/getLikedPosts', { params });
  },

  getUserSavedPosts(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<IUserPostForFeed>> {
    return $axios.$get('/feed/userPosts/getUserSavedPosts', { params });
  },

  addPostToSaved(id: string): Promise<void> {
    return $axios.$post('/feed/userPosts/addPostToSaved', { id }, { localError: true });
  },

  deletePostToSaved(id: string): Promise<void> {
    return $axios.$post('/feed/userPosts/deletePostFromSaved', { id }, { localError: true });
  },
});
