import { IFooterLinkIcon } from '~/shared/interfaces/footer-link.interface';
import { MILAN_ART_APPLE_URL, MILAN_ART_GOOGLE_URL } from '~/shared/constants';

export const storeBadgeLinks: IFooterLinkIcon[] = [
  {
    icon: 'store-badges/app-store',
    url: MILAN_ART_APPLE_URL,
    customClass: 'store-badge-icon--apple',
  },
  {
    icon: 'store-badges/google-play',
    url: MILAN_ART_GOOGLE_URL,
    customClass: 'store-badge-icon--google',
  },
];
