import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetCommentsCountResponse,
  IGetCommentsResponse,
  IUpdateCommentPayload,
} from '~/api/interfaces/comments.interface';
import { TAddCommentPayload, TGetCommentsParams } from '~/api/types/comments.types';
import { IComment } from '~/features/comments/interfaces/comment.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getCommentsCount(entityId: string): Promise<IGetCommentsCountResponse> {
    return $axios.$get('/comments/getCommentsCount', { params: { entityId } });
  },
  getComments(params: TGetCommentsParams): Promise<IGetCommentsResponse> {
    return $axios.$get('/comments/getComments', { params });
  },
  getCommentById(id: string): Promise<IComment> {
    return $axios.$get('/comments/getCommentById', { params: { id } });
  },
  addComment(payload: TAddCommentPayload): Promise<IComment> {
    return $axios.$post('/comments/addComment', payload, { localError: true });
  },
  updateComment(payload: IUpdateCommentPayload): Promise<IComment> {
    return $axios.$post('/comments/updateComment', payload);
  },
  deleteComment(commentId: string): Promise<void> {
    return $axios.$post('/comments/deleteComment', { commentId });
  },
});
