import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { stripHtmlTagsFromText } from '~/utils/strip-html-tags-from-text';

export interface IState {
  cachedReplies: Record<string, string | undefined>;
}

export interface ICacheReplyPayload {
  entityId: string;
  replyText: string | undefined;
}

const getDefaultState = (): IState => ({
  cachedReplies: {},
});

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {
  cacheReply(state, payload: ICacheReplyPayload) {
    const isCommentEmpty = payload.replyText
      ? !stripHtmlTagsFromText(payload.replyText).trim()
      : true;

    state.cachedReplies = {
      ...state.cachedReplies,
      [payload.entityId]: isCommentEmpty ? undefined : payload.replyText,
    };
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IState, RootState> = {
  getCachedReply: (state) => (entityId: string): string | undefined => {
    return state.cachedReplies[entityId];
  },
};
