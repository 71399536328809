import {
  computed,
  ComputedRef,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  useContext,
  useFetch,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import { SOCIAL_MENU_HEIGHT_MOBILE } from '~/shared/constants/social-menu.constant';
import OnBoardingConfetti from '~/features/on-boarding/components/OnBoardingConfetti.vue';
import { useMembershipsCheck } from '~/shared/composable/useMembershipsCheck';
import { isWebview } from '~/utils/is-webview';
import { useScrollDirection } from '~/shared/composable/useScrollDirection';
import { IUseStatusLines, useStatusLines } from '~/shared/composable/useStatusLines';
import { TABLET_WIDTH } from '~/shared/constants';
import { ELayoutType } from '~/shared/enums/layout-type.enum';

export interface IUseLayout extends IUseStatusLines {
  layoutCssVars: ComputedRef<object>;
  isOnboardingShown: ComputedRef<boolean>;
  isPageTopOffset: ComputedRef<boolean>;
  onOnboardingFinished(): void;
  isMobileBannerShown: Ref<boolean>;
  onHideBannerClick(): void;
  isGuestAuthBottomMenuVisible: ComputedRef<boolean>;
}

export const useLayout = (
  confettiComponent?: Ref<InstanceType<typeof OnBoardingConfetti>>,
  layoutType?: ELayoutType,
): IUseLayout => {
  const viewportJsHeight = ref(0);

  const { $isMobile, $auth, store } = useContext();

  const { isArtClubMembership } = useMembershipsCheck();

  const isOrientationCourseAccess = computed(
    () => store.state['feature-access'].isOrientationCourseAccess,
  );

  const isWorkshopAccess = computed(() => store.state['feature-access'].isWorkshopAccess);

  const isOnboardingShown = computed(() => {
    return (
      $auth.loggedIn &&
      (!$auth.$state.isOnboardingPassed || !$auth.$state.isFirstEmailVerified) &&
      !isOrientationCourseAccess.value &&
      !isWorkshopAccess.value &&
      !isArtClubMembership.value &&
      !$auth.$state.isPurchaser
    );
  });

  const { isConfirmationLineShown, isFailedPaymentLineShown } = useStatusLines();

  const isPageTopOffset = computed(
    () => isFailedPaymentLineShown.value || isConfirmationLineShown.value,
  );

  const onOnboardingFinished = (): void => {
    if (!confettiComponent?.value?.showOnce) {
      setTimeout(() => onOnboardingFinished(), 100);

      return;
    }

    confettiComponent?.value.showOnce();
  };

  const layoutCssVars = computed(() => ({
    '--viewport-js-height': `${viewportJsHeight.value}px`,
    '--mobile-bottom-bar-height': `${SOCIAL_MENU_HEIGHT_MOBILE}px`,
  }));

  const isMobileBannerClosed = computed(() => store.state.isMobileBannerClosed);

  const onHideBannerClick = (): void => {
    store.commit('setIsMobileBannerClosed', true);
  };

  const isMobileBannerShown = computed(() => {
    if (process.server) return false;

    return !isMobileBannerClosed.value && !isWebview();
  });

  const route = useRoute();

  const { isMentoringMembership } = useMembershipsCheck();

  useFetch(async () => {
    if (!$auth.loggedIn) return;

    const promises: Promise<void>[] = [
      store.dispatch('users/messages/getChats'),
      store.dispatch('notifications/getUnreadCount'),
    ];

    if (isMentoringMembership.value)
      promises.push(store.dispatch('users/mentoring/getMentoringInfo'));

    if (layoutType === ELayoutType.ArtClubSocial)
      promises.push(store.dispatch('users/art-club/getNavigationOverview'));

    await Promise.allSettled(promises);
  });

  const computedLoggedIn = computed(() => $auth.loggedIn);

  watch(computedLoggedIn, async (newVal) => {
    if (!newVal) return;

    const promises: Promise<void>[] = [store.dispatch('users/messages/getChats')];

    if (isMentoringMembership.value)
      promises.push(store.dispatch('users/mentoring/getMentoringInfo'));

    await Promise.allSettled(promises);
  });

  onMounted(() => {
    onResizeWindowOnMobile();

    window.addEventListener('resize', onResizeWindowOnMobile);

    if (+route.value.query.registration_success) onOnboardingFinished();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResizeWindowOnMobile);
  });

  const onResizeWindowOnMobile = (): void => {
    if (!$isMobile(TABLET_WIDTH)) return;

    viewportJsHeight.value = window.innerHeight;
  };

  const { isGoingDown } = useScrollDirection();
  const isGuestAuthBottomMenuVisible = computed(() => {
    return !isGoingDown.value;
  });

  return {
    layoutCssVars,
    isOnboardingShown,
    isConfirmationLineShown,
    isFailedPaymentLineShown,
    isPageTopOffset,
    onOnboardingFinished,
    isMobileBannerShown,
    onHideBannerClick,
    isGuestAuthBottomMenuVisible,
  };
};
