import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { clone } from 'ramda';
import {
  ICourseDetails,
  ICourseState,
  IGetCourseParams,
  IReorderContentItemsPayload,
} from '~/store/admin/courses/interfaces';
import { ECourseVersion } from '~/features/courses/enums/course-version.enum';
import { ICourseContentModule } from '~/features/courses/interfaces/courses.interface';
import { IGetCourseEditHistoryParams } from '~/api/interfaces/courses.interface';
import { IUserProfileShort } from '~/features/users/interfaces/users.interface';
import { getDefaultFileState } from '~/utils/get-default-image-state';
import { IFile } from '~/features/files/interfaces/file.interface';

const getDefaultState = (): ICourseState => {
  return {
    cachedCourseVersionId: null,
    isCourseEdited: false,
    course: {
      id: '',
      title: '',
      description: '',
      isPublished: false,
      durationDays: 0,
      durationText: '',
      author: '',
      authorId: '',
      image: null,
      dripSettingDays: null,
      modulesCount: 0,
      studentsCount: 0,
      landingLink: null,
      isAddToArtClubProLibrary: false,
      isFree: false,
    },
    instructorsList: [],
    courseContent: [],

    versionsHistory: [],
    versionsHistoryCount: 0,
  };
};

export const state = (): ICourseState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<ICourseState, RootState> = {
  courseId(state): string {
    return state.course.id;
  },

  courseDetails(state): ICourseDetails {
    return {
      title: state.course.title,
      description: state.course.description,
      durationText: state.course.durationText,
      dripSettingDays: state.course.dripSettingDays,
      imageUrl: state.course.image?.url,
      landingLink: state.course.landingLink,
      isAddToArtClubProLibrary: state.course.isAddToArtClubProLibrary,
      isFree: state.course.isFree,
    };
  },

  dripSettingDays(state): number | null {
    return state.course.dripSettingDays;
  },

  isCourseEmpty(state): boolean {
    return !state.courseContent.length;
  },

  isAnyEmptyModule(state): boolean {
    return state.courseContent.some((module): boolean => {
      return !module.lessons.length || module.lessons.every((lesson) => !lesson.isPublished);
    });
  },

  isSingleModuleCourse(state): boolean {
    return state.courseContent.length === 1;
  },
};

export const actions: ActionTree<ICourseState, RootState> = {
  async getCourse(ctx, params: IGetCourseParams) {
    const courseResponse = await this.$api.courses.getCourseById({
      courseId: params.courseId,
      version: params.version || ECourseVersion.Latest,
    });

    const courseInstructorsResponse = await this.$api.courses.getInstructorList(params.courseId);

    const courseContentResponse = await this.$api.courses.getCourseContentItems(params.courseId);

    ctx.commit('setInstructorsList', courseInstructorsResponse);
    ctx.commit('setCourse', courseResponse);
    ctx.commit('setCourseContent', courseContentResponse.items);
  },

  async getCourseVersionsHistory(ctx, params: Omit<IGetCourseEditHistoryParams, 'courseId'>) {
    const response = await this.$api.courses.getCourseEditHistory({
      courseId: ctx.state.course.id,
      ...params,
    });

    ctx.commit('setVersionsHistory', response.items);
    ctx.commit('setVersionsHistoryCount', response.total);
  },

  async updateCourseSession(ctx) {
    let versionId = ctx.state.cachedCourseVersionId;

    if (!versionId) {
      const startSessionResponse = await this.$api.courses.startEditCourseSession({
        courseId: ctx.state.course.id,
        fromVersion: ECourseVersion.Latest,
      });

      versionId = startSessionResponse.versionId;
    }

    await this.$api.courses.saveEditCourseSession({
      versionId,
      title: ctx.state.course.title,
      description: ctx.state.course.description,
      imageId: ctx.state.course.image?.id,
      authorId: ctx.state.course.authorId,
      durationText: ctx.state.course.durationText,
      landingLink: ctx.state.course.landingLink || null,
    });

    ctx.commit('setIsCourseEdited', false);
    ctx.commit('setCachedCourseVersionId', versionId);
  },

  async addInstructor(ctx, instructor: IUserProfileShort) {
    return await this.$api.courses.addInstructor({
      courseId: ctx.getters.courseId,
      instructorId: instructor.id,
    });
  },

  async removeInstructor(ctx, instructorId) {
    return await this.$api.courses.removeInstructor({
      courseId: ctx.getters.courseId,
      instructorId,
    });
  },

  async publishCourse(ctx) {
    if (ctx.state.isCourseEdited) await ctx.dispatch('updateCourseSession');

    await this.$api.courses.publishCourseVersion({
      courseId: ctx.state.course.id,
      version: ECourseVersion.Latest,
    });

    await this.$api.courses.updateState({
      courseId: ctx.state.course.id,
      isPublished: true,
      dripSettingDays: ctx.state.course.dripSettingDays,
      isAddToArtClubProLibrary: ctx.state.course.isAddToArtClubProLibrary,
      isFree: ctx.state.course.isFree,
    });

    ctx.commit('setCachedCourseVersionId', null);
  },

  async deleteCourse(ctx) {
    return await this.$api.courses.updateState({ courseId: ctx.getters.courseId, isDeleted: true });
  },

  async reorderContentItems(ctx, payload: IReorderContentItemsPayload) {
    const courseContent: ICourseContentModule[] = clone(ctx.state.courseContent);

    const currentModule = courseContent.find(
      (module: ICourseContentModule) => module.id === payload.currentModuleId,
    );

    const targetModule = courseContent.find(
      (module: ICourseContentModule) => module.id === payload.targetModuleId,
    );

    if (!currentModule || !targetModule) return;

    const lessonIndex = currentModule.lessons.findIndex((lesson) => lesson.id === payload.lessonId);

    const [lessonObj] = currentModule.lessons.splice(lessonIndex, 1);

    targetModule.lessons[targetModule.lessons.length] = lessonObj;

    await this.$api.courses.reorderCourseContentItems({
      courseId: ctx.state.course.id,
      items: courseContent,
    });
  },
};

export const mutations: MutationTree<ICourseState> = {
  setCachedCourseVersionId(state, cachedCourseVersionId) {
    state.cachedCourseVersionId = cachedCourseVersionId;
  },

  setIsCourseEdited(state, isCourseEdited) {
    state.isCourseEdited = isCourseEdited;
  },

  setCourse(state, course) {
    state.course = course;
  },

  setInstructorsList(state, instructorsList) {
    state.instructorsList = instructorsList;
  },

  setCourseContent(state, courseContent) {
    state.courseContent = courseContent;
  },

  setCourseTitle(state, title) {
    state.course.title = title;

    state.isCourseEdited = true;
  },

  setCourseDescription(state, description) {
    state.course.description = description;

    state.isCourseEdited = true;
  },

  setDurationText(state, durationText) {
    state.course.durationText = durationText;

    state.isCourseEdited = true;
  },

  setDripSettingDays(state, dripSettingDays) {
    state.course.dripSettingDays = dripSettingDays;

    state.isCourseEdited = true;
  },

  setImage(state, image: IFile) {
    state.course.image = state.course.image ?? getDefaultFileState();
    state.course.image.url = image.url;
    state.course.image.id = image.id;

    state.isCourseEdited = true;
  },

  setVersionsHistory(state, versionsHistory) {
    state.versionsHistory = versionsHistory;
  },

  setVersionsHistoryCount(state, versionsHistoryCount) {
    state.versionsHistoryCount = versionsHistoryCount;
  },

  setLandingLink(state, landingLink: string) {
    state.course.landingLink = landingLink;

    state.isCourseEdited = true;
  },

  setIsAddToArtClubProLibrary(state, isAddToArtClubProLibrary: boolean) {
    state.course.isAddToArtClubProLibrary = isAddToArtClubProLibrary;
  },

  setIsFree(state, isFree: boolean) {
    state.course.isFree = isFree;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
