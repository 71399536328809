import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil } from 'ramda';
import { IArtClubUserSingleVideo } from '~/features/art-club/interfaces/art-club-video.interface';
import { UPDATE_VIDEO_SESSION_GAP } from '~/features/art-club/constants';
import { IRating } from '~/features/art-club/interfaces/rating.interface';
import { EUserReaction } from '~/features/art-club/enums/user-reaction.enum';
import { SECONDS_LENGTH_FOR_FINISHED_VIDEO } from '~/features/tv-show/constants';

export interface IArtClubVideoState {
  sessionId: string;
  isUpdatePending: boolean;
  video: IArtClubUserSingleVideo;
  rating: IRating;
}

interface IUpdateWatchSessionPayload {
  isVideoEnded: boolean;
  updateSeconds: number;
}

const getDefaultState = (): IArtClubVideoState => ({
  sessionId: '',
  isUpdatePending: false,
  video: {
    id: '',
    publishedAt: '',
    title: '',
    isSaved: false,
    categories: [],
    authors: [],
    vimeoVideoUrl: '',
    description: '',
    watchedUntilSeconds: 0,
    durationSeconds: 0,
    isPartOf: null,
  },
  rating: {
    videoId: '',
    status: null,
    likesPercentage: 0,
    dislikesPercentage: 0,
  },
});

export const state = (): IArtClubVideoState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getVideo(ctx, id: string) {
    const video = await this.$api.artClubVideos.getVideoDetails(id);
    const rating = await this.$api.artClubVideos.getVideoRating(id);

    ctx.commit('setVideo', video);
    ctx.commit('setRating', rating);
  },

  async changeSavedStatus(ctx) {
    await this.$api.artClubVideos.setVideoSaved({
      videoId: ctx.state.video.id,
      isSaved: !ctx.state.video.isSaved,
    });

    ctx.commit('setSavedStatus', !ctx.state.video.isSaved);
  },

  async changeReaction(ctx, reaction: EUserReaction) {
    await this.$api.artClubVideos.setVideoReaction({
      videoId: ctx.state.video.id,
      status: ctx.state.rating.status === reaction ? null : reaction,
    });

    const rating = await this.$api.artClubVideos.getVideoRating(ctx.state.video.id);
    ctx.commit('setRating', rating);
  },

  async startWatchSession(ctx, fromSeconds: number) {
    const { sessionId } = await this.$api.artClubVideos.startVideoSession({
      videoId: ctx.state.video.id,
      fromSeconds: Math.ceil(fromSeconds),
    });

    ctx.commit('setSessionId', sessionId);
  },

  async updateWatchSession(ctx, payload: IUpdateWatchSessionPayload) {
    if (
      !payload.isVideoEnded &&
      payload.updateSeconds - ctx.state.video.watchedUntilSeconds < UPDATE_VIDEO_SESSION_GAP
    )
      return;

    if (ctx.state.isUpdatePending) return;
    ctx.commit('setIsUpdatePending', true);

    const { watchingAtSeconds } = await this.$api.artClubVideos.updateVideoSession({
      sessionId: ctx.state.sessionId,
      watchingAtSeconds: Math.ceil(payload.updateSeconds),
    });

    ctx.commit('setWatchedUntilSeconds', watchingAtSeconds);
    ctx.commit('setIsUpdatePending', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setVideo(state, video: IArtClubUserSingleVideo) {
    state.video = video;
  },

  setSavedStatus(state, isSaved: boolean) {
    state.video.isSaved = isSaved;
  },

  setRating(state, rating: IRating) {
    state.rating = rating;
  },

  setSessionId(state, sessionId: string) {
    state.sessionId = sessionId;
  },

  setWatchedUntilSeconds(state, watchedUntilSeconds: number) {
    state.video.watchedUntilSeconds = watchedUntilSeconds;
  },

  setIsUpdatePending(state, isUpdatePending) {
    state.isUpdatePending = isUpdatePending;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<RootState, RootState> = {
  isVideoLiked(state): boolean {
    return state.rating.status === EUserReaction.Like;
  },

  isVideoDisliked(state): boolean {
    return state.rating.status === EUserReaction.Dislike;
  },

  isPartOfShow(state): boolean {
    return !isNil(state.video.isPartOf);
  },

  isVideoFinished(state): boolean {
    return (
      state.video.watchedUntilSeconds + SECONDS_LENGTH_FOR_FINISHED_VIDEO >=
      state.video.durationSeconds
    );
  },
};
