


























import { defineComponent } from '@nuxtjs/composition-api';
import ContactUsModal from '~/shared/components/modals/ContactUsModal.vue';
import { socialFooterShortenLinks } from '~/shared/config/social-footer.config';

export default defineComponent({
  name: 'AdditionalMenu',
  components: {
    ContactUsModal,
  },
  props: {
    guestMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      socialFooterShortenLinks,
    };
  },
  methods: {
    openContactModal(): void {
      (this.$refs.contactUs as InstanceType<typeof ContactUsModal>).show();
    },

    onLinkClick(): void {
      this.$emit('click');
    },
  },
});
