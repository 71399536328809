























import { defineComponent } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import UiModal from '~/shared/components/modals/UiModal.vue';
import ContactUsSection from '~/shared/components/ContactUsSection.vue';
import { FULL_DATE_WITH_DOTS } from '~/shared/constants/time-formats.constant';
import { IResponseErrorDataBlocked } from '~/shared/interfaces/response.interface';
import { timeParametersList } from '~/features/users/config/time-parameters-list.config';

export default defineComponent({
  name: 'BlockedUserNotificationModal',
  components: { ContactUsSection, UiModal },
  props: {
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['hide'],
  data() {
    return {
      errorData: {
        blockedUntil: '',
      } as IResponseErrorDataBlocked,
    };
  },
  computed: {
    isBlockedForever(): boolean {
      return this.errorData.blockedUntil === timeParametersList.Forever;
    },

    text(): TranslateResult {
      if (this.isBlockedForever) return this.$t('common.blockedUserModal.textBlockedPermanently');

      return this.$t('common.blockedUserModal.text', { blockDuration: this.blockDuration });
    },

    expiresDate(): string {
      return this.$dayjs(this.errorData.blockedUntil).format(FULL_DATE_WITH_DOTS);
    },

    blockDuration(): string | TranslateResult {
      return this.$dayjs(this.errorData.blockedUntil).toNow(true);
    },
  },
  methods: {
    show(errorData: IResponseErrorDataBlocked): void {
      this.errorData = { ...this.errorData, ...errorData };

      (this.$refs.modal as InstanceType<typeof UiModal>).show();
    },

    hide(): void {
      this.onHide();

      (this.$refs.modal as InstanceType<typeof UiModal>).hide();
    },

    onHide(): void {
      this.$emit('hide');
    },
  },
});
