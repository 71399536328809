import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';
import {
  IAddInstructorPayload,
  IInstructorListItemForAdmin,
  IInstructorsAdminApi,
  IUpdateInstructorPayload,
} from '~/api/interfaces/instructors-admin.interface';
import {
  IItemsListRequestParams,
  IItemsListWithQueryRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): IInstructorsAdminApi => ({
  addInstructor(payload: IAddInstructorPayload): Promise<IEntityWithId> {
    return $axios.$post('/admin/instructors/addInstructor ', payload);
  },
  updateInstructor(payload: IUpdateInstructorPayload): Promise<IInstructorListItemForAdmin> {
    return $axios.$post('/admin/instructors/updateInstructor', payload);
  },
  deleteInstructor(id: string): Promise<void> {
    return $axios.$post('/admin/instructors/deleteInstructor', { id });
  },
  getListItemById(id: string): Promise<IInstructorListItemForAdmin> {
    return $axios.$get('/admin/instructors/getListItemById', { params: { id } });
  },
  getList(
    params: IItemsListRequestParams | IItemsListWithQueryRequestParams,
  ): Promise<IItemsListWithTotalResponse<IInstructorListItemForAdmin>> {
    return $axios.$get('/admin/instructors/getList', { params });
  },
});
