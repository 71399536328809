import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IUserCrmLabelsApi } from '~/api/interfaces/user-crm-labels.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IUserLabel } from '~/features/users/interfaces/user-label.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmLabelsApi => ({
  getAll(): Promise<IItemsListResponse<IUserLabel>> {
    return $axios.$get('/users/admin/crm/userLabels/getAll');
  },
});
