
























































import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import FormInput from '~/shared/components/FormInput.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import { VForm } from '~/shared/types/form.type';
import { useLogin } from '~/shared/composable/useLogin';
import { EAuthBackendErrorCode } from '~/shared/enums/backend-error-code.enum';

export default defineComponent({
  name: 'LoginForm',
  components: { UiButton, FormInput },
  emits: ['login-submit', 'register-click', 'blocked', 'forgot-click'],
  setup() {
    const form: Ref<VForm> = ref(null as any);

    const { onValidate, formData, handleSubmit, rules, isLogging } = useLogin(form);

    return { onValidate, formData, handleSubmit, rules, form, isLogging };
  },
  methods: {
    async onSubmit(): Promise<void> {
      const { isSubmitted, errorData, errorCode } = await this.handleSubmit();

      if (errorData?.blockedUntil !== undefined) this.$emit('blocked', errorData);

      if (!isSubmitted && errorCode !== EAuthBackendErrorCode.LoginFailedEmailConfirmationRequired)
        return;

      this.$emit('login-submit');
    },

    onRegisterClick(): void {
      this.$emit('register-click');
    },
    onForgotPasswordClick(): void {
      this.$emit('forgot-click');
    },
  },
});
