import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { differenceWith, isNil } from 'ramda';
import { IContestPost } from '~/features/contests/interfaces/contest.interface';
import { SUBMISSIONS_PER_PAGE } from '~/features/contests/constants/index.constant';
import { EContestsFilter } from '~/features/contests/enums/contests-filter.enum';

export interface ISubmissionsState {
  submissions: IContestPost[];
  winnerSubmissions: IContestPost[];
  total: number;
  gotRequired: boolean;
}

interface ISetSubmissionIsVotedPayload {
  submissionId: string;
  isVoted: boolean;
}

interface IGetSubmissionsParams {
  contestId: string;
  filter: EContestsFilter;
}

const getDefaultState = (): ISubmissionsState => ({
  submissions: [],
  winnerSubmissions: [],
  total: 0,
  gotRequired: false,
});

export const state = (): ISubmissionsState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getInitialSubmissions(ctx, params: IGetSubmissionsParams) {
    const { items, total } = await this.$api.artClubContestsUser.getSubmissions({
      ...params,
      count: SUBMISSIONS_PER_PAGE,
      offset: 0,
    });

    ctx.commit('setInitialSubmissions', items);
    ctx.commit('setTotal', total);
  },

  async getSubmissions(ctx, params: IGetSubmissionsParams) {
    const { items, total } = await this.$api.artClubContestsUser.getSubmissions({
      ...params,
      count: SUBMISSIONS_PER_PAGE,
      offset: ctx.state.submissions.length - (ctx.state.gotRequired ? 1 : 0),
    });

    ctx.commit('setSubmissions', items);
    ctx.commit('setTotal', total);
  },

  async appendSubmissionById(ctx, submissionId: string) {
    try {
      const resp = await this.$api.artClubContestsUser.getSubmissionById(submissionId);

      ctx.commit('appendSubmission', resp);
      ctx.commit('setGotRequired');
    } catch (error) {
      console.error(error);
    }
  },

  async getWinnerSubmissions(ctx, contestId: string) {
    const { items } = await this.$api.artClubContestsUser.getSubmissions({
      contestId,
      filter: EContestsFilter.Winners,
      // NOTE: Was set static count & offset because at that moment was just 5 prize categories
      count: 5,
      offset: 0,
    });

    ctx.commit('setWinnerSubmissions', items);
  },

  async voteSubmission(ctx, submissionId: string) {
    const current = ctx.state.submissions.find((s) => s.id === submissionId) as IContestPost;

    if (current.isVoted) {
      await this.$api.artClubContestsUser.removeVote(submissionId);
      ctx.commit('setSubmissionIsVoted', { submissionId, isVoted: false });
    } else {
      await this.$api.artClubContestsUser.addVote(submissionId);
      ctx.commit('setSubmissionIsVoted', { submissionId, isVoted: true });
    }

    await ctx.dispatch('users/art-club/contests/contest/getVotes', null, { root: true });
  },
};

export const mutations: MutationTree<RootState> = {
  setInitialSubmissions(state, submissions: IContestPost[]) {
    state.submissions = submissions;
  },
  setSubmissions(state, submissions: IContestPost[]) {
    const filteredResult = differenceWith((a, b) => a.id === b.id, submissions, state.submissions);

    state.submissions = [...state.submissions, ...filteredResult];
  },
  setWinnerSubmissions(state, winnerSubmissions: IContestPost[]) {
    state.winnerSubmissions = winnerSubmissions;
  },
  setTotal(state, total: number) {
    state.total = total;
  },
  setSubmissionIsVoted(state, { submissionId, isVoted }: ISetSubmissionIsVotedPayload) {
    const current = state.submissions?.find((s) => s.id === submissionId) as IContestPost;

    if (isNil(current)) return;

    current.isVoted = isVoted;
  },
  removeSubmission(state, submissionId: string) {
    state.submissions = state.submissions.filter((s) => s.id !== submissionId);
  },
  appendSubmission(state, submission: IContestPost) {
    state.submissions = [...state.submissions, submission];
  },
  setGotRequired(state) {
    state.gotRequired = true;
  },
  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<ISubmissionsState, RootState> = {
  isMoreSubmissions(state) {
    return state.submissions.length < state.total;
  },
};
