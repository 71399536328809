import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { isNil } from 'ramda';
import { ref, useContext } from '@nuxtjs/composition-api';
import { isTouchDevice } from '~/utils/is-touch-device';

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
  // INFO: Was get from here: https://github.com/willmcpo/body-scroll-lock#more-complex
  // @ts-ignore
  allowTouchMove: (el) => {
    // @ts-ignore
    while (el && el !== document.body) {
      if (!isNil(el.getAttribute('body-scroll-lock-ignore'))) {
        return true;
      }

      if (el.parentElement === document.body) return;

      // @ts-ignore
      el = el.parentElement;
    }
  },
};

export interface IUseBodyScrollLock {
  makeDisabledBodyScroll(targetElement: HTMLElement | Element): void;
  makeEnabledBodyScroll(targetElement: HTMLElement | Element, preventMoveToPosition?: true): void;
}

export const useBodyScrollLock = (): IUseBodyScrollLock => {
  const prevYPosition = ref(0);

  const { $device } = useContext();

  // since Safari in IPad IOS 13+ forces desktop mode as a default, to define tablets we add an extra check here
  // that device has touch events (because no macOS has touch screen)
  // https://github.com/nuxt-community/device-module/issues/20
  const getIsIosDevice = (): boolean => {
    return $device.isIos || ($device.isMacOS && isTouchDevice());
  };

  const makeDisabledBodyScroll = (targetElement: HTMLElement | Element): void => {
    if (getIsIosDevice()) prevYPosition.value = window.scrollY;

    disableBodyScroll(targetElement, options);
  };

  const makeEnabledBodyScroll = (
    targetElement: HTMLElement | Element,
    preventMoveToPosition?: true,
  ): void => {
    enableBodyScroll(targetElement);

    if (getIsIosDevice() && !preventMoveToPosition) window.scrollTo(0, prevYPosition.value);
  };

  return { makeDisabledBodyScroll, makeEnabledBodyScroll };
};
