import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetTagsListParams,
  IUpdateTagPayload,
  IUserCrmTagsApi,
} from '~/api/interfaces/user-crm-tags.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IUserTag } from '~/features/users/interfaces/user-tag.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmTagsApi => ({
  getTagsList(params: IGetTagsListParams): Promise<IItemsListWithTotalResponse<IUserTag>> {
    return $axios.$get('/users/admin/crm/userTags/getTagsList', { params });
  },

  getTagsByIds(ids: string[]): Promise<IUserTag[]> {
    return $axios.$post('users/admin/crm/userTags/getTagsByIds', { ids });
  },

  createTag(value: string): Promise<IUserTag> {
    return $axios.$post('/users/admin/crm/userTags/createTag', { value }, { localError: true });
  },

  updateTag(payload: IUpdateTagPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/userTags/updateTag', payload);
  },

  deleteTag(id: string): Promise<void> {
    // @ts-ignore
    return $axios.$post('/users/admin/crm/userTags/deleteTag', { id });
  },
});
