import { IMenuItemForAdmin } from '~/shared/interfaces/menu-item.interface';
import { OUTSTANDING_ARTISTS_SHOW_ID } from '~/features/tv-show/constants';
import { EUserPermission } from '~/features/users/enums/permissions.enum';

export const adminMenu: IMenuItemForAdmin[] = [
  {
    title: 'Pinned Posts',
    icon: 'user-menu/feed',
    to: '/admin/pinned-posts',
    access: [EUserPermission.ManageGlobalPinnedPosts],
  },
  {
    title: 'Social moderation',
    icon: 'user-menu/feed',
    access: [EUserPermission.SocialModeration],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Posts',
        to: '/admin/social-moderation/posts',
        access: [EUserPermission.SocialModeration],
      },
      {
        title: 'Comments',
        to: '/admin/social-moderation/comments',
        access: [EUserPermission.SocialModeration],
      },
      {
        title: 'Profiles',
        to: '/admin/social-moderation/profiles',
        access: [EUserPermission.SocialModeration],
      },
      {
        title: 'Communities',
        to: '/admin/social-moderation/communities',
        access: [EUserPermission.ManageCommunities],
      },
      {
        title: 'Demo Feed',
        to: '/admin/social-moderation/demo-feed',
        access: [EUserPermission.ManageDemoFeedUsers],
      },
    ],
  },
  {
    title: 'People',
    icon: 'user-menu/people',
    access: [EUserPermission.UserCrm],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Users',
        to: '/admin/users',
        access: [EUserPermission.UserCrm],
      },
      {
        title: 'Admins',
        to: '/admin/users/admins',
        access: [EUserPermission.UserCrm],
      },
    ],
  },
  {
    title: 'Mentoring',
    icon: 'user-menu/mentoring',
    access: [EUserPermission.ManageMentors],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Mentors',
        to: '/admin/mentoring/mentors',
        access: [EUserPermission.ManageMentors],
      },
      {
        title: 'Students',
        to: '/admin/mentoring/students',
        access: [EUserPermission.ManageMentors],
      },
    ],
  },
  {
    title: 'Products',
    icon: 'user-menu/products',
    access: [EUserPermission.EditCourses],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Courses',
        to: '/admin/courses',
        access: [EUserPermission.EditCourses],
      },
      {
        title: 'Offers',
        to: '/admin/offers',
        access: [EUserPermission.ManageOffers],
      },
      {
        title: 'Workshops',
        to: '/admin/workshops',
        access: [EUserPermission.ManageWorkshops],
      },
    ],
  },
  {
    title: 'Website',
    icon: 'user-menu/website',
    access: [EUserPermission.ManageBlog],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Blog',
        to: '/admin/blog',
        access: [EUserPermission.ManageBlog],
      },
    ],
  },
  {
    title: 'Art club',
    icon: 'user-menu/art-club',
    access: [EUserPermission.ManageArtClub],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Library',
        to: '/admin/art-club/videos',
        access: [EUserPermission.ManageArtClub],
      },
      {
        title: 'Contests',
        to: '/admin/art-club/contests',
        access: [EUserPermission.ManageArtContests],
      },
      {
        title: 'Reality Show',
        to: `/admin/art-club/tv-show/${OUTSTANDING_ARTISTS_SHOW_ID}`,
        access: [EUserPermission.ManageArtClub],
      },
      {
        title: 'Instructors',
        to: `/admin/art-club/instructors`,
        access: [EUserPermission.ManageArtClub],
      },
    ],
  },
  {
    title: 'Marketing',
    icon: 'user-menu/marketing',
    access: [EUserPermission.ManageBanners],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Banners',
        to: '/admin/banners',
        access: [EUserPermission.ManageBanners],
      },
    ],
  },
  {
    title: 'Payments',
    icon: 'sidebar-payments',
    access: [],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Products',
        to: '/products',
        access: [],
      },
      {
        title: 'Offers',
        to: '/offers',
        access: [],
      },
    ],
  },
  {
    title: 'Analytics',
    icon: 'sidebar-analytics',
    access: [],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Promocodes',
        to: '/promocodes',
        access: [],
      },
    ],
  },
  {
    title: 'Admin settings',
    icon: 'sidebar-settings',
    access: [],
    isExpandedByDefault: true,
    items: [
      {
        title: 'Promocodes',
        to: '/promocodes',
        access: [],
      },
    ],
  },
];
