import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IUserCrmOffersApi } from '~/api/interfaces/user-crm-offers.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IOfferForList } from '~/features/offers/interfaces/offer.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmOffersApi => ({
  getAll(): Promise<IItemsListResponse<IOfferForList>> {
    return $axios.$get('/users/admin/crm/offers/getAll');
  },
});
