import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';

export default defineNuxtMiddleware(({ route, store }) => {
  const isVisible = route?.meta?.[0]?.isCreatePostButtonVisible || false;
  store.commit('setIsCreatePostButtonVisible', isVisible);

  if (!isNil(route?.meta?.[0]?.isMobileBannerClosed))
    store.commit('setIsMobileBannerClosed', route?.meta?.[0]?.isMobileBannerClosed);
});
