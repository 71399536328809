




































































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { mapGetters, mapState } from 'vuex';
import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import { useSocialSidebarMenu } from '~/shared/composable/useSocialSidebarMenu';

export default defineComponent({
  name: 'AppMenu',
  props: {
    menuItems: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    guestMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const {
      getRoutePath,
      defaultActiveIndex,
      defaultOpenedIndexes,
      onMenuLinkClick,
    } = useSocialSidebarMenu(props.menuItems);

    return { getRoutePath, defaultActiveIndex, defaultOpenedIndexes, onMenuLinkClick };
  },
  computed: {
    ...mapGetters('users/messages', {
      unreadMessagesCount: 'unreadMessagesCount',
    }),

    ...mapState('notifications', {
      countOfJoinRequestsWhereUserIsModerator: 'countOfJoinRequestsWhereUserIsModerator',
    }),
  },
  methods: {
    onLinkClick(item: IMenuItem): void {
      this.$emit('click');

      this.onMenuLinkClick(item);
    },
  },
});
