





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { storeBadgeLinks } from '~/shared/config/store-badge-links';

type TStoreBadgeSize = 'large' | 'small';

export default defineComponent({
  name: 'StoreBadges',
  props: {
    size: {
      type: String as PropType<TStoreBadgeSize>,
      default: 'large',
    },
    reversedItems: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      storeBadgeLinks,
    };
  },
});
