export type TButtonType =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'secondary-white'
  | 'delete'
  | 'text'
  | 'text-weight-black'
  | 'table-navigation'
  | 'table-dropdown'
  | 'text-link'
  | 'cta-grey-small'
  | 'bg-red-grad-2'
  | 'bg-red-grad-1'
  | 'bg-teal-grad-2'
  | 'success'
  | 'user-action'
  | 'recommended-to-follow'
  | 'unfollow'
  | 'settings-resume'
  | 'secondary-red-border'
  | 'message-mentor'
  | 'post-action'
  | 'with-red-grad-border'
  | 'with-cyan-grad-border';

export const uiButtonTypes = [
  'default',
  'primary',
  'secondary',
  'secondary-white',
  'delete',
  'text',
  'text-weight-black',
  'table-navigation',
  'table-dropdown',
  'text-link',
  'cta-grey-small',
  'bg-red-grad-2',
  'bg-red-grad-1',
  'bg-teal-grad-2',
  'success',
  'user-action',
  'recommended-to-follow',
  'unfollow',
  'secondary-red-border',
  'message-mentor',
  'settings-resume',
  'post-action',
  'with-red-grad-border',
];
