export const getProp = (holder: any, propName: string): any => {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder;
  }
  if (propName in holder) {
    return holder[propName];
  }
  const propParts = Array.isArray(propName) ? propName : (propName + '').split('.');
  let result = holder;
  while (propParts.length && result) {
    result = result[propParts.shift()];
  }

  return result;
};
