
















import { defineComponent } from '@nuxtjs/composition-api';
import GuestModal from '~/shared/components/modals/GuestModal.vue';
import { useLayout } from '~/shared/composable/useLayout';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import MobileAppBanner from '~/shared/components/MobileAppBanner.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: { MobileAppBanner, UiAdaptive, GuestModal },
  setup() {
    const { layoutCssVars, isMobileBannerShown, onHideBannerClick } = useLayout();

    return {
      layoutCssVars,
      isMobileBannerShown,
      onHideBannerClick,
    };
  },
});
