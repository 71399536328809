import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(({ $cookies, req }) => {
  // https://github.com/nuxt/nuxt/issues/2914#issuecomment-369087470
  const userIp =
    (req?.headers['x-forwarded-for'] as string)?.split(',').pop() || // From proxy headers, can be spoofed if you don't have a proxy in front of your app, so drop it if your app is naked.
    req?.connection?.remoteAddress ||
    req?.socket?.remoteAddress || // socket is an alias to connection, just delete this line
    (req?.connection as any)?.socket?.remoteAddress;

  const referer = req?.headers?.Referer || req?.headers?.referer;

  $cookies.set('userIp', userIp, {
    maxAge: 60 * 60 * 24 * 30, // 30 days
    path: '/',
  });
  $cookies.set('referer', referer, {
    maxAge: 60 * 60 * 24 * 30, // 30 days
    path: '/',
  });
});
