import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api';
import { EArtClubRank } from '~/shared/enums/art-club-rank.enum';

export interface IUseArtClubRankCheck {
  isArtClubBasic: ComputedRef<boolean>;
  isArtClubPro: ComputedRef<boolean>;
  isArtClubPlus: ComputedRef<boolean>;
}

export const useArtClubRankCheck = (): IUseArtClubRankCheck => {
  const ctx = useContext();

  const checkCurrentRank = (rank: EArtClubRank): boolean =>
    ctx.$auth.$storage.state.artClubRank === rank;

  const isArtClubBasic = computed<boolean>(() => checkCurrentRank(EArtClubRank.Basic));
  const isArtClubPro = computed<boolean>(() => checkCurrentRank(EArtClubRank.Pro));
  const isArtClubPlus = computed<boolean>(() => checkCurrentRank(EArtClubRank.Plus));

  return {
    isArtClubBasic,
    isArtClubPro,
    isArtClubPlus,
  };
};
