import { computed, reactive } from '@nuxtjs/composition-api';
import { VForm } from '~/shared/types/form.type';
import { IUseFormValidation } from '~/shared/composable/useFormValidation/interfaces';

export function useFormValidation(rulesSchema: Record<string, any>): IUseFormValidation {
  const errors = reactive<Record<string, string | number>>(
    Object.fromEntries(Object.keys(rulesSchema).map((i) => [i, ''])),
  );

  const onValidate = (fieldName: string, isValid: boolean, returnError: string): void | string => {
    if (isValid) return clearError(fieldName);

    errors[fieldName] = returnError;

    return returnError;
  };

  const clearError = (fieldName: string): void => {
    errors[fieldName] = '';
  };

  const validate = async (form: VForm): Promise<boolean> => {
    return await form.validate();
  };

  const validateField = async (form: VForm, fieldName: string): Promise<void> => {
    return await form.validateField(fieldName);
  };

  const hasErrors = computed(() => {
    return Object.values(errors).some((error) => !!error);
  });

  return { onValidate, errors, hasErrors, validate, validateField };
}
