import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetNotifications,
  IGetNotificationsCount,
  INotificationsApi,
} from '~/api/interfaces/notifications.interface';

export default ($axios: NuxtAxiosInstance): INotificationsApi => ({
  getUnreadCount(): Promise<IGetNotificationsCount> {
    return $axios.$get('/notifications/getUnreadCount');
  },

  getNotifications(count = 1, fromId?: string): Promise<IGetNotifications> {
    return $axios.$get('/notifications/getNotifications', { params: { count, fromId } });
  },

  markAllAsReadFromId(fromId: string): Promise<void> {
    return $axios.$post('/notifications/markAllAsReadFromId', {
      fromId,
    });
  },

  markAsClicked(id: string): Promise<void> {
    return $axios.$post('/notifications/markAsClicked', {
      id,
    });
  },
});
