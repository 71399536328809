import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ICancelUserSubscriptionPayload,
  IGrantProductToUsersBySelectionPayload,
  IGrantProductToUsersByUserIdsPayload,
  IGrantAccessToProductPayload,
  IUserCrmProductsApi,
  IRevokeAccessToProductPayload,
  ICancelUserSubscriptionPlanPayload,
  IRefundPurchasePayload,
  IRefundUserSubscriptionPlanLastPaymentPayload,
  IRefundUserSubscriptionLastPaymentPayload,
} from '~/api/interfaces/user-crm-products.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IUserCrmProduct } from '~/features/users/interfaces/user-crm-product.interface';
import { TUserCrmListProduct } from '~/features/users/types/user-crm-list-product.type';
import { IPaymentsListItem } from '~/shared/interfaces/payments-list-item.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmProductsApi => ({
  getAllProducts(): Promise<IItemsListResponse<IUserCrmProduct>> {
    return $axios.$get('/users/admin/crm/products/getAllProducts');
  },

  grantProductToUser(payload: IGrantAccessToProductPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/grantProductToUser', payload, {
      localError: true,
    });
  },

  revokeAccessToProduct(payload: IRevokeAccessToProductPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/revokeAccessToProduct', payload);
  },

  cancelUserSubscription(payload: ICancelUserSubscriptionPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/cancelUserSubscription', payload);
  },

  cancelUserSubscriptionPlan(payload: ICancelUserSubscriptionPlanPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/cancelUserSubscriptionPlan', payload);
  },

  getAllUserProductsList(userId: string): Promise<IItemsListResponse<TUserCrmListProduct>> {
    return $axios.$get('/users/admin/crm/products/getAllUserProductsList', { params: { userId } });
  },

  grantProductToUsersByUserIds(payload: IGrantProductToUsersByUserIdsPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/grantProductToUsersByUserIds', payload);
  },

  revokeAccessToProductByUserIds(payload: IGrantProductToUsersByUserIdsPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/revokeAccessToProductByUserIds', payload);
  },

  grantProductToUsersBySelection(payload: IGrantProductToUsersBySelectionPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/grantProductToUsersBySelection', payload);
  },

  revokeAccessToProductBySelection(payload: IGrantProductToUsersBySelectionPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/revokeAccessToProductBySelection', payload);
  },

  refundUserPurchase(payload: IRefundPurchasePayload): Promise<void> {
    return $axios.$post('/users/admin/crm/products/refundUserPurchase', payload);
  },

  refundUserSubscriptionPlanLastPayment(
    payload: IRefundUserSubscriptionPlanLastPaymentPayload,
  ): Promise<void> {
    return $axios.$post('/users/admin/crm/products/refundUserSubscriptionPlanLastPayment', payload);
  },

  refundUserSubscriptionLastPayment(
    payload: IRefundUserSubscriptionLastPaymentPayload,
  ): Promise<void> {
    return $axios.$post('/users/admin/crm/products/refundUserSubscriptionLastPayment', payload);
  },

  getUserPaymentEntityAllPaymentsList(
    paymentEntityId: string,
    userId: string,
  ): Promise<IItemsListResponse<IPaymentsListItem>> {
    return $axios.$get('/users/admin/crm/products/getUserPaymentEntityAllPaymentsList', {
      params: { paymentEntityId, userId },
    });
  },
});
