

















import { defineComponent } from '@nuxtjs/composition-api';
import { IMenuItemForAdmin } from '~/shared/interfaces/menu-item.interface';
import { adminMenu } from '~/shared/config/admin-menu.config';
import UiAppLogo from '~/shared/components/UiAppLogo/index.vue';
import AppMenu from '~/shared/components/layouts/parts/menus/AppMenu.vue';
import { checkEnvValidity } from '~/utils/check-env-validity';
import { EUserPermission } from '~/features/users/enums/permissions.enum';

export default defineComponent({
  name: 'AdminSidebar',
  components: { AppMenu, UiAppLogo },
  computed: {
    navItems() {
      const isSomePermissionExist = (permissions: EUserPermission[] | undefined): boolean => {
        return !permissions ? false : permissions.some((p) => this.$auth.hasScope(p));
      };

      const reduceLinksArray = (linksArray: IMenuItemForAdmin[]): IMenuItemForAdmin[] => {
        return linksArray.reduce((accumulator: IMenuItemForAdmin[], current: IMenuItemForAdmin) => {
          if (
            !isSomePermissionExist(current?.access) ||
            !checkEnvValidity(this.$config, current.environmentsToHide)
          )
            return accumulator;

          return current?.items?.length
            ? [...accumulator, { ...current, items: reduceLinksArray(current.items) }]
            : [...accumulator, current];
        }, []);
      };

      return reduceLinksArray(adminMenu);
    },
  },
});
