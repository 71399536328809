import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IBlogBannerAdminApi,
  ICreateBlogBannerPayload,
  ISetBannerVisiblePayload,
  IUpdateBlogBannerPayload,
} from '~/api/interfaces/blog-banner-admin.interface';
import { IItemsListResponse } from '~/shared/interfaces/items-list.interface';
import { IBlogBannerListItemForAdmin } from '~/features/blog/interfaces/blog-banner-list-item.interface';
import { IBlogBannerItemForAdmin } from '~/features/blog/interfaces/blog-banner-item.interface';

export default ($axios: NuxtAxiosInstance): IBlogBannerAdminApi => ({
  getBannersList(): Promise<IItemsListResponse<IBlogBannerListItemForAdmin>> {
    return $axios.$get('/blog/banner/admin/getBannersList');
  },

  getBannerListItemById(id: string): Promise<IBlogBannerListItemForAdmin> {
    return $axios.$get('/blog/banner/admin/getBannerListItemById', { params: { id } });
  },

  getBannerById(id: string): Promise<IBlogBannerItemForAdmin> {
    return $axios.$get('/blog/banner/admin/getBannerById', { params: { id } });
  },

  createBanner(payload: ICreateBlogBannerPayload): Promise<string> {
    return $axios.$post('/blog/banner/admin/createBanner', payload);
  },

  updateBanner(payload: IUpdateBlogBannerPayload): Promise<void> {
    return $axios.$post('/blog/banner/admin/updateBanner', payload);
  },

  setBannerVisible(payload: ISetBannerVisiblePayload): Promise<void> {
    return $axios.$post('/blog/banner/admin/setBannerVisible', payload);
  },

  reorderItems(ids: string[]): Promise<void> {
    return $axios.$post('/blog/banner/admin/reorderItems', { ids });
  },

  deleteBanner(id: string): Promise<void> {
    return $axios.$post('/blog/banner/admin/deleteBanner', { id });
  },
});
