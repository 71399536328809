import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { isNil } from 'ramda';
import { validate } from 'uuid';
import { IUserPublicProfile } from '~/features/users/interfaces/users.interface';
import { getFullName } from '~/utils/get-full-name';

interface IProfileState {
  profile: IUserPublicProfile;
}

export const state = (): IProfileState => ({
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    username: '',
    image: null,
    backgroundImage: null,
    isFollowed: false,
    isDeleted: false,
    isOnline: false,
    isMyFollower: false,
    isBlocked: false,
    isBlockedGlobally: false,
    about: '',
    badges: [],
    data: {
      description: '',
      followersCount: 0,
      followedUsersCount: 0,
      coursesCount: 0,
      publicPostCount: 0,
      savedPostsCount: 0,
      mutual: [],
      mutualCount: 0,
      portfolioItemsCount: 0,
    },
  },
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  isPrivateProfile(state): boolean {
    return isNil(state.profile.data);
  },

  isDeleted(state): boolean {
    return state.profile.isDeleted;
  },

  fullName(state): string {
    return getFullName(state.profile);
  },

  about(state): string {
    return state.profile.about;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getUserProfile(ctx, userId: string) {
    const response = await this.$api.users.getPublicProfile(
      validate(userId) ? { userId } : { username: userId },
    );

    ctx.commit('setProfile', response);
  },
};

export const mutations: MutationTree<RootState> = {
  setProfile(state, profile) {
    state.profile = profile;
  },

  changeBlockedState(state, isBlocked: boolean) {
    state.profile.isBlocked = isBlocked;
  },

  updateMutualData(state, { mutual, mutualCount }) {
    state.profile.data.mutual = mutual;
    state.profile.data.mutualCount = mutualCount;
  },
};
