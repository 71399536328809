import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IChangeUserEmailPayload,
  IChangeUserPasswordPayload,
  IExportDataParams,
  IGetUsersListParams,
  IUserCrmApi,
} from '~/api/interfaces/user-crm.interface';
import { IUserProfileForCrm } from '~/features/users/interfaces/users.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';

export default ($axios: NuxtAxiosInstance): IUserCrmApi => ({
  getUserListItemById(id: string): Promise<IUserProfileForCrm> {
    return $axios.$get('/users/admin/crm/getUserListItemById', { params: { id } });
  },

  getUsersList(
    params: IGetUsersListParams,
  ): Promise<IItemsListWithTotalResponse<IUserProfileForCrm>> {
    return $axios.$post('/users/admin/crm/getUsersList', params);
  },

  deleteUser(userId: string): Promise<void> {
    return $axios.$post('/users/admin/crm/deleteUser', { userId });
  },

  sendResetPasswordEmail(userId: string): Promise<void> {
    return $axios.$post('/users/admin/crm/sendResetPasswordEmail', { userId });
  },

  exportDataBySelection(request: IExportDataParams): Promise<{ fileUrl: string }> {
    return $axios.$get('/users/admin/crm/exportDataBySelection', {
      params: { request: btoa(JSON.stringify(request)) },
    });
  },

  exportDataByUserIds(userIds: string[]): Promise<{ fileUrl: string }> {
    return $axios.$get('/users/admin/crm/exportDataByUserIds', {
      params: {
        request: btoa(
          JSON.stringify({
            userIds,
          }),
        ),
      },
    });
  },

  changeUserPassword(params: IChangeUserPasswordPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/changeUserPassword', params);
  },

  changeUserEmail(params: IChangeUserEmailPayload): Promise<void> {
    return $axios.$post('/users/admin/crm/changeUserEmail', params, { localError: true });
  },
});
