import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api';
import { EMembership } from '~/features/auth/enums/membership.enum';

export interface IUseMembershipsCheck {
  isArtClubMembership: ComputedRef<boolean>;
  isMentoringMembership: ComputedRef<boolean>;
  isProLibraryMembership: ComputedRef<boolean>;
  isMasteryProgramMembership: ComputedRef<boolean>;
}

export const useMembershipsCheck = (): IUseMembershipsCheck => {
  const ctx = useContext();

  const isMembershipExist = (membership: EMembership): boolean =>
    !!ctx.$auth.$storage.state.memberships?.includes(membership);

  const isArtClubMembership = computed<boolean>(() => isMembershipExist(EMembership.ArtClub));

  const isMentoringMembership = computed<boolean>(() => isMembershipExist(EMembership.Mentoring));

  const isProLibraryMembership = computed<boolean>(() => isMembershipExist(EMembership.ProLibrary));

  const isMasteryProgramMembership = computed<boolean>(() =>
    isMembershipExist(EMembership.MasteryProgram),
  );

  return {
    isArtClubMembership,
    isMentoringMembership,
    isProLibraryMembership,
    isMasteryProgramMembership,
  };
};
