import type dayjs from 'dayjs';
import { isNil } from 'ramda';
import { IUserPost } from '~/features/profile/interfaces/user-post.interface';
import { FULL_DATE_FOR_LAST_MODIFIED } from '~/shared/constants/time-formats.constant';
import { TUserPostItemForFeed } from '~/features/profile/types/user-post-item-for-feed.type';

export const getLastModifiedFormattedDate = (date: string, $dayjs: typeof dayjs): string =>
  $dayjs(date).tz('GMT').format(FULL_DATE_FOR_LAST_MODIFIED);

export const getPostLastModifiedFormattedDate = (post: IUserPost, $dayjs: typeof dayjs): string => {
  return getLastModifiedFormattedDate(post?.updatedAt || post?.publishedAt, $dayjs);
};

interface IGetLastModifiedDateOfLastPost {
  posts: TUserPostItemForFeed[];
  $dayjs: typeof dayjs;
}

export const getLastModifiedDateForFeed = ({
  posts,
  $dayjs,
}: IGetLastModifiedDateOfLastPost): string => {
  const firstPost = posts?.[0];
  if (isNil(firstPost)) return '';

  const secondPost = posts?.[1];

  return firstPost?.post?.isGlobalPinnedPost && secondPost
    ? getPostLastModifiedFormattedDate(secondPost.post, $dayjs)
    : getPostLastModifiedFormattedDate(firstPost.post, $dayjs);
};
