<script>
import { defineComponent, h } from '@nuxtjs/composition-api';
import { MOBILE_WIDTH } from '~/shared/constants';

export default defineComponent({
  name: 'UiAdaptive',

  props: {
    mobileWidth: {
      type: Number,
      default: MOBILE_WIDTH,
    },
  },

  data: () => ({
    isMobile: null,
  }),

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = this.$isMobile(this.mobileWidth);
    },
  },

  render() {
    if (this.isMobile === null) return h('template');

    return this.isMobile ? this.$slots.mobile : this.$slots.desktop;
  },
});
</script>
