export enum ECommonBackendErrorCode {
  NotFound = 'NOT_FOUND',
  CannotPerformThisAction = 'CANNOT_PERFORM_THIS_ACTION',
  ArtClubAlreadySubscribed = 'ART_CLUB_ALREADY_SUBSCRIBED',
  ArtClubSubscriptionPaymentRetrieveDataFailed = 'ART_CLUB_SUBSCRIPTION_PAYMENT_RETRIEVE_DATA_FAILED',
  EmailIsAlreadyConfirmed = 'EMAIL_ALREADY_CONFIRMED',
  EmailIsAlreadyTaken = 'EMAIL_ALREADY_TAKEN',
}

export enum EAuthBackendErrorCode {
  AuthFailedUserIsBlocked = 'AUTH_FAILED_USER_IS_BLOCKED',
  LoginFailedEmailConfirmationRequired = 'LOGIN_FAILED_EMAIL_CONFIRMATION_REQUIRED',
  LoginFailedUserNotFound = 'LOGIN_FAILED_USER_NOT_FOUND',
  LoginFailedPasswordIsIncorrect = 'LOGIN_FAILED_PASSWORD_IS_INCORRECT',
  LoginFailedPasswordWasNotSetUp = 'LOGIN_FAILED_PASSWORD_WAS_NOT_SET_UP',
  SignUpFailedEmailAlreadyUsed = 'SIGN_UP_FAILED_EMAIL_ALREADY_USED',
  SignUpEmailDomainValidationFailed = 'SIGN_UP_EMAIL_DOMAIN_VALIDATION_FAILED',
}
