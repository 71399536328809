import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetCategoriesResponse,
  IGetVideoListParams,
  IGetVimeoVideoInfoParams,
  ISaveVideoPayload,
} from '~/api/interfaces/art-club-videos-admin.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import {
  IArtClubTableVideo,
  IArtClubVideo,
} from '~/features/art-club/interfaces/art-club-video.interface';
import { IArtClubVideoInfo } from '~/features/art-club/interfaces/art-club-video-info.interface';
import { IArtClubVideoStatistic } from '~/features/art-club/interfaces/art-club-video-statistic.interface';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';

export default ($axios: NuxtAxiosInstance): any => ({
  getVideosList(
    params: IGetVideoListParams,
  ): Promise<IItemsListWithTotalResponse<IArtClubTableVideo>> {
    return $axios.$get('/artClub/media/admin/getVideosList', { params });
  },

  createVideo(vimeoVideoUrl: string): Promise<IEntityWithId> {
    return $axios.$post('/artClub/media/admin/createVideo', { vimeoVideoUrl });
  },

  getVideoDetails(id: string): Promise<IArtClubVideo> {
    return $axios.$get('/artClub/media/admin/getVideoDetails', { params: { id } });
  },

  getVideoStatistics(id: string): Promise<IArtClubVideoStatistic> {
    return $axios.$get('/artClub/media/admin/getVideoStatistics', { params: { id } });
  },

  saveVideo(payload: ISaveVideoPayload): Promise<ISaveVideoPayload> {
    return $axios.$post('/artClub/media/admin/saveVideo', payload);
  },

  getCategories(): Promise<IGetCategoriesResponse> {
    return $axios.$get('/artClub/media/admin/getCategories');
  },

  getVimeoVideoInfo(params: IGetVimeoVideoInfoParams): Promise<IArtClubVideoInfo> {
    return $axios.$get('/artClub/media/admin/getVimeoVideoInfo', { params });
  },
});
