import { computed, ComputedRef, useContext, useRoute } from '@nuxtjs/composition-api';
import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import { TAuthUser } from '~/features/auth/types/auth-user.type';

interface ISocialSidebarMenu {
  getRoutePath(item: IMenuItem): Record<string, any>;
  defaultActiveIndex: ComputedRef<string>;
  defaultOpenedIndexes: ComputedRef<string[]>;
  onMenuLinkClick(item: IMenuItem): void;
}

export const useSocialSidebarMenu = (menuItems: IMenuItem[]): ISocialSidebarMenu => {
  const ctx = useContext();
  const route = useRoute();

  const currentUserId = computed<string>(
    () => (ctx.$auth.user as TAuthUser)?.username || (ctx.$auth?.user as TAuthUser)?.id,
  );

  const replaceUserIdInPath = (path: string): string =>
    path.replace(':userId', currentUserId.value || '');

  const getRoutePath = (item: IMenuItem): Record<string, any> => {
    const withDynamicParams = replaceUserIdInPath(item?.to || '');
    const routePath = { path: ctx.localePath(withDynamicParams) };

    if (item.isFeedLink && route.value.path === item.to && process.client && ctx.$isMobile()) {
      return { ...routePath, query: { ...route.value.query } };
    }

    return routePath;
  };

  const defaultActiveIndex = computed((): string => {
    const currentPathWithoutPrefix = route.value.fullPath;

    return menuItems
      .findIndex((item) => {
        if (!item.to) return false;

        const withDynamicParams = replaceUserIdInPath(item.to);

        return currentPathWithoutPrefix.includes(withDynamicParams);
      })
      .toString();
  });

  const defaultOpenedIndexes = computed((): string[] =>
    menuItems.reduce((arr: string[], curr, index) => {
      if (curr.isExpandedByDefault) arr[arr.length] = `${index}`;

      return arr;
    }, []),
  );

  const onMenuLinkClick = (item: IMenuItem): void => {
    if (!item?.isFeedLink || route.value.path !== item.to) return;

    if (item.isFeedLink && !ctx.$isMobile()) {
      ctx.$bus.emit('refresh-feed');
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return {
    getRoutePath,
    defaultActiveIndex,
    defaultOpenedIndexes,
    onMenuLinkClick,
  };
};
