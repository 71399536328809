import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { omit } from 'ramda';
import { IWorkshopForAdmin } from '~/features/workshop/interfaces/workshop.interface';
import { EWorkshopStatus } from '~/features/workshop/enums/workshop-status.enum';
import { ICreateWorkshopResponse } from '~/api/interfaces/workshop-admin.interface';
import { DEFAULT_ADMIN_TIMEZONE } from '~/shared/constants';
import { getDefaultFileState } from '~/utils/get-default-image-state';

interface ISetBackgroundImagePayload {
  backgroundImageUrl: string;
  backgroundImageId: string;
}

export interface IState {
  workshop: IWorkshopForAdmin;
  backgroundImageId: string;
  isStateChanged: boolean;
}

const prepareUrl = (url: string): string => {
  const isUrlEndsOnRightChar = /[0-9a-zA-Z]$/.test(url);

  if (isUrlEndsOnRightChar) return url;

  return prepareUrl(url.slice(0, url.length - 1));
};

const getDefaultState = (): IState => ({
  workshop: {
    id: '',
    heading: '',
    internalTitle: '',
    status: EWorkshopStatus.Draft,
    description: '',
    liveStreamUrl: '',
    backgroundImage: null,
    startDate: '',
    createdAt: '',
  },
  backgroundImageId: '',
  isStateChanged: false,
});

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async getWorkshop(ctx, id: string) {
    const response = await this.$api.workshopAdmin.getWorkshopById(id);

    ctx.commit('setWorkshop', {
      ...response,
      startDate: response.startDate
        ? this.$dayjs(response.startDate)
            .tz(DEFAULT_ADMIN_TIMEZONE)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
            .format()
        : undefined,
    });
  },

  createWorkshop(ctx): Promise<ICreateWorkshopResponse> {
    ctx.commit('setIsStateChanged', false);

    return this.$api.workshopAdmin.createWorkshop({
      ...omit(['backgroundImage', 'createdAt', 'id', 'status'], ctx.state.workshop),
      backgroundImageId: ctx.state.backgroundImageId,
      liveStreamUrl: ctx.state.workshop.liveStreamUrl
        ? prepareUrl(ctx.state.workshop.liveStreamUrl)
        : null,
      startDate: ctx.state.workshop.startDate
        ? this.$dayjs(ctx.state.workshop.startDate).tz(DEFAULT_ADMIN_TIMEZONE, true).format()
        : undefined,
    });
  },

  async updateWorkshop(ctx): Promise<void> {
    await this.$api.workshopAdmin.updateWorkshop({
      ...omit(['backgroundImage', 'createdAt', 'status'], ctx.state.workshop),
      backgroundImageId: ctx.state.backgroundImageId?.length
        ? ctx.state.backgroundImageId
        : undefined,
      liveStreamUrl: ctx.state.workshop.liveStreamUrl
        ? prepareUrl(ctx.state.workshop.liveStreamUrl)
        : null,
      startDate: ctx.state.workshop.startDate
        ? this.$dayjs(ctx.state.workshop.startDate).tz(DEFAULT_ADMIN_TIMEZONE, true).format()
        : undefined,
    });

    ctx.commit('setIsStateChanged', false);
  },
};

export const mutations: MutationTree<RootState> = {
  setWorkshop(state, workshop: IWorkshopForAdmin) {
    state.workshop = workshop;
  },

  setHeading(state, heading: string) {
    state.workshop.heading = heading;
    state.isStateChanged = true;
  },

  setInternalTitle(state, internalTitle: string) {
    state.workshop.internalTitle = internalTitle;
    state.isStateChanged = true;
  },

  setDescription(state, description) {
    state.workshop.description = description;
    state.isStateChanged = true;
  },

  setLiveStreamUrl(state, liveStreamUrl) {
    state.workshop.liveStreamUrl = liveStreamUrl;
    state.isStateChanged = true;
  },

  setStartDate(state, startDate) {
    state.workshop.startDate = startDate;
    state.isStateChanged = true;
  },

  setImage(state, payload: ISetBackgroundImagePayload) {
    state.workshop.backgroundImage = state.workshop.backgroundImage ?? getDefaultFileState();
    state.workshop.backgroundImage.url = payload.backgroundImageUrl;
    state.backgroundImageId = payload.backgroundImageId;

    state.isStateChanged = true;
  },

  setIsStateChanged(state, isStateChanged) {
    state.isStateChanged = isStateChanged;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IState, RootState> = {
  isEditWorkshopPage(state) {
    return !!state.workshop.id?.length;
  },
};
