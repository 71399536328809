import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IAddSeriesVideoPayload,
  IArtClubSeasonsAdminApi,
  ICreateSeasonPartPayload,
  ICreateSeasonPayload,
  IMoveSeasonPartPayload,
  IMoveSeasonPayload,
  IMoveSeriesVideoPayload,
  ISearchVideosParams,
  IUpdateSeasonPartPayload,
  IUpdateSeasonPayload,
} from '~/api/interfaces/art-club-seasons-admin.interface';
import { ISeriesForAdmin } from '~/features/tv-show/interfaces/series.interface';
import { ISeriesVideoForAdminSearch } from '~/features/tv-show/interfaces/series-video.interface';
import { IItemsListWithTotalResponse } from '~/shared/interfaces/items-list.interface';
import { IEntityWithId } from '~/shared/interfaces/entity-with-id.interface';

export default ($axios: NuxtAxiosInstance): IArtClubSeasonsAdminApi => ({
  getSeriesContentsById(id: string): Promise<ISeriesForAdmin> {
    return $axios.$get('/artClub/media/admin/videoSeries/getSeriesContentsById', {
      params: { id },
    });
  },

  searchVideos(
    params: ISearchVideosParams,
  ): Promise<IItemsListWithTotalResponse<ISeriesVideoForAdminSearch>> {
    return $axios.$get('/artClub/media/admin/videoSeries/searchVideos', { params });
  },

  createSeries(title: string): Promise<IEntityWithId> {
    return $axios.$post('/artClub/media/admin/videoSeries/createSeries', { title });
  },

  createSeason(payload: ICreateSeasonPayload): Promise<IEntityWithId> {
    return $axios.$post('/artClub/media/admin/videoSeries/createSeason', payload);
  },

  updateSeason(payload: IUpdateSeasonPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/updateSeason', payload);
  },

  moveSeason(payload: IMoveSeasonPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/moveSeason', payload);
  },

  deleteSeason(seasonId: string): Promise<void> {
    return $axios.$post(
      '/artClub/media/admin/videoSeries/deleteSeason',
      { seasonId },
      { localError: true },
    );
  },

  createSeasonPart(payload: ICreateSeasonPartPayload): Promise<IEntityWithId> {
    return $axios.$post('/artClub/media/admin/videoSeries/createSeasonPart', payload);
  },

  updateSeasonPart(payload: IUpdateSeasonPartPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/updateSeasonPart', payload);
  },

  moveSeasonPart(payload: IMoveSeasonPartPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/moveSeasonPart', payload);
  },

  deleteSeasonPart(partId: string): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/deleteSeasonPart', { partId });
  },

  addSeriesVideo(payload: IAddSeriesVideoPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/addSeriesVideo', payload);
  },

  moveSeriesVideo(payload: IMoveSeriesVideoPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/moveSeriesVideo', payload);
  },

  removeSeriesVideo(payload: IAddSeriesVideoPayload): Promise<void> {
    return $axios.$post('/artClub/media/admin/videoSeries/removeSeriesVideo', payload);
  },
});
