import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export interface IState {
  isMobileBannerPositionLocked: boolean;
  isMobileBannerClosed: boolean;
  isCreatePostButtonVisible: boolean;
  sessionId: string;
  isSessionExpired: boolean;
  isConfirmationEmailModalShown: boolean;
}

const getDefaultState = (): IState => ({
  isMobileBannerPositionLocked: false,
  isMobileBannerClosed: false,
  isCreatePostButtonVisible: false,
  sessionId: '',
  isSessionExpired: false,
  isConfirmationEmailModalShown: false,
});

export const state = (): IState => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit(ctx) {
    const cookieSessionId = this.$cookies.get('sessionId');

    if (cookieSessionId) ctx.commit('setSessionId', cookieSessionId);
    else {
      const sessionId = uuidv4();
      this.$cookies.set('sessionId', sessionId);
      ctx.commit('setSessionId', sessionId);
    }

    if (!this.$auth.loggedIn) return;
    await ctx.dispatch('feature-access/getAccessInfo', undefined, { root: true });
  },
};

export const mutations: MutationTree<RootState> = {
  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
  setIsMobileBannerPositionLocked(state, isMobileBannerPositionLocked: boolean) {
    state.isMobileBannerPositionLocked = isMobileBannerPositionLocked;
  },

  setIsMobileBannerClosed(state, isMobileBannerClosed: boolean) {
    state.isMobileBannerClosed = isMobileBannerClosed;
  },

  setIsCreatePostButtonVisible(state, isCreatePostButtonVisible: boolean) {
    state.isCreatePostButtonVisible = isCreatePostButtonVisible;
  },

  setIsSessionExpired(state, isSessionExpired: boolean) {
    state.isSessionExpired = isSessionExpired;
  },

  setIsConfirmationEmailModalShown(state, isConfirmationEmailModalShown: boolean) {
    state.isConfirmationEmailModalShown = isConfirmationEmailModalShown;
  },
};

export const getters: GetterTree<IState, RootState> = {};
