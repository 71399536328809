import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IGetMentoringInfoResponse,
  IMentoringStudentsApi,
} from '~/api/interfaces/mentoring-students.interface';

export default ($axios: NuxtAxiosInstance): IMentoringStudentsApi => ({
  getMentoringInfo(): Promise<IGetMentoringInfoResponse> {
    return $axios.$get('/mentoring/student/getMentoringInfo');
  },

  setFirstTimePassed(): Promise<void> {
    return $axios.$post('/mentoring/student/setFirstTimePassed');
  },
});
