import { IUserName } from '~/features/users/interfaces/users.interface';

export function getInitials<T extends IUserName>(user: T): string {
  return `${user?.firstName?.charAt(0) || ''}${user?.lastName?.charAt(0) || ''}`.trim();
}

export function getInitialsFromFullName(fullName = ''): string {
  return fullName
    .split(' ')
    .slice(0, 2)
    .map((part) => part.charAt(0))
    .join('');
}
