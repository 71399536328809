import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IArtClubSeasonsApi } from '~/api/interfaces/art-club-seasons.interface';
import { ISeriesForUser } from '~/features/tv-show/interfaces/series.interface';
import { ISeasonForUserWithParts } from '~/features/tv-show/interfaces/season.interface';
import { IPartForUserContents } from '~/features/tv-show/interfaces/part.inteface';

export default ($axios: NuxtAxiosInstance): IArtClubSeasonsApi => ({
  getSeriesContents(seriesId: string): Promise<ISeriesForUser> {
    return $axios.$get('/artClub/media/videoSeries/getSeriesContents', { params: { seriesId } });
  },

  getSeasonContents(seasonId: string): Promise<ISeasonForUserWithParts> {
    return $axios.$get('/artClub/media/videoSeries/getSeasonContents', { params: { seasonId } });
  },

  getPartContents(partId: string): Promise<IPartForUserContents> {
    return $axios.$get('/artClub/media/videoSeries/getPartContents', { params: { partId } });
  },
});
