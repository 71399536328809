
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import UiModal from '~/shared/components/modals/UiModal.vue';
import LoginForm from '~/features/auth/components/LoginForm.vue';
import RegistrationForm from '~/features/auth/components/RegistrationForm.vue';
import BlockedUserNotificationModal from '~/shared/components/modals/BlockedUserNotificationModal.vue';
import { IResponseErrorDataBlocked } from '~/shared/interfaces/response.interface';
import ForgotPasswordModal from '~/features/auth/components/ForgotPasswordModal.vue';

export enum ENonRegisteredFormComponentTag {
  Login = 'LoginForm',
  Register = 'RegistrationForm',
}

export default defineComponent({
  name: 'GuestModal',
  components: {
    BlockedUserNotificationModal,
    LoginForm,
    RegistrationForm,
    UiModal,
    ForgotPasswordModal,
  },
  props: {
    defaultActiveFormTag: {
      type: String as PropType<ENonRegisteredFormComponentTag>,
      default: ENonRegisteredFormComponentTag.Login,
    },
  },
  emits: ['submit'],

  data() {
    return {
      activeFormTag: this.defaultActiveFormTag,
    };
  },
  computed: {
    modalTitle(): TranslateResult {
      return this.activeFormTag === ENonRegisteredFormComponentTag.Login
        ? this.$t('common.guestModal.login.title')
        : this.$t('common.guestModal.register.title');
    },
  },
  mounted() {
    this.$bus.on('open-login', this.loginEmit);
    this.$bus.on('open-register', this.registerEmit);
  },
  beforeDestroy() {
    this.$bus.off('open-login', this.loginEmit);
    this.$bus.off('open-register', this.registerEmit);
  },
  methods: {
    loginEmit(): void {
      this.onLoginClick();
      this.show();
    },

    registerEmit(): void {
      this.onRegisterClick();
      this.show();
    },

    show(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).show();
    },
    hide(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).hide();
    },

    onRegisterClick(): void {
      this.activeFormTag = ENonRegisteredFormComponentTag.Register;
    },

    onLoginClick(): void {
      this.activeFormTag = ENonRegisteredFormComponentTag.Login;
    },

    onLoginSubmit(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).hide();

      this.$emit('submit');
    },

    onRegisterSubmit(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).hide();
    },

    onBlocked(data: IResponseErrorDataBlocked): void {
      this.hide();
      (this.$refs.blockedModal as InstanceType<typeof BlockedUserNotificationModal>).show(data);
    },

    onForgotClick(): void {
      (this.$refs.forgotPasswordModal as InstanceType<typeof ForgotPasswordModal>).show();
    },
  },
});
