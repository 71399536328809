import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { set } from 'vue';
import { isNil, reduce } from 'ramda';
import { NuxtApp } from '@nuxt/types/app';
import { getInitials } from '~/utils/get-initials';
import { IChat, IChatExtras } from '~/features/messages/interfaces/chat.interface';
import {
  IChatMessage,
  IChatMessageContent,
} from '~/features/messages/interfaces/chat-message.interface';
import { IChatData } from '~/features/messages/interfaces/chat-data.interface';
import { DEFAULT_NAVIGATION_CHATS_COUNT } from '~/features/messages/constant/navigation-chats.constant';
import { EChatType } from '~/features/messages/enums/chat-type.enum';
import { DEFAULT_INITIAL_MESSAGES_COUNT } from '~/features/messages/constant/initial-messages-count.constant';
import { IChatParticipant } from '~/features/messages/interfaces/chat-participant.interface';
import { DEFAULT_CHAT_PARTICIPANTS_COUNT } from '~/features/messages/constant/chat-participants-count.constant';
import {
  IChatNotification,
  IChatNotificationNewMessage,
} from '~/features/notifications/interfaces/chat-notification-message.interface';
import { EChatNotificationType } from '~/features/notifications/enums/chat-notification-type.enum';
import { getFullName } from '~/utils/get-full-name';
import { TChatMessageGroupItem } from '~/features/messages/types/chat-message-group-item.type';
import { EChatMessageGroupItem } from '~/features/messages/enums/chat-message-group-item.enum';
import { DATE_MONTH, DATE_MONTH_YEAR } from '~/shared/constants/time-formats.constant';
import { IChatMessageGroupItemMessage } from '~/features/messages/interfaces/chat-message-group-item.interface';
import { IAuth } from '~/features/auth/interfaces/auth.interface';
import { IChatPermissions } from '~/features/messages/interfaces/chat-permissions.interface';
import { IFileExtras } from '~/features/files/interfaces/file.interface';
import { EMessageTag } from '~/features/messages/enums/message-tag.enum';

export interface IChatDataCached {
  chatId: string;
  chatTitle: string;
  remoteMaxRowVersion: number;
  remoteMaxMessageIndex: number;
  localMaxRowVersion: number;
  localMinMessageIndex: number;
  localMaxMessageIndex: number;
  messages: IChatMessage[];
  isDirectChatInterlocutorOnline: boolean;
  isDirectChatInterlocutorDeleted: boolean;
  extras: IChatExtras;
}

interface INewLocalIndexesPayload {
  chatId: string;
  data: {
    localMaxRowVersion?: number;
    localMinMessageIndex?: number;
    localMaxMessageIndex?: number;
  };
}

interface INewRemoteIndexesPayload {
  chatId: string;
  data: {
    remoteMaxRowVersion: number;
    remoteMaxMessageIndex: number;
  };
}

interface IUpdatedMessagesPayload {
  chatId: string;
  messages: IChatMessage[];
}

interface IChangeCompanionBlockedStatusPayload {
  userId: string;
  isBlocked: boolean;
}

interface ISetChatPermissionsPayload {
  chatId: string;
  permissions: IChatPermissions;
}

export interface IState {
  navigationChatsOffset: number;
  selectedChatId: string;
  hasChatMoreHistory: boolean;
  navigationChats: IChat[];
  cachedChatsData: Record<string, IChatDataCached>;
  cachedUsersInfos: Record<string, IChatParticipant>;
  isLoadingProcessing: boolean;
  isLoadingPending: boolean;
}

export const state = (): IState => ({
  navigationChatsOffset: 0,
  selectedChatId: '',
  hasChatMoreHistory: true,
  navigationChats: [],
  cachedChatsData: {},
  cachedUsersInfos: {},
  isLoadingProcessing: false,
  isLoadingPending: false,
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  isChatSelected(state): boolean {
    return !!state.selectedChatId?.length;
  },

  isSelectedChatInNavigation(state): boolean {
    return !!state.navigationChats.find((chat) => chat.id === state.selectedChatId);
  },

  isSelectedChatCached(state): boolean {
    return !!state.cachedChatsData[state.selectedChatId];
  },

  isSelectedGroupChat(_state, getters): boolean {
    return getters.selectedChatInfo?.type === EChatType.Group;
  },

  isSelectedEmptyChat(_state, getters): boolean {
    return !getters.selectedChatData?.messages.length;
  },

  selectedChatInfo(state) {
    return state.navigationChats.find((chat) => chat.id === state.selectedChatId);
  },

  selectedChatData(state): IChatDataCached | undefined {
    return state.cachedChatsData[state.selectedChatId] || undefined;
  },

  selectedChatMessagesGroups: (state) => (ctx: NuxtApp): TChatMessageGroupItem[][] => {
    if (!state.selectedChatId) return [];

    const currentMessages: IChatMessage[] = state.cachedChatsData[
      state.selectedChatId
    ]?.messages.filter((message) => !message.isDeleted);

    return currentMessages.reduce(
      (accumulator: TChatMessageGroupItem[][], message) => {
        let lastGroup = accumulator[accumulator.length - 1];
        const lastItem = lastGroup[lastGroup.length - 1];

        const isFirstGroup = accumulator.length === 1;
        const isLastItemExist = !isNil(lastItem);
        const isLastItemTypeMessage = lastItem?.type === EChatMessageGroupItem.Message;

        const isTheSameDay = isLastItemExist
          ? isLastItemTypeMessage
            ? ctx
                .$dayjs((lastItem as IChatMessageGroupItemMessage).message.createdAt)
                .isSame(message.createdAt, 'date')
            : true
          : false;

        if (!isTheSameDay) {
          const isToday = ctx.$dayjs(message.createdAt).isToday();
          const isTheSameYear = ctx.$dayjs(message.createdAt).isSame(new Date(), 'year');

          accumulator[isFirstGroup ? 0 : accumulator.length] = [
            {
              type: EChatMessageGroupItem.Date,
              date: isToday
                ? ctx.$tc('common.today')
                : ctx
                    .$dayjs(message.createdAt)
                    .format(isTheSameYear ? DATE_MONTH : DATE_MONTH_YEAR),
            },
          ];

          lastGroup = accumulator[accumulator.length] = [];
        }

        const isTheSameSender =
          isLastItemTypeMessage &&
          (lastItem as IChatMessageGroupItemMessage)?.message.senderId === message.senderId;

        if (isLastItemExist && (!isTheSameSender || !isTheSameDay))
          lastGroup = accumulator[accumulator.length] = [];

        lastGroup[lastGroup.length] = {
          type: EChatMessageGroupItem.Message,
          message,
        };

        return accumulator;
      },
      [[]],
    );
  },

  getSingleChatCompanion: (state) => ($auth: IAuth): IChatParticipant => {
    return Object.values(state.cachedUsersInfos).find(
      (info) => info.userId !== $auth.user.id,
    ) as IChatParticipant;
  },

  chatsExist(state): boolean {
    return !!state.navigationChats?.length;
  },

  currentMessageUserImageUrl: (state) => (messageSenderId: string): string | null | undefined => {
    return state.cachedUsersInfos[messageSenderId]?.user?.profileImage?.url;
  },

  currentMessageUserImageExtras: (state) => (messageSenderId: string): IFileExtras | undefined => {
    return state.cachedUsersInfos[messageSenderId]?.user?.profileImage?.extras;
  },

  isUnreadChats(state): boolean {
    return state.navigationChats?.some((chat) => chat.unreadMessageCount >= 1);
  },

  getUserFullName: (state) => (senderId: string): string | undefined => {
    if (!state.cachedUsersInfos[senderId]) return undefined;

    return getFullName(state.cachedUsersInfos[senderId].user);
  },
  getUserInitials: (state) => (senderId: string): string | undefined => {
    if (!state.cachedUsersInfos[senderId]) return undefined;

    return getInitials(state.cachedUsersInfos[senderId].user);
  },

  checkIsUserDeleted: (state) => (senderId: string): boolean | undefined => {
    return state.cachedUsersInfos[senderId]?.user?.isDeleted;
  },

  unreadMessagesCount(state): number {
    return reduce((a, b) => a + b.unreadMessageCount, 0, state.navigationChats);
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getChats(ctx) {
    const { items } = await this.$api.chats.getChats({
      count: DEFAULT_NAVIGATION_CHATS_COUNT,
      offset: ctx.state.navigationChatsOffset,
    });

    if (!items) return;

    ctx.commit('setNavigationChats', items);

    for (const chat of items) {
      ctx.commit('createCachedChatData', chat);
    }
  },

  async getMoreChats(ctx) {
    ctx.commit(
      'setNavigationChatsOffset',
      ctx.state.navigationChatsOffset + DEFAULT_NAVIGATION_CHATS_COUNT,
    );

    await ctx.dispatch('getChats');
  },

  async getNavigationChatsUpdates(ctx) {
    const { items } = await this.$api.chats.getChats({
      count: ctx.state.navigationChatsOffset + DEFAULT_NAVIGATION_CHATS_COUNT,
      offset: 0,
    });

    ctx.commit('setNavigationChats', items);
  },

  async getChatByName(ctx, query: string) {
    ctx.commit('setNavigationChatsOffset', 0);

    const { items } = await this.$api.chats.getChats({
      query,
      count: DEFAULT_NAVIGATION_CHATS_COUNT,
      offset: ctx.state.navigationChatsOffset,
    });

    ctx.commit('setNavigationChats', items);
  },

  async getSelectedChat(ctx) {
    const chat = await this.$api.chats.getChatById(ctx.state.selectedChatId);

    if (!ctx.getters.isSelectedChatInNavigation) {
      ctx.commit('prependNewNavigationChat', chat);
    }

    if (!ctx.getters.isSelectedChatCached) {
      ctx.commit('createCachedChatData', chat);
    }

    if (ctx.getters.isSelectedEmptyChat) {
      const chatData: IChatData = await this.$api.chats.getInitialChatMessages({
        chatId: ctx.state.selectedChatId,
        count: DEFAULT_INITIAL_MESSAGES_COUNT,
      });

      ctx.commit('setInitialChatData', chatData);
    } else {
      await ctx.dispatch('getSelectedChatUpdates');
    }
  },

  async getChatParticipants(ctx) {
    const response = await this.$api.chats.getChatParticipants({
      chatId: ctx.state.selectedChatId,
      count: DEFAULT_CHAT_PARTICIPANTS_COUNT,
      offset: 0,
    });

    response.items.forEach((userInfo) => ctx.commit('setCachedUserInfo', userInfo));
  },

  async getUserInfos(ctx, userIds: string[]) {
    const response = await this.$api.chats.getUserInfos(userIds);

    response.forEach((userInfo) => ctx.commit('setCachedUserInfo', userInfo));
  },

  async getSelectedChatUpdates(ctx) {
    if (ctx.getters.isSelectedEmptyChat) {
      await ctx.dispatch('getSelectedChat');

      return;
    }

    const {
      chatId,
      newMessages,
      maxMessageIndex,
      maxRowVersion,
      updatedMessages,
    } = await this.$api.chats.getChatMessageUpdates({
      chatId: ctx.getters.selectedChatData.chatId,
      maxMessageIndex: ctx.getters.selectedChatData.localMaxMessageIndex,
      minMessageIndex: ctx.getters.selectedChatData.localMinMessageIndex,
      maxRowVersion: ctx.getters.selectedChatData.localMaxRowVersion,
      count: DEFAULT_INITIAL_MESSAGES_COUNT,
    });

    ctx.commit('updateChatRemoteIndexes', {
      chatId,
      data: {
        remoteMaxRowVersion: maxRowVersion,
        remoteMaxMessageIndex: maxMessageIndex,
      },
    });

    ctx.commit('updateChatLocalIndexes', {
      chatId,
      data: {
        localMaxRowVersion: maxRowVersion,
        localMinMessageIndex: ctx.getters.selectedChatData.localMinMessageIndex,
        localMaxMessageIndex: maxMessageIndex,
      },
    });

    if (newMessages.length) ctx.commit('appendNewMessages', { chatId, messages: newMessages });

    if (updatedMessages.length)
      ctx.commit('changeUpdatedMessages', { chatId, messages: updatedMessages });
  },

  async getChatHistory(ctx) {
    const {
      chatId,
      messages,
      minMessageIndex,
      hasMoreHistory,
    } = await this.$api.chats.getChatMessagesHistory({
      chatId: ctx.state.selectedChatId,
      minMessageIndex: ctx.getters.selectedChatData.localMinMessageIndex,
      count: DEFAULT_INITIAL_MESSAGES_COUNT,
    });

    ctx.commit('prependHistoryMessages', {
      chatId,
      messages,
    });

    ctx.commit('updateChatLocalIndexes', {
      chatId,
      data: {
        localMinMessageIndex: minMessageIndex,
      },
    });

    ctx.commit('setHasChatMoreHistory', hasMoreHistory);
  },

  async sendMessage(ctx, content: IChatMessageContent) {
    await this.$api.chats.sendChatMessage({
      chatId: ctx.state.selectedChatId,
      content,
      extras: {
        tags: content.attachments?.length ? [EMessageTag.ArtSaleConversationStart] : undefined,
      },
    });
  },

  onChatNotification(ctx, message: IChatNotification) {
    if (ctx.state.isLoadingProcessing) {
      ctx.commit('setIsLoadingPending', true);
    } else {
      ctx.dispatch('processLoadUpdates', message).then(null);
    }
  },

  async processLoadUpdates(ctx, message: IChatNotification) {
    ctx.commit('setIsLoadingProcessing', true);
    do {
      ctx.commit('setIsLoadingPending', false);

      const availableTypes = [
        EChatNotificationType.NewChatMessage,
        EChatNotificationType.ChatMessageDeleted,
        EChatNotificationType.ChatUpdated,
      ];

      if (availableTypes.includes(message.type)) {
        await ctx.dispatch('onChatMessageUpdates', message);
      }
      // if during ASYNC loading onChatNotification was called
      // then isLoadingPending will be true and loading will be repeated
    } while (ctx.state.isLoadingPending === true);

    ctx.commit('setIsLoadingProcessing', false);
  },

  async onChatMessageUpdates(ctx, message: IChatNotificationNewMessage) {
    await ctx.dispatch('getNavigationChatsUpdates');

    if (ctx.state.selectedChatId === message.chatId) {
      await ctx.dispatch('getSelectedChatUpdates');
    }
  },

  async setMessageIsViewed(ctx, message: IChatMessage) {
    await this.$api.chats.markMessageAsRead(message.id);

    const updatedMessage = await this.$api.chats.getChatMessageById(message.id);

    ctx.commit('updateChatMessage', updatedMessage);

    await ctx.dispatch('getNavigationChatsUpdates');
  },

  async updateChatPermissions(ctx) {
    const { permissions } = await this.$api.chats.getChatById(ctx.state.selectedChatId);

    ctx.commit('setChatPermissions', { chatId: ctx.state.selectedChatId, permissions });
  },
};

export const mutations: MutationTree<RootState> = {
  setNavigationChatsOffset(state, navigationChatsOffset) {
    state.navigationChatsOffset = navigationChatsOffset;
  },

  prependNewNavigationChat(state, navigationChat) {
    state.navigationChats = [navigationChat, ...state.navigationChats];
  },

  setNavigationChats(state, navigationChats) {
    state.navigationChats = navigationChats;
  },

  setSelectedChatId(state, selectedChatId) {
    state.selectedChatId = selectedChatId;
  },

  clearSelectedChat(state) {
    state.selectedChatId = '';
    state.hasChatMoreHistory = true;
  },

  clearCachedUsersInfo(state) {
    state.cachedUsersInfos = {} as Record<string, IChatParticipant>;
  },

  createCachedChatData(state, chat: IChat) {
    if (!state.cachedChatsData[chat.id]) {
      set(state.cachedChatsData, chat.id, {
        chatId: chat.id,
        chatTitle: chat.title,
        localMinMessageIndex: -1,
        localMaxMessageIndex: -1,
        remoteMaxMessageIndex: chat.maxMessageIndex,
        localMaxRowVersion: -1,
        remoteMaxRowVersion: chat.maxRowVersion,
        messages: [],
        isDirectChatInterlocutorOnline: chat.isDirectChatInterlocutorOnline,
        isDirectChatInterlocutorDeleted: chat.isDirectChatInterlocutorDeleted,
        extras: chat.extras,
      });
    } else {
      set(state.cachedChatsData, chat.id, {
        ...state.cachedChatsData[chat.id],
        chatId: chat.id,
        chatTitle: chat.title,
        remoteMaxMessageIndex: chat.maxMessageIndex,
        remoteMaxRowVersion: chat.maxRowVersion,
        isDirectChatInterlocutorOnline: chat.isDirectChatInterlocutorOnline,
        extras: chat.extras,
      });
    }
  },

  setInitialChatData(state, chatData: IChatData) {
    set(state.cachedChatsData, state.selectedChatId, {
      ...state.cachedChatsData[state.selectedChatId],
      localMinMessageIndex: chatData.minMessageIndex,
      localMaxMessageIndex: chatData.maxMessageIndex,
      localMaxRowVersion: chatData.maxRowVersion,
      messages: chatData.messages,
    });
  },

  updateChatLocalIndexes(state, payload: INewLocalIndexesPayload) {
    set(state.cachedChatsData, payload.chatId, {
      ...state.cachedChatsData[payload.chatId],
      ...payload.data,
    });
  },

  updateChatRemoteIndexes(state, payload: INewRemoteIndexesPayload) {
    set(state.cachedChatsData, payload.chatId, {
      ...state.cachedChatsData[payload.chatId],
      ...payload.data,
    });
  },

  prependHistoryMessages(state, payload: IUpdatedMessagesPayload) {
    const currentChatData = state.cachedChatsData[payload.chatId];
    currentChatData.messages = [...payload.messages, ...currentChatData.messages];
  },

  appendNewMessages(state, payload: IUpdatedMessagesPayload) {
    const currentChatData = state.cachedChatsData[payload.chatId];
    currentChatData.messages = [...currentChatData.messages, ...payload.messages];
  },

  changeUpdatedMessages(state, payload: IUpdatedMessagesPayload) {
    const currentChatData = state.cachedChatsData[payload.chatId];

    currentChatData.messages = currentChatData.messages.map((message) => {
      return payload.messages.find((m) => m.id === message.id) || message;
    });
  },

  setHasChatMoreHistory(state, hasChatMoreHistory) {
    state.hasChatMoreHistory = hasChatMoreHistory;
  },

  setCachedUserInfo(state, userInfo: IChatParticipant) {
    set(state.cachedUsersInfos, userInfo.userId, userInfo);
  },
  updateChatMessage(state, message: IChatMessage) {
    const currentMessageIndex = state.cachedChatsData[state.selectedChatId].messages.findIndex(
      (m) => m.id === message.id,
    );

    set(state.cachedChatsData[state.selectedChatId].messages, currentMessageIndex, message);
  },

  setDeletedChatMessageStatus(state, messageId: String) {
    const currentChat = state.cachedChatsData[state.selectedChatId];
    const currentMessageIndex = currentChat.messages.findIndex((m) => m.id === messageId);

    set(currentChat.messages[currentMessageIndex], 'isDeleted', true);
  },

  changeCompanionBlockedStatus(state, { userId, isBlocked }: IChangeCompanionBlockedStatusPayload) {
    state.cachedUsersInfos[userId].user.isBlocked = isBlocked;
  },

  setChatPermissions(state, { chatId, permissions }: ISetChatPermissionsPayload) {
    const current = state.navigationChats.find((chat) => chat.id === chatId) as IChat;

    current.permissions = {
      ...current.permissions,
      ...permissions,
    };
  },

  setIsLoadingProcessing(state, isLoadingProcessing) {
    state.isLoadingProcessing = isLoadingProcessing;
  },

  setIsLoadingPending(state, isLoadingPending) {
    state.isLoadingPending = isLoadingPending;
  },
};
