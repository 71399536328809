














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import UiButton from '~/shared/components/UiButton/index.vue';
import { nonHandledServerErrorsStatusCodes } from '~/shared/config/non-handled-server-errors-status-codes.config';
import { INuxtError } from '~/shared/interfaces/nuxt-error.interface';
import { ENuxtError } from '~/shared/enums/nuxt-error.enum';

export default defineComponent({
  name: 'ErrorLayout',
  components: { UiButton },
  layout: 'default',
  props: {
    error: {
      type: Object as PropType<INuxtError>,
      default: () => ({}),
    },
  },
  computed: {
    isArtSaleIsNotFound(): boolean {
      return this.error.message === ENuxtError.ART_SALE_IS_NOT_FOUND;
    },

    isNonHandledStatusError(): boolean {
      return nonHandledServerErrorsStatusCodes.includes(this.error?.statusCode as number);
    },

    isTooManyRequests(): boolean {
      return this.error?.statusCode === 429;
    },

    isServiceUpdated(): boolean {
      return this.error?.statusCode === 503;
    },

    errorCode(): number | string | TranslateResult {
      if (this.isArtSaleIsNotFound) return this.$t('users.artSales.errorPage.statusCode');

      return this.error.statusCode || 404;
    },

    errorTitle(): TranslateResult {
      return this.$t(
        this.isArtSaleIsNotFound ? 'users.artSales.errorPage.title' : 'errorPage.subtitle',
      );
    },

    errorText(): TranslateResult {
      if (this.isArtSaleIsNotFound) return this.$t('users.artSales.errorPage.subtitle');
      else if (this.isTooManyRequests) return this.$t('errorPage.weGotTooManyRequestsFromYou');
      else if (this.isServiceUpdated) return this.$t('errorPage.serviceUpdating');
      else if (this.isNonHandledStatusError) return this.$t('errorPage.sorrySomethingBadHappened');

      return this.$t('errorPage.text');
    },

    buttonText(): TranslateResult {
      if (this.isNonHandledStatusError) return this.$t('common.buttons.back');

      return this.$t(this.$auth.loggedIn ? 'common.buttons.goToFeed' : 'errorPage.goToMainPage');
    },

    isNotFound(): boolean {
      return this.error?.statusCode === 404;
    },
  },
  methods: {
    onButtonClick(): void {
      if (this.isNonHandledStatusError) this.$router.go(0);
      else window.open(this.localePath(this.$auth.loggedIn ? '/feed' : '/'), '_self');
    },
  },
});
