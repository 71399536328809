import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isNil, omit } from 'ramda';
import dayjs from 'dayjs';
import { IBlogListItemForAdmin } from '~/features/blog/interfaces/blog-list-item.interface';
import { EBlogItemStatus } from '~/features/blog/enums/blog-item-status.enum';
import { IFile } from '~/features/files/interfaces/file.interface';
import { prepareStringToSlug } from '~/utils/prepare-string-to-slug';
import { DEFAULT_ADMIN_TIMEZONE } from '~/shared/constants';
import { IApi } from '~/api/interfaces/api.interface';
import { generateUniqueSlugWithCounter } from '~/features/blog/utils/generate-unique-slug-with-counter';
import { ICreateBlogPostPayload } from '~/api/interfaces/blog-admin.interface';
import { getDefaultFileState } from '~/utils/get-default-image-state';

export interface IBlogPostState {
  isStateChanged: boolean;
  originalSlug: string;
  post: IBlogListItemForAdmin;
}

const getDefaultState = (): IBlogPostState => ({
  isStateChanged: false,
  originalSlug: '',
  post: {
    id: '',
    title: '',
    isPublished: false,
    shortDescription: '',
    contentText: '',
    scheduledPublishAt: undefined,
    image: null,
    seo: {
      pageDescription: '',
      slug: '',
      pageTitle: '',
    },
  },
});

export const state = (): IBlogPostState => getDefaultState();

export type RootState = ReturnType<typeof state>;

const preparePostData = async (
  post: IBlogListItemForAdmin,
  $dayjs: typeof dayjs,
  $api: IApi,
): Promise<ICreateBlogPostPayload> => {
  const slug =
    post.seo.slug ||
    (await generateUniqueSlugWithCounter(prepareStringToSlug(post.title, true), $api));

  const data: ICreateBlogPostPayload = {
    ...omit(['id', 'publishedAt', 'scheduledPublishAt'], post),
    contentText: post.contentText || ' ',
    imageId: post.image?.id || '',
    seo: {
      pageTitle: post.seo.pageTitle || post.title,
      pageDescription: post.seo.pageDescription || post.shortDescription,
      slug,
    },
  };

  if (post.isPublished) {
    const scheduledPublishAt = isNil(post.scheduledPublishAt)
      ? $dayjs(new Date()).tz(DEFAULT_ADMIN_TIMEZONE).format()
      : $dayjs(post.scheduledPublishAt).tz(DEFAULT_ADMIN_TIMEZONE, true).format();

    data.scheduledPublishAt = scheduledPublishAt;
  }

  return data;
};

export const actions: ActionTree<RootState, RootState> = {
  async createPost(ctx): Promise<string> {
    const data = await preparePostData(ctx.state.post, this.$dayjs, this.$api);

    const { id } = await this.$api.blogAdmin.createPost(data);

    ctx.commit('setIsStatusChanged', false);

    return id;
  },

  async updatedPost(ctx): Promise<void> {
    const data = await preparePostData(ctx.state.post, this.$dayjs, this.$api);

    await this.$api.blogAdmin.updatePost({
      id: ctx.state.post.id,
      ...data,
    });

    ctx.commit('setOriginalSlug', data.seo.slug);
    ctx.commit('setIsStatusChanged', false);
  },

  async getPost(ctx, postId: string) {
    const post = await this.$api.blogAdmin.getPostListItemById(postId);
    ctx.commit('setOriginalSlug', post.seo.slug);

    ctx.commit('setPost', {
      ...post,
      scheduledPublishAt: isNil(post.scheduledPublishAt)
        ? !isNil(post.publishedAt)
          ? this.$dayjs(post.publishedAt)
              .tz(DEFAULT_ADMIN_TIMEZONE)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
              .format()
          : post.scheduledPublishAt
        : this.$dayjs(post.scheduledPublishAt)
            .tz(DEFAULT_ADMIN_TIMEZONE)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
            .format(),
    });
  },
};

export const mutations: MutationTree<RootState> = {
  setPost(state, post) {
    state.post = post;
  },

  setTitle(state, title: string) {
    state.post.title = title;

    state.isStateChanged = true;
  },

  setStatus(state, status: EBlogItemStatus) {
    state.post.isPublished = status === EBlogItemStatus.Published;
    state.isStateChanged = true;

    state.post.scheduledPublishAt = undefined;
  },

  setShortDescription(state, shortDescription: string) {
    state.post.shortDescription = shortDescription;
    state.isStateChanged = true;
  },

  setContent(state, contentText: string) {
    state.post.contentText = contentText;
    state.isStateChanged = true;
  },

  setImage(state, image: IFile) {
    state.post.image = state.post.image ?? getDefaultFileState();
    state.post.image.id = image.id;
    state.post.image.url = image.url;
    state.isStateChanged = true;
  },

  setPageTitle(state, pageTitle: string) {
    state.post.seo.pageTitle = pageTitle;
    state.isStateChanged = true;
  },

  setPageDescription(state, pageDescription: string) {
    state.post.seo.pageDescription = pageDescription;
    state.isStateChanged = true;
  },

  setCustomUrlPath(state, slug: string) {
    state.post.seo.slug = prepareStringToSlug(slug);
    state.isStateChanged = true;
  },

  setScheduledPublishAt(state, scheduledPublishAt: string | null) {
    const isEmpty = isNil(scheduledPublishAt);
    state.post.scheduledPublishAt = scheduledPublishAt as string;

    state.post.isPublished = !isEmpty;
    state.isStateChanged = true;
  },

  setIsStatusChanged(state, isStateChanged: boolean) {
    state.isStateChanged = isStateChanged;
  },

  setOriginalSlug(state, originalSlug) {
    state.originalSlug = originalSlug;
  },

  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<IBlogPostState, RootState> = {
  isNewPost(state): boolean {
    return !state.post.id?.length;
  },

  status(state): EBlogItemStatus {
    return state.post.isPublished ? EBlogItemStatus.Published : EBlogItemStatus.Draft;
  },

  isPublishDateBeforeNow: (state) => ($dayjs: typeof dayjs): boolean => {
    if (isNil(state.post.scheduledPublishAt)) return false;

    const date = $dayjs(state.post.scheduledPublishAt)
      .tz(DEFAULT_ADMIN_TIMEZONE, true)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

    return $dayjs(date).isBefore(new Date());
  },

  isSlugChanged(state): boolean {
    return state.originalSlug !== state.post.seo.slug;
  },
};
