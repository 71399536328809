import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IItemsListRequestParams,
  IItemsListWithTotalResponse,
} from '~/shared/interfaces/items-list.interface';
import {
  ICreatePortfolioImagePayload,
  ICheckItemLimitReachedResponse,
  IGetUserItemsParams,
  IGetUserPinnedItemsParams,
  IPortfolioApi,
  IReorderArtsPayload,
  ISetItemPinnedPayload,
  IUpdatePortfolioImagePayload,
} from '~/api/interfaces/portfolio.interface';
import { IPortfolioImage } from '~/features/profile/interfaces/portfolio-item.interface';
import { TPortfolioItem } from '~/features/profile/types/portfolio-item.type';

export default ($axios: NuxtAxiosInstance): IPortfolioApi => ({
  createPortfolioImage(payload: ICreatePortfolioImagePayload): Promise<IPortfolioImage> {
    return $axios.$post('/users/portfolio/createPortfolioImage', payload);
  },
  updatePortfolioImage(payload: IUpdatePortfolioImagePayload): Promise<IPortfolioImage> {
    return $axios.$post('/users/portfolio/updatePortfolioImage', payload);
  },
  deletePortfolioImage(id: string): Promise<void> {
    return $axios.$post('/users/portfolio/deletePortfolioImage', { id });
  },
  setItemPinned(payload: ISetItemPinnedPayload): Promise<void> {
    return $axios.$post('/users/portfolio/setItemPinned', payload);
  },
  reorderArts(payload: IReorderArtsPayload): Promise<void> {
    return $axios.$post('/users/portfolio/reorderItems', payload, { localError: true });
  },
  checkItemLimitReached(): Promise<ICheckItemLimitReachedResponse> {
    return $axios.$get('/users/portfolio/checkItemLimitReached');
  },
  getUserItems(params: IGetUserItemsParams): Promise<IItemsListWithTotalResponse<TPortfolioItem>> {
    return $axios.$get('/users/portfolio/getUserItems', { params });
  },
  getUserPinnedItems(
    params: IGetUserPinnedItemsParams,
  ): Promise<IItemsListWithTotalResponse<TPortfolioItem>> {
    return $axios.$get('/users/portfolio/getUserPinnedItems', { params });
  },
  getMyItems(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<TPortfolioItem>> {
    return $axios.$get('/users/portfolio/getMyItems', { params });
  },
  getMyPinnedItems(
    params: IItemsListRequestParams,
  ): Promise<IItemsListWithTotalResponse<TPortfolioItem>> {
    return $axios.$get('/users/portfolio/getMyPinnedItems', { params });
  },
  getItemById(id: string): Promise<TPortfolioItem> {
    return $axios.$get('/users/portfolio/getItemById', { params: { id } });
  },
});
