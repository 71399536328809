import dayjs from 'dayjs'

import 'dayjs/locale/en'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(advancedFormat)

dayjs.locale('en')

dayjs.tz.setDefault('America/New_York')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
