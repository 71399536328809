export enum EGcpActionType {
  Registration = 'registration',
  Login = 'login',
  Post = 'post',
  Like = 'like',
  Save = 'save',
  Comment = 'comment',
  Payment = 'payment',
  DeleteAccount = 'delete_account',
  Unsubscribe = 'unsubscribe',
  Resubscribe = 'resubscribe',
}
