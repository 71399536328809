import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IArtSalesApi,
  IArtSalesGeneralTermsAcceptanceStatus,
  ICreateArtSalePayload,
  ICreateArtSalePublishTo,
  IGetAllArtSaleMaterialTypesResponse,
  IGetPostsLimitResponse,
  IStartChatAsCollectorPayload,
  IStartChatAsCollectorResponse,
  IUpdateArtSalePayload,
} from '~/api/interfaces/art-sales.interface';
import { IArtSale } from '~/features/art-sales/interfaces/art-sale.interface';

export default ($axios: NuxtAxiosInstance): IArtSalesApi => ({
  getAllArtSaleMaterialTypes(): Promise<IGetAllArtSaleMaterialTypesResponse> {
    return $axios.$get('/artSales/getAllArtSaleMaterialTypes');
  },

  getArtSaleById(id: string): Promise<IArtSale> {
    return $axios.$get('/artSales/getArtSaleById', { params: { id }, localError: true });
  },

  createArtSale(payload: ICreateArtSalePayload): Promise<IArtSale> {
    return $axios.$post('/artSales/createArtSale', payload);
  },

  updateArtSale(payload: IUpdateArtSalePayload): Promise<void> {
    return $axios.$post('/artSales/updateArtSale', payload);
  },

  deleteArtSale(id: string): Promise<void> {
    return $axios.$post('/artSales/deleteArtSale', { id });
  },

  startChatAsCollector(
    payload: IStartChatAsCollectorPayload,
  ): Promise<IStartChatAsCollectorResponse> {
    return $axios.$post('/artSales/startChatAsCollector', payload, { localError: true });
  },

  acceptArtistTerms(chatId: string): Promise<void> {
    return $axios.$post('/artSales/acceptArtistTerms', { chatId });
  },

  getArtSalePublishToById(id: string): Promise<ICreateArtSalePublishTo> {
    return $axios.$get('/artSales/getArtSalePublishToById', { params: { id } });
  },

  getArtistGeneralTermsStatus(): Promise<IArtSalesGeneralTermsAcceptanceStatus> {
    return $axios.$get('/artSales/getArtistGeneralTermsStatus');
  },

  acceptArtistGeneralTerms(termsVersion: string): Promise<void> {
    return $axios.$post('/artSales/acceptArtistGeneralTerms', { termsVersion });
  },

  getPostsLimits(): Promise<IGetPostsLimitResponse> {
    return $axios.$get('/artSales/getPostsLimits');
  },
});
