
import { defineComponent } from '@nuxtjs/composition-api';
import { MessageBox } from 'element-ui';
import UiButton from '~/shared/components/UiButton/index.vue';

export default defineComponent({
  name: 'SessionExpiredModal',
  mounted() {
    const h = this.$createElement;

    MessageBox({
      message: h('div', [
        h('div', { class: 'session-expired-modal__content' }, [
          h('div', { class: 'session-expired-modal__icon-wrap' }, [
            h('svg', { class: 'tw-w-7 tw-h-7 tw-fill-current' }, [
              h('use', {
                attrs: {
                  'xlink:href': require('~/assets/sprite/gen/icons.svg').default + '#i-clock',
                  href: require('~/assets/sprite/gen/icons.svg').default + '#i-clock',
                },
              }),
            ]),
          ]),
          h(
            'p',
            { class: 'text--h4 modal__title tw-mt-8 tw-mb-2.5' },
            this.$t('common.sessionExpiredModal.title') as string,
          ),
          h(
            'p',
            { class: 'tw-text-base' },
            this.$t('common.sessionExpiredModal.description') as string,
          ),
        ]),
        h(
          UiButton,
          {
            class: 'session-expired-modal__button',
            attrs: {
              type: 'bg-red-grad-1',
            },
            on: {
              click: this.moveToLogin,
            },
          },
          this.$t('common.buttons.logIn') as string,
        ),
      ]),
      customClass: 'session-expired-modal',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      showClose: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  },
  destroyed() {
    MessageBox.close();
  },
  methods: {
    moveToLogin() {
      window.open(this.localePath(`/login?redirectTo=${this.$route.fullPath}`), '_self');
    },
  },
  render(h) {
    return h('div');
  },
});
