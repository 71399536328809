





































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { contactUsSectionItemsList } from '~/shared/config/contact-us-section-items-list.config';
import ContactUsModal from '~/shared/components/modals/ContactUsModal.vue';
import { CONTACT_US_MESSENGER_LINK } from '~/shared/constants';

type TContactUsSection = 'default' | 'modal' | 'block-modal';

export default defineComponent({
  name: 'ContactUsSection',
  components: {
    ContactUsModal,
  },
  props: {
    type: {
      type: String as PropType<TContactUsSection>,
      default: 'default',
    },
  },
  setup() {
    return {
      contactUsSectionItemsList,
    };
  },
  computed: {
    contactUsMessengerLink(): string {
      return CONTACT_US_MESSENGER_LINK;
    },
    isTypeDefault(): boolean {
      return this.type === 'default';
    },
    isTypeBlockModal(): boolean {
      return this.type === 'block-modal';
    },
  },
  methods: {
    openContactUsModal(): void {
      (this.$refs.contactUs as InstanceType<typeof ContactUsModal>).show();
    },
  },
});
