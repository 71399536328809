import { filter, includes } from 'ramda';

export const containsOtherLetters = (str: string, allowedLetters: string[]): boolean => {
  const lowerStr = str.toLowerCase();
  const strChars = lowerStr.split('');

  const filteredChars = filter((char: string) => !includes(char, allowedLetters), strChars);

  return filteredChars.length > 0;
};
