var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label)?_c('label',{staticClass:"input__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('el-input',_vm._g(_vm._b({staticClass:"input",class:[
      _vm.$attrs.class,
      _vm.isError && 'error',
      _vm.isValid && 'success',
      { 'input--max-length-reached': _vm.isMaxLengthReached },
      _vm.inputClass,
      `input--${_vm.uiType}`,
    ],attrs:{"value":_vm.value,"type":_vm.typeToSet,"disabled":_vm.disabled,"clearable":_vm.clearable,"maxlength":_vm.maxlength},on:{"input":_vm.onInput},scopedSlots:_vm._u([(_vm.prepend)?{key:"prepend",fn:function(){return [_vm._v(_vm._s(_vm.prepend))]},proxy:true}:null],null,true)},'el-input',_vm.$attrs,false),_vm.bindListeners),[(_vm.withLeftIcon)?_c('svg-icon',{staticClass:"input__prefix-icon",attrs:{"slot":"prefix","name":_vm.icon},slot:"prefix"}):_vm._e(),_vm._v(" "),_vm._v(" "),(_vm.$slots.suffix)?_c('template',{slot:"suffix"},[_vm._t("suffix")],2):(_vm.withRightIcon && !_vm.$slots.suffix)?_c('svg-icon',{staticClass:"input__suffix-icon",attrs:{"slot":"suffix","name":_vm.icon},slot:"suffix"}):_vm._e()],2),_vm._v(" "),(_vm.message)?_c('p',{staticClass:"input__message",class:{
      'tw-text-secondary-400': _vm.isError === true,
      [`input__message--${_vm.hintPlacement}`]: !_vm.isError,
    }},[_vm._v("\n    "+_vm._s(_vm.message)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }