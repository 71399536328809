













































import { defineComponent, Ref, ref } from '@nuxtjs/composition-api';
import UiModal from '~/shared/components/modals/UiModal.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import FormInput from '~/shared/components/FormInput.vue';
import { VForm } from '~/shared/types/form.type';
import { useForgotPassword } from '~/shared/composable/useForgotPassword';
import { ESignUpFlow } from '~/features/auth/enums/sign-up-flow.enum';
import { EToastType } from '~/shared/enums/toast.enum';

export default defineComponent({
  name: 'ForgotPasswordModal',
  components: {
    UiButton,
    UiModal,
    FormInput,
  },
  setup() {
    const form: Ref<VForm> = ref(null as any);

    const { onValidate, validate, formData, rules, handleSubmit } = useForgotPassword(
      form,
      ESignUpFlow.Modal,
    );

    return { onValidate, validate, formData, rules, handleSubmit, form };
  },
  methods: {
    show(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).show();
    },
    hide(): void {
      (this.$refs.modal as InstanceType<typeof UiModal>).hide();

      this.clearData();
    },

    clearData(): void {
      (this.$refs.form as VForm).resetFields();
    },

    async onSubmit(): Promise<void> {
      const isSubmitted = await this.handleSubmit();

      if (!isSubmitted) return;

      this.$showToast(this.$t('notifications.restorePasswordLinkWasSentToYourEmail'), {
        type: EToastType.Secondary,
      });

      this.hide();
    },
  },
});
