import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IFeedApi,
  IGetFeedPayload,
  IGetFeedResponse,
  IGetUserPostsParams,
  IGetUserPostsResponse,
} from '~/api/interfaces/feed.interface';
import { IUserPost } from '~/features/profile/interfaces/user-post.interface';
import { TUserPostItemForFeed } from '~/features/profile/types/user-post-item-for-feed.type';

export default ($axios: NuxtAxiosInstance): IFeedApi => ({
  getFeed(payload: IGetFeedPayload): Promise<IGetFeedResponse> {
    return $axios.$post('/feed/getFeed', payload);
  },

  getUserPosts(payload: IGetUserPostsParams): Promise<IGetUserPostsResponse> {
    return $axios.$post('/feed/getUserPosts', payload);
  },

  getPostById(id: string): Promise<IUserPost> {
    return $axios.$get('/feed/getPostById', { params: { id } });
  },

  getFeedItemByPostId(id: string): Promise<TUserPostItemForFeed> {
    return $axios.$get('/feed/getFeedItemByPostId', { params: { id } });
  },

  getDemoFeed(payload: IGetFeedPayload): Promise<IGetFeedResponse> {
    return $axios.$post('/feed/demo/getFeed', payload);
  },
});
