import { EFiltersCategory } from '~/features/users/enums/filters-category.enum';
import { ETagCondition } from '~/features/users/enums/tag-condition.enum';
import { TTypedFilterForComponent } from '~/features/users/types/typed-filters.type';

export const filterStructuresMap: Record<EFiltersCategory, TTypedFilterForComponent> = {
  [EFiltersCategory.Tag]: {
    category: EFiltersCategory.Tag,
    condition: (null as unknown) as ETagCondition,
    selectedTags: [],
  },
  [EFiltersCategory.Label]: {
    category: EFiltersCategory.Label,
    condition: (null as unknown) as ETagCondition,
    labelIds: [],
  },
  [EFiltersCategory.Offer]: {
    category: EFiltersCategory.Offer,
    condition: (null as unknown) as ETagCondition,
    offerIds: [],
  },
  [EFiltersCategory.Workshop]: {
    category: EFiltersCategory.Workshop,
    condition: (null as unknown) as ETagCondition,
    workshopIds: [],
  },
};
