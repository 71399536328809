import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import {
  ART_CLUB_SALES_LANDING_LINK,
  MASTERY_PROGRAM_SALES_LANDING_LINK,
} from '~/shared/constants';

export const guestMenuItems: IMenuItem[] = [
  {
    title: 'Social',
    icon: 'user-menu/feed',
    to: '/feed',
    vendorIconPath: require('~/assets/img/icons/feed.svg'),
    vendorActiveIconPath: require('~/assets/img/icons/feed-active.svg'),
    showInMobileBottomMenu: true,
    isFeedLink: true,
    showInLanding: true,
  },
  {
    title: 'Communities',
    vendorIconPath: require('~/assets/img/icons/communities.svg'),
    vendorActiveIconPath: require('~/assets/img/icons/communities-active.svg'),
    to: '/communities',
    showInLanding: true,
  },
  {
    title: 'Education',
    icon: 'user-menu/school',
    href: 'https://www.milanartinstitute.com/online-learning',
    showInLanding: true,
  },
  {
    title: 'Mastery Program',
    icon: 'user-menu/mastery-program',
    href: MASTERY_PROGRAM_SALES_LANDING_LINK,
    showInLanding: false,
  },
  {
    title: 'Art Club',
    icon: 'user-menu/art-club',
    href: ART_CLUB_SALES_LANDING_LINK,
    showInMobileBottomMenu: true,
    showInLanding: true,
  },
  {
    title: 'Art Store',
    icon: 'user-menu/art-store',
    href: 'https://www.milanartstore.com/',
    showInLanding: true,
  },
  {
    title: 'Art Gallery',
    icon: 'user-menu/gallery',
    href: 'https://artsocial.gallery/',
    showInLanding: true,
  },
  {
    title: 'Resources',
    icon: 'user-menu/free-resources',
    showInMobileBottomMenu: true,
    showInLanding: true,
    items: [
      {
        title: 'Live Workshop',
        to: '/workshop',
      },
      {
        title: 'Blog',
        to: '/blog',
      },
    ],
  },
  // {
  //   title: 'Auctions',
  //   icon: 'user-menu/auctions',
  //   href: 'https://auction.artsocial.com/',
  // },
];

export const guestMenuItemsForSocialSidebar: IMenuItem[] = guestMenuItems.map((item) =>
  item.isFeedLink ? { ...item, title: 'Feed' } : item,
);

export const guestBottomBarLinks: IMenuItem[] = guestMenuItems.filter(
  (item) => item.showInMobileBottomMenu,
);

export const guestMenuItemsDesktop = [
  ...guestMenuItems.filter((item) => item.showInLanding),
  {
    title: 'About',
    to: '/about',
  },
];
